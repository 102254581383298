const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "EPIRB_RETURN_LINK_SERVICE_TYPES_RETRIEVED":
            return action.epirbLinkServiceFeatures;

        default:
            return state;
    }
}
