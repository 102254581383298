import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { POST } from "../adapters/xhr";

export default function (licenceApplicationId, paymentAmount) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const model = {
            id: 0,
            transId: null,
            licenceApplicationId: Number(licenceApplicationId) || 0,
            paymentAmount: paymentAmount,
            created: new Date(),
            createdBy: ""
        };

        POST("licenceApplication/NewLicencePayment", model)
            .then((response) => {
                if (response.status === 200) {
                    //LICENCE_PAYMENT_RETRIEVED
                    dispatch({
                        type: "LICENCE_PAYMENT_RETRIEVED",
                        data: response.data.licencePayment
                    });
                }
                dispatch(CreateDataRetrievalEndedAction());
            })
            .catch((response) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
