import axios from "axios";

import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreatePostLicenceIsValidAction from "./CreatePostLicenceIsValidAction";
import CreateLicencePaymentAction from "./CreateLicencePaymentAction";

export default function (licenceApplicationId, paymentAmount) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());
        const { reviewAndDeclaration, requiredEquipment, requiredEpirbs } = getState();

        const newReviewAndDeclarationDetail = {
            licenceApplicationId: Number(licenceApplicationId) || 0,
            created: new Date(),
            updated: new Date(),
            declarationAccepted: reviewAndDeclaration.declarationsCheckbox || false
        };

        const hasRequiredEquipment = requiredEquipment != null;
        const outstanding = requiredEquipment?.outstandingRequiredEquipment || [];
        const outstandingGroups = requiredEquipment?.outstandingRequiredEquipmentGrouped || [];

        const hasRequiredEpirbs = requiredEpirbs != null;
        const outstandingEpirbs = requiredEpirbs?.outstandingEpirbs || [];

        const isNotValid = (hasRequiredEquipment && (outstanding.length > 0 || outstandingGroups.length > 0)) ||
            hasRequiredEpirbs && outstandingEpirbs.length > 0;

        //authService.getAccessToken().then((token) => {
                axios({
                    method: "post",
                    url: "applicantDeclaration", 
                    //headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    data: newReviewAndDeclarationDetail
                })
                    .then((data) => {
                        dispatch(CreatePostLicenceIsValidAction(licenceApplicationId, !isNotValid));
                    })
                    .then((data) => {
                        dispatch(CreateLicencePaymentAction(licenceApplicationId, paymentAmount));
                    })
                    .then((data) => {
                        dispatch(CreateDataRetrievalEndedAction());
                    })
                    .catch((error) => {
                        dispatch(CreateDataRetrievalEndedAction());
                    });
            //});
    };
}
