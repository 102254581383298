import React, { Component } from "react";
import { withTranslation } from "react-i18next"; 
import { Container, Navbar } from "reactstrap";
import EmergencyServicesLoginMenu from "./EmergencyServicesLoginMenu"; 
import "../NavMenu/NavMenu.css";

class EmergencyServicesNavMenu extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        
        this.state = {
            collapsed: true,
            userIsAuthenticated: false
        };
    }

    componentDidMount() {
        const that = this;

        window.userManager.getUser().then(function(user) {
            that.setState({
                userIsAuthenticated: user != null
            });
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    doLogin() {
        window.userManager.signinRedirect();
    }

    doLogout() {
        window.userManager.signoutRedirect();
    }

    render() {
        const t = this.props.t;
        
        return (
            <header className="ShipsRadioHeader">  
                <Navbar className="navbar-expand-md border-bottom box-shadow mb-3" dark style={{ backgroundColor: "#004D44"}}>
                    <Container>
                        <div className="HarpImage mx-1" > </div>
                        <EmergencyServicesLoginMenu userIsAuthenticated={this.state.userIsAuthenticated} t={t} />
                    </Container>  
                </Navbar>  
            </header >
        );
    }
}

const languageAwareComponent = withTranslation()(EmergencyServicesNavMenu);
export default languageAwareComponent;