import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LinkButton from "./LinkButton";
import { withTranslation } from "react-i18next";
import PageHeader from "./PageHeader";
import Breadcrumb from "./Breadcrumb";

import CreateGetRequiredEquipmentAction from "../store/actionCreators/CreateGetRequiredEquipmentAction";
import CreateUpdateEquipmentTypeCategoryAction from "../store/actionCreators/CreateUpdateEquipmentTypeCategoryAction";
import CreateUpdateIsMedApprovedAction from "../store/actionCreators/CreateUpdateIsMedApprovedAction";

class VesselEquipmentDetails extends Component {
   
    componentDidMount() {
        this.props.getRequiredEquipment(this.props.licenceApplicationId);
    }

    render() {
        const { t } = this.props;

        const vesselTypes = {
            PleasureVessel: 1,
            FishingVessel: 2,
            PassengerBoat: 3,
            PassengerShip: 4,
            MerchantVessel: 5,
            NavalShip: 6,
            RescueVessel: 7
        };

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6,
            TransferInitiated: 7,
            Transferred: 8
        };

        const EquipmentTypeCategories = {
            AisSart: 1,
            AisTransponder: 2,
            ClassMmob: 3,
            MfHf: 4,
            NonGmdss: 5,
            Plb: 6,
            HandheldVhf: 7,
            Radar: 8,
            Sart: 9,
            Vhf: 10,
            GNSS: 11,
            AeronauticalVHF: 12,
            GMDSS: 13,
            Other: 14,
            HandheldUhf: 15
        };

        //cancelled, TransferInitiated or transferred Status
        const isReadOnlyPage = this.props.licenceApplicationStatusTypeId === LicenceStatus.Cancelled
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.TransferInitiated
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.Transferred;

        const isLicenceInProgress = this.props.licenceApplicationStatusTypeId === LicenceStatus.InProgress;

        const isPassengerBoat = Number(this.props.vesselTypeId) === vesselTypes.PassengerBoat;

        const isShipOwerWithNonIssuedLicence = this.props.role === "Ship Owner" && (this.props.licenceApplicationStatusTypeId == LicenceStatus.InProgress
            || this.props.licenceApplicationStatusTypeId == LicenceStatus.ReadyToReview);

        return (
            <React.Fragment>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    licenceList={t("AdminLandingPage.LicenceList")}
                    dashboard={t("Common.Dashboard")}
                    parentLink=""
                    parentPageName=""
                    pageName={t("Equipments.Title")} />

                <PageHeader
                    pageTitle={t("Equipments.Title")}
                    vesselLabel={t("OwnerLandingPage.VesselName")}
                    vesselName={this.props.vesselName}
                    isShipOwerWithNonIssuedLicence={isShipOwerWithNonIssuedLicence}
                    mmsiLabel={t("OwnerLandingPage.MMSINumber")}
                    mmsiNumber={this.props.mmsiNumber}
                    callSignLabel={t("OwnerLandingPage.CallSign")}
                    callSignNumber={this.props.callSignNumber}
                    pageDescription={t("Equipments.Details")}
                    requiredField="" />
                
                {
                    this.props.requiredEquipment && this.props.requiredEquipment.outstandingRequiredEquipment == null
                    && this.props.requiredEquipment.outstandingRequiredEquipmentGrouped == null
                    && this.props.requiredEquipment.addedEquipmentMatchingRequirement == null
                    /*&& this.props.vesselTypeId !== vesselTypes.PleasureVessel*/
                    &&
                    <div className="col-md-12 bg-light p-2 mb-2 rounded-lg bg-customGood">
                        <p className="h6" tabIndex="0">{t("Equipments.NoOutstandingEquipmentTitle", { vesselHeader: this.props.requiredEquipment.vesselHeader ?? "" })}</p>
                        {
                            isPassengerBoat &&
                            <p className="h6">{t("Equipments.NoOutstandingPassengerBoatEquipment")}</p>
                        }
                    </div>
                }

                {
                    this.props.requiredEquipment && ((this.props.requiredEquipment.outstandingRequiredEquipment &&
                    this.props.requiredEquipment.outstandingRequiredEquipment.length > 0) ||
                    (this.props.requiredEquipment.outstandingRequiredEquipmentGrouped &&
                    this.props.requiredEquipment.outstandingRequiredEquipmentGrouped.length > 0))
                    &&
                <div className="col-md-12 bg-light p-2 rounded-lg bg-customWarning">
                    <p className="h5" tabIndex="0">{t("Equipments.OutstandingEquipmentTitle1")}</p>
                    <p className="h6" tabIndex="0">{t("Equipments.OutstandingEquipmentTitle2", { vesselHeader: this.props.requiredEquipment.vesselHeader })}:</p>
                    <ul aria-label="Outstanding Equipment">
                        {
                    this.props.requiredEquipment.outstandingRequiredEquipment &&
                    this.props.requiredEquipment.outstandingRequiredEquipment.length > 0 &&
                    <span>
                        {
                        this.props.requiredEquipment.outstandingRequiredEquipment.map(equipment => (
                            <li key={equipment.id}>
                                {
                                    isReadOnlyPage &&
                                    <span>
                                        {equipment.minimumQuantityRequired > 1 && <span>{equipment.minimumQuantityRequired} x </span>}
                                        {
                                            equipment.equipmentTypeCategory.id === EquipmentTypeCategories.Plb &&
                                            <span>{t("Equipments.Plb")}</span>
                                        }
                                        {
                                            equipment.equipmentTypeCategory.id !== EquipmentTypeCategories.Plb &&
                                            equipment.equipmentTypeCategory.name
                                        }
                                        {
                                            equipment.equipmentTypeCategory.id === EquipmentTypeCategories.Radar && equipment.radarBandTypeId === 1 &&
                                            <span> (9 GHz, X-Band)</span>
                                        }
                                        {
                                            equipment.equipmentTypeCategory.id === EquipmentTypeCategories.Radar && equipment.radarBandTypeId === 2 &&
                                            <span> (3 GHz, S-Band)</span>
                                        }
                                        {equipment.dscRequired && <span> {t("Equipments.DSCEnabled") }.</span>}
                                        {equipment.isMedApproved && <span> {t("Equipments.MedApproved")}.</span>}
                                    </span>
                                }
                                {
                                    !isReadOnlyPage &&
                                    <Link
                                        className="btn btn-link listItem-Button"
                                        to="/apply/add-vessel-equipment"
                                        onClick={() => {
                                            this.props.updateEquipmentTypeCategory(equipment.equipmentTypeCategory.id, equipment.isMedApproved, equipment.radarBandTypeId);
                                        }}>{t("Common.ButtonText.ClickHereToAdd")}&nbsp;
                                        { equipment.minimumQuantityRequired > 1 && <span>{equipment.minimumQuantityRequired} x </span>}
                                        {
                                            equipment.equipmentTypeCategory.id === EquipmentTypeCategories.Plb &&
                                            <span>{t("Equipments.Plb")}</span>
                                        }
                                        {
                                            equipment.equipmentTypeCategory.id !== EquipmentTypeCategories.Plb &&
                                            equipment.equipmentTypeCategory.name
                                        }
                                        {
                                            equipment.equipmentTypeCategory.id === EquipmentTypeCategories.Radar && equipment.radarBandTypeId === 1 &&
                                            <span> (9 GHz, X-Band)</span>
                                        }
                                        {
                                            equipment.equipmentTypeCategory.id === EquipmentTypeCategories.Radar && equipment.radarBandTypeId === 2 &&
                                            <span> (3 GHz, S-Band)</span>
                                        }
                                        {equipment.dscRequired && <span> {t("Equipments.DSCEnabled")}.</span>}
                                        {equipment.isMedApproved && <span> {t("Equipments.MedApproved")}.</span>}
                                    </Link>
                                }
                            </li>
                        ))
                    }
                    </span>
                }

                    {
                    this.props.requiredEquipment.outstandingRequiredEquipmentGrouped &&
                    this.props.requiredEquipment.outstandingRequiredEquipmentGrouped.length > 0 &&
                    <span>
                        {
                    this.props.requiredEquipment.outstandingRequiredEquipmentGrouped.map(groupedEquipment => (
                        <li key={groupedEquipment.requiredEquipment[0].equipmentTypeCategoryGroupId}>
                            {
                                groupedEquipment.minimumQuantityRequired === 1 &&
                                <span>{groupedEquipment.equipmentGroup
                                    .split("DSCEnabled").join(t("Equipments.DSCEnabled"))
                                    .split("MEDApproved").join(t("Equipments.MedApproved"))
                                }
                                </span>
                            }
                            {
                                groupedEquipment.minimumQuantityRequired > 1 &&
                                <span>{t("Equipments.SelectEquipmentFrom",
                                    {
                                        selectNumber: groupedEquipment.minimumQuantityRequired,
                                        equipmentGroup: groupedEquipment.equipmentGroup
                                            .split("DSCEnabled").join(t("Equipments.DSCEnabled"))
                                            .split("MEDApproved").join(t("Equipments.MedApproved"))
                                    })}:
                                </span>
                            }
                            <ul aria-label="Outstanding Equipment - Select From group">
                                {
                                    groupedEquipment.requiredEquipment.map(equip => (
                                        <li key={equip.id}>
                                            {
                                                isReadOnlyPage &&
                                                <span>
                                                    {equip.equipmentTypeCategory.name}
                                                    {equip.dscRequired && <span> {t("Equipments.DSCEnabled")}.</span>}
                                                    {equip.isMedApproved && <span> {t("Equipments.MedApproved")}.</span>}
                                                </span>
                                            }
                                            {
                                                !isReadOnlyPage &&
                                                <Link
                                                    className="btn btn-link listItem-Button"
                                                    to="/apply/add-vessel-equipment"
                                                    onClick={() => {
                                                        this.props.updateEquipmentTypeCategory(equip.equipmentTypeCategory.id, equip.isMedApproved);
                                                    }}>{t("Common.ButtonText.ClickHereToAdd")}&nbsp;
                                                    {equip.equipmentTypeCategory.name}
                                                    {equip.dscRequired && <span> {t("Equipments.DSCEnabled")}.</span>}
                                                    {equip.isMedApproved && <span> {t("Equipments.MedApproved")}.</span>}
                                                </Link>
                                            }
                                        </li>))
                                }
                            </ul>
                        </li>
                        ))
                    }
                    </span>
                }
                    </ul>
                </div>
                    
                }
                {
                    this.props.requiredEquipment && this.props.requiredEquipment.vesselHeader &&
                    this.props.vesselTypeId !== vesselTypes.PleasureVessel &&
                    <p className="h6 my-2" tabIndex="0">{t("Equipments.RequiredEquipmentNote", { vesselHeader: this.props.requiredEquipment.vesselHeader })}</p>
                }

                {
                    this.props.requiredEquipment && this.props.requiredEquipment.addedEquipmentMatchingRequirement &&
                    this.props.requiredEquipment.addedEquipmentMatchingRequirement.length > 0 &&
                    <div className="col-md-12 bg-light p-2 rounded-lg bg-customGood">
                        <div className="h5" tabIndex="0">{t("Equipments.AddedEquipmentTitle1")}</div>
                        <span className="h6" tabIndex="0">{t("Equipments.AddedEquipmentTitle2")}:</span>
                        <ul aria-label="Required Equipment Added">
                            {
                                this.props.requiredEquipment.addedEquipmentMatchingRequirement.map(equipment => (
                                    <li key={equipment.id}>
                                        <LinkButton className="btn btn-link listItem-Button"
                                            onClick={() => {
                                                this.props.editEquipment(equipment);
                                            }}
                                            to="/apply/add-vessel-equipment"
                                        >{t("Common.ButtonText.View")}&nbsp;
                                            {
                                                equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.Plb &&
                                                <span>{t("Equipments.Plb")}</span>
                                            }
                                            {
                                                equipment.equipmentType.equipmentTypeCategory.id !== EquipmentTypeCategories.Plb &&
                                                equipment.equipmentType.equipmentTypeCategory.name
                                            }
                                            {
                                                equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.Radar && equipment.radarBandTypeId === 1 &&
                                                <span> (9 GHz, X-Band)</span>
                                            }
                                            {
                                                equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.Radar && equipment.radarBandTypeId === 2 &&
                                                <span> (3 GHz, S-Band)</span>
                                            }
                                            {equipment.dscEnabled && <span> {t("Equipments.DSCEnabled")}.</span>}
                                            {(equipment.equipmentType != null && equipment.equipmentType.medApproved || equipment.medApproved) && <span> {t("Equipments.MedApproved")}.</span>}
                                            {" - "}
                                            {equipment.equipmentType.name !== "Other" && equipment.equipmentType.name}
                                            {
                                                equipment.equipmentType.name === "Other" &&
                                                <span>
                                                    {equipment.make} - {equipment.model}
                                                </span>
                                            }
                                        </LinkButton>
                                    </li>
                            ))
                            }
                        </ul>
                    </div>
                }

                {
                    this.props.requiredEquipment.additionalAddedEquipment && this.props.requiredEquipment.additionalAddedEquipment.length > 0 &&
                    <div className="col-md-12 bg-light p-2 rounded-lg bg-customNeutral mt-2">
                        <div className="h5" tabIndex="0">{t("Equipments.OtherEquipmentTitle1")}</div>

                        <ul aria-label="Additional Equipment Added">
                            {
                                this.props.requiredEquipment.additionalAddedEquipment.map(equipment => (
                                    <li key={equipment.id}>
                                        <LinkButton
                                            className="btn btn-link listItem-Button"
                                            onClick={() => {
                                                this.props.editEquipment(equipment);
                                            }}
                                            to="/apply/add-vessel-equipment"
                                        >{t("Common.ButtonText.View")}&nbsp;
                                            {
                                                (equipment.equipmentType?.equipmentTypeCategory.id || 0) === EquipmentTypeCategories.Plb &&
                                                <span>{t("Equipments.Plb")}</span>
                                            }
                                            {
                                                (equipment.equipmentType?.equipmentTypeCategory.id || 0) !== EquipmentTypeCategories.Plb &&
                                                equipment.equipmentType?.equipmentTypeCategory.name || equipment.otherEquipmentType
                                            }
                                            {
                                                (equipment.equipmentType?.equipmentTypeCategory.id || 0) === EquipmentTypeCategories.Radar && equipment.radarBandTypeId === 1 &&
                                                <span> (9 GHz, X-Band)</span>
                                            }
                                            {
                                                (equipment.equipmentType?.equipmentTypeCategory.id || 0) === EquipmentTypeCategories.Radar && equipment.radarBandTypeId === 2 &&
                                                <span> (3 GHz, S-Band)</span>
                                            }
                                            {equipment.dscEnabled && <span> {t("Equipments.DSCEnabled")}.</span>}
                                            {(equipment.equipmentType != null && (equipment.equipmentType.medApproved || equipment.medApproved)) && <span> {t("Equipments.MedApproved")}.</span>}
                                            {" - "}
                                            {(equipment.equipmentType != null && equipment.equipmentType.name !== "Other") && equipment.equipmentType.name}
                                            {(equipment.equipmentType == null || equipment.equipmentType.name === "Other") &&
                                                <span>
                                                    {equipment.make} - {equipment.model}
                                                </span>
                                            }
                                        </LinkButton>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                }

                {
                    this.props.requiredEquipment &&
                    (this.props.requiredEquipment.outstandingRequiredEquipment == null || this.props.requiredEquipment.outstandingRequiredEquipment.length === 0) &&
                    (this.props.requiredEquipment.outstandingRequiredEquipmentGrouped == null || this.props.requiredEquipment.outstandingRequiredEquipmentGrouped.length === 0) &&
                    this.props.requiredEquipment.addedEquipmentMatchingRequirement &&
                    this.props.requiredEquipment.addedEquipmentMatchingRequirement.length > 0 &&
                    (this.props.licenceApplicationStatusTypeId === LicenceStatus.InProgress || this.props.licenceApplicationStatusTypeId === LicenceStatus.ReadyToReview) &&
                    <div className="col-md-12 bg-light p-2 rounded-lg bg-customGood">
                        <p className="h6" tabIndex="0">{t("Equipments.AllRequiredEquipmentAddedTitle", { vesselHeader: this.props.requiredEquipment.vesselHeader ?? "" })}</p>
                    </div>
                }

                <div className="h6 mt-2" role="heading" aria-level="2" id="ComplianceTitle" tabIndex="0">
                    {t("Equipments.ComplianceTitle")}
                </div>

                <div className="d-grid gap-2 d-md-flex justify-content-md-end my-2">
                    {
                        !isReadOnlyPage && 
                        this.props.vesselTypeId !== vesselTypes.PleasureVessel &&
                        <Link
                            id="addOtherEquipmentButton"
                            className="btn btn-primary me-2"
                            onClick={() => {
                                this.props.clearEquipment();
                            }}
                            to="/apply/add-vessel-equipment">{t("Equipments.AddAnyOtherEquipment")}
                        </Link>
                    }
                    {
                        !isReadOnlyPage &&
                        this.props.vesselTypeId === vesselTypes.PleasureVessel &&
                        <Link
                            id="addOtherEquipmentButton"
                            className="btn btn-primary me-2"
                            onClick={() => {
                                this.props.clearEquipment();
                            }}
                            to="/apply/add-vessel-equipment">{t("Equipments.AddEquipment")}
                        </Link>
                    }
                    {
                        !isLicenceInProgress &&
                        <Link
                            id="epirbDetailsButton"
                            className="btn btn-primary"
                            to={this.props.parentRoute + "/epirb-list/" + this.props.licenceApplicationId}>
                            {t("Common.ButtonText.Next")}
                        </Link>
                    }
                    {
                        isLicenceInProgress &&
                        <Link
                            id="btnReview"
                            className="btn btn-primary"
                            to={this.props.parentRoute + "/review-declarations/" + this.props.licenceApplicationId}
                        >
                            {t("Common.ButtonText.Review")}

                        </Link>
                    }
                </div>

            </React.Fragment>
        ); 
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        role: state.currentUserInfo.role || "",

        requiredEquipment: state.requiredEquipment,
        licenceApplicationId: state.currentApplication.id || 0,
        licenceApplicationStatusTypeId: state.currentApplication.licenceApplicationStatusTypeId || 0,
        applicationTypeId: state.currentApplication.applicationTypeId || 1,
        vesselId: state.currentVessel.id || 0,
        vesselTypeId: state.currentVessel.vesselTypeId || "",

        vesselName: state.currentVessel.name || "",
        callSignNumber: state.currentVessel.callSignNumber || "",
        mmsiNumber: state.currentVessel.mmsiNumber || ""
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getRequiredEquipment: (applicationId) => {
            dispatch(CreateGetRequiredEquipmentAction(applicationId));
        },
        editEquipment: (equipment) => {

            const equipmentTypeCategoryId = equipment?.equipmentType?.equipmentTypeCategoryId || 14;

            dispatch({
                type: "VESSEL_EQUIPMENT_VHF_DETAILS_RETRIEVED",
                vesselEquipmentVhfDetail: equipment
            });
            dispatch({
                type: "VESSEL_EQUIPMENT_DETAILS_EQUIPMENT_CATEGORY_ID_UPDATED",
                data: equipmentTypeCategoryId
            });
            dispatch({
                type: "VESSEL_EQUIPMENT_DETAILS_IS_NEW_AMENDMENT",
                data: equipment.isNewAmendment
            });
            if (equipmentTypeCategoryId === 14 || equipment.equipmentType.name === "Other") {
                dispatch({
                    type: "VESSEL_EQUIPMENT_MANUFACTURER_MODEL_IS_OTHER_UPDATED",
                    data: true
                });
                dispatch({
                    type: "VESSEL_OTHER_EQUIPMENT_TYPE_UPDATED",
                    data: equipment.otherEquipmentType
                });
            } else {
                dispatch({
                    type: "VESSEL_EQUIPMENT_MANUFACTURER_MODEL_IS_OTHER_UPDATED",
                    data: false
                });
            }
            dispatch({
                type: "VESSEL_EQUIPMENT_IS_MED_APPROVED_UPDATED",
                data: equipment.medApproved
            });
            dispatch({
                type: "VESSEL_EQUIPMENT_IS_GPS_ENABLED_UPDATED",
                data: equipment.gpsEnabled
            });
            dispatch({
                type: "VESSEL_EQUIPMENT_IS_DSC_UPDATED",
                data: equipment.dscEnabled
            });
            dispatch({
                type: "VESSEL_EQUIPMENT_GMDSS_SATELLITE_TYPE_ID_UPDATED",
                data: equipment.gmdssSatelliteTypeId
            });
            dispatch({
                type: "VESSEL_EQUIPMENT_IS_EGC_RECEIVER_UPDATED",
                data: equipment.egcReceiver
            });
            dispatch({
                type: "VESSEL_EQUIPMENT_IS_LRIT_FACILITY_UPDATED",
                data: equipment.lritFacility
            });
        },
        updateEquipmentTypeCategory: (equipmentTypeCategoryId, isMedApproved, radarBandTypeId) => {

            dispatch({ type: "CLEAR_CURRENT_EQUIPMENT" });

            dispatch(CreateUpdateEquipmentTypeCategoryAction(equipmentTypeCategoryId));
            dispatch(CreateUpdateIsMedApprovedAction(isMedApproved));

            dispatch({
                type: "VESSEL_EQUIPMENT_RADAR_BAND_TYPE_ID_UPDATED",
                data: radarBandTypeId
            });

            if (equipmentTypeCategoryId === 1) {
                dispatch({
                    type: "VESSEL_EQUIPMENT_MMSI_NUMBER_UPDATED",
                    data: "970"
                });
            }

            if (equipmentTypeCategoryId === 3) {
                dispatch({
                    type: "VESSEL_EQUIPMENT_MMSI_NUMBER_UPDATED",
                    data: "972"
                });
            }

            if (equipmentTypeCategoryId === 6) {
                dispatch({
                    type: "VESSEL_EQUIPMENT_PLB_NUMBER_OF_PLBS_UPDATED",
                    data: 1
                });
            }

            dispatch({
                type: "DISABLE_EQUIPMENT_TYPE_CATEGORY_UPDATED",
                data: true
            });
            
        },
        clearEquipment: () => {
            dispatch({ type: "CLEAR_CURRENT_EQUIPMENT" });
        }
    };
};


const languageAwareComponent = withTranslation()(VesselEquipmentDetails);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent); 