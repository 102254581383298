import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faEdit, faBookOpen,faBroadcastTower, faQuestion, faChalkboardTeacher, faCamera } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import parse from "html-react-parser";

import CreateClearCurrentApplicationAction from "../../store/actionCreators/CreateClearCurrentApplicationAction";
import CreateClearCurrentVesselAction from "../../store/actionCreators/CreateClearCurrentVesselAction";
import CreateGetIsAuthenticatedAction from "../../store/actionCreators/CreateGetIsAuthenticatedAction";

class PublicLandingPage extends Component {
    constructor() {
        super();

        this.doLogin = this.doLogin.bind(this);

        this.state = {
            collapsed: true,
            userIsAuthenticated: false
        };
    }

    componentDidMount() {
        this.props.clearCurrentApplication();
        this.props.clearCurrentVessel();

        const that = this;

        window.userManager.getUser().then(function (user) {
            that.setState({
                userIsAuthenticated: user != null
            });
        });
        this.props.getIsAuthenticatedAction();
    }


    doLogin() {
        var { history } = this.props;

        window.userManager.getUser().then(function(user) {
            if (user) {
                history.push("dashboard");
            } else {
                window.userManager.signinRedirect();        
            }
        });
    }

    render() {
        const t = this.props.t;

        return (
            <React.Fragment>
                <div className="mt-3 mb-1">

                    <ul className="srl-ul">
                        <li>{t("PublicLandingPage.WelcomeText1")}</li>
                        <li>
                            <Link id="WelcomeDocumentsLink" to={"/documents"} >
                                {t("PublicLandingPage.WelcomeText2")}
                            </Link>
                        </li>
                        <li>{t("PublicLandingPage.WelcomeText3")}</li>            
                    </ul>
                    <hr className="srl-hr" />

                    <div className="row">
                        <div className="col-md-4">
                            <div className="ShipsRadioColumnItem">
                                <Link icon={faEdit} to={"/dashboard"} className="btn btn-licence-actions">
                                    <FontAwesomeIcon icon={faChalkboardTeacher} style={{ fontSize: 8 }} />
                                    {
                                        !this.props.userIsAuthenticated &&
                                        <div>
                                            {t("PublicLandingPage.LoginToPortal")}
                                        </div>
                                    }
                                    {
                                        this.props.userIsAuthenticated &&
                                        <div>
                                            {t("PublicLandingPage.GoToPortal")}
                                        </div>
                                    }
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ShipsRadioColumnItem">
                                <Link icon={faEdit} to={"/videos"} className="btn btn-licence-actions">
                                    <FontAwesomeIcon icon={faCamera} style={{ fontSize: 8 }} />
                                    <div>{t("PublicLandingPage.Videos")}</div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ShipsRadioColumnItem">
                                <Link icon={faEdit} to={"/requirementsCalculator"} className="btn btn-licence-actions">
                                    <FontAwesomeIcon icon={faCalculator} style={{ fontSize: 8 }} />
                                    <div>{t("PublicLandingPage.RequirementCalculator")}</div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="ShipsRadioColumnItem">
                                <Link icon={faEdit} to={"/documents"} className="btn btn-licence-actions">
                                    <FontAwesomeIcon icon={faBookOpen} style={{ fontSize: 8 }} />
                                    <div style={{ minHeight: 50 }}>{t("PublicLandingPage.UsefulDocuments")}</div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ShipsRadioColumnItem">
                                <Link icon={faBroadcastTower} to={"/faqs"} className="btn btn-licence-actions">
                                    <FontAwesomeIcon icon={faQuestion} style={{ fontSize: 8 }} />
                                    <div style={{ minHeight: 50 }}>{t("PublicLandingPage.GoToFAQ")}</div>
                                </Link>
                            </div>
                            </div>
                        <div className="col-md-4">
                            <div className="ShipsRadioColumnItem">
                                <Link icon={faBroadcastTower} to={"/links"} className="btn btn-licence-actions">
                                    <FontAwesomeIcon icon={faBroadcastTower} style={{ fontSize: 8 }} />
                                    <div style={{ minHeight: 50 }}>{t("PublicLandingPage.UsefulLinks")}</div>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>  
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userIsAuthenticated: state.currentUserInfo.isAuthenticated || false
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        clearCurrentApplication: () => {
            dispatch(CreateClearCurrentApplicationAction());
        },
        clearCurrentVessel: () => {
            dispatch(CreateClearCurrentVesselAction());
        },
        getIsAuthenticatedAction: () => {
            dispatch(CreateGetIsAuthenticatedAction());
        }
    };
};

const languageAwareComponent = withTranslation()(withRouter(PublicLandingPage));

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);