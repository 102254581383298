import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LinkButton from "./LandingPage/LinkButton";

import ValidationFailureMessage from "./ValidationFailureMessage";
import Breadcrumb from "./Breadcrumb";
import Autoaddress from "@autoaddress.ie/autoaddress-react";

import CreateGetCurrentUserAction from "../store/actionCreators/CreateGetCurrentUserAction";
import CreateGetSalutationsAction from "../store/actionCreators/CreateGetSalutationsAction";
import CreateGetLicenceApplicationTypesAction from "../store/actionCreators/CreateGetLicenceApplicationTypesAction";
import CreateUpdateUserAction from "../store/actionCreators/CreateUpdateUserAction";
import CreateGetWebsiteConfigAction from "../store/actionCreators/CreateGetWebsiteConfigAction";


class MyAccount extends Component {
    componentDidMount() {
        this.props.getWebsiteConfig();
        this.props.getCurrentUser();
        this.props.getSalutations();
        this.props.getLicenceApplicationTypes();
    }

    render() {
        const { t } = this.props;

        const hideCompanyField = (Number(this.props.licenceApplicationTypeId) || 0) !== 2;

        // disable the Next/submit button if required fields are missing
        var canSubmitForm = (this.props.licenceApplicationTypeRequired ||
            this.props.firstNameRequired ||
            this.props.firstName === "" ||
            this.props.lastNameRequired ||
            this.props.lastName === "" ||
            this.props.phoneNumberRequired ||
            this.props.phoneNumber === "" ||
            this.props.addressLine1Required ||
            this.props.addressLine1 === "" ||
            this.props.addressLine2Required ||
            this.props.addressLine2 === "");

        return (
            <React.Fragment>
                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    dashboard={t("Common.Dashboard")}
                    pageName={t("Common.ButtonText.MyAccount")} />

                <h4 className="h4 mt-2" role="heading" aria-level="1" id="MyAccountTitleLabel" tabIndex="0">{t("MyAccount.TitleLabel")}</h4>
                <div className="row col-md-12">
                    <div aria-hidden="true" id="required-description" className="d-flex justify-content-end">* {t("Common.RequiredField")}</div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="licenceApplicationTypeLabel" htmlFor="licenceApplicationTypeId" className="form-label">{t("MyAccount.LicenceApplicationTypeLabel")}</label>
                            <select id="licenceApplicationTypeId"
                                    className="form-control" required
                                    onChange={this.props.onLicenceApplicationTypeSelected}
                                    onBlur={this.props.onLicenceApplicationTypeSelectionCompleted}
                                    value={this.props.licenceApplicationTypeId}
                            >
                                <option value="">{t("MyAccount.LicenceApplicationTypeSelect")}</option>
                                {
                                        this.props.licenceApplicationTypes.map(x => (
                                            <option key={x.id} value={x.id}>{x.name}</option>
                                        ))
                                    }
                            </select>
                            {
                                    this.props.licenceApplicationTypeRequired &&
                                    <ValidationFailureMessage>
                                        {t("MyAccount.LicenceApplicationTypeRequired")}
                                    </ValidationFailureMessage>
                                }
                        </div>
                    </div>
                    <div className="col-md-6" hidden={hideCompanyField}>
                        <div className="form-group">
                            <label id="companyRegistrationNumberLabel" htmlFor="companyRegistrationNumber" className="form-label">{t("MyAccount.CompanyRegistrationNumber")} </label>
                            <input
                                id="companyRegistrationNumber" type="text"
                                className="form-control" required
                                onChange={this.props.onCompanyRegistrationChanged}
                                value={this.props.companyRegistrationNumber}/>
                        </div>
                    </div>
                    <div className="col-md-6" hidden={hideCompanyField}>
                        <div className="form-group">
                            <label id="companyNameLabel" htmlFor="companyName" className="form-label">{t("MyAccount.CompanyName")}</label>
                            <input
                                id="companyName" type="text"
                                className="form-control"
                                onChange={this.props.onCompanyNameChanged}
                                value={this.props.companyName}/>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="salutationLabel" htmlFor="salutationId" className="form-label">{t("MyAccount.Salutation")} </label>
                            <select id="salutationId"
                                    className="form-control"
                                    onChange={this.props.onSalutationSelected}
                                    value={this.props.salutationId}>
                                <option value="">{t("Common.ButtonText.PleaseSelect")} </option>
                                {
                                        this.props.salutations.map(salutation => (
                                            <option
                                                key={salutation.id}
                                                value={salutation.id}
                                            >
                                                {salutation.name}
                                            </option>
                                        ))
                                    }
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="firstNameLabel" htmlFor="firstName" className="form-label">{t("MyAccount.FirstName")} </label>
                            <input
                                id="firstName" type="text"
                                className="form-control" required
                                onChange={this.props.onFirstNameChanged}
                                onBlur={this.props.onFirstNameChangeCompleted}
                                value={this.props.firstName}/>
                            {
                                    this.props.firstNameRequired &&
                                    <ValidationFailureMessage>
                                        {t("MyAccount.FirstNameRequired")}
                                    </ValidationFailureMessage>
                                }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="lastNameLabel" htmlFor="lastName" className="form-label">{t("MyAccount.LastName")}</label>
                            <input
                                id="lastName" type="text"
                                className="form-control" required
                                onChange={this.props.onLastNameChanged}
                                onBlur={this.props.onLastNameChangeCompleted}
                                value={this.props.lastName}/>

                            {
                                    this.props.lastNameRequired &&
                                    <ValidationFailureMessage>
                                        {t("MyAccount.LastNameRequired")}
                                    </ValidationFailureMessage>
                                }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="emailAddressLabel" htmlFor="emailAddress" className="form-label">{t("MyAccount.EmailAddress")}: *</label>
                            <input id="emailAddress" type="email"
                                   className="form-control" required
                                   disabled={true}
                                   value={this.props.emailAddress}/>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="phoneNumberLabel" htmlFor="phoneNumber" className="form-label">{t("MyAccount.PhoneNumber")}: *</label>
                            <input
                                id="phoneNumber" type="text"
                                className="form-control" required
                                onChange={this.props.onPhoneNumberChanged}
                                onBlur={this.props.onPhoneNumberChangeCompleted}
                                value={this.props.phoneNumber}/>
                            {
                                    this.props.phoneNumberRequired &&
                                    <ValidationFailureMessage>
                                        {t("MyAccount.PhoneNumberRequired")}
                                    </ValidationFailureMessage>
                                }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="alternativePhoneNumberLabel" htmlFor="alternativePhoneNumber" className="form-label">{t("MyAccount.AlternativePhoneNumber")}:</label>
                            <input
                                id="alternativePhoneNumber" type="text"
                                className="form-control" required
                                onChange={this.props.onAlternativePhoneNumberChanged}
                                value={this.props.alternativePhoneNumber}/>
                        </div>
                    </div>
                </div>
                {
                        this.props.autoAddressLicenceKey &&
                        <div className="col-md-11 bg-light p-2 rounded-lg m-3">
                            <div className="form-group col-md-12">
                                <Autoaddress
                                    licencekey={this.props.autoAddressLicenceKey}
                                    vanityMode={true}
                                    addressProfile="Demo5LineV2"
                                    country="ie"
                                    language="en"
                                    onAddressFound={(data) => this.props.onAddressFoundCallback(data)}
                                />
                            </div>
                        </div>
                    }

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="addressEircodeLabel" htmlFor="addressEircode" className="form-label">{t("MyAccount.AddressEircode")}</label>

                            <input id="addressEircode" type="text"
                                   className="form-control"
                                   onChange={this.props.onAddressEircodeChanged}
                                   value={this.props.addressEircode}/>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="addressLine1Label" htmlFor="addressLine1" className="form-label">{t("MyAccount.AddressLine1")}</label>
                            <input id="addressLine1" type="text"
                                   className="form-control" required
                                   onChange={this.props.onAddressLine1Changed}
                                   onBlur={this.props.onAddressLine1ChangeCompleted}
                                   value={this.props.addressLine1}/>
                            {
                                    this.props.addressLine1Required &&
                                    <ValidationFailureMessage>
                                        {t("MyAccount.AddressLine1Required")}
                                    </ValidationFailureMessage>
                                }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="addressLine2Label" htmlFor="addressLine2" className="form-label">{t("MyAccount.AddressLine2")}</label>
                            <input id="addressLine2" type="text"
                                   className="form-control" required
                                   onChange={this.props.onAddressLine2Changed}
                                   onBlur={this.props.onAddressLine2ChangeCompleted}
                                   value={this.props.addressLine2}/>
                            {
                                    this.props.addressLine2Required &&
                                    <ValidationFailureMessage>
                                        {t("MyAccount.AddressLine2Required")}
                                    </ValidationFailureMessage>
                                }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="addressLine3Label" htmlFor="addressLine3" className="form-label">{t("MyAccount.AddressLine3")}</label>
                            <input id="addressLine3" type="text"
                                   className="form-control"
                                   onChange={this.props.onAddressLine3Changed}
                                   value={this.props.addressLine3}/>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="addressLine4Label" htmlFor="addressLine4" className="form-label">{t("MyAccount.AddressLine4")}</label>
                            <input id="addressLine4" type="text"
                                   className="form-control"
                                   onChange={this.props.onAddressLine4Changed}
                                   value={this.props.addressLine4}/>
                        </div>
                    </div>
                </div>
                <div className="my-3">
                    <LinkButton
                        className="btn btn-warning"
                        id="cancelUpdateUser"
                        to={"/dashboard"}>
                        {t("Common.ButtonText.Cancel")}
                    </LinkButton>
                    <a id="changePassword" href="/Account/ForgotPassword" className="btn btn-secondary ms-3">
                        {t("Common.ButtonText.ChangePassword")}
                    </a>
                    {
                        this.props.role === "Ship Owner" &&
                        <LinkButton
                            id="assignLicenceButton"
                            to={"/assign-licence"}
                            className="btn btn-secondary ms-3"
                        >
                            {t("Common.ButtonText.AssignLicence")}
                        </LinkButton>
                    }
                    <LinkButton
                        id="updateUserButton"
                        className="btn btn-primary ms-3"
                        disabled={canSubmitForm}
                        to={"/dashboard"}
                        onClick={() => this.props.updateUser(this.props.id)}
                    >
                        {t("Common.ButtonText.Save")}
                    </LinkButton>
               
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: state.currentUser.id || 0,
        licenceApplicationTypeId: state.currentUser.licenceApplicationTypeId || "",
        role: state.currentUserInfo.role || "",

        companyName: state.currentUser.companyName || "",
        companyRegistrationNumber: state.currentUser.companyRegistrationNumber || "",
        salutationId: state.currentUser.salutationId || "",
        firstName: state.currentUser.firstName || "",
        lastName: state.currentUser.lastName || "",
        phoneNumber: state.currentUser.phoneNumber || "",
        alternativePhoneNumber: state.currentUser.alternativePhoneNumber || "",
        addressEircode: state.currentUser.addressEircode || "",
        emailAddress: state.currentUser.emailAddress || "",
        addressLine1: state.currentUser.addressLine1 || "",
        addressLine2: state.currentUser.addressLine2 || "",
        addressLine3: state.currentUser.addressLine3 || "",
        addressLine4: state.currentUser.addressLine4 || "",

        salutations: state.availableSalutations,
        licenceApplicationTypes: state.availableLicenceApplicationTypes,
        autoAddressLicenceKey: state.websiteConfig.autoAddressKey || "",

        // validations
        licenceApplicationTypeRequired: state.currentUser.licenceApplicationTypeRequired || false,
        firstNameRequired: state.currentUser.firstNameRequired || false,
        lastNameRequired: state.currentUser.lastNameRequired || false,
        phoneNumberRequired: state.currentUser.phoneNumberRequired || false,
        addressLine1Required: state.currentUser.addressLine1Required || false,
        addressLine2Required: state.currentUser.addressLine2Required || false
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCurrentUser: () => {
            dispatch(CreateGetCurrentUserAction());
        },
        getSalutations: () => {
            dispatch(CreateGetSalutationsAction());
        },
        getLicenceApplicationTypes: () => {
            dispatch(CreateGetLicenceApplicationTypesAction());
        },
        getWebsiteConfig: () => {
            dispatch(CreateGetWebsiteConfigAction());
        },

        updateUser: (id) => {

            //update user
            if (id !== 0) {
                dispatch(CreateUpdateUserAction());
            }

        },

        onLicenceApplicationTypeSelected: (evt) => {
            var updatedTypeId = evt.target.value;

            dispatch({
                type: "CURRENT_USER_LICENCE_APPLICATION_TYPE_ID_UPDATED",
                data: updatedTypeId
            });
        },
        onLicenceApplicationTypeSelectionCompleted: (evt) => {
            var updatedTypeId = evt.target.value;

            if (updatedTypeId === "" || updatedTypeId === null || updatedTypeId.length === 0) {
                dispatch({
                    type: "CURRENT_USER_LICENCE_APPLICATION_TYPE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "CURRENT_USER_LICENCE_APPLICATION_TYPE_REQUIRED",
                    data: false
                });
            }
        },

        onCompanyNameChanged: (evt) => {
            var updatedCompanyName = evt.target.value;

            dispatch({
                type: "CURRENT_USER_COMPANY_NAME_UPDATED",
                data: updatedCompanyName
            });
        },

        onCompanyRegistrationChanged: (evt) => {
            var updatedCompanyRegistration = evt.target.value;

            dispatch({
                type: "CURRENT_USER_COMPANY_REGISTRATION_UPDATED",
                data: updatedCompanyRegistration
            });
        },
       
        onSalutationSelected: (evt) => {
            var updatedSalutationId = evt.target.value;

            dispatch({
                type: "CURRENT_USER_SALUTATION_ID_UPDATED",
                data: updatedSalutationId
            });
        },
        onFirstNameChanged: (evt) => {
            var updatedFirstName = evt.target.value;

            dispatch({
                type: "CURRENT_USER_FIRST_NAME_UPDATED",
                data: updatedFirstName
            });
        },
        onFirstNameChangeCompleted: (evt) => {
            var updatedFirstName = evt.target.value;

            if (updatedFirstName.trim() === "") {
                dispatch({
                    type: "CURRENT_USER_FIRST_NAME_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "CURRENT_USER_FIRST_NAME_REQUIRED",
                    data: false
                });
            }
        },
        onLastNameChanged: (evt) => {
            var updatedLastName = evt.target.value;

            dispatch({
                type: "CURRENT_USER_LAST_NAME_UPDATED",
                data: updatedLastName
            });
        },
        onLastNameChangeCompleted: (evt) => {
            var updatedLastName = evt.target.value;

            if (updatedLastName.trim() === "") {
                dispatch({
                    type: "CURRENT_USER_LAST_NAME_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "CURRENT_USER_LAST_NAME_REQUIRED",
                    data: false
                });
            }
        },
        onPhoneNumberChanged: (evt) => {
            var updatedPhoneNumber = evt.target.value;

            if (isNaN(updatedPhoneNumber) || updatedPhoneNumber.includes(".")) {
                return;
            }
            dispatch({
                type: "CURRENT_USER_PHONE_NUMBER_UPDATED",
                data: updatedPhoneNumber
            });
        },
        onPhoneNumberChangeCompleted: (evt) => {
            var updatedPhoneNumber = evt.target.value;

            if (updatedPhoneNumber.trim() === "") {
                dispatch({
                    type: "CURRENT_USER_PHONE_NUMBER_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "CURRENT_USER_PHONE_NUMBER_REQUIRED",
                    data: false
                });
            }
        },
        onAlternativePhoneNumberChanged: (evt) => {
            var updatedAlternativePhoneNumber = evt.target.value;
            if (updatedAlternativePhoneNumber.includes(".") || isNaN(updatedAlternativePhoneNumber)) {
                return;
            }
            dispatch({
                type: "CURRENT_USER_ALTERNATIVE_PHONE_NUMBER_UPDATED",
                data: updatedAlternativePhoneNumber
            });
        },
        onAddressEircodeChanged: (evt) => {
            var updatedAddressEircode = evt.target.value;

            dispatch({
                type: "CURRENT_USER_ADDRESS_EIRCODE_UPDATED",
                data: updatedAddressEircode
            });
        },
        onAddressFoundCallback: (data) => {

            if (data.postcode) {
                dispatch({
                    type: "CURRENT_USER_ADDRESS_EIRCODE_UPDATED",
                    data: data.postcode
                });
            } else {
                dispatch({
                    type: "CURRENT_USER_ADDRESS_EIRCODE_UPDATED",
                    data: ""
                });
            }
            if (data.reformattedAddress) {

                var index = 1;

                if (data.reformattedAddress[0]) {
                    dispatch({
                        type: "CURRENT_USER_ADDRESS_LINE" + index + "_UPDATED",
                        data: data.reformattedAddress[0]
                    });
                    index++;
                }

                if (data.reformattedAddress[1]) {
                    dispatch({
                        type: "CURRENT_USER_ADDRESS_LINE" + index + "_UPDATED",
                        data: data.reformattedAddress[1]
                    });
                    index++;
                }

                if (data.reformattedAddress[2]) {
                    dispatch({
                        type: "CURRENT_USER_ADDRESS_LINE" + index + "_UPDATED",
                        data: data.reformattedAddress[2]
                    });
                    index++;
                }

                if (data.reformattedAddress[3]) {
                    dispatch({
                        type: "CURRENT_USER_ADDRESS_LINE" + index + "_UPDATED",
                        data: data.reformattedAddress[3]
                    });
                    index++;
                }

                if (data.reformattedAddress[4]) {
                    if (index === 5) {
                        dispatch({
                            type: "CURRENT_USER_ADDRESS_LINE4_UPDATED",
                            data: data.reformattedAddress[3] + ", " + data.reformattedAddress[4]
                        });
                    } else {
                        dispatch({
                            type: "CURRENT_USER_ADDRESS_LINE" + index + "_UPDATED",
                            data: data.reformattedAddress[4]
                        });
                    }
                    index++;
                }

                for (var i = index; i < 5; i++) {
                    dispatch({
                        type: "CURRENT_USER_ADDRESS_LINE" + i + "_UPDATED",
                        data: ""
                    });
                }
            }
        },

        onAddressLine1Changed: (evt) => {
            var updatedAddressLine1 = evt.target.value;

            dispatch({
                type: "CURRENT_USER_ADDRESS_LINE1_UPDATED",
                data: updatedAddressLine1
            });
        },
        onAddressLine1ChangeCompleted: (evt) => {
            var updatedAddressLine1 = evt.target.value;

            if (updatedAddressLine1.trim() === "") {
                dispatch({
                    type: "CURRENT_USER_ADDRESS_LINE1_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "CURRENT_USER_ADDRESS_LINE1_REQUIRED",
                    data: false
                });
            }
        },

        onAddressLine2Changed: (evt) => {
            var updatedAddressLine2 = evt.target.value;

            dispatch({
                type: "CURRENT_USER_ADDRESS_LINE2_UPDATED",
                data: updatedAddressLine2
            });
        },
        onAddressLine2ChangeCompleted: (evt) => {
            var updatedAddressLine2 = evt.target.value;

            if (updatedAddressLine2.trim() === "") {
                dispatch({
                    type: "CURRENT_USER_ADDRESS_LINE2_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "CURRENT_USER_ADDRESS_LINE2_REQUIRED",
                    data: false
                });
            }
        },

        onAddressLine3Changed: (evt) => {
            var updatedAddressLine3 = evt.target.value;

            dispatch({
                type: "CURRENT_USER_ADDRESS_LINE3_UPDATED",
                data: updatedAddressLine3
            });
        },
        onAddressLine4Changed: (evt) => {
            var updatedAddressLine4 = evt.target.value;

            dispatch({
                type: "CURRENT_USER_ADDRESS_LINE4_UPDATED",
                data: updatedAddressLine4
            });
        }
    }
}

const languageAwareComponent = withTranslation()(MyAccount);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);

