const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_CURRENT_EQUIPMENT":
            return initialState;

        case "CURRENT_EQUIPMENT_RETRIEVED":
            {
                return action.data;
            }

        default:
            return state;
    }
}
