const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_WEBSITE_CONFIGS":
            return initialState;

        case "WEBSITE_CONFIGS_RETRIEVED":
            return {
                ...action.data
            };

        default:
            return state;
    }
}
