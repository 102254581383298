const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_EMERGENCY_CONTACT_LIST":
            return initialState;

        case "EMERGENCY_CONTACT_LIST_RETRIEVED":
            return action.emergencyContacts;

        case "EMERGENCY_CONTACTS_RETRIEVED":
            return action.data;

        default:
            return state;
    }
}
