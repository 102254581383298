import axios from "axios";

import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateClearCurrentEquipmentAction from "./CreateClearCurrentEquipmentAction";
import CreateGetRequiredEquipmentAction from "./CreateGetRequiredEquipmentAction";

export default function (licenceApplicationId) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());
        const { vesselEquipmentVhfDetail } = getState();

        var hexCode = vesselEquipmentVhfDetail.hexCode ? vesselEquipmentVhfDetail.hexCode.trim() : null;
        const numberOfPlbs = vesselEquipmentVhfDetail.numberOfPlbs && vesselEquipmentVhfDetail.numberOfPlbs >= 0
            ? Number(vesselEquipmentVhfDetail.numberOfPlbs)
            : null;

        //only save correct number of plb hex codes
        if (hexCode !== null) {
            const hexCodeArrayLength = numberOfPlbs === null ? 1 : numberOfPlbs;
            const hexCodeArray = hexCode.split(", ");
            hexCodeArray.length = hexCodeArrayLength;
            hexCode = hexCodeArray.join(", ");
        }

        const newVesselEquipmentDetail = {
            id: vesselEquipmentVhfDetail.id || 0,
            licenceApplicationId: Number(licenceApplicationId) || 0,
            equipmentTypeId: Number(vesselEquipmentVhfDetail.equipmentTypeId),
            mmsiNumber: vesselEquipmentVhfDetail.mmsiNumber,
            otherEquipmentType: vesselEquipmentVhfDetail.otherEquipmentTypeName,
            equipmentMfHfTypeId: vesselEquipmentVhfDetail.equipmentMfHfTypeId ? Number(vesselEquipmentVhfDetail.equipmentMfHfTypeId) : null,
            dscEnabled: vesselEquipmentVhfDetail.isDscEnabled || false,

            make: vesselEquipmentVhfDetail.make,
            model: vesselEquipmentVhfDetail.model,
            medApproved: vesselEquipmentVhfDetail.isMedApproved || false,
            customMakeModel: vesselEquipmentVhfDetail.isOtherMakeModel || false,
            gpsEnabled: vesselEquipmentVhfDetail.isGpsEnabled || false,

            radarBandTypeId: vesselEquipmentVhfDetail.radarBandTypeId ? Number(vesselEquipmentVhfDetail.radarBandTypeId) : null,
            egcReceiver: vesselEquipmentVhfDetail.isEgcReceiver || false,
            gmdssSatelliteTypeId: vesselEquipmentVhfDetail.satelliteTypeId ? Number(vesselEquipmentVhfDetail.satelliteTypeId) : null,
            hexCode: hexCode,
            lritFacility: vesselEquipmentVhfDetail.isLritFacility || false,
            numberOfPlbs: numberOfPlbs,
            satelliteNumber: vesselEquipmentVhfDetail.satelliteNumber
        };

        //authService.getAccessToken().then((token) => {
                axios({
                    method: "post",
                    url: "equipmentAmendment",
                    data: newVesselEquipmentDetail
                })
                    .then((data) => {
                        dispatch(CreateClearCurrentEquipmentAction());
                    })
                    .then((data) => {
                        dispatch(CreateGetRequiredEquipmentAction(licenceApplicationId));
                    })
                    .catch((error) => {
                        dispatch(CreateDataRetrievalEndedAction());
                    });
            //});
    };
}
