import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (applicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "RescueNote/GetByLicenceApplicationId";

        const params = {
            licenceApplicationId: applicationId
        };

        GET(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data == null) {
                        dispatch({
                            type: "CLEAR_CURRENT_RESCUE_NOTE"
                        });
                    } else {
                        dispatch({
                            type: "RESCUE_NOTE_ID_UPDATED",
                            data: response.data.id
                        });
                        dispatch({
                            type: "RESCUE_NOTE_DESCRIPTION_UPDATED",
                            data: response.data.note
                        });
                        const startDate = (response.data.startDate && response.data.startDate.length)
                            ? new Date(response.data.startDate)
                            : "";
                        dispatch({
                            type: "RESCUE_NOTE_START_DATE_UPDATED",
                            data: startDate
                        });
                        const endDate = (response.data.endDate && response.data.endDate.length)
                            ? new Date(response.data.endDate)
                            : "";
                        dispatch({
                            type: "RESCUE_NOTE_END_DATE_UPDATED",
                            data: endDate
                        });
                    }
                } else {
                    dispatch({
                        type: "CLEAR_CURRENT_RESCUE_NOTE"
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

