const initialState = {
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_TRANSFER_LICENCE_DATA":
            return initialState;

        case "TRANSFER_LICENCE_DATA_RETRIEVED":
            return {
                ...action.data
            };
        case "TRANSFER_LICENCE_UPDATED_SHOW_MODAL":
            return {
                ...state,
                showModal: action.data
            };
        case "TRANSFER_LICENCE_SHOW_ALERT":
            return {
                ...state,
                showAlert: action.data
            };
        case "TRANSFER_LICENCE_FIRST_NAME_UPDATED":
            return {
                ...state,
                firstName: action.data
            };

        case "TRANSFER_LICENCE_FIRST_NAME_REQUIRED":
            return {
                ...state,
                firstNameRequired: action.data
            };
        case "TRANSFER_LICENCE_LAST_NAME_UPDATED":
            return {
                ...state,
                lastName: action.data
            };

        case "TRANSFER_LICENCE_LAST_NAME_REQUIRED":
            return {
                ...state,
                lastNameRequired: action.data
            };
        case "TRANSFER_LICENCE_PHONE_NUMBER_UPDATED":
            return {
                ...state,
                phoneNumber: action.data
            };

        case "TRANSFER_LICENCE_PHONE_NUMBER_REQUIRED":
            return {
                ...state,
                phoneNumberRequired: action.data
            };
        case "TRANSFER_LICENCE_EMAIL_UPDATED":
            return {
                ...state,
                emailAddress: action.data
            };

        case "TRANSFER_LICENCE_EMAIL_REQUIRED":
            return {
                ...state,
                emailAddressRequired: action.data
            };
        default:
            return state;
    }
}

