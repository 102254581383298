import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GETAnonymous } from "../adapters/xhr";

export default function (vesselTypeId, vesselSubTypeId, seaAreaOfOperationId,
    vesselLength, grossTonnage) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "requiredEquipmentConfiguration/Calculate";
        const params = {
            vesselTypeId, vesselSubTypeId, seaAreaOfOperationId,
            vesselLength, grossTonnage
        };

        GETAnonymous(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "CALCULATE_REQUIRED_EQUIPMENT_RETRIEVED",
                        data: response.data
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
