import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { PUT } from "../adapters/xhr";

export default function(applicationTypeId) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const updatedLicenceApplication = getData(getState, applicationTypeId);

        PUT("licenceApplication/Save", updatedLicenceApplication)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: "LICENCE_APPLICATION_RETRIEVED",
                        data: response.data.licenceApplication
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            })
            .catch((response) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

function getData(getState, applicationTypeId) {
    const { currentApplication } = getState();

    const certificateNumber = currentApplication.certificateNumber ? currentApplication.certificateNumber.trim() : null;
    const nameOnCertificate = currentApplication.nameOnCertificate ? currentApplication.nameOnCertificate.trim() : null;

    //update model
    currentApplication.certificateNumber = certificateNumber;
    currentApplication.id = Number(currentApplication.id || 0);
    currentApplication.certificateTypeId = Number(currentApplication.certificateTypeId);
    currentApplication.salutationId = Number(currentApplication.salutationId);
    currentApplication.nameOnCertificate = nameOnCertificate;
    currentApplication.licenceApplicationStatusTypeId = Number(currentApplication.licenceApplicationStatusTypeId || 1);
    currentApplication.dateOfBirth = new Date(currentApplication.dateOfBirth);
    currentApplication.applicationTypeId = Number(applicationTypeId || 1);

    if (!isNaN(currentApplication.dateOfBirth)) {
        var dob = currentApplication.dateOfBirth;
        dob.setHours(4, 4, 4, 4);
        currentApplication.dateOfBirth = dob.toISOString();
    }

    return currentApplication;
}
