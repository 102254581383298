import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function () {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const { currentTransfer } = getState();

        var requestData = {
            licenceApplicationId: currentTransfer.licenceApplicationId,
            licenceTransferId: currentTransfer.id
        };

        GET("licenceApplication/AcceptLicenceTransfer", requestData)
            .then((response) => {
                if (response.status === 200) {
                    //redirect to new application
                    window.location.href = "/application-details-page/" + response.data.newLicenceApplicationId;

                    dispatch({
                        type: "CLEAR_CURRENT_TRANSFER"
                    });
                }
                dispatch(CreateDataRetrievalEndedAction());
            })
            .catch((response) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
