import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (licenceApplicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "LicenceApplication/GetNotesByLicenceId";
        const params = {
            licenceApplicationId
        };

        GET(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "LICENCE_NOTES_RETRIEVED",
                        licenceNotes: response.data
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

