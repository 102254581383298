import React from "react";
import { FilePicker } from "react-file-picker";

export default ({ id, buttonText, onChange, onError}) => {
    return (
        <FilePicker
            id={id}
            maxSize="10"
            extensions={["jpg", "jpeg", "png"]}
            onChange={file => onChange(file)}
            onError={errMsg => onError(errMsg)}
        >
            <button className="btn btn-primary" aria-label={buttonText} >
                {buttonText}
            </button>
        </FilePicker>
    );
};
