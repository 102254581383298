import axios from "axios";

// import authService from "../../components/api-authorization/AuthorizeService";

export default function (name, id) {
    return (dispatch) => {
        var requestData = {
            name,
            id
        };

        // authService.getAccessToken().then((token) => {
                axios({
                    method: "GET",
                    url: "vessel/Exists",
                    params: requestData
                        //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    })
                    .then((response) => {
                        dispatch({
                            type: "VESSEL_NAME_IN_USE",
                            data: response.data
                        });
                    })
                    .catch((error) => {
                    });
            //});
    };
}
