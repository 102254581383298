import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetEmergencyContactDetailsAction from "./CreateGetEmergencyContactDetailsAction";

import { POST } from "../adapters/xhr";

export default function (licenceApplicationId) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());
        
        const newEmergencyContact = getPostData(licenceApplicationId, getState);

        POST("emergencyContact", newEmergencyContact)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(CreateGetEmergencyContactDetailsAction(licenceApplicationId));
                }

                CreateDataRetrievalEndedAction();
            })
            .catch((response) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

function getPostData(licenceApplicationId, getState) {
    const { emergencyContact } = getState();

    const alternativePhoneNumber = emergencyContact.alternativePhoneNumber || "";
    const addressEirCode = emergencyContact.addressEirCode || "";
    const addressLine3 = emergencyContact.addressLine3 || "";
    const addressLine4 = emergencyContact.addressLine4 || "";

    const newEmergencyContact = {
        licenceApplicationId: Number(licenceApplicationId) || 0,
        salutationId: Number(emergencyContact.salutationId) || null,
        firstName: emergencyContact.firstName.trim(),
        lastName: emergencyContact.lastName.trim(),
        phoneCountryCodeId: Number(emergencyContact.phoneCountryCodeId) || 1,
        phoneNumber: emergencyContact.phoneNumber.trim(),
        confirmPhoneNumber: emergencyContact.confirmPhoneNumber.trim(),
        alternativePhoneCountryCodeId: Number(emergencyContact.alternativePhoneCountryCodeId) || 1,
        alternativePhoneNumber: alternativePhoneNumber.trim(),
        addressEirCode: addressEirCode.trim(),
        emailAddress: emergencyContact.emailAddress.trim(),
        confirmEmailAddress: emergencyContact.confirmEmailAddress.trim(),
        addressLine1: emergencyContact.addressLine1.trim(),
        addressLine2: emergencyContact.addressLine2.trim(),
        addressLine3: addressLine3.trim(),
        addressLine4: addressLine4.trim()
    };

    return newEmergencyContact;
}
