import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LinkButton from "./LandingPage/LinkButton";
import ValidationFailureMessage from "./ValidationFailureMessage";
import Autoaddress from "@autoaddress.ie/autoaddress-react";
import PageHeader from "./PageHeader";
import Breadcrumb from "./Breadcrumb";

import CreateGetSalutationsAction from "../store/actionCreators/CreateGetSalutationsAction";
import CreateAddEmergencyContactDetailsAction from "../store/actionCreators/CreateAddEmergencyContactDetailsAction";
import CreateUpdateEmergencyContactDetailsAction from "../store/actionCreators/CreateUpdateEmergencyContactDetailsAction";
import CreateGetPhoneCountryCodesAction from "../store/actionCreators/CreateGetPhoneCountryCodesAction";
import CreateDeleteEmergencyContactAction from "../store/actionCreators/CreateDeleteEmergencyContactAction";
import CheckEmergencyContactPhoneNumberAction from "../store/actionCreators/CheckEmergencyContactPhoneNumberAction";

class AddEmergencyContactDetails extends Component {
    componentDidMount() {
        this.props.getSalutations();
        this.props.getPhoneCountryCodes();
    }

    render() {
        const { t } = this.props;

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6,
            TransferInitiated: 7,
            Transferred: 8
        };

        // disable the Next/submit button if required fields are missing
        var canSubmitForm = (this.props.firstNameRequired ||
            this.props.firstName === "" ||
            this.props.lastNameRequired ||
            this.props.lastName === "" ||
            this.props.phoneNumberRequired ||
            this.props.phoneNumber === "" ||
            this.props.confirmPhoneNumberRequired ||
            this.props.confirmPhoneNumber === "" ||
            this.props.emailAddressRequired ||
            this.props.emailAddress === "" ||
            this.props.confirmEmailAddressRequired ||
            this.props.confirmEmailAddress === "" ||
            this.props.addressLine1Required ||
            this.props.addressLine1 === "" ||
            this.props.addressLine2Required ||
            this.props.addressLine2 === "" ||
            this.props.isEmailSameToConfirmEmail ||
            this.props.confirmEmailAddress === "" ||
            this.props.isPhoneNumberSameToConfirmPhoneNumber ||
            this.props.confirmPhoneNumber === "" ||
            this.props.isAltPhoneNumberSameToPhoneNumber ||
            this.props.phoneNumberInvalid);

        //original licence at transferred Status
        const isLicenceTransfer = this.props.licenceApplicationStatusTypeId === LicenceStatus.Transferred;

        const isShipOwerWithNonIssuedLicence = this.props.role === "Ship Owner" && (this.props.licenceApplicationStatusTypeId === LicenceStatus.InProgress
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.ReadyToReview);

        return (
            <React.Fragment>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    licenceList={t("AdminLandingPage.LicenceList")}
                    dashboard={t("Common.Dashboard")}
                    parentLink={this.props.parentRoute + "/emergency-contacts-list/" + this.props.licenceApplicationId}
                    parentPageName={t("EmergencyContacts.EmergencyContactsTitleLabel")}
                    pageName={t("EmergencyContacts.AddEditEmergencyContactTitleLabel")} />

                <PageHeader
                    pageTitle={t("EmergencyContacts.AddEditEmergencyContactTitleLabel")}
                    vesselLabel={t("OwnerLandingPage.VesselName")}
                    vesselName={this.props.vesselName}
                    isShipOwerWithNonIssuedLicence={isShipOwerWithNonIssuedLicence}
                    mmsiLabel={t("OwnerLandingPage.MMSINumber")}
                    mmsiNumber={this.props.mmsiNumber}
                    callSignLabel={t("OwnerLandingPage.CallSign")}
                    callSignNumber={this.props.callSignNumber}
                    pageDescription={t("EmergencyContacts.StepEmergencyContactsLabel")}
                    requiredField={t("Common.RequiredField")} />
               
                <div className="h6" role="heading" aria-level="2" tabIndex="0">
                    {t("EmergencyContacts.InformationText")}
                </div>

                <div className="row mt-3">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="salutationLabel" htmlFor="salutationId" className="form-label">{t("EmergencyContacts.Salutation")} </label>
                            <select id="salutationId"
                                className="form-control"
                                onChange={this.props.onSalutationSelected}
                                disabled={isLicenceTransfer}
                                value={this.props.salutationId}>
                                <option value="">{t("Common.ButtonText.PleaseSelect")} </option>
                                {
                                    this.props.salutations.map(salutation => (
                                        <option
                                            key={salutation.id}
                                            value={salutation.id}
                                        >
                                            {salutation.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="firstNameLabel" htmlFor="firstName" className="form-label">{t("EmergencyContacts.FirstName")} </label>
                            <input
                                id="firstName" type="text"
                                className="form-control" required
                                onChange={this.props.onFirstNameChanged}
                                disabled={isLicenceTransfer}
                                onBlur={this.props.onFirstNameChangeCompleted}
                                value={this.props.firstName}/>
                            {
                                this.props.firstNameRequired &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.FirstNameRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="lastNameLabel" htmlFor="lastName" className="form-label">{t("EmergencyContacts.LastName")}</label>
                            <input
                                id="lastName" type="text"
                                className="form-control" required
                                onChange={this.props.onLastNameChanged}
                                disabled={isLicenceTransfer}
                                onBlur={this.props.onLastNameChangeCompleted}
                                value={this.props.lastName}/>

                            {
                                this.props.lastNameRequired &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.LastNameRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="phoneCountryCodeLabel" htmlFor="phoneCountryCodeId" className="form-label"> {t("EmergencyContacts.PhoneCountryCode")}</label>
                            <select id="phoneCountryCodeId"
                                className="form-control"
                                disabled={isLicenceTransfer}
                                onChange={this.props.onPhoneCountryCodeSelected}
                                value={this.props.phoneCountryCodeId}>
                                {
                                    this.props.phoneCountryCodes.map(phoneCountryCode => (
                                        <option
                                            key={phoneCountryCode.id}
                                            value={phoneCountryCode.id}
                                        >
                                            {phoneCountryCode.name}
                                        </option>
                                    ))
                                }
                                    </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="phoneNumberLabel" htmlFor="phoneNumber" className="form-label">{t("EmergencyContacts.PhoneNumber")} </label>
                            <input
                                id="phoneNumber" type="text"
                                disabled={isLicenceTransfer}
                                maxLength="20"
                                className="form-control" required
                                onChange={this.props.onPhoneNumberChanged}
                                onBlur={evt => this.props.onPhoneNumberChangeCompleted(evt,
                                    this.props.licenceApplicationId, this.props.id)}
                                value={this.props.phoneNumber}/>
                            {
                                this.props.phoneNumberRequired &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.PhoneNumberRequired")}
                                </ValidationFailureMessage>
                            }
                            {
                                this.props.phoneNumberInvalid &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.PhoneNumberValidated")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="confirmPhoneNumberLabel" htmlFor="confirmPhoneNumber" className="form-label">{t("EmergencyContacts.ConfirmPhoneNumber")}</label>
                            <input
                                id="confirmPhoneNumber" type="text"
                                disabled={isLicenceTransfer}
                                maxLength="20"
                                className="form-control" required
                                onChange={this.props.onConfirmPhoneNumberChanged}
                                //onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 46 || e.keyCode === 110 || e.keyCode === 43


                                //    || e.keyCode === 189 || e.keyCode === 107 || e.keyCode === 16 || e.keyCode === 109 || e.keyCode === 187) && e.preventDefault()}


                                onBlur={this.props.onConfirmPhoneNumberChangeCompleted}
                                value={this.props.confirmPhoneNumber}
                                onPaste={e => { e.preventDefault(); }}/>
                            {
                                this.props.phoneNumber && this.props.isPhoneNumberSameToConfirmPhoneNumber &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.PhoneNumberDidNotMatch")}
                                </ValidationFailureMessage>
                            }
                            {
                                this.props.confirmPhoneNumberRequired &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.ConfirmPhoneNumberRequired")}
                                </ValidationFailureMessage>
                                    }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="alternativePhoneCountryCodeLabel" htmlFor="alternativePhoneCountryCodeId" className="form-label"> {t("EmergencyContacts.AlternativePhoneCountryCode")}</label>
                            <select id="alternativePhoneCountryCodeId"
                                className="form-control"
                                disabled={isLicenceTransfer}
                                onChange={this.props.onAlternativePhoneCountryCodeSelected}
                                value={this.props.alternativePhoneCountryCodeId}>
                                {
                                    this.props.alternativePhoneCountryCodes.map(phoneCountryCode => (
                                        <option
                                            key={phoneCountryCode.id}
                                            value={phoneCountryCode.id}
                                        >
                                            {phoneCountryCode.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="alternativePhoneNumberLabel" htmlFor="alternativePhoneNumber" className="form-label"> {t("EmergencyContacts.AlternativePhoneNumber")}</label>
                            <input
                                id="alternativePhoneNumber" type="text"
                                disabled={isLicenceTransfer}
                                maxLength="20"
                                className="form-control"
                                onChange={this.props.onAlternativePhoneNumberChanged}
                                onBlur={this.props.onAlternativePhoneNumberChangeCompleted}
                                value={this.props.alternativePhoneNumber} />
                            {
                                this.props.alternativePhoneNumberRequired &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.AlternativePhoneNumberRequired")}
                                </ValidationFailureMessage>
                            }
                            {
                                this.props.phoneNumber && this.props.isAltPhoneNumberSameToPhoneNumber &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.AlternativePhoneNumberDidMatch")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="emailAddressLabel" htmlFor="emailAddress" className="form-label">{t("EmergencyContacts.EmailAddress")}</label>
                            <input id="emailAddress" type="email"
                                className="form-control" required
                                disabled={isLicenceTransfer}
                                autoComplete="off"
                                onChange={this.props.onEmailAddressChanged}
                                onBlur={this.props.onEmailAddressChangeCompleted}
                                value={this.props.emailAddress}/>
                            {
                                this.props.emailAddress && !this.props.emailAddress.includes("@") &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.EmailAddressValidation")}
                                </ValidationFailureMessage>
                            }
                            {
                                this.props.emailAddressRequired &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.EmailAddressRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="confirmEmailAddressLabel" htmlFor="confirmEmailAddress" className="form-label">{t("EmergencyContacts.ConfirmEmailAddress")}</label>
                            <input id="confirmEmailAddress" type="email"
                                className="form-control" required
                                disabled={isLicenceTransfer}
                                autoComplete="off"
                                onChange={this.props.onConfirmEmailAddressChanged}
                                onBlur={this.props.onConfirmEmailAddressChangeCompleted}
                                value={this.props.confirmEmailAddress}
                                onPaste={e => { e.preventDefault(); }}/>
                            {
                                this.props.emailAddress && this.props.isEmailSameToConfirmEmail &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.ConfirmEmailAddressValidation")}
                                </ValidationFailureMessage>
                            }
                            {
                                this.props.confirmEmailAddressRequired &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.ConfirmEmailAddressRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>
                </div>
                {
                    !isLicenceTransfer &&
                    <div>
                        <label id="addressLabel" className="mt-3 form-label">{t("EmergencyContacts.Address")}</label>
                        <div className="col-md-12 bg-light p-2 rounded-lg ms-3 me-5 mb-3 mt-0">
                            <div className="form-group col-md-12">
                                <Autoaddress
                                    licencekey={this.props.autoAddressLicenceKey}
                                    vanityMode={true}
                                    addressProfile="Demo5LineV2"
                                    country="ie"
                                    language="en"
                                    onAddressFound={(data) => this.props.onAddressFoundCallback(data)}
                                />
                            </div>
                        </div>
                    </div>
                }
                
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="addressEirCodeLabel" htmlFor="addressEirCode" className="form-label">{t("EmergencyContacts.AddressEircode")}</label>
                            <input id="addressEirCode" type="text"
                                   disabled={isLicenceTransfer}
                                   className="form-control"
                                   onChange={this.props.onAddressEirCodeChanged}
                                   value={this.props.addressEirCode}/>
                            </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="addressLine1Label" htmlFor="addressLine1" className="form-label">{t("EmergencyContacts.AddressLine1")}</label>
                            <input id="addressLine1" type="text"
                                className="form-control" required
                                disabled={isLicenceTransfer}
                                onChange={this.props.onAddressLine1Changed}
                                onBlur={this.props.onAddressLine1ChangeCompleted}
                                value={this.props.addressLine1} />
                            {
                                this.props.addressLine1Required &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.AddressLine1Required")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="addressLine2Label" htmlFor="addressLine2" className="form-label">{t("EmergencyContacts.AddressLine2")}</label>
                            <input id="addressLine2" type="text"
                                className="form-control" required
                                disabled={isLicenceTransfer}
                                onChange={this.props.onAddressLine2Changed}
                                onBlur={this.props.onAddressLine2ChangeCompleted}
                                value={this.props.addressLine2} />
                            {
                                this.props.addressLine2Required &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.AddressLine2Required")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="addressLine3Label" htmlFor="addressLine3" className="form-label">{t("EmergencyContacts.AddressLine3")}</label>
                            <input id="addressLine3" type="text"
                                   disabled={isLicenceTransfer}
                                   className="form-control"
                                   onChange={this.props.onAddressLine3Changed}
                                   value={this.props.addressLine3}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="addressLine4Label" htmlFor="addressLine4" className="form-label">{t("EmergencyContacts.AddressLine4")}</label>
                            <input id="addressLine4" type="text"
                                   disabled={isLicenceTransfer}
                                   className="form-control"
                                   onChange={this.props.onAddressLine4Changed}
                                   value={this.props.addressLine4}/>
                        </div>
                    </div>
                </div>
                <div className="my-3">
                    <LinkButton className="btn btn-warning"
                        id="cancelContact"
                        to={this.props.parentRoute + "/emergency-contacts-list/" + this.props.licenceApplicationId}>
                        {t("Common.ButtonText.Cancel")}
                    </LinkButton>
                    {
                        !isLicenceTransfer && this.props.id > 0 &&
                        <LinkButton
                            id="deleteEmergencyContactButton"
                            className="btn btn-danger ms-3"
                            to={this.props.parentRoute + "/emergency-contacts-list/" + this.props.licenceApplicationId}
                            onClick={() => this.props.deleteEmergencyDetail(this.props.id, this.props.licenceApplicationId)}
                        >
                            {t("Common.ButtonText.Delete")}
                        </LinkButton>
                    }
                    {
                        !isLicenceTransfer &&
                        <LinkButton
                            id="addEmergencyContactButton"
                            className="btn btn-primary mx-3"
                            disabled={canSubmitForm}

                            to={this.props.parentRoute + "/emergency-contacts-list/" + this.props.licenceApplicationId}
                            onClick={() => this.props.addEmergencyDetail(this.props.licenceApplicationId, this.props.id)}
                        >
                            {t("Common.ButtonText.Save")}
                        </LinkButton>
                    }
                   
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: state.emergencyContact.id || 0,
        licenceApplicationId: state.currentApplication.id || 0,
        licenceApplicationStatusTypeId: state.currentApplication.licenceApplicationStatusTypeId || 0,
        role: state.currentUserInfo.role || "",

        salutationId: state.emergencyContact.salutationId || "",
        firstName: state.emergencyContact.firstName || "",
        lastName: state.emergencyContact.lastName || "",
        phoneCountryCodeId: state.emergencyContact.phoneCountryCodeId || 1,
        phoneNumber: state.emergencyContact.phoneNumber || "",
        confirmPhoneNumber: state.emergencyContact.confirmPhoneNumber || "",
        alternativePhoneCountryCodeId: state.emergencyContact.alternativePhoneCountryCodeId || 1,
        alternativePhoneNumber: state.emergencyContact.alternativePhoneNumber || "",
        addressEirCode: state.emergencyContact.addressEirCode || "",
        emailAddress: state.emergencyContact.emailAddress || "",
        confirmEmailAddress: state.emergencyContact.confirmEmailAddress || "",
        addressLine1: state.emergencyContact.addressLine1 || "",
        addressLine2: state.emergencyContact.addressLine2 || "",
        addressLine3: state.emergencyContact.addressLine3 || "",
        addressLine4: state.emergencyContact.addressLine4 || "",

        salutations: state.availableSalutations,
        phoneCountryCodes: state.availablePhoneCountryCodes,

        autoAddressLicenceKey: state.websiteConfig.autoAddressKey || "",

        vesselName: state.currentVessel.name || "",
        callSignNumber: state.currentVessel.callSignNumber || "",
        mmsiNumber: state.currentVessel.mmsiNumber || "",

        // validations
        alternativePhoneCountryCodes: state.availablePhoneCountryCodes,
        isEmailSameToConfirmEmail: (state.emergencyContact.emailAddress !== state.emergencyContact.confirmEmailAddress) && state.emergencyContact.confirmEmailAddress,
        isPhoneNumberSameToConfirmPhoneNumber: (state.emergencyContact.phoneNumber !== state.emergencyContact.confirmPhoneNumber) && state.emergencyContact.confirmPhoneNumber,
        firstNameRequired: state.emergencyContact.firstNameRequired || false,
        lastNameRequired: state.emergencyContact.lastNameRequired || false,
        phoneNumberRequired: state.emergencyContact.phoneNumberRequired || false,
        phoneNumberInvalid: state.emergencyContact.phoneNumberValidated || false,
        confirmPhoneNumberRequired: state.emergencyContact.confirmPhoneNumberRequired || false,
        alternativePhoneNumberRequired: state.emergencyContact.alternativePhoneNumberRequired || false,
        isAltPhoneNumberSameToPhoneNumber: (state.emergencyContact.alternativePhoneNumber === state.emergencyContact.phoneNumber) && state.emergencyContact.alternativePhoneNumber,

        emailAddressRequired: state.emergencyContact.emailAddressRequired || false,
        confirmEmailAddressRequired: state.emergencyContact.confirmEmailAddressRequired || false,
        addressLine1Required: state.emergencyContact.addressLine1Required || false,
        addressLine2Required: state.emergencyContact.addressLine2Required || false
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        prevent: (e) => {
            var code = e.keyCode;
            if (code === 69 || code === 190 || code === 46 || code === 110) {
                return; // e.preventDefault()
            }
        },
        getSalutations: () => {
            dispatch(CreateGetSalutationsAction());
        },
        getPhoneCountryCodes: () => {
            dispatch(CreateGetPhoneCountryCodesAction());
        },

        addEmergencyDetail: (licenceApplicationId, id) => {
            if (Number(id) === 0) {
                dispatch(CreateAddEmergencyContactDetailsAction(licenceApplicationId));
            }
            else {
                dispatch(CreateUpdateEmergencyContactDetailsAction(licenceApplicationId));
            }
            
        },

        deleteEmergencyDetail: (id, licenceApplicationId) => {
            dispatch(CreateDeleteEmergencyContactAction(id, licenceApplicationId));
        },

        onSalutationSelected: (evt) => {
            var updatedSalutationId = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_SALUTATION_ID_UPDATED",
                data: updatedSalutationId
            });
        },
        onFirstNameChanged: (evt) => {
            var updatedFirstName = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_FIRST_NAME_UPDATED",
                data: updatedFirstName
            });
        },
        onFirstNameChangeCompleted: (evt) => {
            var updatedFirstName = evt.target.value;

            if (updatedFirstName.trim() === "") {
                dispatch({
                    type: "EMERGENCY_CONTACT_FIRST_NAME_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EMERGENCY_CONTACT_FIRST_NAME_REQUIRED",
                    data: false
                });
            }
        },
        onLastNameChanged: (evt) => {
            var updatedLastName = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_LAST_NAME_UPDATED",
                data: updatedLastName
            });
        },
        onLastNameChangeCompleted: (evt) => {
            var updatedLastName = evt.target.value;

            if (updatedLastName.trim() === "") {
                dispatch({
                    type: "EMERGENCY_CONTACT_LAST_NAME_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EMERGENCY_CONTACT_LAST_NAME_REQUIRED",
                    data: false
                });
            }
        },
        onPhoneCountryCodeSelected: (evt) => {
            var updatedPhoneCountryCodeId = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_PHONE_COUNTRY_CODE_UPDATED",
                data: updatedPhoneCountryCodeId
            });
        },
        onPhoneNumberChanged: (evt) => {
            var updatedPhoneNumber = evt.target.value;

            if (isNaN(updatedPhoneNumber) || updatedPhoneNumber.includes(".")) {
                return;
            }
            dispatch({
                type: "EMERGENCY_CONTACT_PHONE_NUMBER_UPDATED",
                data: updatedPhoneNumber
            });
        },
        onPhoneNumberChangeCompleted: (evt, licenceApplicationId, emergencyContactId) => {
            var updatedPhoneNumber = evt.target.value;

            if (updatedPhoneNumber.trim() === "" || updatedPhoneNumber.trim().length < 7) {
                dispatch({
                    type: "EMERGENCY_CONTACT_PHONE_NUMBER_REQUIRED",
                    data: true
                });
            } else {

                dispatch(CheckEmergencyContactPhoneNumberAction(licenceApplicationId, updatedPhoneNumber, emergencyContactId));


                dispatch({
                    type: "EMERGENCY_CONTACT_PHONE_NUMBER_REQUIRED",
                    data: false
                });
            }
        },
        onConfirmPhoneNumberChanged: (evt) => {
            var updatedConfirmPhoneNumber = evt.target.value;

            if (updatedConfirmPhoneNumber.includes(".") || isNaN(updatedConfirmPhoneNumber)) {
                return;
            }
            dispatch({
                type: "EMERGENCY_CONTACT_CONFIRM_PHONE_NUMBER_UPDATED",
                data: updatedConfirmPhoneNumber
            });
        },
        onConfirmPhoneNumberChangeCompleted: (evt) => {
            var updatedConfirmPhoneNumber = evt.target.value;

            if (updatedConfirmPhoneNumber.trim() === "" || updatedConfirmPhoneNumber.trim().length < 7) {
                dispatch({
                    type: "EMERGENCY_CONTACT_CONFIRM_PHONE_NUMBER_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EMERGENCY_CONTACT_CONFIRM_PHONE_NUMBER_REQUIRED",
                    data: false
                });
            }
        },
        onAlternativePhoneCountryCodeSelected: (evt) => {
            var updatedPhoneCountryCodeId = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_ALTERNATIVE_PHONE_COUNTRY_CODE_UPDATED",
                data: updatedPhoneCountryCodeId
            });
        },
        onAlternativePhoneNumberChanged: (evt) => {
            var updatedAlternativePhoneNumber = evt.target.value;
            if (updatedAlternativePhoneNumber.includes(".") || isNaN(updatedAlternativePhoneNumber)) {
                return;
            }
            dispatch({
                type: "EMERGENCY_CONTACT_ALTERNATIVE_PHONE_NUMBER_UPDATED",
                data: updatedAlternativePhoneNumber
            });
        },
        onAlternativePhoneNumberChangeCompleted: (evt) => {
            var updatedAlternativePhoneNumber = evt.target.value;

            if (updatedAlternativePhoneNumber.trim() !== "" && updatedAlternativePhoneNumber.trim().length < 7) {
                dispatch({
                    type: "EMERGENCY_CONTACT_ALTERNATIVE_PHONE_NUMBER_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EMERGENCY_CONTACT_ALTERNATIVE_PHONE_NUMBER_REQUIRED",
                    data: false
                });
            }
        },
        onAddressEirCodeChanged: (evt) => {
            var updatedAddressEirCode = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_ADDRESS_EIRCODE_UPDATED",
                data: updatedAddressEirCode
            });
        },
        onEmailAddressChanged: (evt) => {
            var updatedEmailAddress = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_EMAIL_ADDRESS_UPDATED",
                data: updatedEmailAddress
            });
        },
        onEmailAddressChangeCompleted: (evt) => {
            var updatedEmailAddress = evt.target.value;

            if (updatedEmailAddress.trim() === "") {
                dispatch({
                    type: "EMERGENCY_CONTACT_EMAIL_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EMERGENCY_CONTACT_EMAIL_REQUIRED",
                    data: false
                });
            }
        },
        onConfirmEmailAddressChanged: (evt) => {
            var updatedConfirmEmailAddress = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_CONFIRM_EMAIL_ADDRESS_UPDATED",
                data: updatedConfirmEmailAddress
            });
        },
        onConfirmEmailAddressChangeCompleted: (evt) => {
            var updatedConfirmEmailAddress = evt.target.value;

            if (updatedConfirmEmailAddress.trim() === "") {
                dispatch({
                    type: "EMERGENCY_CONTACT_CONFIRM_EMAIL_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EMERGENCY_CONTACT_CONFIRM_EMAIL_REQUIRED",
                    data: false
                });
            }
        },

        onAddressFoundCallback: (data) => {

            if (data.postcode) {
                dispatch({
                    type: "EMERGENCY_CONTACT_ADDRESS_EIRCODE_UPDATED",
                    data: data.postcode
                });
            } else {
                dispatch({
                    type: "EMERGENCY_CONTACT_ADDRESS_EIRCODE_UPDATED",
                    data: ""
                });
            }
            if (data.reformattedAddress) {
                var index = 1;

                if (data.reformattedAddress[0]) {
                    dispatch({
                        type: "EMERGENCY_CONTACT_ADDRESS_LINE" + index + "_UPDATED",
                        data: data.reformattedAddress[0]
                    });
                    index++;
                }

                if (data.reformattedAddress[1]) {
                    dispatch({
                        type: "EMERGENCY_CONTACT_ADDRESS_LINE" + index + "_UPDATED",
                        data: data.reformattedAddress[1]
                    });
                    index++;
                }

                if (data.reformattedAddress[2]) {
                    dispatch({
                        type: "EMERGENCY_CONTACT_ADDRESS_LINE" + index + "_UPDATED",
                        data: data.reformattedAddress[2]
                    });
                    index++;
                }

                if (data.reformattedAddress[3]) {
                    dispatch({
                        type: "EMERGENCY_CONTACT_ADDRESS_LINE" + index + "_UPDATED",
                        data: data.reformattedAddress[3]
                    });
                    index++;
                }

                if (data.reformattedAddress[4]) {
                    if (index === 5) {
                        dispatch({
                            type: "EMERGENCY_CONTACT_ADDRESS_LINE4_UPDATED",
                            data: data.reformattedAddress[3] + ", " + data.reformattedAddress[4]
                        });
                    } else {
                        dispatch({
                            type: "EMERGENCY_CONTACT_ADDRESS_LINE" + index + "_UPDATED",
                            data: data.reformattedAddress[4]
                        });
                    }
                    index++;
                }

                for (var i = index; i < 5; i++) {
                    dispatch({
                        type: "EMERGENCY_CONTACT_ADDRESS_LINE" + i + "_UPDATED",
                        data: ""
                    });
                }
                
            }
        },

        onAddressLine1Changed: (evt) => {
            var updatedAddressLine1 = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_ADDRESS_LINE1_UPDATED",
                data: updatedAddressLine1
            });
        },
        onAddressLine1ChangeCompleted: (evt) => {
            var updatedAddressLine1 = evt.target.value;

            if (updatedAddressLine1.trim() === "") {
                dispatch({
                    type: "EMERGENCY_CONTACT_ADDRESS_LINE1_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EMERGENCY_CONTACT_ADDRESS_LINE1_REQUIRED",
                    data: false
                });
            }
        },

        onAddressLine2Changed: (evt) => {
            var updatedAddressLine2 = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_ADDRESS_LINE2_UPDATED",
                data: updatedAddressLine2
            });
        },
        onAddressLine2ChangeCompleted: (evt) => {
            var updatedAddressLine2 = evt.target.value;

            if (updatedAddressLine2.trim() === "") {
                dispatch({
                    type: "EMERGENCY_CONTACT_ADDRESS_LINE2_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EMERGENCY_CONTACT_ADDRESS_LINE2_REQUIRED",
                    data: false
                });
            }
        },

        onAddressLine3Changed: (evt) => {
            var updatedAddressLine3 = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_ADDRESS_LINE3_UPDATED",
                data: updatedAddressLine3
            });
        },
        onAddressLine4Changed: (evt) => {
            var updatedAddressLine4 = evt.target.value;

            dispatch({
                type: "EMERGENCY_CONTACT_ADDRESS_LINE4_UPDATED",
                data: updatedAddressLine4
            });
        }

    }
}

const languageAwareComponent = withTranslation()(AddEmergencyContactDetails);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);

