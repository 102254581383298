const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "SEA_AREA_OF_OPERATION_TYPES_RETRIEVED":
            return action.seaAreasOfOperation;

        default:
            return state;
    }
}
