import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LinkButton from "./LandingPage/LinkButton";

import ValidationFailureMessage from "./ValidationFailureMessage";
import FileUploader from "./FileUploader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import CreateSaveLicenceApplicationAction from "../store/actionCreators/CreateSaveLicenceApplicationAction";
import CreateAddVesselAction from "../store/actionCreators/CreateAddVesselAction";
import CreateUpdateVesselAction from "../store/actionCreators/CreateUpdateVesselAction";
import CreateUpdateIssuedVesselAction from "../store/actionCreators/CreateUpdateIssuedVesselAction";
import CreateGetVesselTypesAction from "../store/actionCreators/CreateGetVesselTypesAction";
import CreateGetVesselSubTypesAction from "../store/actionCreators/CreateGetVesselSubTypesAction";
import CreateGetHullColoursAction from "../store/actionCreators/CreateGetHullColorsAction";
import CreateGetAllSeaAreasOfOperationAction from "../store/actionCreators/CreateGetAllSeaAreasOfOperationAction";
import CreateCheckIfNamedVesselExistsAction from "../store/actionCreators/CreateCheckIfNamedVesselExistsAction";
import CreateGetAccountingAuthorityIdentificationCodesAction from "../store/actionCreators/CreateGetAccountingAuthorityIdentificationCodesAction"
import CreateUploadVesselPhotoAction from "../store/actionCreators/CreateUploadVesselPhotoAction";
import CreateGetFishingVesselPostfixesAction from "../store/actionCreators/CreateGetFishingVesselPostfixesAction";
import CreateGetFishingVesselPrefixesAction from "../store/actionCreators/CreateGetFishingVesselPrefixesAction";
import CreateGetVesselImagesAction from "../store/actionCreators/CreateGetVesselImagesByLicenceIdAction";
import CreateDeleteLicenceImageAction from "../store/actionCreators/CreateDeleteLicenceImageAction";
import PageHeader from "./PageHeader";
import Breadcrumb from "./Breadcrumb";

class AddVessel extends Component {
    componentDidMount() {
        this.props.getVesselTypes();
        this.props.getVesselSubTypes();
        this.props.getHullColors();
        this.props.getAccountingAuthorityIdentificationCodes();
        this.props.getFishingVesselPrefixes();
        this.props.getFishingVesselPostfixes();
        this.props.getSeaAreasOfOperation();
    }

    getSeaAreaChoices = (seaAreasOfOperation, isMerchantOrFvOver24, isPassengerBoat, isPassengerBoatP5, selectedCertificateType) => {

        const certificateTypes = {
            SRC: 1,
            LRC: 3,
            GMDSSROC: 5,
            GMDSSGOC: 6
        };

        const seaAreas = {
            SeaArea1: 1,
            SeaArea2: 2,
            SeaArea3: 3,
            SeaArea4: 4
        };

        if (!isMerchantOrFvOver24 && !isPassengerBoat && selectedCertificateType) {
            switch (selectedCertificateType) {
                case certificateTypes.SRC:
                case certificateTypes.GMDSSROC:
                    return seaAreasOfOperation.filter(l => Number(l.id) === seaAreas.SeaArea1);
                case certificateTypes.LRC:
                    return seaAreasOfOperation.filter(l => Number(l.id) !== seaAreas.SeaArea4);
                default:
                    return seaAreasOfOperation.filter(l => Number(l.id) !== seaAreas.SeaArea4);
            }
        }

        if (isMerchantOrFvOver24) {
            return seaAreasOfOperation;
        }

        //For P5 they should have the option to select A2 only if the operator has LRC or GOC 
        if (isPassengerBoat && isPassengerBoatP5 && selectedCertificateType && (selectedCertificateType == certificateTypes.LRC || selectedCertificateType == certificateTypes.GMDSSGOC)) {
            return seaAreasOfOperation.filter(l => Number(l.id) === seaAreas.SeaArea1 || Number(l.id) === seaAreas.SeaArea2);
        }

        //Sea Area A1 should be automatically selected for P1, P2, P3, P4 and P6 (these never go beyond A1)
        if (isPassengerBoat) {
            return seaAreasOfOperation.filter(l => Number(l.id) === seaAreas.SeaArea1);
        }

        //default. exclude SeaArea4
        return seaAreasOfOperation.filter(l => Number(l.id) !== seaAreas.SeaArea4);
    }

    render() {
        const { t } = this.props;

        const vesselTypes = {
            PleasureVessel: 1,
            FishingVessel: 2,
            PassengerBoat: 3,
            PassengerShip: 4,
            MerchantVessel: 5,
            NavalShip: 6,
            RescueVessel: 7,
            Other:8
        };

        const passengerBoatSubTypes = {
            P1: 3,
            P2: 4,
            P3: 5,
            P4: 6,
            P5: 7,
            P6: 8
        };

        const hideFVNumberField = this.props.vesselTypeId != vesselTypes.FishingVessel;
        const hideIMONumberField = this.props.vesselTypeId != vesselTypes.MerchantVessel
            && this.props.vesselTypeId != vesselTypes.PassengerShip
            && this.props.vesselTypeId != vesselTypes.FishingVessel;


        // disable the Next/submit button if required fields are missing
        var canSubmitForm = (this.props.nameRequired ||
            this.props.name === "" ||
            this.props.vesselTypeRequired ||
            this.props.vesselTypeId === "" ||
            (this.props.hasVesselSubTypes && (this.props.vesselSubTypeRequired || this.props.vesselSubTypeId === "")) ||
            this.props.vesselDescriptionRequired ||
            this.props.vesselDescription === "" ||
            this.props.maxVesselCapacityRequired ||
            this.props.maxVesselCapacity === "" ||
            this.props.vesselLengthRequired ||
            this.props.vesselLength === "" ||
            this.props.grossTonnageRequired ||
            this.props.grossTonnage === "" ||
            this.props.seaAreaOfOperationRequired ||
            this.props.seaAreaOfOperationId === "" ||
            this.props.homeportRequired ||
            this.props.homeport === "" ||
            this.props.hullColorRequired ||
            this.props.hullColorId === "" ||
            this.props.vesselImoNumberInvalid
        );

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6,
            TransferInitiated: 7,
            Transferred: 8
        };

        //cancelled, TransferInitiated or transferred Status
        const isReadOnlyPage = this.props.licenceApplicationStatusTypeId === LicenceStatus.Cancelled
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.TransferInitiated
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.Transferred;

        //remove Other vessel Type
        const vesselTypeChoices = this.props.vesselTypes.filter(l => Number(l.id) !== vesselTypes.Other);

        //compute sea areas Of Operation Choice
        const isPassengerBoat = Number(this.props.vesselTypeId) === vesselTypes.PassengerBoat;
        const isPassengerBoatP5 = isPassengerBoat && Number(this.props.vesselSubTypeId) === passengerBoatSubTypes.P5;
        const isMerchantOrFvOver24 = Number(this.props.vesselTypeId) === vesselTypes.MerchantVessel ||
            (Number(this.props.vesselTypeId) === vesselTypes.FishingVessel && this.props.vesselLength > 24);
        const seaAreaOfOperationChoices = this.getSeaAreaChoices(this.props.seaAreasOfOperation, isMerchantOrFvOver24,
            isPassengerBoat, isPassengerBoatP5, this.props.selectedCertificateType);

        const isShipOwerWithNonIssuedLicence = this.props.role === "Ship Owner" && (this.props.licenceApplicationStatusTypeId === LicenceStatus.InProgress
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.ReadyToReview);

        return (
            <React.Fragment>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    licenceList={t("AdminLandingPage.LicenceList")}
                    dashboard={t("Common.Dashboard")}
                    parentLink=""
                    parentPageName=""
                    pageName={t("AddVessel.AddVesselTitleLabel")} />

                <PageHeader
                    pageTitle={t("AddVessel.AddVesselTitleLabel")}
                    vesselLabel={t("OwnerLandingPage.VesselName")}
                    vesselName={this.props.name}
                    isShipOwerWithNonIssuedLicence={isShipOwerWithNonIssuedLicence}
                    mmsiLabel={t("OwnerLandingPage.MMSINumber")}
                    mmsiNumber={this.props.mmsiNumber}
                    callSignLabel={t("OwnerLandingPage.CallSign")}
                    callSignNumber={this.props.callSignNumber}
                    pageDescription={t("AddVessel.StepAddVesselPageNumberLabel")}
                    requiredField={t("Common.RequiredField")} />

            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label id="vesselNameLabel" htmlFor="vesselName" className="form-label">{t("AddVessel.VesselNameLabel")} *</label>
                        <input id="vesselName" type="text" max="50"
                               className="form-control" required
                               disabled={isReadOnlyPage}
                               onChange={this.props.onVesselNameChanged}
                               onBlur={this.props.onVesselNameChangeCompleted}
                               value={this.props.name}
                               data-vessel-id={this.props.id}/>
                        <small id="vesselNameHelp" className="form-text text-muted" tabIndex="0">{t("AddVessel.VesselNameInformation")}</small>
                        {
                            this.props.nameInUse &&
                            <ValidationFailureMessage>
                                {t("AddVessel.VesselNameInUse")}
                            </ValidationFailureMessage>
                        }
                        {
                            this.props.nameRequired &&
                            <ValidationFailureMessage>
                                {t("AddVessel.VesselNameRequired")}
                            </ValidationFailureMessage>
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label id="vesselTypeLabel" htmlFor="vesselTypeId" className="form-label">{t("AddVessel.VesselTypeLabel")}: *</label>
                        <select id="vesselTypeId"
                                className="form-control" required
                                disabled={isReadOnlyPage}
                                onChange={this.props.onVesselTypeSelected}
                                onBlur={this.props.onVesselTypeSelectionCompleted}
                                value={this.props.vesselTypeId}
                        >
                                <option value="">{t("AddVessel.VesselTypeSelect")}</option>
                                {
                                    vesselTypeChoices.map(vesselType => (
                                        <option key={vesselType.id} value={vesselType.id}>{vesselType.name}</option>
                                    ))
                                }
                        </select>
                        {
                            this.props.vesselTypeRequired &&
                            <ValidationFailureMessage>
                                {t("AddVessel.VesselTypeRequired")}
                            </ValidationFailureMessage>
                        }
                    </div>
                </div>
                {
                        this.props.hasVesselSubTypes &&
                        <div className="col-md-6">
                                <div className="form-group">
                                    <label id="vesselSubTypeLabel" htmlFor="vesselSubTypeId" className="form-label">{t("AddVessel.VesselSubTypeLabel")}</label>
                                    <select id="vesselSubTypeId"
                                        className="form-control" required
                                        disabled={isReadOnlyPage}
                                        onChange={this.props.onVesselSubTypeSelected}
                                        onBlur={this.props.onVesselSubTypeSelectionCompleted}
                                        value={this.props.vesselSubTypeId}
                                    >
                                    <option value="">{t("AddVessel.VesselTypeSelect")}</option>
                                    {
                                        this.props.vesselSubTypes.filter(vesselType => vesselType.vesselTypeId === Number(this.props.vesselTypeId)
                                        ).map(vesselSubType => (
                                            <option key={vesselSubType.id}
                                                value={vesselSubType.id}>
                                                {vesselSubType.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            {
                                this.props.vesselSubTypeRequired &&
                                <ValidationFailureMessage>
                                    {t("AddVessel.VesselSubTypeRequired")}
                                </ValidationFailureMessage>
                            }
                            </div>
                        </div>
                    }
                {
                        this.props.isOtherVesselType &&
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label id="otherVesselLabel" htmlFor="otherVesselType" className="form-label">{t("AddVessel.OtherVesselTypeDescription")}</label>
                                    <input id="otherVesselType"
                                        className="form-control"
                                        disabled={isReadOnlyPage}
                                        onChange={this.props.onOtherVesselChanged}
                                        value={this.props.otherVesselTypeDescription}
                                    />
                                </div>
                        </div>
                    }

                <div className="col-md-6">
                    <div className="form-group">
                        <label id="maxVesselCapacityLabel" htmlFor="maxVesselCapacity" className="form-label">{t("AddVessel.VesselMaxCapacityLabel")}: *</label>
                        <input id="maxVesselCapacity" type="Number" min="0" max="9999"
                               className="form-control" required
                               disabled={isReadOnlyPage}
                               onChange={this.props.onMaxVesselCapacityChanged}
                               onBlur={this.props.onMaxVesselCapacityChangeCompleted}
                               value={this.props.maxVesselCapacity}
                        />
                        {
                                this.props.maxVesselCapacityRequired &&
                                <ValidationFailureMessage>
                                    {t("AddVessel.VesselMaxCapacityRequired")}
                                </ValidationFailureMessage>
                            }
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label id="vesselLengthLabel" htmlFor="vesselLength" className="form-label">{t("AddVessel.VesselLengthLabel")}: *</label>
                        <input id="vesselLength" type="Number" min="0" step=".1" max="999"
                               className="form-control" required
                               disabled={isReadOnlyPage}
                               onChange={this.props.onVesselLengthChanged}
                               onBlur={this.props.onVesselLengthChangeCompleted}
                               value={this.props.vesselLength}
                        />
                        <small id="vesselLengthHelp" className="form-text text-muted" tabIndex="0">{t("AddVessel.VesselLengthInformation")}</small>
                        {
                                    this.props.vesselLengthRequired &&
                                    <ValidationFailureMessage>
                                        {t("AddVessel.VesselLengthRequired")}
                                    </ValidationFailureMessage>
                                }
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label id="grossTonnageLabel" htmlFor="grossTonnage" className="form-label">{t("AddVessel.VesselGrossTonnageLabel")}: *</label>
                        <input id="grossTonnage" type="Number" min="0" step=".1"
                               className="form-control" required
                               disabled={isReadOnlyPage}
                               onChange={this.props.onGrossTonnageChanged}
                               onBlur={this.props.onGrossTonnageChangeCompleted}
                               value={this.props.grossTonnage}
                        />
                        <small id="grossTonnageHelp" className="form-text text-muted" tabIndex="0">{t("AddVessel.VesselGrossTonnageInformation")}</small>
                        {
                                this.props.grossTonnageRequired &&
                                <ValidationFailureMessage>
                                    {t("AddVessel.VesselGrossTonnageRequired")}
                                </ValidationFailureMessage>
                            }
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label id="hullColorLabel" htmlFor="hullColorId" className="form-label">{t("AddVessel.VesselHullColorLabel")}: *</label>
                        <select id="hullColorId"
                                className="form-control"
                                disabled={isReadOnlyPage}
                                onChange={this.props.onHullColorSelected}
                                onBlur={this.props.onHullColorChangeCompleted}
                                value={this.props.hullColorId}
                        >
                            <option value="">{t("Common.ButtonText.Select")}</option>
                            {
                                        this.props.hullColors.map(hullColor => (
                                            <option key={hullColor.id} value={hullColor.id}>{hullColor.name}</option>
                                        ))
                                    }
                        </select>
                        <small id="hullColorHelp" className="form-text text-muted" tabIndex="0">{t("AddVessel.VesselHullColorInformation")}</small>
                        {
                                this.props.hullColorRequired &&
                                <ValidationFailureMessage>
                                        {t("AddVessel.VesselHullColorRequired")}
                                </ValidationFailureMessage>
                            }
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label id="vesselDescriptionLabel" htmlFor="vesselDescription" className="form-label"> {t("AddVessel.VesselDescriptionLabel")}: *</label>
                        <input id="vesselDescription" type="text"
                               className="form-control" required
                               disabled={isReadOnlyPage}
                               onChange={this.props.onVesselDescriptionChanged}
                               onBlur={this.props.onVesselDescriptionChangeCompleted}
                               value={this.props.vesselDescription}
                        />
                        <small id="vesselDescriptionHelp" className="form-text text-muted" tabIndex="0">{t("AddVessel.VesselDescriptionInformation")}</small>
                        {
                                this.props.vesselDescriptionRequired &&
                                <ValidationFailureMessage>
                                    {t("AddVessel.VesselDescriptionRequired")}
                                </ValidationFailureMessage>
                            }
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <div id="vesselPhotoUploadLabel" role="heading" aria-level="2" className="form-label"> {t("AddVessel.VesselPhotoUploadLabel")}</div>
                        {
                                !isReadOnlyPage && this.props.vesselImages.length < 6 &&
                                <FileUploader
                                    id="vesselPhotoUpload"
                                    buttonText={t("Common.ButtonText.UploadImage")}
                                    onChange={this.props.uploadVesselPhoto} 
                                    onError={this.props.uploadErrorMessage } />
                            }
                            {
                                this.props.vesselImageError !== "" &&
                                <ValidationFailureMessage>
                                    {this.props.vesselImageError}
                                </ValidationFailureMessage>
                            }
                            <div tabIndex="0"> {this.props.vesselImages.length} {t("Common.ButtonText.ImagesUploaded")}</div>
                            {
                                this.props.vesselImages.length > 0 &&
                                <div className="card mt-3">
                                    <div className="card-header">{t("AddVessel.ListOfVesselImages")}</div>
                                    <ul className="list-group list-group-flush">
                                        {this.props.vesselImages &&
                                            this.props.vesselImages.map((img, index) => (
                                                <li className="list-group-item" key={index}>
                                                    <div className="float-start">
                                                        <a href={"/viewLicenceImage?id=" + img.id} target="_blank" rel="noreferrer">{img.name}</a>
                                                    </div>
                                                    {
                                                        !isReadOnlyPage &&
                                                        <div className="float-end">
                                                            <button onClick={() => this.props.deleteLicenceImage(img)} style={{border:"0", backgroundColor:"#fff"}}>
                                                                <FontAwesomeIcon icon={faTrash} style={{ color: "red", fontSize: "4px", cursor: "pointer" }} />
                                                            </button>
                                                        </div>
                                                    }
                                           
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            }
                        <small id="vesselPhotoUploadHelp" className="form-text text-muted" tabIndex="0">{t("AddVessel.VesselPhotoUploadInformation")}</small>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label id="homeportLabel" htmlFor="homeport" className="form-label">{t("AddVessel.VesselHomeportLabel")}: *</label>
                        <input id="homeport" type="text" max="255"
                               className="form-control" required
                               disabled={isReadOnlyPage}
                               onChange={this.props.onHomeportChanged}
                               onBlur={this.props.onHomeportChangeCompleted}
                               value={this.props.homeport}
                        />
                        {
                                this.props.homeportRequired &&
                                <ValidationFailureMessage>
                                    {t("AddVessel.VesselHomeportRequired")}
                                </ValidationFailureMessage>
                            }
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label id="maxAreaOfOperationLabel" htmlFor="maxAreaOfOperationId" className="form-label">{t("AddVessel.VesselSeaAreaOfOperationLabel")}: *</label>
                        <select id="maxAreaOfOperationId"
                                className="form-control" required
                                disabled={isReadOnlyPage}
                                onChange={this.props.onSeaAreaOfOperationSelected}
                                onBlur={this.props.onSeaAreaOfOperationSelectionCompleted}
                                value={this.props.seaAreaOfOperationId}
                        >
                            <option value="">{t("Common.ButtonText.Select")}</option>
                                {
                                    seaAreaOfOperationChoices.map(seaAreaOfOperation => (
                                        <option key={seaAreaOfOperation.id} value={seaAreaOfOperation.id}>{seaAreaOfOperation.name}</option>
                                    ))
                                }
                        </select>
                        {
                                this.props.seaAreaOfOperationRequired &&
                                <ValidationFailureMessage>
                                    {t("AddVessel.VesselSeaAreaOfOperationRequired")}
                                </ValidationFailureMessage>
                            }
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label id="vesselRegistrationNumberLabel" htmlFor="vesselRegistrationNumber" className="form-label"> {t("AddVessel.VesselRegistrationNumberLabel")}:</label>
                        <input id="vesselRegistrationNumber" type="text"
                               className="form-control"
                               disabled={isReadOnlyPage}
                               onChange={this.props.onVesselRegistrationNumberChanged}
                               value={this.props.vesselRegistrationNumber}
                        />
                        <small id="vesselRegistrationNumberHelp" className="form-text text-muted" tabIndex="0">{t("AddVessel.VesselRegistrationNumberInformation")}</small>
                    </div>
                </div>
            </div>

            <div className="row" hidden={hideFVNumberField}>
                <div className="col-md-12">
                    <label id="fvVesselIdentificationNumberLabel" htmlFor="fvIdPrefixId" className="form-label"> {t("AddVessel.VesselFvIdNumberLabel")} </label>
                </div>
                <div className="col-md-3">
                    <select id="fvIdPrefixId"
                            className="form-control"
                            disabled={isReadOnlyPage}
                            onChange={this.props.onFvIdPrefixSelected}
                            value={this.props.fvIdPrefixId}
                    >
                        <option value="">{t("Common.ButtonText.Select")}</option>
                        {
                                        this.props.fishingVesselPrefixes.map(fvIdPrefix => (
                                            <option key={fvIdPrefix.id} value={fvIdPrefix.id}>{fvIdPrefix.name}</option>
                                        ))
                                    }
                    </select>
                </div>
                <div className="col-md-6">
                    <input id="fvVesselIdentificationNumber" type="text"
                           className="form-control"
                           disabled={isReadOnlyPage}
                           onChange={this.props.onFvVesselIdentificationNumberChanged}
                           value={this.props.fvVesselIdentificationNumber}
                    />
                </div>
                <div className="col-md-3">
                    <select id="fvIdPostfixId"
                            className="form-control"
                            disabled={isReadOnlyPage}
                            onChange={this.props.onFvIdPostfixSelected}
                            value={this.props.fvIdPostfixId}
                    >
                        <option value="">{t("Common.ButtonText.Select")}</option>
                        {
                                        this.props.fishingVesselPostfixes.map(fvIdPostfix => (
                                            <option key={fvIdPostfix.id} value={fvIdPostfix.id}>{fvIdPostfix.name}</option>
                                        ))
                                    }
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6" hidden={hideIMONumberField}>
                    <div className="form-group">
                        <label id="vesselImoNumberLabel" htmlFor="vesselImoNumber" className="form-label">{t("AddVessel.VesselImoNumberLabel")}:</label>
                        <input id="vesselImoNumber" type="text"
                               className="form-control"
                               disabled={isReadOnlyPage}
                               onChange={this.props.onVesselImoNumberChanged}
                               onBlur={this.props.onVesselImoNumberChangeCompleted}
                               value={this.props.vesselImoNumber}
                        />
                        <small id="vesselImoNumberHelp" className="form-text text-muted" tabIndex="0">{t("AddVessel.VesselImoNumberInformation")}</small>
                        {
                                this.props.vesselImoNumberInvalid &&
                                <ValidationFailureMessage>
                                    {t("AddVessel.VesselImoNumberInvalid")}
                                </ValidationFailureMessage>
                            }
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label id="numberOfLifeBoatsLabel" htmlFor="numberOfLifeBoats" className="form-label">{t("AddVessel.VesselNumberOfLifeBoatsLabel")}:</label>
                        <input id="numberOfLifeBoats" type="Number"
                               className="form-control"
                               disabled={isReadOnlyPage}
                               onChange={this.props.onNumberOfLifeBoatsChanged}
                               value={this.props.numberOfLifeBoats}
                        />
                        <small id="numberOfLifeBoatsHelp" className="form-text text-muted" tabIndex="0">{t("AddVessel.VesselNumberOfLifeBoatsInformation")}</small>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label id="accountingAuthorityIdentificationCodeLabel" htmlFor="accountingAuthorityIdentificationCode" className="form-label"> {t("AddVessel.VesselAAICLabel")}</label>
                        <select id="accountingAuthorityIdentificationCode"
                                className="form-control"
                                disabled={isReadOnlyPage}
                                onChange={this.props.onAccountingAuthorityIdentificationCodeChanged}
                                value={this.props.accountingAuthorityIdentificationCodeId}
                        >
                            <option value="">N/A</option>
                            {
                                            this.props.accountingAuthorityIdentificationCodes.map(accountingAuthorityIdentificationCode => (
                                                <option key={accountingAuthorityIdentificationCode.id}
                                                    value={accountingAuthorityIdentificationCode.id}>
                                                    {accountingAuthorityIdentificationCode.name}
                                                </option>
                                            ))
                                        }
                        </select>
                        <small id="accountingAuthorityHelp" className="form-text text-muted" tabIndex="0">{t("AddVessel.VesselAAICInformation")}</small>
                    </div>
                </div>
            </div>

            <div className="d-grid gap-2 d-md-flex justify-content-md-end my-2">
                {
                            !isReadOnlyPage &&
                            <LinkButton
                                id="addVesselButton"
                                className="btn btn-primary"
                                disabled={canSubmitForm}
                                to={this.props.parentRoute + "/emergency-contacts-list/" + this.props.licenceApplicationId}
                                onClick={() => this.props.addVessel(this.props.licenceApplicationId, this.props.id,
                                    this.props.licenceApplicationStatusTypeId, this.props.applicationTypeId)}
                            >
                                {t("Common.ButtonText.SaveAndNext")}
                            </LinkButton>
                        }
                        {
                            isReadOnlyPage &&
                            <LinkButton
                                id="addVesselButton"
                                className="btn btn-primary"
                                to={this.props.parentRoute + "/emergency-contacts-list/" + this.props.licenceApplicationId}
                            >
                                {t("Common.ButtonText.Next")}
                            </LinkButton>
                        }
            </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: state.currentVessel.id || 0,
        licenceApplicationId: state.currentApplication.id || 0,
        licenceApplicationStatusTypeId: state.currentApplication.licenceApplicationStatusTypeId || 0,
        role: state.currentUserInfo.role || "",

        name: state.currentVessel.name || "",
        nameInUse: state.currentVessel.nameInUse || false,
        vesselTypeId: state.currentVessel.vesselTypeId || "",
        vesselSubTypeId: state.currentVessel.vesselSubTypeId || "",
        hasVesselSubTypes: state.currentVessel.hasVesselSubTypes ||
            (Number(state.currentVessel.vesselTypeId) === 1 || Number(state.currentVessel.vesselTypeId) === 3 ||
            Number(state.currentVessel.vesselTypeId) === 4) ? true : false,
        isOtherVesselType: state.currentVessel.isOtherVesselType || false,
        otherVesselTypeDescription: state.currentVessel.otherVesselTypeDescription || "",

        hullColorId: state.currentVessel.hullColorId || "",
        seaAreaOfOperationId: state.currentVessel.seaAreaOfOperationId || "",
        vesselTypes: state.availableVesseltypes,
        vesselSubTypes: state.availableVesselSubTypes,

        hullColors: state.availableHullColor,
        seaAreasOfOperation: state.availableSeaAreaOfOperation,
        accountingAuthorityIdentificationCodes: state.accountingAuthorityIdentificationCodes,
        fishingVesselPrefixes: state.fishingVesselPrefixes,
        fishingVesselPostfixes: state.fishingVesselPostfixes,

        maxVesselCapacity: state.currentVessel.maxCapacityOfPersonsOnBoard || "",
        vesselLength: state.currentVessel.vesselLength || "",
        grossTonnage: state.currentVessel.grossTonnage || "",
        vesselDescription: state.currentVessel.description || "",
        homeport: state.currentVessel.homePort || "",
        vesselRegistrationNumber: state.currentVessel.registerationNumber || "",
        fvIdPostfixId: state.currentVessel.fishingVesselPostfixTypeId || "",
        fvVesselIdentificationNumber: state.currentVessel.fvVesselIdentificationNumber || "",
        fvIdPrefixId: state.currentVessel.fishingVesselPrefixTypeId || "",
        vesselImoNumber: state.currentVessel.imoNumber || "",
        numberOfLifeBoats: state.currentVessel.numberOfLifeBoats || "",
        accountingAuthorityIdentificationCodeId: state.currentVessel.accountingAuthorityIdentificationCodeId || "",
        selectedCertificateType: state.currentApplication.certificateTypeId | "",
        applicationTypeId: state.currentApplication.applicationTypeId || 1,
        vesselImages: state.vesselImages || [],

        callSignNumber: state.currentVessel.callSignNumber || "",
        mmsiNumber: state.currentVessel.mmsiNumber || "",

        // validations
        nameRequired: state.currentVessel.nameRequired || false,
        vesselTypeRequired: state.currentVessel.vesselTypeRequired || false,
        vesselSubTypeRequired: state.currentVessel.vesselSubTypeRequired || false,
        maxVesselCapacityRequired: state.currentVessel.maxVesselCapacityRequired || false,
        vesselLengthRequired: state.currentVessel.vesselLengthRequired || false,
        grossTonnageRequired: state.currentVessel.grossTonnageRequired || false,
        hullColorRequired: state.currentVessel.hullColorRequired || false,
        vesselDescriptionRequired: state.currentVessel.vesselDescriptionRequired || false,
        homeportRequired: state.currentVessel.homeportRequired || false,
        seaAreaOfOperationRequired: state.currentVessel.seaAreaOfOperationRequired || false,
        vesselImageError: state.currentVessel.vesselImageError || "",
        vesselImoNumberInvalid: state.currentVessel.vesselImoNumberInvalid || false
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getVesselTypes: () => {
            dispatch(CreateGetVesselTypesAction());
        },
        getVesselSubTypes: () => {
            dispatch(CreateGetVesselSubTypesAction());
        },
        getHullColors: () => {
            dispatch(CreateGetHullColoursAction());
        },
        getAccountingAuthorityIdentificationCodes: () => {
            dispatch(CreateGetAccountingAuthorityIdentificationCodesAction());
        },
        getFishingVesselPrefixes: () => {
            dispatch(CreateGetFishingVesselPrefixesAction());
        },
        getFishingVesselPostfixes: () => {
            dispatch(CreateGetFishingVesselPostfixesAction());
        },
        getSeaAreasOfOperation: () => {
            dispatch(CreateGetAllSeaAreasOfOperationAction());
        },
        getVesselImages: (licenceApplicationId) => {
            if (licenceApplicationId > 0) {
                dispatch(CreateGetVesselImagesAction(licenceApplicationId));
            }
        },

        addVessel: (licenceApplicationId, vesselId, licenceApplicationStatusTypeId, applicationTypeId) => {

            //Save licence
            dispatch(CreateSaveLicenceApplicationAction(applicationTypeId));

            // update vessel if id is not zero, create a new record otherwise update the existing record
            if (Number(vesselId) === 0) {
                dispatch(CreateAddVesselAction(licenceApplicationId));
            }
            else {
                if ((licenceApplicationStatusTypeId === 4 || licenceApplicationStatusTypeId === 5)) {
                    //update vessel and add/update vesselAmendment record
                    //check if additional payment required. for issued or dispatched licences not already amended
                    //refresh IsValid depending on vessel changes
                    dispatch(CreateUpdateIssuedVesselAction(licenceApplicationId));
                } else {
                    //update vessel
                    //refresh IsValid if status not 1. In-Progress....depending on vessel changes
                    dispatch(CreateUpdateVesselAction());
                }
            }
        },
        onVesselNameChanged: (evt) => {
            var updatedName = evt.target.value;
            if (updatedName.length > 50) {
                return;
            }
            dispatch({
                type: "UPDATE_CURRENT_VESSEL_NAME",
                data: updatedName
            });

            var vesselId = 0;

            if (evt.target.dataset.vesselId) {
                vesselId = Number(evt.target.dataset.vesselId);
            }

            dispatch(CreateCheckIfNamedVesselExistsAction(updatedName.trim(), vesselId));
        },
        onVesselNameChangeCompleted: (evt) => {
            var updatedName = evt.target.value;

            if (updatedName.trim() === "") {
                dispatch({
                    type: "UPDATE_VESSEL_NAME_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_NAME_REQUIRED",
                    data: false
                });
            }
        },

        onMaxVesselCapacityChanged: (evt) => {
            var maxCapacity = evt.target.value;
            if (isNaN(maxCapacity) || Number(maxCapacity) < 0 || Number(maxCapacity) > 999) {
                return;
            }

            if (maxCapacity.indexOf(".") > -1) {
                return;
            }
            dispatch({
                type: "UPDATE_VESSEL_MAX_CAPACITY",
                data: maxCapacity
            });
        },
        onMaxVesselCapacityChangeCompleted: (evt) => {
            var maxCapacity = evt.target.value;

            if (maxCapacity === "" || maxCapacity === null || maxCapacity.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_MAX_CAPACITY_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_MAX_CAPACITY_REQUIRED",
                    data: false
                });
            }
        },

        onVesselLengthChanged: (evt) => {
            var vesselLength = evt.target.value;

            if (isNaN(vesselLength) || Number(vesselLength) < 0 || Number(vesselLength) > 999) {
                return;
            }

            if (vesselLength.indexOf('.') > -1) {
                const vesselLengthCharCount = vesselLength.length;

                if (vesselLength.indexOf('.') < vesselLengthCharCount - 2) {
                    return;
                }
            }

            dispatch({
                type: "UPDATE_VESSEL_LENGTH",
                data: vesselLength
            });
        },
        onVesselLengthChangeCompleted: (evt) => {
            var vesselLength = evt.target.value;
            if (vesselLength === "" || vesselLength === null || vesselLength.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_LENGTH_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_LENGTH_REQUIRED",
                    data: false
                });
            }
        },
        onGrossTonnageChanged: (evt) => {
            var updatedGrossTonnage = evt.target.value;

            if (isNaN(updatedGrossTonnage)|| Number(updatedGrossTonnage) < 0 || Number(updatedGrossTonnage) > 999999) {
                return;
            }

            if (updatedGrossTonnage.indexOf('.') > -1) {
                const updatedGrossTonnageCharCount = updatedGrossTonnage.length;

                if (updatedGrossTonnage.indexOf('.') < updatedGrossTonnageCharCount - 2) {
                    return;
                }
            }

            dispatch({
                type: "UPDATED_GROSS_TONNAGE",
                data: updatedGrossTonnage
            });
        },
        onGrossTonnageChangeCompleted: (evt) => {
            var updatedGrossTonnage = evt.target.value;
            if (updatedGrossTonnage === "" || updatedGrossTonnage === null || updatedGrossTonnage.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_GROSS_TONNAGE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_GROSS_TONNAGE_REQUIRED",
                    data: false
                });
            }
        },
        onVesselDescriptionChanged: (evt) => {
            var updatedVesselDescription = evt.target.value;
            if (updatedVesselDescription.length > 400) {
                return;
            }
            dispatch({
                type: "UPDATED_VESSEL_DESCRIPTION",
                data: updatedVesselDescription
            });
        },
        onVesselDescriptionChangeCompleted: (evt) => {
            var updatedVesselDescription = evt.target.value;
            if (updatedVesselDescription.trim() === "") {
                dispatch({
                    type: "UPDATE_VESSEL_DESCRIPTION_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_DESCRIPTION_REQUIRED",
                    data: false
                });
            }
        },

        onHomeportChanged: (evt) => {
            var updatedHomeport = evt.target.value;
            if (updatedHomeport.length > 50) {
                return;
            }
            dispatch({
                type: "UPDATE_HOMEPORT",
                data: updatedHomeport
            });
        },
        onHomeportChangeCompleted: (evt) => {
            var updatedHomeport = evt.target.value;
            if (updatedHomeport.trim() === "") {
                dispatch({
                    type: "UPDATE_VESSEL_HOMEPORT_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_HOMEPORT_REQUIRED",
                    data: false
                });
            }
        },

        onVesselRegistrationNumberChanged: (evt) => {
            var updateVesselRegistrationNumber = evt.target.value;
            if (updateVesselRegistrationNumber.length > 7) {
                return;
            }
            dispatch({
                type: "UPDATE_VESSEL_REGISTRATION_NUMBER",
                data: updateVesselRegistrationNumber
            });
        },
        onFvIdPrefixSelected: (evt) => {
            var updateFvIdPrefix = evt.target.value;

            dispatch({
                type: "UPDATE_FV_VESSEL_ID_PREFIX",
                data: updateFvIdPrefix
            });
        },
        onFvVesselIdentificationNumberChanged: (evt) => {
            var updateFvVesselIdentificationNumber = evt.target.value;
            if (updateFvVesselIdentificationNumber.includes(".") || isNaN(updateFvVesselIdentificationNumber) || updateFvVesselIdentificationNumber.length > 4) {
                return;
            }
            dispatch({
                type: "UPDATE_FV_VESSEL_ID_NUMBER",
                data: updateFvVesselIdentificationNumber
            });
        },
        onFvIdPostfixSelected: (evt) => {
            var updateFvIdPostfix = evt.target.value;

            dispatch({
                type: "UPDATE_FV_VESSEL_ID_POSTFIX",
                data: updateFvIdPostfix
            });
        },
        onVesselImoNumberChanged: (evt) => {
            var updatedVesselImoNumber = evt.target.value;

            if (updatedVesselImoNumber.includes(".") || isNaN(updatedVesselImoNumber) || updatedVesselImoNumber.length > 7) {
                return;
            }

            dispatch({
                type: "UPDATE_VESSEL_IMO_NUMBER",
                data: updatedVesselImoNumber
            });
        },
        onVesselImoNumberChangeCompleted: (evt) => {
            /*
            An IMO number is made of the three letters "IMO" followed by a seven-digit number.
            This consists of a six-digit sequential unique number followed by a check digit.
            The integrity of an IMO number can be verified using its check digit.
            This is done by multiplying each of the first six digits by a factor of 2 to 7 corresponding to their position from right to left.
            The rightmost digit of this sum is the check digit. For example, for IMO 9074729: (9�7) + (0�6) + (7�5) + (4�4) + (7�3) + (2�2) = 139
             */
            var updatedVesselImoNumber = evt.target.value;

            if (updatedVesselImoNumber === "" || updatedVesselImoNumber === null || updatedVesselImoNumber.length === 0) {
                dispatch({
                    type: "INVALID_VESSEL_IMO_NUMBER",
                    data: false
                });
                return;
            }

            var isValid = updatedVesselImoNumber.length === 7;

            if (isValid) {
                //check integrity
                const updatedVesselImoString = updatedVesselImoNumber.toString();
                const updatedVesselImoCheck = (parseInt(updatedVesselImoString[0]) * 7) +
                    (parseInt(updatedVesselImoString[1]) * 6) +
                    (parseInt(updatedVesselImoString[2]) * 5) +
                    (parseInt(updatedVesselImoString[3]) * 4) +
                    (parseInt(updatedVesselImoString[4]) * 3) +
                    (parseInt(updatedVesselImoString[5]) * 2);
                const lastCheckDigit = updatedVesselImoCheck % 10;
                isValid = parseInt(updatedVesselImoString[6]) === lastCheckDigit;
            }

            dispatch({
                type: "INVALID_VESSEL_IMO_NUMBER",
                data: !isValid
            });

        },
        onNumberOfLifeBoatsChanged: (evt) => {
            var updatedNumberOfLifeBoats = evt.target.value;
            if (updatedNumberOfLifeBoats > 100) {
                return;
            }
            dispatch({
                type: "UPDATE_LIFE_BOATS",
                data: updatedNumberOfLifeBoats
            });
        },
        onAccountingAuthorityIdentificationCodeChanged: (evt) => {
            var updatedAccountingAuthorityIdentificationCode = evt.target.value;
            dispatch({
                type: "UPDATE_ACCOUNTING_AUTHORITY_ID_CODE",
                data: updatedAccountingAuthorityIdentificationCode
            });
        },

        onVesselTypeSelected: (evt) => {
            var selectedVesselType = evt.target.value;

            dispatch({
                type: "UPDATE_CURRENT_VESSEL_TYPE",
                data: selectedVesselType
            });

            //const VesselTypes = {
            //    PleasureVessel: 1,
            //    FishingVessel: 2,
            //    PassengerVessel: 3,
            //    PassengerShip: 4,
            //    MerchantVessel: 5,
            //    NavalShip: 6,
            //    RescueVessel: 7
            //};

            var isCommercial = selectedVesselType === "2" || selectedVesselType === "3"
                                || selectedVesselType === "4" || selectedVesselType === "5";
            if (isCommercial) {
                dispatch({
                    type: "UPDATE_DEFAULT_EPIRB_MOUNTINGTYPE",
                    data: "2"
                });
                dispatch({
                    type: "UPDATE_IS_COMMERCIAL_VESSEL_TYPE",
                    data: true
                });
            } else {
                    dispatch({
                        type: "UPDATE_DEFAULT_EPIRB_MOUNTINGTYPE",
                        data: "0"
                    });
                    dispatch({
                        type: "UPDATE_IS_COMMERCIAL_VESSEL_TYPE",
                        data: false
                    });
            }

            

            // show/hide the sub types
            if (selectedVesselType === "1" || selectedVesselType === "3" || selectedVesselType === "4") {
                dispatch({
                    type: "UPDATE_CURRENT_VESSEL_HAS_SUB_TYPE",
                    data: true
                });
                dispatch({
                    type: "UPDATE_CURRENT_VESSEL_SUB_TYPE",
                    data: null
                });
                dispatch({
                    type: "UPDATE_VESSEL_SUB_TYPE_REQUIRED",
                    data: true
                });
            }
            else {
                dispatch({
                    type: "UPDATE_CURRENT_VESSEL_SUB_TYPE",
                    data: null
                });
                dispatch({
                    type: "UPDATE_CURRENT_VESSEL_HAS_SUB_TYPE",
                    data: false
                });

            }

            // show/hide the other vessel text box
            if (selectedVesselType === "8") {
                dispatch({
                    type: "UPDATE_OTHER_VESSEL_TYPE_SELECTED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_OTHER_VESSEL_TYPE_SELECTED",
                    data: false
                });
                dispatch({
                    type: "UPDATE_OTHER_VESSEL_TEXT",
                    data: ""
                });
            }
        },
        onOtherVesselChanged: (evt) => {
            var selectedOtherVesselType = evt.target.value;

            dispatch({
                type: "UPDATE_OTHER_VESSEL_TEXT",
                data: selectedOtherVesselType
            });
        },
        onVesselSubTypeSelected: (evt) => {
            var selectedVesselType = evt.target.value;

            dispatch({
                type: "UPDATE_CURRENT_VESSEL_SUB_TYPE",
                data: selectedVesselType
            });
        },
        onVesselTypeSelectionCompleted: (evt) => {
            var selectedVesselType = evt.target.value;

            if (selectedVesselType === "" || selectedVesselType === null || selectedVesselType.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_TYPE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_TYPE_REQUIRED",
                    data: false
                });
            }
        },
        onVesselSubTypeSelectionCompleted: (evt) => {
            var selectedVesselType = evt.target.value;

            if (selectedVesselType === "" || selectedVesselType === null || selectedVesselType.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_SUB_TYPE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_SUB_TYPE_REQUIRED",
                    data: false
                });
            }
        },
        onHullColorSelected: (evt) => {
            var selectedHullColor = evt.target.value;

            dispatch({
                type: "UPDATE_VESSEL_HULLCOLOR",
                data: selectedHullColor
            });
        },
        onHullColorChangeCompleted: (evt) => {
            var updatedHullColor = evt.target.value;
            if (updatedHullColor === "" || updatedHullColor === null || updatedHullColor.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_HULL_COLOR_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_HULL_COLOR_REQUIRED",
                    data: false
                });
            }
        },
        onSeaAreaOfOperationSelected: (evt) => {
            var selectedSeaAreaOfOperation = evt.target.value;

            dispatch({
                type: "UPDATE_VESSEL_SEA_AREA_OF_OPERATION",
                data: selectedSeaAreaOfOperation
            });
        },
        onSeaAreaOfOperationSelectionCompleted: (evt) => {
            var selectedSeaAreaOfOperation = evt.target.value;
            if (selectedSeaAreaOfOperation === "" || selectedSeaAreaOfOperation === null || selectedSeaAreaOfOperation.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_SEA_AREA_OF_OPERATION_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_SEA_AREA_OF_OPERATION_REQUIRED",
                    data: false
                });
            }
        },
        uploadVesselPhoto: (file) => {
            dispatch(CreateUploadVesselPhotoAction(file));
            dispatch({
                type: "UPDATE_VESSEL_IMAGE_ERROR",
                data: ""
            });
        },
        uploadErrorMessage: (errMessage) => {
            dispatch({
                type: "UPDATE_VESSEL_IMAGE_ERROR",
                data: errMessage
            });
        },
        deleteLicenceImage: (img) => {
            dispatch(CreateDeleteLicenceImageAction(img.id,2));
        }
    }
}

const languageAwareComponent = withTranslation()(AddVessel);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);
