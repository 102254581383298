import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

export default function (applicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());


        //authService.getAccessToken().then((token) => {
        fetch(
            "epirbDetail/GetByLicenceApplicationId?licenceApplicationId=" + applicationId,
            {
                //              headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then((response) => response.json())
            .then((data) => {
                dispatch({
                    type: "EPIRB_DETAILS_RETRIEVED",
                    epirb: data
                });

                dispatch(CreateDataRetrievalEndedAction());
            })
            .catch((error) => {

                dispatch(CreateDataRetrievalEndedAction());
            });
        //});
    };
}
