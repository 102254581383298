const initialState = false;

export default (state = initialState, action) => {
    switch (action.type) {
        //case "DATA_RETRIEVAL_IN_PROGRESS":
        //    return true;

        //case "DATA_RETRIEVAL_ENDED":
        //    return false;

        case "SHOW_SPINNER":
            return true;

        case "HIDE_SPINNER":
            return false;

        default:
            return state;
    }
}

