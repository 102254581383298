import React, { Component } from "react";
import { connect } from "react-redux";

import ClipLoader from "react-spinners/ClipLoader";

class LoadingSpinner extends Component {
    render() {
        const color = "#ffffff";
        const loading = true;

        const override = `
          display: block;
          margin: 0 auto;
          border-color: red;
        `;

        if (this.props.showSpinner) {
            return (
                <div style={{ zIndex: "500"}}>
                    <ClipLoader color={color} loading={loading} css={override} size={150} />
                </div>
                
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        showSpinner: state.showSpinner
    };
};

export default connect(mapStateToProps)(LoadingSpinner);