const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_CURRENT_REVIEW_AND_DECLARATIONS_APPLICATION":
            return initialState;

        case "DECLARATIONS_CHECKBOX_UPDATED":
            return {
                ...state,
                declarationsCheckbox   : action.data
            }
        case "REVIEW_VESSEL_EQUIPMENT_COUNT_RETRIEVED":
            return {
                ...state,
                vesselEquipmentDetailsCount: action.data
            }

        default:
            return state;
    }
}
