import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { PUT} from "../adapters/xhr";

export default function () {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const { currentTransfer } = getState();

        const url = "LicenceApplication/RejectLicenceTransfer";

        PUT(url, currentTransfer)
            .then(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}