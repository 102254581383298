import axios from "axios";

// import authService from "../../components/api-authorization/AuthorizeService";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetLicenceApplicationsAction from "./CreateGetLicenceApplicationsAction";

export default function() {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());
        const { currentApplication } = getState();

        const updatedLicenceApplication = {
            id: Number(currentApplication.id),
            certificateTypeId: Number(currentApplication.certificateTypeId),
            certificateIssuedByOtherAdministration: currentApplication.certificateIssuedByOtherAdministration,
            certificateNumber: currentApplication.certificateNumber,
            salutationId: Number(currentApplication.salutationId),
            nameOnCertificate: currentApplication.nameOnCertificate,
            licenceApplicationStatusTypeId: 1,
            dateOfBirth: new Date(currentApplication.dateOfBirth) //.toISOString() //.toUTCString()
        };

        if (!isNaN(updatedLicenceApplication.dateOfBirth)) {
            var dob = updatedLicenceApplication.dateOfBirth;
            dob.setHours(4, 4, 4, 4);
            updatedLicenceApplication.dateOfBirth = dob.toISOString();
        }

        //updatedLicenceApplication.dateOfBirth = updatedLicenceApplication.dateOfBirth.toISOString();

        // authService.getAccessToken().then((token) => {
                axios({
                    method: "POST",
                    url: "licenceApplication/Submit",
                    // headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    data: updatedLicenceApplication
                })
                    .then((response) => {
                        dispatch(CreateGetLicenceApplicationsAction());
                    })
                    .catch((error) => {
                        dispatch(CreateDataRetrievalEndedAction());
                    });
            // });
    };
}
