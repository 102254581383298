import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";
import LinkButton from "./LandingPage/LinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import CreateCertificateNumberUpdatedAction from "../store/actionCreators/CreateCertificateNumberUpdatedAction";
import CreateGetCertificateTypesAction from "../store/actionCreators/CreateGetCertificateTypesAction";
import CreateGetSalutationsAction from "../store/actionCreators/CreateGetSalutationsAction";
import CreateGetLicenceImagesAction from "../store/actionCreators/CreateGetLicenceImagesByLicenceIdAction";
import CreateSubmitLicenceApplicationAction from "../store/actionCreators/CreateSubmitLicenceApplicationAction";
import CreateSaveLicenceApplicationAction from "../store/actionCreators/CreateSaveLicenceApplicationAction";
import CreateUploadOperatorCertificatesAction from "../store/actionCreators/CreateUploadOperatorCertificatesAction";
import CreateGetCertificateNumbersValidationAction from "../store/actionCreators/CreateGetCertificateNumbersValidationAction";
import CreateGetDobValidationAction from "../store/actionCreators/CreateGetDobValidationAction";
import CreateDeleteLicenceImageAction from "../store/actionCreators/CreateDeleteLicenceImageAction";
import CreateCheckValidSeaAreaAction from "../store/actionCreators/CreateCheckValidSeaAreaAction";

import "react-datepicker/dist/react-datepicker.css";
import ValidationFailureMessage from "./ValidationFailureMessage";
import FileUploader from "./FileUploader";
import PageHeader from "./PageHeader";
import Breadcrumb from "./Breadcrumb";


class RadioOperatorCertificateDetails extends Component {
    componentDidMount() {
        this.props.getCertificateTypes();
        this.props.getSalutations();
    }

    render() {

        const { t } = this.props;
        const today = new Date();
        const eighteenYearsAgo = today.setFullYear(today.getFullYear() - 18);

        const nextPageUrl = this.props.licenceApplicationId === 0
            ? this.props.parentRoute + "/add-vessel"
            : this.props.parentRoute + "/add-vessel/" + this.props.licenceApplicationId;

        // hide the Next/submit button if required fields are missing
        var canSubmitForm = (
            this.props.certificateNumberRequired ||
                this.props.certificateNumber === "" ||
                this.props.certificateTypeIdRequired ||
                this.props.certificateTypeId === "" ||
                this.props.nameOnCertificateRequired ||
                this.props.nameOnCertificate === "" ||
                this.props.salutationRequired ||
                this.props.salutationId === "" ||
                this.props.dateOfBirthRequired ||
                this.props.dateOfBirth === "" ||
                (!this.props.certificateIssuedByOtherAdministration &&
                    (this.props.certificateNumberHasValidationErrors || this.props.dobHasValidationErrors)) ||
                    (this.props.certificateIssuedByOtherAdministration && this.props.licenceImages.length < 1)
        );

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6,
            TransferInitiated: 7,
            Transferred: 8
        };

        const newTransferLicence = this.props.applicationTypeId === 2;

        //cancelled, TransferInitiated or transferred Status
        const isReadOnlyPage = this.props.licenceApplicationStatusTypeId === LicenceStatus.Cancelled
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.TransferInitiated
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.Transferred;

        const isShipOwerWithNonIssuedLicence = this.props.role === "Ship Owner" && (this.props.licenceApplicationStatusTypeId == LicenceStatus.InProgress
            || this.props.licenceApplicationStatusTypeId == LicenceStatus.ReadyToReview);

        return (
            <React.Fragment>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    licenceList={t("AdminLandingPage.LicenceList")}
                    dashboard={t("Common.Dashboard")}
                    parentLink=""
                    parentPageName=""
                    pageName={t("LicenceApplication.RadioOperatorCertificateDetailsTitleLabel")} />

                <PageHeader
                    pageTitle={t("LicenceApplication.RadioOperatorCertificateDetailsTitleLabel")}
                    vesselLabel={t("OwnerLandingPage.VesselName")}
                    vesselName={this.props.vesselName}
                    isShipOwerWithNonIssuedLicence={isShipOwerWithNonIssuedLicence}
                    mmsiLabel={t("OwnerLandingPage.MMSINumber")}
                    mmsiNumber={this.props.mmsiNumber}
                    callSignLabel={t("OwnerLandingPage.CallSign")}
                    callSignNumber={this.props.callSignNumber}
                    pageDescription={t("LicenceApplication.StepOneOfFourLabel")}
                    requiredField={t("Common.RequiredField")} />

                <div className="form-group mt-3">
                    <label id="certificateTypeLabel" htmlFor="certificateTypeId" className="form-label">{t("LicenceApplication.CertificateTypeLabel")} *</label>
                    <select id="certificateTypeId"
                            className="form-control" required
                            disabled={isReadOnlyPage}
                            onChange={this.props.onCertificateTypeSelected}
                            onBlur={evt => this.props.onCertificateTypeSelectedChangeCompleted(evt,
                                this.props.certificateIssuedByOtherAdministration, this.props.dateOfBirth, this.props.certificateNumber)}
                            value={this.props.certificateTypeId}>
                        <option value="">{t("Common.ButtonText.PleaseSelect")} </option>
                        {
                            this.props.certificateTypes.map(certificateType => (
                                <option
                                    key={certificateType.id}
                                    value={certificateType.id}
                                >
                                    {certificateType.name}
                                </option>
                            ))
                        }
                    </select>
                    {
                        this.props.certificateTypeIdRequired &&
                        <ValidationFailureMessage>
                            {t("LicenceApplication.CertificateTypeInformation")}
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-check">
                    <input type="checkbox" id="CertificateIssuedByOtherAdministrationCheckbox" className="form-check-input"
                           disabled={isReadOnlyPage}
                           onChange={evt => this.props.certificateIssuedByOtherAdministrationCheckboxValueChanged(evt,
                               this.props.certificateNumber, this.props.dateOfBirth,
                               this.props.applicationTypeId, this.props.licenceApplicationId)}
                           value={this.props.certificateIssuedByOtherAdministration}
                           checked={this.props.certificateIssuedByOtherAdministration}
                    />
                    <label id="CertificateIssuedByOtherAdministrationLabel" htmlFor="CertificateIssuedByOtherAdministrationCheckbox" className="form-check-label form-label">
                        {t("LicenceApplication.CertificateIssuedByOtherAdministrationLabel")}
                    </label>
                </div>
                <div>
                    {
                        this.props.certificateIssuedByOtherAdministration &&
                        <div id="uploadOperatorCertificateLabel" role="heading" aria-level="2" className="form-label" tabIndex="0">
                            {t("LicenceApplication.CertificateIssuedByOtherAdministrationInformation")} *
                        </div>
                    }
                    {
                        this.props.certificateIssuedByOtherAdministration && this.props.licenceImages.length < 2 && !isReadOnlyPage &&
                        <FileUploader
                            id="uploadOperatorCertificateImagePicker"
                            buttonText={t("Common.ButtonText.UploadImage")}
                            onChange={this.props.uploadOperatorCertificate}
                            onError={this.props.uploadErrorMessage} />
                    }
                    {
                        this.props.licenceImageError !== "" &&
                        <ValidationFailureMessage>
                            {this.props.licenceImageError}
                        </ValidationFailureMessage>
                    }
                    {
                        this.props.certificateIssuedByOtherAdministration &&
                        <div tabIndex="0"> {this.props.licenceImages.length} {t("Common.ButtonText.ImagesUploaded")}</div>
                    }

                    {
                        this.props.certificateIssuedByOtherAdministration && this.props.licenceImages.length > 0 &&
                        <div className="card mt-3">
                            <div className="card-header">{t("LicenceApplication.ListOfCertificateImages")}</div>
                            <ul className="list-group list-group-flush">
                                {this.props.licenceImages &&
                                    this.props.licenceImages.map((img, index) => (
                                        <li className="list-group-item" key={index}>
                                            <div className="float-start">
                                                <a href={"/viewLicenceImage?id=" + img.id} target="_blank" rel="noreferrer">{img.name}</a>
                                            </div>
                                            {
                                                !isReadOnlyPage &&
                                                <div className="float-end">
                                                    <a onClick={() => this.props.deleteLicenceImage(img)}>
                                                        <FontAwesomeIcon icon={faTrash} style={{ color: "red", fontSize: "4px", cursor: "pointer" }} /></a>
                                                </div>
                                            }
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    }
                </div>

                <div className="form-group">
                    <label id="certificateNumberLabel" htmlFor="certificateNumber" className="form-label">{t("LicenceApplication.CertificateNumberLabel")}</label>
                    <input
                        id="certificateNumber" type="text"
                        disabled={isReadOnlyPage}
                        className="form-control" required
                        onChange={this.props.onCertificateNumberChanged}
                        onBlur={evt => this.props.onCertificateNumberChangedCompleted(evt,
                            this.props.certificateIssuedByOtherAdministration, this.props.dateOfBirth, this.props.certificateTypeId)}
                        value={this.props.certificateNumber}/>
                    <small id="certificateNumberHelp" className="form-text text-muted" tabIndex="0">{t("LicenceApplication.CertificateNumberInformation")}</small>
                    {
                        this.props.certificateNumberHasValidationErrors && !this.props.certificateIssuedByOtherAdministration &&
                        <ValidationFailureMessage>
                            {t("LicenceApplication.CertificateNumberValidation")}
                        </ValidationFailureMessage>
                    }
                    {
                        this.props.certificateNumberRequired &&
                        <ValidationFailureMessage>
                            {t("LicenceApplication.CertificateNumberRequired")}
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-group">
                    <label id="salutationLabel" htmlFor="salutationId" className="form-label">{t("LicenceApplication.SalutationLabel")}</label>
                    <select id="salutationId"
                            className="form-control" required
                            disabled={isReadOnlyPage}
                            onChange={this.props.onSalutationSelected}
                            value={this.props.salutationId}
                            onBlur={this.props.onSalutationSelectedChangeCompleted}>
                        <option value="">{t("Common.ButtonText.PleaseSelect")}</option>
                        {
                            this.props.salutations.map(salutation => (
                                <option
                                    key={salutation.id}
                                    value={salutation.id}
                                >
                                    {salutation.name}
                                </option>
                            ))
                        }
                    </select>
                    {
                        this.props.salutationRequired &&
                        <ValidationFailureMessage>
                            {t("LicenceApplication.SalutationRequired")}
                        </ValidationFailureMessage>
                    }
                    <label id="nameOnCertificateLabel" htmlFor="nameOnCertificate" className="form-label">{t("LicenceApplication.NameOnCertificateLabel")}</label>
                    <input
                        id="nameOnCertificate" type="text"
                        className="form-control" required
                        disabled={isReadOnlyPage}
                        onChange={this.props.onNameOnCertificateChanged}
                        onBlur={this.props.onNameOnCertificateChangeCompleted}
                        value={this.props.nameOnCertificate}/>
                    <small id="nameOnCertificateHelp" className="form-text text-muted">{t("LicenceApplication.NameOnCertificateInformation")}</small>
                    {
                        this.props.nameOnCertificateRequired &&
                        <ValidationFailureMessage>
                            {t("LicenceApplication.NameOnCertificateRequired")}
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-group">
                    <label id="dateOfBirthLabel" htmlFor="dateOfBirth" className="form-label">{t("LicenceApplication.DateOfBirthLabel")}</label>
                    <br/>
                    <DatePicker
                        id="dateOfBirth"
                        selected={this.props.dateOfBirth || eighteenYearsAgo} required
                        className="form-control"
                        disabled={isReadOnlyPage}
                        onChange={this.props.onDatePickerDateOfBirthChanged}
                        onBlur={date => this.props.onDobChangedCompleted(date,
                            this.props.certificateIssuedByOtherAdministration, this.props.certificateNumber)}
                        value={this.props.dateOfBirth}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        useShortMonthInDropdown
                        dropdownMode="select"
                        placeholderText="DD/MM/YYYY"
                    />
                    <br/>
                    <small id="dateOfBirthHelp" className="form-text text-muted" tabIndex="0">{t("LicenceApplication.DateOfBirthInformation")}</small>
                    {
                        this.props.dobHasValidationErrors && !this.props.certificateIssuedByOtherAdministration &&
                        <ValidationFailureMessage>
                            {t("LicenceApplication.DateOfBirthValidation")}
                        </ValidationFailureMessage>
                    }
                    {
                        this.props.dateOfBirthRequired && !this.props.dobHasValidationErrors &&
                        <ValidationFailureMessage>
                            {t("LicenceApplication.DateOfBirthRequired")}
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="d-grid gap-2 d-md-flex justify-content-md-end my-2">
                    {
                        (newTransferLicence || this.props.vesselId === 0) &&
                        <LinkButton
                            id="apply-button"
                            className="btn btn-primary"
                            disabled={canSubmitForm}
                            to={nextPageUrl}
                            onClick={() => this.props.saveCertificateDetails(this.props.applicationTypeId)}
                        >
                            {t("Common.ButtonText.SaveAndNext")}
                        </LinkButton>
                    }
                    {
                        !newTransferLicence && this.props.vesselId !== 0 && !isReadOnlyPage &&
                        <LinkButton
                            id="apply-button"
                            className="btn btn-primary"
                            disabled={canSubmitForm}
                            to={nextPageUrl}
                            onClick={() => this.props.setupVesselPage(this.props.certificateTypeId, this.props.seaAreaOfOperationId)}
                        >
                            {t("Common.ButtonText.Next")}
                        </LinkButton>
                    }
                    {
                        !newTransferLicence && this.props.vesselId !== 0 && isReadOnlyPage &&
                        <LinkButton
                            id="apply-button"
                            className="btn btn-primary"
                            to={nextPageUrl}
                        >
                            {t("Common.ButtonText.Next")}
                        </LinkButton>
                    }
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        licenceApplicationId: state.currentApplication.id || 0,
        role: state.currentUserInfo.role || "",

        certificateTypeId: state.currentApplication.certificateTypeId || "",
        certificateIssuedByOtherAdministration: state.currentApplication.certificateIssuedByOtherAdministration || false,
        certificateTypes: state.availableCertificateTypes || [],
        salutationId: state.currentApplication.salutationId || "",
        salutations: state.availableSalutations,
        nameOnCertificate: state.currentApplication.nameOnCertificate || "",
        certificateNumber: state.currentApplication.certificateNumber || "",
        dateOfBirth: state.currentApplication.dateOfBirth || "",
        licenceApplicationStatusTypeId: state.currentApplication.licenceApplicationStatusTypeId || 1,
        licenceImages: state.licenceImages || [],
        lastCertificateTypeId: state.currentApplication.lastCertificateTypeId || 0,
        applicationTypeId: state.currentApplication.applicationTypeId || 1,
        vesselId: state.currentVessel.id || 0,
        seaAreaOfOperationId: state.currentVessel.seaAreaOfOperationId || "",

        vesselName: state.currentVessel.name || "",
        callSignNumber: state.currentVessel.callSignNumber || "",
        mmsiNumber: state.currentVessel.mmsiNumber || "",

        // validations
        certificateNumberHasValidationErrors: state.currentApplication.certificateNumberHasValidationErrors || false,
        dobHasValidationErrors: state.currentApplication.dobHasValidationErrors || false,
        certificateNumberRequired: state.currentApplication.certificateNumberRequired || false,
        certificateTypeIdRequired: state.currentApplication.certificateTypeIdRequired || false,
        nameOnCertificateRequired: state.currentApplication.nameOnCertificateRequired || false,
        salutationRequired: state.currentApplication.salutationRequired || false,
        dateOfBirthRequired: state.currentApplication.dateOfBirthRequired || false,
        licenceImageError: state.currentApplication.licenceImageError || ""
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCertificateTypes: () => {
            dispatch(CreateGetCertificateTypesAction());
        },

        getSalutations: () => {
            dispatch(CreateGetSalutationsAction());
        },

        getLicenceImages: (licenceApplicationId) => {
            if (licenceApplicationId > 0) {
                dispatch(CreateGetLicenceImagesAction(licenceApplicationId));
            }
        },

        onCertificateTypeSelected: (evt) => {
            var updatedCertTypeId = evt.target.value;

            dispatch({
                type: "CERTIFICATE_TYPE_ID_UPDATED",
                data: updatedCertTypeId
            });
        },
        onCertificateTypeSelectedChangeCompleted: (evt,certificateIssuedByOtherAdministration, dateOfBirth, certificateNumber) => {
            var updatedCertTypeId = evt.target.value;

            if (!certificateIssuedByOtherAdministration && certificateNumber !== null) {
                dispatch(CreateGetCertificateNumbersValidationAction(certificateNumber, updatedCertTypeId));
            }
            if (!certificateIssuedByOtherAdministration && dateOfBirth && !isNaN(dateOfBirth)) {
                dispatch(CreateGetDobValidationAction(dateOfBirth));
            }

            if (updatedCertTypeId === "" || updatedCertTypeId === null || updatedCertTypeId.length === 0) {
                dispatch({
                    type: "CERTIFICATE_TYPE_IS_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "CERTIFICATE_TYPE_IS_REQUIRED",
                    data: false
                });
            }
        },
        certificateIssuedByOtherAdministrationCheckboxValueChanged: (evt, updatedCertificateNumber, date,
            applicationTypeId, licenceApplicationId) => {
            var updateCheckBoxCertificateFromOtherAdmin = evt.target.checked;

            dispatch({
                type: "CERTIFICATE_CHECKBOX_UPDATED",
                data: updateCheckBoxCertificateFromOtherAdmin
            });
            
            if (!updateCheckBoxCertificateFromOtherAdmin) {
                dispatch(CreateGetCertificateNumbersValidationAction(updatedCertificateNumber));
                if (date && !isNaN(date)) {
                    dispatch(CreateGetDobValidationAction(date));
                }
            }
            if (licenceApplicationId === 0) {
                dispatch(CreateSaveLicenceApplicationAction(applicationTypeId));
            }
        },

        onSalutationSelected: (evt) => {
            var updatedSalutationId = evt.target.value;

            dispatch({
                type: "SALUTATION_ID_UPDATED",
                data: updatedSalutationId
            });
        },
        onSalutationSelectedChangeCompleted: (evt) => {
            var updatedSalutationId = evt.target.value;

            if (updatedSalutationId === "" || updatedSalutationId === null || updatedSalutationId.length === 0) {
                dispatch({
                    type: "CERTIFICATE_SALUTATION_IS_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "CERTIFICATE_SALUTATION_IS_REQUIRED",
                    data: false
                });
            }
        },
        onNameOnCertificateChanged: (evt) => {
            var updatedName = evt.target.value;
            dispatch({
                type: "CERTIFICATE_NAME_UPDATED",
                data: updatedName
            });
        },
        onNameOnCertificateChangeCompleted: (evt) => {
            var updatedName = evt.target.value;
            if (updatedName.trim() === "") {
                dispatch({
                    type: "CERTIFICATE_NAME_IS_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "CERTIFICATE_NAME_IS_REQUIRED",
                    data: false
                });
            }

        },
        onCertificateNumberChanged: (evt) => {
            var updatedCertificateNumber = evt.target.value;

            var action = CreateCertificateNumberUpdatedAction(updatedCertificateNumber);
            dispatch(action);
        },
        onCertificateNumberChangedCompleted: (evt, certificateIssuedByOtherAdministration, dateOfBirth, certificateTypeId) => {
            var updatedCertificateNumber = evt.target.value;
            if (!certificateIssuedByOtherAdministration && updatedCertificateNumber !== null) {
                dispatch(CreateGetCertificateNumbersValidationAction(updatedCertificateNumber, certificateTypeId));
            }
            if (!certificateIssuedByOtherAdministration && dateOfBirth && !isNaN(dateOfBirth)) {
                dispatch(CreateGetDobValidationAction(dateOfBirth));
            }
            if (updatedCertificateNumber.trim() === "") {
                dispatch({
                    type: "CERTIFICATE_NUMBER_IS_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "CERTIFICATE_NUMBER_IS_REQUIRED",
                    data: false
                });
            }
        },
        onDatePickerDateOfBirthChanged: (date) => {
            dispatch({
                type: "CERTIFICATE_DATE_OF_BIRTH_UPDATED",
                data: date
            });
        },
        onDobChangedCompleted: (evt, otherAdministration, certNumber) => {
            var dateString = evt.target.value;
            if (dateString === "" || dateString === null || dateString.length === 0 || !dateString) {
                dispatch({
                    type: "CERTIFICATE_DOB_IS_REQUIRED",
                    data: true
                });
            } else {
                const [day, month, year] = dateString.split('/');
                const date = new Date(+year, month - 1, +day);

                if (isNaN(date)) {
                    dispatch({
                        type: "CERTIFICATE_DOB_IS_REQUIRED",
                        data: true
                    });
                } else {
                    dispatch({
                        type: "CERTIFICATE_DOB_IS_REQUIRED",
                        data: false
                    });
                    if (!otherAdministration && (certNumber !== null && certNumber !== "") && !isNaN(date)) {
                        dispatch(CreateGetDobValidationAction(date));
                    }
                }
            }
            
        },
        addCertificateDetails: () => {
            dispatch(CreateSubmitLicenceApplicationAction());
        },
        saveCertificateDetails: (applicationTypeId) => {
            dispatch(CreateSaveLicenceApplicationAction(applicationTypeId));
        },
        setupVesselPage: (updatedCertTypeId, seaAreaOfOperationId) => {
            if (updatedCertTypeId !== "" && seaAreaOfOperationId !== "") {
                dispatch(CreateCheckValidSeaAreaAction(updatedCertTypeId, seaAreaOfOperationId));
            }
        },
        saveCertificateDetailsAndClose: (history) => {
            dispatch(CreateSaveLicenceApplicationAction());
            history.push("/");
        },
        uploadOperatorCertificate: (file) => {
            dispatch(CreateUploadOperatorCertificatesAction(file));
            dispatch({
                type: "UPDATE_LICENCE_IMAGE_ERROR",
                data: ""
            });
        },
        uploadErrorMessage: (errMessage) => {
            dispatch({
                type: "UPDATE_LICENCE_IMAGE_ERROR",
                data: errMessage
            });
        },
        deleteLicenceImage: (img) => {
            dispatch(CreateDeleteLicenceImageAction(img.id, 1));
        }
    };
};

const languageAwareComponent = withTranslation()(RadioOperatorCertificateDetails);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);