const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_CURRENT_TRANSFER":
            return initialState;

        case "LICENCE_TRANSFER_RETRIEVED":
            {
                return action.data;
            }

        case "LICENCE_TRANSFER_VESSEL_NAME_RETRIEVED":
            return {
                ...state,
                vesselName: action.data
            };

        case "LICENCE_TRANSFER_UPDATED_SHOW_REJECT_MODAL":
            return {
                ...state,
                showRejectModal: action.data
            };

        default:
            return state;
    }
}
