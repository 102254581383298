const initialState = {
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_REQUIREMENTS_CALCULATOR":
            return initialState;

        case "CALCULATOR_CERTIFICATE_TYPE_ID_UPDATED":
            return {
                ...state,
                certificateTypeId: action.data
            };
        case "CALCULATOR_CERTIFICATE_TYPE_IS_REQUIRED":
            return {
                ...state,
                certificateTypeIdRequired: action.data
            };

        case "UPDATE_CALCULATOR_VESSEL_LENGTH":
            return {
                ...state,
                vesselLength: action.data
            };

        case "UPDATED_CALCULATOR_GROSS_TONNAGE":
            return {
                ...state,
                grossTonnage: action.data
            };


        case "UPDATE_CALCULATOR_VESSEL_TYPE":
            return {
                ...state,
                vesselTypeId: action.data
            };
       
        case "UPDATE_CALCULATOR_VESSEL_HAS_SUB_TYPE":
            return {
                ...state,
                hasVesselSubTypes: action.data
            };
        case "UPDATE_CALCULATOR_VESSEL_SUB_TYPE":
            return {
                ...state,
                vesselSubTypeId: action.data
            };
        case "UPDATE_CALCULATOR_VESSEL_TYPE_REQUIRED":
            return {
                ...state,
                vesselTypeRequired: action.data
            };
        case "UPDATE_CALCULATOR_VESSEL_SUB_TYPE_REQUIRED":
            return {
                ...state,
                vesselSubTypeRequired: action.data
            };

        case "UPDATE_CALCULATOR_VESSEL_SEA_AREA_OF_OPERATION":
            return {
                ...state,
                seaAreaOfOperationId: action.data
            };
        case "UPDATE_CALCULATOR_VESSEL_SEA_AREA_OF_OPERATION_REQUIRED":
            return {
                ...state,
                seaAreaOfOperationRequired: action.data
            };

        default:
            return state;
    }
}
