import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (applicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "licenceApplication/GetByLicenceApplicationId";

        const params = {
            licenceApplicationId: applicationId
        };

        GET(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.licence == null) {
                        dispatch({
                            type: "CLEAR_CURRENT_APPLICATION",
                            data: response.data.licence
                        });
                    } else {
                        dispatch({
                            type: "LICENCE_APPLICATION_RETRIEVED",
                            data: response.data.licence
                        });
                    }
                    if (response.data.vessel != null) {
                        dispatch({
                            type: "VESSEL_DATA_RETRIEVED",
                            data: response.data.vessel
                        });
                    }
                    if (response.data.emergencyContactModels != null) {
                        dispatch({
                            type: "EMERGENCY_CONTACTS_RETRIEVED",
                            data: response.data.emergencyContactModels
                        });
                    }
                    if (response.data.equipmentData != null) {
                        dispatch({
                            type: "VESSEL_REQUIRED_EQUIPMENT_RETRIEVED",
                            requiredEquipmentRecords: response.data.equipmentData
                        });
                    }
                    if (response.data.licencePayment != null) {
                        dispatch({
                            type: "LICENCE_PAYMENT_RETRIEVED",
                            data: response.data.licencePayment
                        });
                    }
                    dispatch({
                        type: "HAS_ACCESS_UPDATED",
                        data: response.data.hasAccess
                    });

                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

