const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
       
        case "VESSEL_REQUIRED_EQUIPMENT_RETRIEVED":
            return action.requiredEquipmentRecords;
        default:
            return state;
    }
}
