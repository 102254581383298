const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "EGC_RECEIVER_TYPES_RETRIEVED":
            return action.availableEgcReceiverTypes;

        default:
            return state;
    }
}
