const initialState = {
    isAuthenticated: false,
    role: "",
    licenceApplications: [],
    vessels: [],
    ownerDashboardInformation: [],
    selectedLicenceApplicationIdForDetails: 0,
    showCancelModal: false,
    showUndoCancelModal: false,
    showCancelTransferModal: false,
    showRescueNoteModal: false,
    licenceTransfers: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_CURRENT_USER_INFO":
            return initialState;

        case "CURRENT_USER_INFO_RECEIVED":
            return {
                ...state,
                isAuthenticated: action.data.isAuthenticated,
                role: action.data.role,
                licenceApplications: action.data.licenceApplications,
                vessels: action.data.vessels,
                ownerDashboardInformation: action.data.ownerDashboardInformation,
                showCancelModal: false,
                showUndoCancelModal: false,
                showCancelTransferModal: false,
                licenceTransfers: action.data.licenceTransfers,
                showEpirbAlert: false
            }
        case "CURRENT_USER_INFO_IS_AUTHENTICATED":
            return {
                ...state,
                isAuthenticated: action.data
            }
        case "UPDATED_SELECTED_APPLICATION_ID_FOR_DETAILS":
            return {
                ...state,
                selectedLicenceApplicationIdForDetails : action.data
            }

        case "CANCEL_LICENCE_UPDATED_SHOW_CANCEL_MODAL":
            return {
                ...state,
                showCancelModal: action.data
            };
        case "CANCEL_LICENCE_TRANSFER_UPDATED_SHOW_CANCEL_MODAL":
            return {
                ...state,
                showCancelTransferModal: action.data
            };
        case "RESCUE_NOTE_UPDATED_SHOW_MODAL":
            return {
                ...state,
                showRescueNoteModal: action.data
            };
        case "LICENCE_DETAILS_SHOW_EPIRB_ALERT":
            return {
                ...state,
                showEpirbAlert: action.data
            };
        case "UNDO_CANCEL_LICENCE_SHOW_MODAL":
            return {
                ...state,
                showUndoCancelModal: action.data
            };
        default:
            return state;
    }
}
