import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import LinkButton from "./LandingPage/LinkButton";
import Breadcrumb from "./Breadcrumb";

import CreateGetLicenceTransferByGuidAction from "../store/actionCreators/CreateGetLicenceTransferByGuidAction"
import CreateAddPaymentConfimrationAction from "../store/actionCreators/CreateAddPaymentConfirmationAction";
import CreateAddPaymentFailureAction from "../store/actionCreators/CreateAddPaymentFailureAction";
import CreateRejectLicenceTransferAction from "../store/actionCreators/CreateRejectLicenceTransferAction";
import CreateUpdateLicenceApplicationStatusAction from "../store/actionCreators/CreateUpdateLicenceApplicationStatusAction";
import CreateAcceptLicenceTransferAction from "../store/actionCreators/CreateAcceptLicenceTransferAction";

class LicenceTransfer extends Component {
    componentDidMount() {
        const guid = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);

        if (guid !== "" && guid !== this.props.guid) {
            this.props.getLicenceTransferDetails(guid);
        }
    }

    render() {
        const { t } = this.props;

        const transferFound = this.props.licenceApplicationId !== 0;

        const formatDate = (date) => {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
        }

        const dateString = formatDate(new Date(this.props.created));

        return (
            <React.Fragment>

                <Modal show={this.props.showRejectModal} onHide={this.props.handleCloseRejectModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("LicenceTransferPage.RejectLicenceTransfer")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {t("LicenceTransferPage.RejectLicenceTransferQuestion")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.props.handleCloseRejectModal}>
                            {t("Common.ButtonText.No")}
                        </Button>
                        <LinkButton id="rejectYesButton"
                            className="btn btn-danger"
                            to={"/dashboard/"}
                            onClick={() => { this.props.rejectLicenceTransfer(this.props.licenceApplicationId ) }}>
                            {t("Common.ButtonText.Yes")}
                        </LinkButton>
                    </Modal.Footer>
                </Modal>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    dashboard={t("Common.Dashboard")}
                    pageName={t("LicenceTransferPage.PageHeaderTitle1")} />

                {
                    !transferFound && !this.props.showSpinner && 
                    <div className="my-3 text-center">
                        <div className="h4" tabIndex="0">
                            {t("NoAccessPage.TitleLabel")}
                        </div>
                        <div className="fa-icons" >
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "red" }} />
                        </div>

                        <label id="NoAccessLabel" className="h4">{t("NoAccessPage.StepLabel")}</label>
                    </div>
                }

                {
                    transferFound && !this.props.showSpinner &&
                    <div>
                        <div className="h4 text-center" id="PageHeaderTitle1" tabIndex="0">{t("LicenceTransferPage.PageHeaderTitle1")}</div>
                        <div className="h5" id="PageHeaderTitle2" tabIndex="0">{t("LicenceTransferPage.PageHeaderTitle2", { vesselName: this.props.vesselName })}</div>
                        <div className="h6" id="transferSubTitle" tabIndex="0">{t("LicenceTransferPage.PageHeaderTitle3", { createdBy: this.props.createdBy, created: dateString })}</div>
                    </div>
                }

                {
                    transferFound && !this.props.showSpinner &&
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label id="firstNameLabel" htmlFor="firstName" className="form-label">{t("MyAccount.FirstName")}</label>
                                <input
                                    id="firstName" type="text"
                                    className="form-control" disabled={true}
                                    value={this.props.firstName} />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label id="lastNameLabel" htmlFor="lastName" className="form-label">{t("MyAccount.LastName")}</label>
                                <input
                                    id="lastName" type="text"
                                    className="form-control" disabled={true}
                                    value={this.props.lastName} />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label id="emailAddressLabel" htmlFor="emailAddress" className="form-label">{t("MyAccount.EmailAddress")}</label>
                                <input id="emailAddress" type="email"
                                    className="form-control" disabled={true}
                                        value={this.props.emailAddress} />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label id="phoneNumberLabel" htmlFor="phoneNumber" className="form-label">{t("MyAccount.PhoneNumber")} </label>
                                <input
                                    id="phoneNumber" type="text"
                                    className="form-control" disabled={true}
                                    value={this.props.phoneNumber} />
                            </div>
                        </div>

                    </div>
                }

                {
                    transferFound && !this.props.showSpinner &&
                    <div className="my-4">
                        <Link
                            id="transferRejectButton"
                            className="btn btn-danger"
                            to="#"
                            onClick={() => { this.props.handleShowRejectModal() }}
                        >
                            {t("Common.ButtonText.Reject")}
                        </Link>
                        <Link
                            id="transferAcceptButton"
                            className="btn btn-primary mx-3"
                            to="#"
                            onClick={() => { this.props.acceptLicenceTransfer() }}
                        >
                            {t("Common.ButtonText.Accept")}
                        </Link>
                    </div>
                }


            </React.Fragment>
        );
    }
}

// map redux state attributes to this view's property collection.
const mapStateToProps = (state, ownProps) => {
    return {
        licenceApplicationId: state.currentTransfer.licenceApplicationId || 0,
        licenceTransferId: state.currentTransfer.id || 0,
        guid: state.currentTransfer.licenceTransferGuid || "",
        firstName: state.currentTransfer.firstName || "",
        lastName: state.currentTransfer.lastName || "",
        phoneNumber: state.currentTransfer.phoneNumber || "",
        emailAddress: state.currentTransfer.emailAddress || "",
        createdBy: state.currentTransfer.createdBy || "",
        created: state.currentTransfer.created || "",
        vesselName: state.currentTransfer.vesselName || "",

        showRejectModal: state.currentTransfer.showRejectModal || false,
        showSpinner: state.showSpinner
    };
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLicenceTransferDetails: (guid) => {
            dispatch(CreateGetLicenceTransferByGuidAction(guid));
        },
        addPaymentConfimration: (licenceApplicationId, vesselId, paymentAmount) => {
            dispatch(CreateAddPaymentConfimrationAction(licenceApplicationId, vesselId, paymentAmount));
        },
        addPaymentFailure: (licenceApplicationId, vesselId) => {
            dispatch(CreateAddPaymentFailureAction(licenceApplicationId));
        },
        handleShowRejectModal: () => {
            dispatch({
                type: "LICENCE_TRANSFER_UPDATED_SHOW_REJECT_MODAL",
                data: true
            });
        },
        handleCloseRejectModal: () => {
            dispatch({
                type: "LICENCE_TRANSFER_UPDATED_SHOW_REJECT_MODAL",
                data: false
            });
        },
        rejectLicenceTransfer: (licenceApplicationId) => {

            //reset licence application to 5 (dispatched) status
            dispatch(CreateUpdateLicenceApplicationStatusAction(licenceApplicationId, 5));

            //reject licence transfer record
            dispatch(CreateRejectLicenceTransferAction());

            dispatch({
                type: "CLEAR_CURRENT_TRANSFER"
            });
        },
        acceptLicenceTransfer: () => {

            dispatch({
                type: "SHOW_SPINNER"
            });

            //accept licence transfer record
            dispatch(CreateAcceptLicenceTransferAction());

        }

    }
}

const languageAwareComponent = withTranslation()(LicenceTransfer);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);

