import axios from "axios";

// import authService from "../../components/api-authorization/AuthorizeService";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
//import CreateGetVesselsAction from "./CreateGetVesselsAction";

export default function (licenceApplicationId) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());
        const { currentVessel } = getState();

        const registrationNumber = currentVessel.registerationNumber ? currentVessel.registerationNumber.trim() : null;

        const newVessel = {
            id : Number(currentVessel.id) || 0,
            name: currentVessel.name.trim(),
            licenceApplicationId: Number(licenceApplicationId) || 0,
            vesselTypeId: currentVessel.vesselTypeId && Number(currentVessel.vesselTypeId),
            vesselSubTypeId: currentVessel.vesselSubTypeId && Number(currentVessel.vesselSubTypeId),
            isOtherVesselType: currentVessel.isOtherVesselType || false,
            otherVesselTypeDescription: currentVessel.otherVesselTypeDescription || "",
            hullColorId: currentVessel.hullColorId && Number(currentVessel.hullColorId),
            seaAreaOfOperationId: currentVessel.seaAreaOfOperationId && Number(currentVessel.seaAreaOfOperationId),
            maxCapacityOfPersonsOnBoard: Number(currentVessel.maxCapacityOfPersonsOnBoard),
            vesselLength: Number(currentVessel.vesselLength),
            grossTonnage: Number(currentVessel.grossTonnage),
            description: currentVessel.description.trim(),
            homePort: currentVessel.homePort.trim(),
            registerationNumber: registrationNumber,
            fishingVesselPrefixTypeId: Number(currentVessel.fishingVesselPrefixTypeId) || null,
            fvVesselIdentificationNumber: currentVessel.fvVesselIdentificationNumber,
            fishingVesselPostfixTypeId: Number(currentVessel.fishingVesselPostfixTypeId) || null,
            imoNumber: currentVessel.imoNumber,
            numberOfLifeBoats: currentVessel.numberOfLifeBoats && Number(currentVessel.numberOfLifeBoats),
            accountingAuthorityIdentificationCodeId: Number(currentVessel.accountingAuthorityIdentificationCodeId) || null
        };

        //authService.getAccessToken().then((token) => {
                axios({
                    method: "post",
                    url: "vessel",
                    //headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    data: newVessel
                })
                    .then((response) => {
                        dispatch({
                            type: "VESSEL_RETRIEVED",
                            vessel: response.data.vessel
                        });
                    })
                    .catch((error) => {
                        dispatch(CreateDataRetrievalEndedAction());
                    });
            //});
    };
}
