import React, { Component } from "react"
import { Route } from "react-router-dom"

export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userIsAuthenticated: false,
            ready: false
        };
    }

    componentDidMount() {
        const that = this;

        window.userManager.getUser().then(function (user) {
            that.setState({
                userIsAuthenticated: user != null,
                ready: true
            });
        });
    }

    render() {
        const { component: Component, ...rest } = this.props;

        if (!this.state.ready) {
            return (<div></div>);
        }

        return (
            <Route {...rest} render={(props) => {
                if (this.state.userIsAuthenticated) {
                    return <Component {...props} />;
                } else {
                    window.userManager.signinRedirect();
                    return null;
                }
            }} />
        );
    }
}

