import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import RadioOperatorCertificateDetails from "./RadioOperatorCertificateDetails";
import AddVessel from "./AddVessel";
import AddEmergencyContactDetails from "./AddEmergencyContactDetails";
import EmergencyContactsList from "./EmergencyContactsList";
import VesselEquipmentDetails from "./VesselEquipmentDetails";
import AddVesselEquipment from "./AddVesselEquipment";
import AddEpirb from "./AddEpirbDetails";
import EpirbList from "./EpirbDetailsList";
import ReviewAndDeclarations from "./ReviewAndDeclarations";
import MakePayment from "./MakePayment";
import PaymentConfirmationPage from "./PaymentConfirmationPage";
import PaymentFailedPage from "./PaymentFailedPage";
import LicenceNotes from "./LicenceNotes";

import CreateGetLicenceApplicationByIdAction from "../store/actionCreators/CreateGetLicenceApplicationByIdAction"
import CreateGetRequiredEpirbAction from "../store/actionCreators/CreateGetRequiredEpirbAction";
import CreateGetLicenceImagesAction from "../store/actionCreators/CreateGetLicenceImagesByLicenceIdAction";
import CreateGetVesselImagesAction from "../store/actionCreators/CreateGetVesselImagesByLicenceIdAction";
import CreateGetLicenceNotesAction from "../store/actionCreators/CreateGetLicenceNotesAction";

import DataMigrations from "./DataMigrations"

class classComponent extends Component {

    componentDidMount() {
        const id = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
        const urlId = (isNaN(id)) ? 0 : Number(id);
        if (urlId !== 0) {
            this.props.getLicenceApplication(urlId);
        }
    }

    render() {
        var { match, history } = this.props;


        if (this.props.hasAccess != null && !this.props.hasAccess) {
            return (
                <React.Fragment>
                    <Redirect to="/no-access"/>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>

                <Route exact path="/apply" render={() =>
                    <RadioOperatorCertificateDetails
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/licence" render={() =>
                    <RadioOperatorCertificateDetails
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                { this.props.hasAccess &&
                    <Route exact path="/apply/licence/:id" render={() =>
                        <RadioOperatorCertificateDetails
                            parentRoute="/apply"
                            history={history}
                        />
                    } />
                }
                

                <Route exact path="/apply/add-vessel" render={() =>
                    <AddVessel
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/add-vessel/:id" render={() =>
                    <AddVessel
                        parentRoute={match.url}
                        history={history}
                    />
                } />

                <Route exact path="/apply/add-emergency-contact" render={() =>
                    <AddEmergencyContactDetails
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/add-emergency-contact/:id" render={() =>
                    <AddVessel
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/data-migrations/:id" render={() =>
                    <DataMigrations
                        parentRoute={match.url}
                        history={history}
                    />
                } />

                <Route exact path="/apply/emergency-contacts-list" render={() =>
                    <EmergencyContactsList
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route path="/apply/emergency-contacts-list/:id" render={() =>
                    <EmergencyContactsList
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/vessel-equipment-details" render={() =>
                    <VesselEquipmentDetails
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route path="/apply/vessel-equipment-details/:id" render={() =>
                    <VesselEquipmentDetails
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/add-vessel-equipment" render={() =>
                    <AddVesselEquipment
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route path="/apply/add-vessel-equipment/:id" render={() =>
                    <AddVesselEquipment
                        match={match}
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/add-epirb" render={() =>
                    <AddEpirb
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route path="/apply/add-epirb/:id" render={() =>
                    <AddEpirb
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/epirb-list" render={() =>
                    <EpirbList
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route path="/apply/epirb-list/:id" render={() =>
                    <EpirbList
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/review-declarations" render={() =>
                    <ReviewAndDeclarations
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route path="/apply/review-declarations/:id" render={() =>
                    <ReviewAndDeclarations
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/notes" render={() =>
                    <LicenceNotes
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route path="/apply/notes/:id" render={() =>
                    <LicenceNotes
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/make-payment" render={() =>
                    <MakePayment
                        parentRoute="/apply"
                        history={history}
                    />
                } />


                <Route exact path="/apply/make-payment/:id" render={() =>
                    <MakePayment
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/payment-success" render={() =>
                    <PaymentConfirmationPage
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/payment-success/:id" render={() =>
                    <PaymentConfirmationPage
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route path="/apply/payment-failed" render={() =>
                    <PaymentFailedPage
                        parentRoute="/apply"
                        history={history}
                    />
                } />

                <Route exact path="/apply/payment-failed/:id" render={() =>
                    <PaymentFailedPage

                        parentRoute="/apply"
                        history={history}
                    />
                } />

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        hasAccess: state.hasAccess
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLicenceApplication: (applicationId) => {
            dispatch(CreateGetLicenceApplicationByIdAction(applicationId));
            dispatch(CreateGetRequiredEpirbAction(applicationId));
            dispatch(CreateGetLicenceImagesAction(applicationId));
            dispatch(CreateGetVesselImagesAction(applicationId,false));
            dispatch(CreateGetLicenceNotesAction(applicationId));
        }
    };
}

const languageAwareComponent = withTranslation()(classComponent);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);