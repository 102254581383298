import axios from "axios";

// import authService from "../../components/api-authorization/AuthorizeService";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateClearCurrentEquipmentAction from "./CreateClearCurrentEquipmentAction";
import CreateGetRequiredEquipmentAction from "./CreateGetRequiredEquipmentAction";
import RefreshLicenceIsvalidAction from "./RefreshLicenceIsvalidAction";

export default function () {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());
        const { vesselEquipmentVhfDetail, currentApplication } = getState();

        const licenceApplicationId = currentApplication.id || 0;
        const licenceStatusId = currentApplication.licenceApplicationStatusTypeId || 0;

        const otherEquipmentType = vesselEquipmentVhfDetail.otherEquipmentTypeName ? vesselEquipmentVhfDetail.otherEquipmentTypeName.trim() : null;
        const make = vesselEquipmentVhfDetail.make ? vesselEquipmentVhfDetail.make.trim() : null;
        const model = vesselEquipmentVhfDetail.model ? vesselEquipmentVhfDetail.model.trim() : null;
        const satelliteNumber = vesselEquipmentVhfDetail.satelliteNumber
            ? vesselEquipmentVhfDetail.satelliteNumber.trim()
            : null;
        var hexCode = vesselEquipmentVhfDetail.hexCode ? vesselEquipmentVhfDetail.hexCode.trim() : null;

        //check equipment category is PLB (6)
        const numberOfPlbs = Number(vesselEquipmentVhfDetail.equipmentTypeCategoryId) === 6 && vesselEquipmentVhfDetail.numberOfPlbs && vesselEquipmentVhfDetail.numberOfPlbs >= 0
            ? Number(vesselEquipmentVhfDetail.numberOfPlbs)
            : null;

        //only save correct number of plb hex codes
        if (hexCode !== null) {
            const hexCodeArrayLength = numberOfPlbs === null ? 1 : numberOfPlbs;
            const hexCodeArray = hexCode.split(", ");
            hexCodeArray.length = hexCodeArrayLength;
            hexCode = hexCodeArray.join(", ");
        }

        const newVesselEquipmentDetail = {
            id: vesselEquipmentVhfDetail.id || 0,
            licenceApplicationId: Number(licenceApplicationId) || 0,
            equipmentTypeId: Number(vesselEquipmentVhfDetail.equipmentTypeId),
            mmsiNumber: vesselEquipmentVhfDetail.mmsiNumber,
            otherEquipmentType: otherEquipmentType,
            equipmentMfHfTypeId: vesselEquipmentVhfDetail.equipmentMfHfTypeId ? Number(vesselEquipmentVhfDetail.equipmentMfHfTypeId) : null,
            dscEnabled: vesselEquipmentVhfDetail.isDscEnabled || false,

            make: make,
            model: model,
            medApproved: vesselEquipmentVhfDetail.isMedApproved || false,
            customMakeModel: vesselEquipmentVhfDetail.isOtherMakeModel || false,
            gpsEnabled: vesselEquipmentVhfDetail.isGpsEnabled || false,

            radarBandTypeId: vesselEquipmentVhfDetail.radarBandTypeId ? Number(vesselEquipmentVhfDetail.radarBandTypeId) : null,
            egcReceiver: vesselEquipmentVhfDetail.isEgcReceiver || false,
            gmdssSatelliteTypeId: vesselEquipmentVhfDetail.satelliteTypeId ? Number(vesselEquipmentVhfDetail.satelliteTypeId) : null,
            hexCode: hexCode,
            lritFacility: vesselEquipmentVhfDetail.isLritFacility || false,
            numberOfPlbs: numberOfPlbs,
            satelliteNumber: satelliteNumber
        };

        axios({
            method: "put",
            url: "Equipment",
            data: newVesselEquipmentDetail
        })
            .then((data) => {
                dispatch(CreateClearCurrentEquipmentAction());
            })
            .then((data) => {
                dispatch(CreateGetRequiredEquipmentAction(licenceApplicationId));
                if (licenceStatusId > 1) {
                    dispatch(RefreshLicenceIsvalidAction(licenceApplicationId));
                }
            })
            .catch((error) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
