const initialState = 0;

export default (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_DEFAULT_EPIRB_MOUNTINGTYPE":
            return action.data;
          
        default:
            return state;
    }
}
