import axios from "axios";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetCurrentUserInfoAction from "./CreateGetCurrentUserInfoAction";

export default function (licenceApplicationId) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const { transferLicence, currentUserInfo } = getState();
        const url = "LicenceApplication/TransferLicenceStep1";


        const applicationDetail = currentUserInfo.ownerDashboardInformation.filter(v => Number(v.licenceApplicationId)
            === licenceApplicationId)[0];
        const vesselName = applicationDetail?.vesselName || "";

        const model = {
            licenceApplicationId: licenceApplicationId,
            vesselName: vesselName,
            firstName: transferLicence.firstName,
            lastName: transferLicence.lastName,
            phoneNumber: transferLicence.phoneNumber,
            emailAddress: transferLicence.emailAddress
        };

        axios({
                method: "post",
                url: url,
                data: model
            })
            .then((data) => {
                dispatch({
                    type: "TRANSFER_LICENCE_UPDATED_SHOW_MODAL",
                    data: false
                });
                dispatch({
                    type: "TRANSFER_LICENCE_SHOW_ALERT",
                    data: true
                });
            })
            .then((data) => {
                dispatch(CreateGetCurrentUserInfoAction());
            })
            .catch((error) => {
                dispatch(CreateDataRetrievalEndedAction());
            });

        //    .then((response) => {
        //        if (response.data) {
        //            dispatch({
        //                type: "TRANSFER_LICENCE_UPDATED_SHOW_MODAL",
        //                data: false
        //            });
        //        };
        //})
        //.catch((error) => {
        //    dispatch(CreateDataRetrievalEndedAction());
        //});
    };
}

//export default function (licenceApplicationId) {
//    return (dispatch, getState) => {
//        dispatch(CreateDataRetrievalBegunAction());

//        const { transferLicence } = getState();
//        const url = "licenceApplication/TransferLicenceStep1";

//        const params = {
//            licenceApplicationId: licenceApplicationId,
//            firstName: transferLicence.firstName || "",
//            lastName: transferLicence.lastName || "",
//            phoneNumber: transferLicence.phoneNumber || "",
//            emailAddress: transferLicence.emailAddress || ""
//        };

//        axios({
//            method: "post",
//            url: url,
//            data: params
//            })
//            .then((response) => {
//                if (response.data) {
//                    dispatch({
//                        type: "TRANSFER_LICENCE_UPDATED_SHOW_MODAL",
//                        data: false
//                    });
//                }
//                dispatch(CreateDataRetrievalEndedAction());
//            })
//            .catch((error) => {
//                dispatch(CreateDataRetrievalEndedAction());
//            });
//    };
//}
