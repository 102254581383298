import { GET } from "../adapters/xhr";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

export default function () {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());
        const url = "account/GetCurrentUser";
        
        GET(url)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "CURRENT_USER_RETRIEVED",
                        user: response.data
                    });
                }
                dispatch(CreateDataRetrievalEndedAction());

            }).catch(function (response) {
                dispatch({
                    type: "CLEAR_CURRENT_USER"                    
                });
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
