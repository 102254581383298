import axios from "axios";

// import authService from "../../components/api-authorization/AuthorizeService";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CheckResetLicenceApplicationTypeAction from "./CheckResetLicenceApplicationTypeAction";
import CreateGetRequiredEquipmentAction from "./CreateGetRequiredEquipmentAction";
import RefreshLicenceIsvalidAction from "./RefreshLicenceIsvalidAction";

export default function (id, isNewAmendment) {
    return (dispatch, getState) => {

        const { currentVessel, currentApplication } = getState();

        const url = isNewAmendment
            ? "EquipmentAmendment?id=" + id
            : "equipment?id=" + id;

        const licenceApplicationId = currentApplication.id || 0;
        const licenceStatusId = currentApplication.licenceApplicationStatusTypeId || 0;

        dispatch(CreateDataRetrievalBegunAction());

        axios({
                method: "DELETE",
                url: url
            })
            .then((response) => {
                if (isNewAmendment) {
                    dispatch(CheckResetLicenceApplicationTypeAction(licenceApplicationId, currentVessel.id));
                }
                if (licenceStatusId > 1) {
                    dispatch(RefreshLicenceIsvalidAction(licenceApplicationId));
                }
                dispatch(CreateGetRequiredEquipmentAction(licenceApplicationId));
            })
            .catch((error) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}