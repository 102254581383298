import React from "react";

export default ({ pageTitle, vesselLabel, vesselName, isShipOwerWithNonIssuedLicence,
    mmsiLabel, mmsiNumber, callSignLabel, callSignNumber, pageDescription, requiredField }) => {

    return (
        <div className="bg-light p-2 rounded-lg mb-2">
            <div className="row">
                <div className="col-md-12">
                    <div className="h4" role="heading" aria-level="1" id="PageTitleLabel" tabIndex="0">
                        {
                            pageTitle
                        }
                    </div>
                </div>
            </div>
            {
                vesselName !== "" && isShipOwerWithNonIssuedLicence &&
                <div className="row">
                    <div className="col-md-12">
                         <span className="form-label">{vesselLabel}: </span>
                        <span className="ms-1">{vesselName}</span>
                    </div>
                </div>
            }
            {
                vesselName !== "" && !isShipOwerWithNonIssuedLicence &&
                <div className="row">
                     <div className="col-md-6">
                        <span className="form-label">{vesselLabel}: </span>
                        <span className="ms-1">{vesselName}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="form-label">{mmsiLabel}: </span>
                        <span className="ms-1">{mmsiNumber}</span>
                        <div className="float-end">
                            <span className="form-label">{callSignLabel}: </span>
                            <span className="ms-1">{callSignNumber}</span>
                        </div>
                    </div>
                </div>
            }
            {
                requiredField !=="" && pageDescription !== "" &&
                <div className="row mt-1">
                    <div className="col-md-9">
                        <div className="h6" role="heading" aria-level="2" id="PageDescriptionLabel" tabIndex="0"> {pageDescription}</div>
                    </div>
                    <div className="col-md-3">
                        <div aria-hidden="true" id="required-description" className="float-end">* {requiredField}</div>
                    </div>
                </div>
            }
            {
                requiredField === "" && pageDescription !== "" &&
                <div className="row mt-1">
                    <div className="col-md-12">
                        <div className="h6" role="heading" aria-level="2" id="PageDescriptionLabel" tabIndex="0"> {pageDescription}</div>
                    </div>
                </div>
            }
            
        </div>
    );
};
