const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_CURRENT_APPLICATION":
            return initialState;

        case "LICENCE_APPLICATION_RETRIEVED":
            {
                if (action.data.dateOfBirth && action.data.dateOfBirth.length) {
                    action.data.dateOfBirth = new Date(action.data.dateOfBirth);
                }

                return action.data;
            }

        case "CERTIFICATE_TYPE_ID_UPDATED":
            return {
                ...state,
                certificateTypeId: action.data
            };
        case "CERTIFICATE_TYPE_IS_REQUIRED":
            return {
                ...state,
                certificateTypeIdRequired: action.data
            };

        case "SALUTATION_ID_UPDATED":
            return {
                ...state,
                salutationId: action.data
            };
        case "CERTIFICATE_SALUTATION_IS_REQUIRED":
            return {
                ...state,
                salutationRequired: action.data
            };

        case "CERTIFICATE_NAME_UPDATED":
            return {
                ...state,
                nameOnCertificate: action.data
            };
        case "CERTIFICATE_NAME_IS_REQUIRED":
            return {
                ...state,
                nameOnCertificateRequired: action.data
            }
        case "CERTIFICATE_NUMBER_UPDATED":
            return {
                ...state,
                certificateNumber: action.data
            };

        case "CERTIFICATE_NUMBER_IS_REQUIRED":
            return {
                ...state,
                certificateNumberRequired : action.data
            };

        case "CERTIFICATE_DATE_OF_BIRTH_UPDATED":
            return {
                ...state,
                dateOfBirth: new Date(action.data)
            };
        case "CERTIFICATE_DOB_IS_REQUIRED":
            return {
                ...state,
                dateOfBirthRequired : action.data
            };

        case "CERTIFICATE_CHECKBOX_UPDATED":
            return {
                ...state,
                certificateIssuedByOtherAdministration: action.data
            };
        case "NEXT_POSSIBLE_LICENCE_APPLICATION_ID":
            return {
                ...state,
                id: action.data
            };
        case "CERTIFICATE_NUMBER_VALIDATED":
            return {
                ...state,
                certificateNumberHasValidationErrors: action.data
            };
        case "DOB_VALIDATED":
            return {
                ...state,
                dobHasValidationErrors: action.data
            };
        //case "OPERATOR_CERTIFICATE_UPLOADED":
        //    return {
        //        ...state,
        //        operatorCertificateImagesCount: action.data
        //    };
        case "LICENCE_AMENDMENT":
            return {
                ...state,
                applicationTypeId: action.data ? 3 : 1
            };
        case "LICENCE_APPLICATIONTYPEID_UPDATED":
            return {
                ...state,
                applicationTypeId: action.data
            };
        case "LICENCE_STATUSTYPEID_UPDATED":
            return {
                ...state,
                licenceApplicationStatusTypeId: action.data
            };
        case "UPDATE_LICENCE_IMAGE_ERROR":
            return {
                ...state,
                licenceImageError: action.data
            };
        case "ISSUE_LICENCE_SHOW_MODAL":
            return {
                ...state,
                showIssueModal: action.data
            };

        default:
            return state;
    }
}
