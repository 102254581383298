import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import { withRouter } from "react-router";
import { Accordion } from "react-bootstrap";
import parse from "html-react-parser";

import CreateGetUsefulLinksAction from "../../store/actionCreators/CreateGetUsefulLinksAction";

class PublicUsefulLinksPage extends Component {
    constructor() {
        super();

        this.doLogin = this.doLogin.bind(this);

        this.state = {
            collapsed: true,
            userIsAuthenticated: false
        };
    }

    componentDidMount() {
        this.props.getUsefulLinks();;

        const that = this;

        window.userManager.getUser().then(function (user) {
            that.setState({
                userIsAuthenticated: user != null
            });
        });
    }

    doLogin() {
        var { history } = this.props;

        window.userManager.getUser().then(function(user) {
            if (user) {
                history.push("dashboard");
            } else {
                window.userManager.signinRedirect();        
            }
        });
    }


    render() {
        const t = this.props.t;

        return (
            <React.Fragment>
                <Breadcrumb
                    role=""
                    homeLink={t("Common.ButtonText.Home")}
                    pageName={t("PublicUsefulLinksPage.UsefulLinks")} />

                <div className="row">
                    <div className="col-md-12">
                        <div class="h5 text-center">{t("PublicUsefulLinksPage.UsefulLinks")}</div>
                    </div>
                </div>
                <hr className="srl-hr" />
                <div className="mb-5">  
                    {
                        this.props.usefulLinks.length > 0 &&
                        <Accordion flush>
                            {
                                this.props.usefulLinks.map((x, index) => {
                                    return (
                                        <Accordion.Item key={index} eventKey={x.id}>
                                            <Accordion.Header><strong>{x.name}</strong></Accordion.Header>
                                            <Accordion.Body>
                                                {parse(x.description)}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })
                            }
                        </Accordion>
                    }
                </div>  
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        usefulLinks: state.usefulLinks || []
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUsefulLinks: () => {
            dispatch(CreateGetUsefulLinksAction());
        }
    };
};

const languageAwareComponent = withTranslation()(withRouter(PublicUsefulLinksPage));

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);