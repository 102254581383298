import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (licenceApplicationId, phoneNumber, emergencyContactId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "emergencyContact/CheckExistingPhoneNumber";
        const params = {
            licenceApplicationId,
            phoneNumber,
            emergencyContactId
        };

        GET(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "EMERGENCY_CONTACT_PHONE_NUMBER_VALIDATED",
                        data: response.data
                    });
                } 

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
