import axios from "axios";

const getAxiosInstance = (anon) => {
    if (anon) {
        return axios.create({
            headers: {}
        });
    }
    return window.userManager.getUser().then(function (user) {
        var token = user.access_token;

        return axios.create({
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
    });
};

export function GET(url, params) {
    return getAxiosInstance(false)
    .then(function(axiosInstance) {
        return axiosInstance.get(url, { params });
        });
}

export function GETAnonymous(url, params) {
    var axiosInstance = getAxiosInstance(true);
    return axiosInstance.get(url, { params });
}

export function POST(url, data) {
    // return axios.post(url, data);

    return getAxiosInstance()
        .then(function (axiosInstance) {
            return axiosInstance.post(url, data);
        });
}

export function PUT(url, data) {
    // return axios.put(url, data);

    return getAxiosInstance()
        .then(function (axiosInstance) {
            return axiosInstance.put(url, data);
        });
}

export function DELETE(url, data) {
    // return axios.delete(url, { data });

    return getAxiosInstance()
        .then(function (axiosInstance) {
            return axiosInstance.delete(url, { data });
        });
}