import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetLicenceNotesAction from "../../store/actionCreators/CreateGetLicenceNotesAction";

import axios from "axios";

export default function (licenceApplicationId) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const formData = getData(getState, licenceApplicationId);

        //POST("Note/AddNote", newNote)
        //    .then((response) => {
        //        dispatch(CreateGetLicenceNotesAction(licenceApplicationId));
        //        CreateDataRetrievalEndedAction();
        //    })
        //    .catch((response) => {
        //        dispatch(CreateDataRetrievalEndedAction());
        //    });

        axios({
            method: "POST",
            url: "ManageVesselLicence/AddNote",
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData
        })
        .then((response) => {
            dispatch(CreateGetLicenceNotesAction(licenceApplicationId));
            dispatch(CreateDataRetrievalEndedAction());
        })
        .catch((error) => {
            dispatch(CreateDataRetrievalEndedAction());
        });
    };
}

function getData(getState, licenceApplicationId) {
    const { noteTemplate } = getState();

    const isInternal = noteTemplate.isInternal === undefined ? true : noteTemplate.isInternal;

    const postData = new FormData();
    postData.append("isInternal", isInternal);
    postData.append("LicenceNumbers", licenceApplicationId);
    postData.append("templateId", isInternal ? null : Number(noteTemplate.noteTemplateId));
    postData.append("subject", noteTemplate.noteTemplateName || "");
    postData.append("note", noteTemplate.noteDescription);
    postData.append("attachment", noteTemplate.noteAttachment);

    return postData;
}