import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { faPrint, faExchangeAlt, faEdit, faStickyNote,faTimesCircle, faFileContract,faFilePowerpoint } from '@fortawesome/free-solid-svg-icons';
import LinkButton from "./LandingPage/LinkButton";
import ValidationFailureMessage from "./ValidationFailureMessage";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Redirect } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";

import CreateClearCurrentApplicationAction from "../store/actionCreators/CreateClearCurrentApplicationAction";
import CreateClearCurrentVesselAction from "../store/actionCreators/CreateClearCurrentVesselAction";
import CreateCancelLicenceApplicationAction from "../store/actionCreators/CreateCancelLicenceApplicationAction";
import CreateLicenceTransferStep1Action from "../store/actionCreators/CreateLicenceTransferStep1Action";
import CreateCancelLicenceTransferAction from "../store/actionCreators/CreateCancelLicenceTransferAction";
import CreateUpdateLicenceApplicationStatusAction from "../store/actionCreators/CreateUpdateLicenceApplicationStatusAction";
import CreateUpdateRescueNoteAction from "../store/actionCreators/CreateUpdateRescueNoteAction";
import CreateGetRescueNoteAction from "../store/actionCreators/CreateGetRescueNoteAction";
import CreateGetEpirbDetailsAction from "../store/actionCreators/CreateGetEpirbDetailsAction";

class LicenceDetailsPage extends Component {

    componentDidMount() {
        const id = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
        const urlId = (isNaN(id)) ? 0 : Number(id);

        if (urlId !== 0 && urlId !== this.props.selectedLicenceApplicationIdForDetails) {
            this.props.setSelectedApplicationIdForDetails(urlId);
        }
        this.props.clearCurrentApplication();
        this.props.clearCurrentVessel();
        this.props.handleAlertClose();
        this.props.getRescueNote(this.props.selectedLicenceApplicationIdForDetails);
        this.props.getEpirbDetails(this.props.selectedLicenceApplicationIdForDetails);
    }

    render() {
        const { t } = this.props;
        var applicationDetail = this.props.ownerDashboardInformation.filter(v => Number(v.licenceApplicationId)
            === Number(this.props.selectedLicenceApplicationIdForDetails))[0];
        const vesselName = applicationDetail?.vesselName || "";
        const vesselId = applicationDetail?.vesselId || 0;
        const licenceNumber = applicationDetail?.licenceNumber || this.props.selectedLicenceApplicationIdForDetails;
        const applicationTypeId = applicationDetail?.applicationTypeId || 0;
        const applicationStatusTypeId = applicationDetail?.applicationStatusTypeId || 0;
        const showAlert = this.props.showAlert;
        const hasEpirb = (this.props.epirbs !== null && this.props.epirbs.length > 0);
        const showEpirbAlert = this.props.showEpirbAlert;

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6,
            TransferInitiated: 7,
            Transferred: 8
        };

        const ApplicationType = {
            NewShipRadioLicence: 1,
            TransferLicence: 2,
            AmendLicence: 3
        };

        // disable the Next/submit button if required fields are missing
        var canSubmitForm = (this.props.firstNameRequired ||
            this.props.firstName === "" ||
            this.props.lastNameRequired ||
            this.props.lastName === "" ||
            this.props.phoneNumberRequired ||
            this.props.phoneNumber === "" ||
            this.props.emailAddressRequired ||
            this.props.emailAddress === "");

        // disable Save Rescue Note button if required fields are missing
        var canSubmitRescueNote = (
            this.props.rescueNoteDescriptionRequired ||
            this.props.noteDescription === "" ||
            this.props.rescueNoteStartDateRequired ||
            this.props.rescueNoteStartDate === "" ||
            this.props.rescueNoteEndDateRequired ||
            this.props.rescueNoteEndDate === "" ||
            this.props.rescueNoteEndDate < this.props.rescueNoteStartDate
        );

        return (
            <React.Fragment>
                {
                    this.props.role === "Emergency Services" && this.props.selectedLicenceApplicationIdForDetails &&
                    <Redirect to={"/emergency-services-licence-view/" + this.props.selectedLicenceApplicationIdForDetails} />
                }
                {
                    showEpirbAlert &&
                    <div className="alert alert-danger alert-dismissible" role="alert">
                        <Link id="closeAlert" to="#" className="close float-end" onClick={() => { this.props.handleEpirbAlertClose() }}>
                            &times;
                        </Link>
                        <strong>{t("LicenceDetailsPage.NoEpirbExistsMessage")}</strong>
                    </div>
                }
                {
                    showAlert &&
                    <div className="alert alert-success alert-dismissible" role="alert">
                        <Link id="closeAlert" to="#" className="close float-end" onClick={() => { this.props.handleAlertClose() }}>
                            &times;
                        </Link>
                        <strong>{t("LicenceDetailsPage.TransferStep1SuccessMessage")}</strong>
                    </div>
                }

                <Modal show={this.props.showModal} onHide={this.props.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("LicenceDetailsPage.TransferLicenceTile")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label id="firstNameLabel" htmlFor="firstName">{t("MyAccount.FirstName")} </label>
                            <input
                                id="firstName" type="text"
                                className="form-control" required
                                onChange={this.props.onFirstNameChanged}
                                onBlur={this.props.onFirstNameChangeCompleted}
                                value={this.props.firstName}/>
                            {
                                this.props.firstNameRequired &&
                                <ValidationFailureMessage>
                                    {t("MyAccount.FirstNameRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                        <div className="form-group">
                            <label id="lastNameLabel" htmlFor="lastName">{t("MyAccount.LastName")}</label>
                            <input
                                id="lastName" type="text"
                                className="form-control" required
                                onChange={this.props.onLastNameChanged}
                                onBlur={this.props.onLastNameChangeCompleted}
                                value={this.props.lastName}/>

                            {
                                this.props.lastNameRequired &&
                                <ValidationFailureMessage>
                                    {t("MyAccount.LastNameRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                        <div className="form-group">
                            <label id="emailAddressLabel" htmlFor="emailAddress">{t("MyAccount.EmailAddress")}: *</label>
                            <input id="emailAddress" type="email"
                                   className="form-control" required
                                   onChange={this.props.onEmailChanged}
                                   onBlur={this.props.onEmailChangeCompleted}
                                   value={this.props.emailAddress}/>
                            {
                                this.props.emailAddress && !this.props.emailAddress.includes("@") &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.EmailAddressValidation")}
                                </ValidationFailureMessage>
                            }
                            {
                                this.props.emailAddressRequired &&
                                <ValidationFailureMessage>
                                    {t("EmergencyContacts.EmailAddressRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>

                        <div className="form-group">
                            <label id="phoneNumberLabel" htmlFor="phoneNumber">{t("MyAccount.PhoneNumber")}: *</label>
                            <input
                                id="phoneNumber" type="text"
                                className="form-control" required
                                onChange={this.props.onPhoneNumberChanged}
                                onBlur={this.props.onPhoneNumberChangeCompleted}
                                value={this.props.phoneNumber}/>
                            {
                                this.props.phoneNumberRequired &&
                                <ValidationFailureMessage>
                                    {t("MyAccount.PhoneNumberRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={this.props.handleClose}>
                            {t("Common.ButtonText.Cancel")}
                        </Button>
                        <Button variant="primary" disabled={canSubmitForm}
                                onClick={() => { this.props.transferLicence(this.props.selectedLicenceApplicationIdForDetails) }}>
                            {t("Common.ButtonText.Submit")}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.props.showCancelModal} onHide={this.props.handleCloseCancelModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("LicenceDetailsPage.CancelLicenceTile")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {t("LicenceDetailsPage.CancelLicenceQuestion")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button id="cancelNoButton"
                                variant="success" onClick={this.props.handleCloseCancelModal}>
                            {t("Common.ButtonText.No")}
                        </Button>
                        <LinkButton id="cancelYesButton"
                                    className="btn btn-danger"
                                    to={"/dashboard"}
                                    onClick={() => { this.props.cancelLicence(this.props.selectedLicenceApplicationIdForDetails, vesselId, applicationTypeId,"owner") }}>
                            {t("Common.ButtonText.Yes")}
                        </LinkButton>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.props.showCancelTransferModal} onHide={this.props.handleCloseCancelTransferModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("LicenceDetailsPage.CancelLicenceTransferTitle")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {t("LicenceDetailsPage.CancelLicenceTransferQuestion", { vesselName: vesselName })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button id="cancelNoButton"
                                variant="success" onClick={this.props.handleCloseCancelTransferModal}>
                            {t("Common.ButtonText.No")}
                        </Button>
                        <LinkButton id="cancelYesButton"
                                    className="btn btn-danger"
                                    to={"/dashboard"}
                                    onClick={() => { this.props.cancelLicenceTransfer(this.props.selectedLicenceApplicationIdForDetails) }}>
                            {t("Common.ButtonText.Yes")}
                        </LinkButton>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.props.showRescueNoteModal} onHide={this.props.handleCloseRescueNoteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("LicenceDetailsPage.AddRescueNote")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group mt-1">
                            <label id="noteDescriptionLabel" htmlFor="noteDescription" className="form-label">{t("LicenceNotes.Note")} *</label>
                            <textarea id="noteDescription"
                                className="form-control" required
                                onChange={this.props.onNoteDescriptionChanged}
                                onBlur={this.props.onNoteDescriptionCompleted}
                                value={this.props.noteDescription}
                                rows={5}
                                cols={20} />
                            {
                                this.props.rescueNoteDescriptionRequired &&
                                <ValidationFailureMessage>
                                    {t("RescueNote.NoteDescriptionRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                        <div className="form-group">
                            <label id="startDateLabel" htmlFor="noteStartDate" className="form-label">{t("RescueNote.StartDateLabel")}</label>
                            <br />
                            <DatePicker
                                id="noteStartDate"
                                selected={this.props.rescueNoteStartDate} required
                                className="form-control"
                                onChange={date => this.props.onNoteStartDateChanged(date)}
                                onBlur={this.props.onNoteStartDateCompleted}
                                value={this.props.rescueNoteStartDate}
                                dateFormat="dd/MM/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                placeholderText="DD/MM/YYYY"
                            />
                            <br />
                            <small id="startDateHelp" className="form-text text-muted">{t("RescueNote.StartDateInformation")}</small>
                            {
                                this.props.rescueNoteStartDateRequired &&
                                <ValidationFailureMessage>
                                    {t("RescueNote.StartDateRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                        <div className="form-group">
                            <label id="endDateLabel" htmlFor="noteEndDate" className="form-label">{t("RescueNote.EndDateLabel")}</label>
                            <br />
                            <DatePicker
                                id="noteEndDate"
                                selected={this.props.rescueNoteEndDate} required
                                className="form-control"
                                onChange={date => this.props.onNoteEndDateChanged(date)}
                                onBlur={this.props.onNoteEndDateCompleted}
                                value={this.props.rescueNoteEndDate}
                                dateFormat="dd/MM/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                placeholderText="DD/MM/YYYY"
                            />
                            <br />
                            <small id="endDateHelp" className="form-text text-muted">{t("RescueNote.EndDateInformation")}</small>
                            {
                                this.props.rescueNoteEndDateRequired &&
                                <ValidationFailureMessage>
                                    {t("RescueNote.EndDateRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button id="closeRescueNoteButton"
                            variant="secondary"
                            onClick={this.props.handleCloseRescueNoteModal}>
                            {t("Common.ButtonText.Cancel")}
                        </Button>
                        <Button id="saveRescueNoteButton"
                            variant="primary"
                            disabled={canSubmitRescueNote}
                            onClick={() => { this.props.updateRescueNote(this.props.selectedLicenceApplicationIdForDetails) }}>
                            {t("Common.ButtonText.Save")}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    dashboard={t("Common.Dashboard")}
                    pageName={t("LicenceDetailsPage.PageHeaderTitle1", { licenceId: licenceNumber })} />

                <div className="row">
                    <div className="col-md-12 text-center">
                        <span className="Sub-Heading-Title-Large">{t("LicenceDetailsPage.PageHeaderTitle1", { licenceId: licenceNumber })}</span>
                        <span className="Sub-Heading-Title-Large">{t("LicenceDetailsPage.PageHeaderTitle2", { vesselName: vesselName })}</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="ShipsRadioColumnItem">
                            <LinkButton icon={faFilePowerpoint} to={"/licenceApplication/PrintShipsRadioLicence/?id=" + this.props.selectedLicenceApplicationIdForDetails} target="_new" className="btn btn-licence-actions">
                                <FontAwesomeIcon icon={faFilePowerpoint}/>
                                <div>{t("LicenceDetailsPage.ViewAndPrintLicenceTile")}</div>
                            </LinkButton>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="ShipsRadioColumnItem">
                            {
                                hasEpirb &&
                                <LinkButton icon={faPrint} to={"/licenceApplication/PrintEpirbPdf/?id=" + this.props.selectedLicenceApplicationIdForDetails}
                                    target="_new" className="btn btn-licence-actions">
                                    <FontAwesomeIcon icon={faPrint} />
                                    <div>{t("LicenceDetailsPage.ViewAndPrintEpirbRegistrationsTile")}</div>
                                </LinkButton>
                            }
                            {
                                !hasEpirb &&
                                <Link icon={faPrint} to="#"
                                    onClick={() => { this.props.handleShowEpirbAlert() }}
                                    className="btn btn-licence-actions">
                                    <FontAwesomeIcon icon={faPrint} />
                                    <div>{t("LicenceDetailsPage.ViewAndPrintEpirbRegistrationsTile")}</div>
                                </Link>
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="ShipsRadioColumnItem">
                            <LinkButton icon={faStickyNote}
                                className="btn btn-licence-actions"
                                to="#"
                                onClick={() => { this.props.handleShowRescueNoteModal() }}
                            >
                                <FontAwesomeIcon icon={faStickyNote} />
                                <div>{t("LicenceDetailsPage.AddRescueNote")}</div>
                            </LinkButton>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="ShipsRadioColumnItem">
                            <LinkButton icon={faEdit} className="btn btn-licence-actions"
                                        to={"/apply/review-declarations/" + this.props.selectedLicenceApplicationIdForDetails}>
                                <FontAwesomeIcon icon={faEdit} />
                                <div>{t("LicenceDetailsPage.EditReviewLicence")}</div>
                            </LinkButton>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="ShipsRadioColumnItem">
                            {/*disable transfer button if licence is only issued or licence has been amended*/}
                            {
                                applicationStatusTypeId !== LicenceStatus.TransferInitiated &&
                                <LinkButton id="btnTransferLicence"
                                    icon={faExchangeAlt} to="#" className="btn btn-licence-actions"
                                    disabled={applicationStatusTypeId === LicenceStatus.LicenceIssued || applicationTypeId === ApplicationType.AmendLicence}
                                    onClick={() => { this.props.handleShow() }}>
                                    <FontAwesomeIcon icon={faExchangeAlt} />
                                    <div>{t("LicenceDetailsPage.TransferLicenceTile")}</div>
                                </LinkButton>
                            }
                            {
                                applicationStatusTypeId === LicenceStatus.TransferInitiated &&
                                <LinkButton id="btnTransferLicence"
                                    icon={faExchangeAlt} to="#" className="btn btn-licence-actions"
                                    onClick={() => { this.props.handleShowCancelTransferModal() }}
                                    >
                                    <FontAwesomeIcon icon={faExchangeAlt} />
                                    <div>{t("LicenceDetailsPage.CancelTransferLicenceTile")}</div>
                                </LinkButton>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="ShipsRadioColumnItem">
                            <LinkButton icon={faFileContract} to="#" className="btn btn-licence-actions"
                                onClick={() => { this.props.handleShowCancelModal() }}
                            >
                                <FontAwesomeIcon icon={faTimesCircle}/>
                                <div>{t("LicenceDetailsPage.CancelLicenceTile")}</div>
                            </LinkButton>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        licenceApplications: state.currentUserInfo.licenceApplications,
        role: state.currentUserInfo.role || "",
        vessels: state.currentUserInfo.vessels,
        ownerDashboardInformation: state.currentUserInfo.ownerDashboardInformation,

        selectedLicenceApplicationIdForDetails: state.currentUserInfo.selectedLicenceApplicationIdForDetails || 0,

        firstName: state.transferLicence.firstName || "",
        lastName: state.transferLicence.lastName || "",
        phoneNumber: state.transferLicence.phoneNumber || "",
        emailAddress: state.transferLicence.emailAddress || "",
        showModal: state.transferLicence.showModal || false,
        showCancelModal: state.currentUserInfo.showCancelModal || false,
        showAlert: state.transferLicence.showAlert || false,
        showCancelTransferModal: state.currentUserInfo.showCancelTransferModal || false,

        showRescueNoteModal: state.currentUserInfo.showRescueNoteModal || false,
        noteDescription: state.rescueNote.noteDescription || "",
        rescueNoteId: state.rescueNote.rescueNoteId || 0,
        rescueNoteStartDate: state.rescueNote.rescueNoteStartDate || "",
        rescueNoteEndDate: state.rescueNote.rescueNoteEndDate || "",
        rescueNoteDescriptionRequired: state.rescueNote.rescueNoteDescriptionRequired || false,
        rescueNoteStartDateRequired: state.rescueNote.rescueNoteStartDateRequired || false,
        rescueNoteStartDateHasError: state.rescueNote.rescueNoteStartDateHasError || false,
        rescueNoteEndDateRequired: state.rescueNote.rescueNoteEndDateRequired || false,
        rescueNoteEndDateHasError: state.rescueNote.rescueNoteEndDateHasError || false,

        epirbs: state.epirb || null,
        showEpirbAlert: state.currentUserInfo.showEpirbAlert || false,

        // validations
        firstNameRequired: state.transferLicence.firstNameRequired || false,
        lastNameRequired: state.transferLicence.lastNameRequired || false,
        phoneNumberRequired: state.transferLicence.phoneNumberRequired || false,
        emailAddressRequired: state.transferLicence.emailAddressRequired || false
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setSelectedApplicationIdForDetails: (licenceApplicationId) => {
            dispatch({
                type: "UPDATED_SELECTED_APPLICATION_ID_FOR_DETAILS",
                data: licenceApplicationId
            });
        },
        clearCurrentApplication: () => {
            dispatch(CreateClearCurrentApplicationAction());
        },
        clearCurrentVessel: () => {
            dispatch(CreateClearCurrentVesselAction());
        },
        getRescueNote: (applicationId) => {
            dispatch(CreateGetRescueNoteAction(applicationId));
        },
        getEpirbDetails: (applicationId) => {
            dispatch(CreateGetEpirbDetailsAction(applicationId));
        },
        handleShowCancelModal: () => {
            dispatch({
                type: "CANCEL_LICENCE_UPDATED_SHOW_CANCEL_MODAL",
                data: true
            });
        },
        handleCloseCancelModal: () => {
            dispatch({
                type: "CANCEL_LICENCE_UPDATED_SHOW_CANCEL_MODAL",
                data: false
            });
        },
        cancelLicence: (licenceApplicationId, vesselId,applicationTypeId) => {
            dispatch(CreateCancelLicenceApplicationAction(licenceApplicationId, vesselId, applicationTypeId));
            dispatch({
                type: "CANCEL_LICENCE_UPDATED_SHOW_CANCEL_MODAL",
                data: false
            });
        },
        handleShowCancelTransferModal: () => {
            dispatch({
                type: "CANCEL_LICENCE_TRANSFER_UPDATED_SHOW_CANCEL_MODAL",
                data: true
            });
        },
        handleCloseCancelTransferModal: () => {
            dispatch({
                type: "CANCEL_LICENCE_TRANSFER_UPDATED_SHOW_CANCEL_MODAL",
                data: false
            });
        },
        handleShowRescueNoteModal: () => {
            dispatch({
                type: "RESCUE_NOTE_UPDATED_SHOW_MODAL",
                data: true
            });
        },
        handleCloseRescueNoteModal: () => {
            dispatch({
                type: "RESCUE_NOTE_UPDATED_SHOW_MODAL",
                data: false
            });
        },
        cancelLicenceTransfer: (licenceApplicationId) => {

            //reset licence application to 5 (dispatched) status
            dispatch(CreateUpdateLicenceApplicationStatusAction(licenceApplicationId, 5));

            dispatch(CreateCancelLicenceTransferAction(licenceApplicationId));
        },
        updateRescueNote: (licenceApplicationId) => {

            dispatch(CreateUpdateRescueNoteAction(licenceApplicationId));

            dispatch({
                type: "RESCUE_NOTE_UPDATED_SHOW_MODAL",
                data: false
            });
        },
        onFirstNameChanged: (evt) => {
            var updatedFirstName = evt.target.value;

            dispatch({
                type: "TRANSFER_LICENCE_FIRST_NAME_UPDATED",
                data: updatedFirstName
            });
        },
        onFirstNameChangeCompleted: (evt) => {
            var updatedFirstName = evt.target.value;

            if (updatedFirstName === "" || updatedFirstName === null || updatedFirstName.length === 0) {
                dispatch({
                    type: "TRANSFER_LICENCE_FIRST_NAME_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "TRANSFER_LICENCE_FIRST_NAME_REQUIRED",
                    data: false
                });
            }
        },
        onLastNameChanged: (evt) => {
            var updatedLastName = evt.target.value;

            dispatch({
                type: "TRANSFER_LICENCE_LAST_NAME_UPDATED",
                data: updatedLastName
            });
        },
        onLastNameChangeCompleted: (evt) => {
            var updatedLastName = evt.target.value;

            if (updatedLastName === "" || updatedLastName === null || updatedLastName.length === 0) {
                dispatch({
                    type: "TRANSFER_LICENCE_LAST_NAME_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "TRANSFER_LICENCE_LAST_NAME_REQUIRED",
                    data: false
                });
            }
        },
        onEmailChanged: (evt) => {
            var updatedEmail = evt.target.value;

            dispatch({
                type: "TRANSFER_LICENCE_EMAIL_UPDATED",
                data: updatedEmail
            });

        },
        onEmailChangeCompleted: (evt) => {
            var updatedEmail = evt.target.value;

            if (updatedEmail === "" || updatedEmail === null || updatedEmail.length === 0) {
                dispatch({
                    type: "TRANSFER_LICENCE_EMAIL_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "TRANSFER_LICENCE_EMAIL_REQUIRED",
                    data: false
                });
            }
        },
        onPhoneNumberChanged: (evt) => {
            var updatedPhoneNumber = evt.target.value;

            if (isNaN(updatedPhoneNumber) || updatedPhoneNumber.includes(".")) {
                return;
            }
            dispatch({
                type: "TRANSFER_LICENCE_PHONE_NUMBER_UPDATED",
                data: updatedPhoneNumber
            });
        },
        onPhoneNumberChangeCompleted: (evt) => {
            var updatedPhoneNumber = evt.target.value;

            if (updatedPhoneNumber === "" || updatedPhoneNumber === null || updatedPhoneNumber.length === 0) {
                dispatch({
                    type: "TRANSFER_LICENCE_PHONE_NUMBER_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "TRANSFER_LICENCE_PHONE_NUMBER_REQUIRED",
                    data: false
                });
            }
        },
        handleShow: () => {
            dispatch({
                type: "TRANSFER_LICENCE_UPDATED_SHOW_MODAL",
                data: true
            });
        },
        handleClose: () => {
            dispatch({
                type: "TRANSFER_LICENCE_UPDATED_SHOW_MODAL",
                data: false
            });
        },
        transferLicence: (licenceApplicationId) => {
            dispatch(CreateLicenceTransferStep1Action(licenceApplicationId));
        },
        handleAlertClose: () => {
            dispatch({
                type: "TRANSFER_LICENCE_SHOW_ALERT",
                data: false
            });
        },
        onNoteDescriptionChanged: (evt) => {
            var noteDescription = evt.target.value;

            dispatch({
                type: "RESCUE_NOTE_DESCRIPTION_UPDATED",
                data: noteDescription
            });
        },
        onNoteDescriptionCompleted: (evt) => {
            var note = evt.target.value;
            if (note === "" || note === null || note.length === 0) {
                dispatch({
                    type: "RESCUE_NOTE_DESCRIPTION_IS_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "RESCUE_NOTE_DESCRIPTION_IS_REQUIRED",
                    data: false
                });
            }
        },
        onNoteStartDateChanged: (date) => {

            dispatch({
                type: "RESCUE_NOTE_START_DATE_UPDATED",
                data: date
            });

            if (!isNaN(date)) {
                dispatch({
                    type: "RESCUE_NOTE_START_DATE_IS_REQUIRED",
                    data: false
                });
            }
        },
        onNoteStartDateCompleted: (evt) => {
            var updatedDate = evt.target.value;
            if (updatedDate === "" || updatedDate === null || updatedDate.length === 0 || !updatedDate) {
                dispatch({
                    type: "RESCUE_NOTE_START_DATE_IS_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "RESCUE_NOTE_START_DATE_IS_REQUIRED",
                    data: false
                });
            }
        },
        onNoteEndDateChanged: (date) => {

            dispatch({
                type: "RESCUE_NOTE_END_DATE_UPDATED",
                data: date
            });

            if (!isNaN(date)) {
                dispatch({
                    type: "RESCUE_NOTE_END_DATE_IS_REQUIRED",
                    data: false
                });
            }
        },
        onNoteEndDateCompleted: (evt) => {
            var updatedDate = evt.target.value;
            if (updatedDate === "" || updatedDate === null || updatedDate.length === 0 || !updatedDate) {
                dispatch({
                    type: "RESCUE_NOTE_END_DATE_IS_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "RESCUE_NOTE_END_DATE_IS_REQUIRED",
                    data: false
                });
            }
        },
        handleShowEpirbAlert: () => {
            dispatch({
                type: "LICENCE_DETAILS_SHOW_EPIRB_ALERT",
                data: true
            });
        },
        handleEpirbAlertClose: () => {
            dispatch({
                type: "LICENCE_DETAILS_SHOW_EPIRB_ALERT",
                data: false
            });
        }
    };
};


const languageAwareComponent = withTranslation()(LicenceDetailsPage);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);