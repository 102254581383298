import React, { Component } from "react"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faEdit, faFilePowerpoint, faUser, faShip } from '@fortawesome/free-solid-svg-icons';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LinkButton from "./LinkButton";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

import CreateGetCurrentUserAction from "../../store/actionCreators/CreateGetCurrentUserAction";
import CreateClearCurrentApplicationAction from "../../store/actionCreators/CreateClearCurrentApplicationAction";
import CreateClearCurrentLicenceImagesAction from "../../store/actionCreators/CreateClearCurrentLicenceImagesAction";
import CreateClearCurrentVesselAction from "../../store/actionCreators/CreateClearCurrentVesselAction";
import CreateClearCurrentVesselImagesAction from "../../store/actionCreators/CreateClearCurrentVesselImagesAction";
import CreateGetCurrentUserInfoAction from "../../store/actionCreators/CreateGetCurrentUserInfoAction";

const $ = require("jquery");
$.DataTable = require("datatables.net");

class OwnerLandingPage extends Component {
    componentDidMount() {
        this.props.clearCurrentApplication();
        this.props.clearCurrentLicenceImages();
        this.props.clearCurrentVessel();
        this.props.clearCurrentVesselImages();
        this.props.getCreateGetCurrentUserInfo();
    }

    render() {
        const { t } = this.props;

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6,
            TransferInitiated: 7,
            Transferred: 8
        };

        const licences = this.props.ownerDashboardInformation.filter(l => Number(l.applicationStatusTypeId) === LicenceStatus.LicenceIssued
            || Number(l.applicationStatusTypeId) === LicenceStatus.Dispatched || Number(l.applicationStatusTypeId) === LicenceStatus.TransferInitiated);
        const amendedlicences = this.props.ownerDashboardInformation.filter(l => (Number(l.applicationStatusTypeId) === LicenceStatus.LicenceIssued
            || Number(l.applicationStatusTypeId) === LicenceStatus.Dispatched) && Number(l.applicationTypeId) === 3);
        const goodlicences = this.props.ownerDashboardInformation.filter(l => (Number(l.applicationStatusTypeId) === LicenceStatus.LicenceIssued
            || Number(l.applicationStatusTypeId) === LicenceStatus.Dispatched || Number(l.applicationStatusTypeId) === LicenceStatus.TransferInitiated) && Number(l.applicationTypeId) !== 3);
        const cancelledTransferredlicences = this.props.ownerDashboardInformation.filter(l => (Number(l.applicationStatusTypeId) === LicenceStatus.Cancelled || Number(l.applicationStatusTypeId) === LicenceStatus.Transferred));
        const applications = this.props.ownerDashboardInformation.filter(l => Number(l.applicationStatusTypeId) === LicenceStatus.InProgress
            || Number(l.applicationStatusTypeId) === LicenceStatus.ReadyToReview || Number(l.applicationStatusTypeId) === LicenceStatus.AwaitingResponse);

        const licencesCount = licences.length;
        const amendedCount = amendedlicences.length;
        const goodCount = goodlicences.length;
        const cancelledTransferredCount = cancelledTransferredlicences.length;
        const applicationsCount = applications.length;
        const welcomeTextForNewApplications = t("OwnerLandingPage.WelcomeForNewApplication");

        const showMyAccountAlert = this.props.showMyAccountAlert;

        return (
            <React.Fragment>

                {
                    showMyAccountAlert &&
                    <div className="alert alert-success alert-dismissible" role="alert">
                        <Link id="closeAlert" to="#" className="close float-end" onClick={() => { this.props.handleAlertClose() }}>
                            &times;
                        </Link>
                        <strong>{t("MyAccount.UpdateSuccessMessage")}</strong>
                    </div>
                }

                <div className="float-end mt-1">
                    <LinkButton
                        onClick={() => this.props.getCurrentUser()}
                        to={"/my-account"} className="btn btn-primary">
                        <FontAwesomeIcon icon={faUser} className="smallWhiteIcon"/>&nbsp;
                        {t("Common.ButtonText.MyAccount")}</LinkButton>
                </div>

                {
                    licencesCount < 1 && applicationsCount < 1 &&
                    <div>
                        <div className="form-group" style={{ textAlignLast: "center" }} >
                            <span className="Sub-Heading-Title" role="heading" aria-level="1" tabIndex="0">{t("OwnerLandingPage.WelcomeHeader")}</span>
                            <br /> <br /><br />
                            <span tabIndex="0">{parse(welcomeTextForNewApplications)}</span>
                            <br /> <br />
                            <LinkButton to={"/apply"} className="btn btn-primary my-3" >{t("Common.ButtonText.NewLicenceRequest")}</LinkButton>
                        </div>
                    </div>
                }
                {
                    (licencesCount > 0 || applicationsCount > 0) &&
                    <div className="Sub-Heading-Title text-center mt-2" role="heading" aria-level="1" tabIndex="0" >
                        {t("OwnerLandingPage.WelcomeHeader")}
                    </div>
                }

                {
                    // Inbound Licence Transfers
                    this.props.licenceTransfers.length > 0 &&
                    <div className="row">
                        <div id="TransferLicencesHeading" role="heading" aria-level="2" className="Sub-Heading-Title" tabIndex="0">
                            {t("OwnerLandingPage.InboundLicenceTransferHeading")}
                        </div>
                        {
                            this.props.licenceTransfers.map(licence => {
                                return (
                                    <div key={licence.licenceTransfer.id} className="col-md-4">
                                        <LinkButton className="btn btn-licencetransfer-details Shadowing"
                                            to={"/LicenceTransfer/" + licence.licenceTransfer.licenceTransferGuid}>
                                            <FontAwesomeIcon icon={faShip} style={{ color: "#ffffff", fontSize: 12 }} />
                                            <div>{t("OwnerLandingPage.VesselName")}</div>
                                            <div className="h5">{licence.vesselName} </div>
                                        </LinkButton>
                                    </div>
                                );
                            })
                        }
                    </div>
                }

                {
                    // Good Licences section (Not Amended)
                    goodCount > 0 && //There are licences
                    <div className="row">
                        <div id="LicencesHeading" role="heading" aria-level="2" className="Sub-Heading-Title" tabIndex="0">
                            {t("OwnerLandingPage.LicencesHeading")}
                        </div>
                        {
                            goodlicences.map(licence => {
                                return (
                                    <div key={licence.licenceApplicationId} className="col-md-4">
                                        <LinkButton className="btn btn-licence-details Shadowing"
                                            onClick={() => this.props.setSelectedApplicationIdForDetails(licence.licenceApplicationId)}
                                            to={"/licence-details-page/" + licence.licenceApplicationId}>
                                            <FontAwesomeIcon icon={faPrint} style={{ fontSize: 12 }} />
                                            <div className="h5">{t("OwnerLandingPage.LicenceNumber")} LSR{licence.licenceNumber || licence.licenceApplicationId}</div>
                                            <div className="h5">{t("OwnerLandingPage.VesselName")}</div>
                                            <div className="h5">{licence.vesselName}</div>
                                        </LinkButton>
                                    </div>
                                );
                            })
                        }
                    </div>
                }

                {
                    // Amended Licence section
                    amendedCount > 0 && //There are licences
                    <div className="row">
                        <div id="AmendedLicencesHeading" role="heading" aria-level="2" className="Sub-Heading-Title mb-1" tabIndex="0">
                            {t("OwnerLandingPage.AmendedLicencesHeading")}
                        </div>
                        {
                            amendedlicences.map(licence => {
                                return (
                                    <div key={licence.licenceApplicationId} className="col-md-4">
                                        <LinkButton className="btn btn-amendedlicence-details Shadowing"
                                            onClick={() => this.props.setSelectedApplicationIdForDetails(licence.licenceApplicationId)}
                                            to={"/licence-details-page/" + licence.licenceApplicationId} >
                                            <FontAwesomeIcon icon={faPrint} style={{ color: "#cc7000", fontSize: 12 }} />
                                            <div className="h5">{t("OwnerLandingPage.LicenceNumber")} LSR{licence.licenceNumber || licence.licenceApplicationId}</div>
                                            <div className="h5">{t("OwnerLandingPage.VesselName")}</div>
                                            <div className="h5">{licence.vesselName}</div>
                                        </LinkButton>
                                    </div>
                                );
                            })
                        }
                    </div>
                }

                {
                    applicationsCount > 0 && //There are applications
                    <div className="row">
                        <div id="ApplicationHeading" role="heading" aria-level="2" className="Sub-Heading-Title mb-1" tabIndex="0">
                            {t("OwnerLandingPage.ApplicationHeading")}
                        </div>
                        {
                                applications.map(licence => {
                                    var textToDisplay =
                                        t("OwnerLandingPage.ApplicationButtonLabel", {
                                            vesselName: licence.vesselName,
                                            status: licence.applicationStatusTypeId === LicenceStatus.ReadyToReview ? "Completed Application Submitted and Under Review" : licence.applicationStatus,
                                            mmsiNumber: licence.mmsiNumber || "",
                                            createdDate: licence?.createdDate == null ? " " : new Date(licence.createdDate).toJSON().slice(0, 10).split('-').reverse().join('/'),
                                            updatedDate: licence?.updatedDate == null ? " " : new Date(licence.updatedDate).toJSON().slice(0, 10).split('-').reverse().join('/')
                                        });


                                    return (
                                        <div key={licence.licenceApplicationId} className="col-md-4">
                                            <LinkButton className="btn btn-app-details Shadowing"
                                                        onClick={() => this.props.setSelectedApplicationIdForDetails(
                                                    licence.licenceApplicationId)}
                                                        to={"/application-details-page/" + licence.licenceApplicationId}>
                                                <FontAwesomeIcon icon={faEdit} style={{ fontSize: 12 }} />
                                                <div className="h5">{t("OwnerLandingPage.LicenceApplication")}</div>
                                                {parse(textToDisplay)}
                                            </LinkButton>
                                        </div>
                                    );
                                }) 
                            }                      
                    </div>
                }
                {
                    cancelledTransferredCount > 0 && //There are cancelled/transferred applications
                    <div >
                        <div id="CancelledTransferHeading" role="heading" aria-level="2" className="Sub-Heading-Title mb-1" tabIndex="0">
                            {t("OwnerLandingPage.CancelledTransferredHeading")}
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered" id="data-table" role="presentation">
                                <thead>
                                    <tr>
                                        <th scope="col">{t("OwnerLandingPage.LicenceNumber")}</th>
                                        <th scope="col">{t("OwnerLandingPage.Status")}</th>
                                        <th scope="col">{t("OwnerLandingPage.VesselName")}</th>
                                        <th scope="col">{t("OwnerLandingPage.MMSINumber")}</th>
                                        <th scope="col">{t("OwnerLandingPage.CallSign")}</th>
                                        <th scope="col">{t("OwnerLandingPage.CancelledTransferredDate")}</th>
                                        <th scope="col">{t("OwnerLandingPage.Link")}Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    cancelledTransferredlicences.map(licence => {
                                    return (
                                        <tr key={licence.licenceApplicationId} >
                                            <td>{"LSR" + (licence.licenceNumber || licence.licenceApplicationId)}</td>
                                            <td>{licence.applicationStatus}</td>
                                            <td>{licence.vesselName}</td>
                                            <td>{licence.mmsiNumber || ""}</td>
                                            <td>{licence.callSignNumber || ""}</td>
                                            <td>{licence.cancelledDate == null
                                                ? new Date(licence.updatedDate).toJSON().slice(0, 10).split('-').reverse().join('/')
                                                : new Date(licence.cancelledDate).toJSON().slice(0, 10).split('-').reverse().join('/')}
                                            </td>
                                           <td className="text-center">
                                               <LinkButton icon={faFilePowerpoint} to={"/licenceApplication/PrintShipsRadioLicence/?id=" + licence.licenceApplicationId} target="_new">
                                                   <FontAwesomeIcon icon={faFilePowerpoint} className="smallIcon" />
                                               </LinkButton>
                                           </td>
                                        </tr>
                                    );
                                })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        licenceApplications: state.currentUserInfo.licenceApplications,
        vessels: state.currentUserInfo.vessels,
        ownerDashboardInformation: state.currentUserInfo.ownerDashboardInformation,
        showMyAccountAlert: state.currentUser.showMyAccountAlert || false,
        licenceTransfers: state.currentUserInfo.licenceTransfers
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCurrentUser: () => {
            dispatch(CreateGetCurrentUserAction());
        },
        setSelectedApplicationIdForDetails: (licenceApplicationId) => {
            dispatch({
                type: "MY_ACCOUNT_UPDATED_SHOW_ALERT",
                data: false
            });
            dispatch({
                type: "UPDATED_SELECTED_APPLICATION_ID_FOR_DETAILS",
                data: licenceApplicationId
            });
        },
        getCreateGetCurrentUserInfo: () => {
            dispatch(CreateGetCurrentUserInfoAction());
        },
        clearCurrentApplication: () => {
            dispatch(CreateClearCurrentApplicationAction());
        },
        clearCurrentLicenceImages: () => {
            dispatch(CreateClearCurrentLicenceImagesAction());
        },
        clearCurrentVessel: () => {
            dispatch(CreateClearCurrentVesselAction());
        },
        clearCurrentVesselImages: () => {
            dispatch(CreateClearCurrentVesselImagesAction());
        },
        handleAlertClose: () => {
            dispatch({
                type: "MY_ACCOUNT_UPDATED_SHOW_ALERT",
                data: false
            });
        }
    };
};


const languageAwareComponent = withTranslation()(OwnerLandingPage);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);