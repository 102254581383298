import axios from "axios";

import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetRequiredEpirbAction from "./CreateGetRequiredEpirbAction";
import RefreshLicenceIsvalidAction from "./RefreshLicenceIsvalidAction";

export default function (id) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const { currentApplication } = getState();

        const licenceApplicationId = currentApplication.id || 0;
        const licenceStatusId = currentApplication.licenceApplicationStatusTypeId || 0;

        axios({
                method: "DELETE",
                url: "EpirbDetail?id=" + id
            })
            .then((response) => {
                dispatch(CreateGetRequiredEpirbAction(licenceApplicationId));
                if (licenceStatusId > 1) {
                    dispatch(RefreshLicenceIsvalidAction(licenceApplicationId));
                }
            })
            .catch((error) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}