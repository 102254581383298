import React from "react";
import { Link } from "react-router-dom";

export default ({ role, homeLink, licenceList, dashboard, parentLink, parentPageName, pageName }) => {
    return (
        <div className="row">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb float-end">
                    <li className="breadcrumb-item">
                        <Link id="homeLink" to="/">{homeLink}</Link>
                    </li>
                    {
                        (role === "Radio Surveyor" || role === "Department Admin Staff") &&
                        <li className="breadcrumb-item"><a href="/ManageVesselLicence">{licenceList}</a></li>
                    }
                    {
                        role !== "" && role !== "Radio Surveyor" && role !== "Department Admin Staff" &&
                        <li className="breadcrumb-item">
                            <Link id="dashboardLink" to="/dashboard" >{dashboard}</Link>
                        </li>
                    }
                    {
                        parentPageName && parentPageName !== "" &&
                        <li className="breadcrumb-item">
                            <Link id="emergencyContactsLink" to={parentLink}>{parentPageName}</Link>
                        </li>
                    }
                   
                    <li className="breadcrumb-item b-active" aria-current="page">{pageName}</li>
                </ol>
            </nav>
        </div>
    );
};
