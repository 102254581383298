const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_FAQS":
            return initialState;

        case "FAQS_RETRIEVED":
            return action.faqs;

        default:
            return state;
    }
}
