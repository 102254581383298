import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonBooth} from '@fortawesome/free-solid-svg-icons';
import LinkButton from "./LinkButton";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageHeader from "./PageHeader";
import Breadcrumb from "./Breadcrumb";

import CreateGetEmergencyContactDetailsAction from "../store/actionCreators/CreateGetEmergencyContactDetailsAction";
import CreateGetWebsiteConfigAction from "../store/actionCreators/CreateGetWebsiteConfigAction";

class EmergencyContactsList extends Component {

    componentDidMount() {
        this.props.getEmergencyContacts(this.props.licenceApplicationId);
        this.props.getWebsiteConfig();
    }


    render() {
        const { t } = this.props;
        const emergencyContacts = this.props.emergencyContacts;
        const emergencyContactsCount = emergencyContacts.length;

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6,
            TransferInitiated: 7,
            Transferred: 8
        };

        //cancelled or transferred Status
        const isReadOnlyPage = this.props.licenceApplicationStatusTypeId === LicenceStatus.Cancelled
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.Transferred;

        const isShipOwerWithNonIssuedLicence = this.props.role === "Ship Owner" && (this.props.licenceApplicationStatusTypeId == LicenceStatus.InProgress
            || this.props.licenceApplicationStatusTypeId == LicenceStatus.ReadyToReview);

        return (
            <React.Fragment>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    licenceList={t("AdminLandingPage.LicenceList")}
                    dashboard={t("Common.Dashboard")}
                    parentLink=""
                    parentPageName=""
                    pageName={t("EmergencyContacts.EmergencyContactsTitleLabel")} />

                <PageHeader
                    pageTitle={t("EmergencyContacts.EmergencyContactsTitleLabel")}
                    vesselLabel={t("OwnerLandingPage.VesselName")}
                    vesselName={this.props.vesselName}
                    isShipOwerWithNonIssuedLicence={isShipOwerWithNonIssuedLicence}
                    mmsiLabel={t("OwnerLandingPage.MMSINumber")}
                    mmsiNumber={this.props.mmsiNumber}
                    callSignLabel={t("OwnerLandingPage.CallSign")}
                    callSignNumber={this.props.callSignNumber}
                    pageDescription={t("EmergencyContacts.StepEmergencyContactsLabel")}
                    requiredField="" />

                <div className="row">
                    {
                        emergencyContactsCount === 0 && !isReadOnlyPage &&
                        <h6 className="h6" tabIndex="0">{t("EmergencyContacts.MinimumRequirementText")}</h6>
                    }

                    {
                        emergencyContactsCount === 1 && !isReadOnlyPage &&
                        <h6 className="h6" tabIndex="0">{t("EmergencyContacts.PleaseAddASecondContact")}</h6>
                    }

                    {
                        emergencyContactsCount > 1 &&
                        <h6 className="h6" tabIndex="0">{t("EmergencyContacts.ThankYouForAddingTwoContacts")}</h6>
                    }

                    {
                        // Add Emergency Contact Buttons x 2
                        emergencyContactsCount === 0 && !isReadOnlyPage && //There are no contacts
                        <div>
                            <Link
                                id="addFirstContact1"
                                to="/apply/add-emergency-contact"
                                className="btn btn-AddEmergencyContact Shadowing"
                                onClick={() => this.props.clearEmergencyContact()}> 
                                <FontAwesomeIcon icon={faPersonBooth} />
                                <div className="h4">{t("EmergencyContacts.ButtonText.AddEmergencyContact")}</div>
                            </Link>

                            <Link
                                id="addFirstContact2"
                                to="/apply/add-emergency-contact"
                                className="btn btn-AddEmergencyContact Shadowing"
                                onClick={() => this.props.clearEmergencyContact()}>
                                <FontAwesomeIcon icon={faPersonBooth} />
                                <div className="h4">{t("EmergencyContacts.ButtonText.AddEmergencyContact")}</div>
                            </Link>
                        </div>
                    }

                    {
                        // Emergency Contacts
                        emergencyContactsCount > 0 && //There are contacts
                        <div>
                            {
                                emergencyContacts.map((contact, index) => {
                                    //var textToDisplay = t("OwnerLandingPage.LicenceNoLabel", { applicationId: emergencyContacts.name, vesselName: emergencyContacts.Id });
                                    return (
                                        <LinkButton
                                            id={"openContact" + index}
                                            key={contact.id}
                                            className="btn btn-licence-details Shadowing"
                                            onClick={() => this.props.selectEmergencyContact(contact)}
                                            to="/apply/add-emergency-contact">
                                            <FontAwesomeIcon icon={faPersonBooth} />
                                            <div className="h4">{t("EmergencyContacts.EmergencyContact")}</div>
                                            {t("EmergencyContacts.Name")}
                                            <h5>{contact.firstName} {contact.lastName}</h5>
                                            {t("EmergencyContacts.Email")}
                                            <h5> {contact.emailAddress}</h5>
                                        </LinkButton>
                                    );
                                })
                            }
                        </div>
                    }

                    {
                        this.props.emergencyContactsCount > 1 && !isReadOnlyPage &&
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <Link
                                id="vesselEquipmentDetailsButton"
                                className="btn-lg btn-primary"
                                to={this.props.parentRoute + "/vessel-equipment-details/" + this.props.licenceApplicationId}
                            >
                                {t("Common.ButtonText.EmergencyContactConfirm")}
                            </Link>
                        </div>
                    }

                    {
                        isReadOnlyPage &&
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <Link
                                id="vesselEquipmentDetailsButton"
                                className="btn btn-primary mb-3"
                                to={this.props.parentRoute + "/vessel-equipment-details/" + this.props.licenceApplicationId}
                            >
                                {t("Common.ButtonText.Next")}
                            </Link>
                        </div>
                    }


                    {
                        // Add just one Emergency Contact Button up to 5 contacts
                        emergencyContactsCount > 0 && emergencyContactsCount < 5 && !isReadOnlyPage && //There are contacts
                        <div className="my-3">
                            <Link
                                id="addAdditionalContact"
                                to="/apply/add-emergency-contact"
                                className="btn btn-AddEmergencyContact Shadowing"
                                onClick={() => this.props.clearEmergencyContact()} >
                                <FontAwesomeIcon icon={faPersonBooth} />
                                <div className="h4">{t("EmergencyContacts.ButtonText.AddEmergencyContact")}</div>
                            </Link> 
                        </div>
                    }

                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        role: state.currentUserInfo.role || "",

        emergencyContacts: state.emergencyContactList,
        emergencyContactsCount: state.emergencyContactList.length || 0,
        licenceApplicationId: state.currentApplication.id || 0,
        licenceApplicationStatusTypeId: state.currentApplication.licenceApplicationStatusTypeId || 0,

        vesselName: state.currentVessel.name || "",
        callSignNumber: state.currentVessel.callSignNumber || "",
        mmsiNumber: state.currentVessel.mmsiNumber || ""
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getEmergencyContacts: (applicationId) => {
            dispatch(CreateGetEmergencyContactDetailsAction(applicationId));
        },
        getWebsiteConfig: () => {
            dispatch(CreateGetWebsiteConfigAction());
        },
        selectEmergencyContact: (contact) => {
            dispatch({
                type: "EMERGENCY_CONTACT_DETAILS_RETRIEVED",
                emergencyContact: contact
            });
        },
        clearEmergencyContact: () => {
            dispatch({
                type: "CLEAR_CURRENT_EMERGENCY_CONTACT_APPLICATION"
            });
        }
    };
};

const languageAwareComponent = withTranslation()(EmergencyContactsList);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);