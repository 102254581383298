const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_CURRENT_EPIRB_APPLICATION":
            return initialState;

        case "EPIRB_DETAILS_RETRIEVED":
            {
                if (action.epirb.batteryExpiryDate && action.epirb.batteryExpiryDate.length) {
                    action.epirb.batteryExpiryDate = new Date(action.epirb.batteryExpiryDate);
                }

                return action.epirb;
            }
        case "EPIRB_MAKE_ID_UPDATED":
            return {
                ...state,
                epirbMakeTypeId: action.data
            };
        case "EPIRB_MODEL_ID_UPDATED":
            return {
                ...state,
                epirbModelTypeId: action.data
            };
        case "EPIRB_MODEL_IS_OTHER_UPDATED":
            return {
                ...state,
                isOtherModel: action.data
            }
        case "EPIRB_MAKE_IS_OTHER_UPDATED":
            return {
                ...state,
                isOtherMake: action.data
            }

        case "EPIRB_MAKE_UPDATED":
            return {
                ...state,
                otherMake: action.data
            };
        case "EPIRB_MODEL_UPDATED":
            return {
                ...state,
                otherModel: action.data
            };
        case "EPIRB_MOUNTING_ID_UPDATED":
            return {
                ...state,
                epirbMountingTypeId: action.data
            };
        case "EPIRB_MOUNTING_WARNING":
            return {
                ...state,
                mountingTypeWarning: action.data
            };
        case "EPIRB_EXPIRY_DATE_UPDATED":
            return {
                ...state,
                batteryExpiryDate: action.data
            };
        case "EPIRB_BATTERY_EXPIRY_DATE_HAS_ERROR":
            return {
                ...state,
                batteryExpiryDateHasError: action.data
            };
        case "EPIRB_SERIAL_NUMBER_UPDATED":
            return {
                ...state,
                serialNumber: action.data
            };
        case "EPIRB_AIS_MMSI_NUMBER_UPDATED":
            return {
                ...state,
                aisMmsiNumber: action.data
            };
        case "EPIRB_IS_MED_APPROVED_UPDATED":
            return {
                ...state,
                isMedApproved: action.data
            };
        case "EPIRB_GPS_ENABLED_ID_UPDATED":
            return {
                ...state,
                epirbGpsEnabledTypeId: action.data
            };
        case "EPIRB_HEX_CODE_UPDATED":
            return {
                ...state,
                hexCode: action.data
            };
        case "EPIRB_HEX_CODE_EXISTS":
            return {
                ...state,
                hexCodeExists: action.data
            };
        case "EPIRB_HOMING_ID_UPDATED":
            return {
                ...state,
                epirbHomingTypeId: action.data
            };
        case "EPIRB_LINK_SERVICE_FEATURE_ID_UPDATED":
            return {
                ...state,
                epirbReturnLinkServiceTypeId: action.data
            };
        case "EPIRB_PROGRAMMED_ID_UPDATED":
            return {
                ...state,
                epirbProgrammedTypeId: action.data
            };


        case "EPIRB_MAKE_REQUIRED":
            return {
                ...state,
                makeRequired: action.data
            };
        case "EPIRB_HEX_CODE_VALID":
            return {
                ...state,
                isHexCodeValid: action.data.isValid,
                errorMessage: action.data.errorMessage
            }
        case "EPIRB_MODEL_REQUIRED":
            return {
                ...state,
                modelRequired: action.data
            };
        case "EPIRB_MOUNTING_REQUIRED":
            return {
                ...state,
                mountingTypeRequired: action.data
            };
        case "EPIRB_BATTERY_EXPIRY_DATE_REQUIRED":
            return {
                ...state,
                batteryExpiryDateRequired: action.data
            };
        case "EPIRB_SERIAL_NUMBER_REQUIRED":
            return {
                ...state,
                serialNumberRequired: action.data
            };
        case "EPIRB_GPS_ENABLED_REQUIRED":
            return {
                ...state,
                gpsEnabledRequired: action.data
            };
        case "EPIRB_AIS_MMSI_NUMBER_ERROR":
            return {
                ...state,
                aisMmsiNumberHasWarning: action.data
            };
        case "EPIRB_HEX_CODE_REQUIRED":
            return {
                ...state,
                hexCodeRequired: action.data
            };
        case "EPIRB_HOMING_REQUIRED":
            return {
                ...state,
                homingRequired: action.data
            };

        default:
            return state;
    }
}
