import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function () {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "noteTemplate/GetAllForDropdown";

        GET(url)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "NOTE_TEMPLATES_RETRIEVED",
                        templates: response.data
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
