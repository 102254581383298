import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function () {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "salutation/GetAllForDropdown";

        GET(url)
            .then((response) => {
                dispatch({
                    type: "SALUTATIONS_RETRIEVED",
                    salutations: response.data
                });

                dispatch(CreateDataRetrievalEndedAction());
            })
            .catch((error) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
