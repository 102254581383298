const initialState = {
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_NOTE_TEMPLATE_DATA":
            return initialState;

        case "RESET_NOTE_TEMPLATE_DATA":
            return { showModal:true };

        case "NOTE_TEMPLATE_UPDATED_SHOW_MODAL":
            return {
                ...state,
                showModal: action.data
            };

        case "NOTE_TEMPLATE_IS_INTERNAL_UPDATED":
            return {
                ...state,
                isInternal: action.data
            };
      
        case "NOTE_TEMPLATE_TEMPLATEID_UPDATED":
            return {
                ...state,
                noteTemplateId: action.data
            };

        case "NOTE_TEMPLATE_NAME_UPDATED":
            return {
                ...state,
                noteTemplateName: action.data
            };

        case "NOTE_TEMPLATE_FIELD_UPDATED":
            return {
                ...state,
                noteTemplateField: action.data
            };

        case "NOTE_TEMPLATE_ATTACHMENT_NAME_UPDATED":
            return {
                ...state,
                noteAttachmentName: action.data
            };

        case "NOTE_TEMPLATE_ATTACHMENT_UPDATED":
            return {
                ...state,
                noteAttachment: action.data
            };
        case "NOTE_TEMPLATE_DESCRIPTION_UPDATED":
            return {
                ...state,
                noteDescription: action.noteDescription
            };
        default:
            return state;
    }
}

