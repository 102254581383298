const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "APPLICATION_PRICING_RETRIEVED":
            return action.pricings;

        default:
            return state;
    }
}
