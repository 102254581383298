const initialState = false;

export default (state = initialState, action) => {
    switch (action.type) {
        
        case "EMERGENCY_SERVICES_SHOW_DETAIL":
            return action.data;
       
        default:
            return state;
    }
}

