import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LinkButton from "./LinkButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers, faScroll, faDatabase, faSearchPlus, faMoneyBillAlt, faCogs } from '@fortawesome/free-solid-svg-icons';

import CreateClearCurrentApplicationAction from "../../store/actionCreators/CreateClearCurrentApplicationAction";
import CreateGetCurrentUserAction from "../../store/actionCreators/CreateGetCurrentUserAction";


class AdminLandingPage extends Component {

    componentDidMount() {
        this.props.clearCurrentApplication();
    }

    render() {
        const { t } = this.props;

        const showMyAccountAlert = this.props.showMyAccountAlert;

        return (
            <React.Fragment>
            {
                    showMyAccountAlert &&
                    <div className="alert alert-success alert-dismissible" role="alert">
                        <Link id="closeAlert" to="#" className="close float-end" onClick={() => { this.props.handleAlertClose() }}>
                        &times;
                        </Link>
                        <strong>{t("MyAccount.UpdateSuccessMessage")}</strong>
                    </div>
                }

            <div className="row mt-1">
                <div className="col-md-12">
                    <LinkButton
                        onClick={() => this.props.getCurrentUser()}
                        to={"/my-account"} className="float-end btn btn-primary">
                        <FontAwesomeIcon icon={faUser} className="smallWhiteIcon" />&nbsp;
                        {t("Common.ButtonText.MyAccount")}</LinkButton>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <div className="ShipsRadioColumnItem">
                        <a href={"/Account/ManageUsers"} className="btn btn-licence-actions">
                            <FontAwesomeIcon icon={faUsers} style={{ fontSize: 12 }} />
                            <div>{t("AdminLandingPage.ManageUsers")}</div>
                        </a>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="ShipsRadioColumnItem">
                        <a href={"/ManageVesselLicence"} className="btn btn-licence-actions">
                            <FontAwesomeIcon icon={faScroll} style={{ fontSize: 12 }} />
                            <div>{t("AdminLandingPage.LicenceList")}</div>
                        </a>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="ShipsRadioColumnItem">
                        <a href={"/emergencyServices"} className="btn btn-licence-actions">
                            <FontAwesomeIcon icon={faSearchPlus} style={{ fontSize: 12 }} />
                            <div>{t("AdminLandingPage.EmergencyServicesSearch")}</div>
                        </a>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <div className="ShipsRadioColumnItem">
                        <a href={"/emergencyServices/AuditLog"} className="btn btn-licence-actions">
                            <FontAwesomeIcon icon={faDatabase} style={{ fontSize: 10 }} />
                            <div>{t("AdminLandingPage.EmergencyServicesSearchLog")}</div>
                        </a>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="ShipsRadioColumnItem">
                            <a href={"/paymentDetails"} className="btn btn-licence-actions">
                                <FontAwesomeIcon icon={faMoneyBillAlt} style={{ fontSize: 12 }} />
                                <div>{t("AdminLandingPage.PaymentDetails")}</div>
                            </a>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="ShipsRadioColumnItem">
                        <a href={"/Admin/Equipment"} className="btn btn-licence-actions">
                                <FontAwesomeIcon icon={faCogs} style={{ fontSize: 12 }} />
                                <div>{t("AdminLandingPage.EquipmentDetails")}</div>
                        </a>
                    </div>
                </div>
            </div>


            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userRole: state.currentUserInfo.role || "",
        showMyAccountAlert: state.currentUser.showMyAccountAlert || false
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        clearCurrentApplication: () => {
            dispatch(CreateClearCurrentApplicationAction());
        },
        getCurrentUser: () => {
            dispatch(CreateGetCurrentUserAction());
        },
        handleAlertClose: () => {
            dispatch({
                type: "MY_ACCOUNT_UPDATED_SHOW_ALERT",
                data: false
            });
        }
    }
};

const languageAwareComponent = withTranslation()(AdminLandingPage);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);