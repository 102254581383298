import { combineReducers } from "redux";
import currentUserInfo from "./currentUserInfo";
import currentVessel from "./currentVessel";
import currentEquipment from "./currentEquipment";
import currentEpirbs from "./currentEpirbs";
import knownUsers from "./knownUsers";
import showSpinner from "./showSpinner";
import vessels from "./vessels";
import currentApplication from "./currentApplication";
import availableCertificateTypes from "./availableCertificateTypes";
import availableSalutations from "./availableSalutations";
import licenceApplications from "./licenceApplications";
import availableHullColor from "./availableHullColors"
import availableSeaAreaOfOperation from "./availableSeaAreaOfOperation"
import availableVesseltypes from "./availableVesselTypes"
import availablePhoneCountryCodes from "./availablePhoneCountryCodes"
import emergencyContactList from "./emergencyContactList"
import emergencyContact from "./emergencyContact"
//import equipment from "./equipment"
import requiredEquipment from "./requiredEquipmentConfigurations"
import requiredEquipmentCalculator from "./requiredEquipmentCalculator"
import requiredEpirbs from "./requiredEpirbConfigurations"
import vesselEquipmentVhfDetail from "./vesselEquipmentVhfDetail"
import availableEquipmentTypeCategories from "./availableEquipmentTypeCategories"
import availableEquipmentTypes from "./availableEquipmentTypes"
import availableVhfDscEnabledTypes from "./availableVhfDscEnabledTypes"
import availableVesselEquipmentVhfTypes from "./availableVesselEquipmentVhfTypes"
import availableEgcReceiverTypes from "./availableEgcReceiverTypes"
import availableLritFacilityTypes from "./availableLritFacilityTypes"
import availableGmdssSatelliteTypes from "./availableGmdssSatelliteTypes"
import availableMfHfDscEnabledTypes from "./availableMfHfDscEnabledTypes"
import availableEquipmentMfHfTypes from "./availableEquipmentMfHfTypes"
import availableRadarBandTypes from "./availableRadarBandTypes"
import availablePortableVhfDscEnabledTypes from "./availablePortableVhfDscEnabledTypes"
import accountingAuthorityIdentificationCodes from "./accountingAuthorityIdentificationCodes"
import fishingVesselPrefixes from "./fishingVesselPrefixes"
import fishingVesselPostfixes from "./fishingVesselPostfixes"
import epirb from "./epirb"
import availableEpirbGpsEnabledTypes from "./availableEpirbGpsEnabledTypes"
import availableEpirbHomingTypes from "./availableEpirbHomingTypes"
import availableEpirbLinkServiceFeatureTypes from "./availableEpirbLinkServiceFeatureTypes"
import availableEpirbMakeTypes from "./availableEpirbMakeTypes"
import availableEpirbModelTypes from "./availableEpirbModelTypes"
import availableEpirbMountingTypes from "./availableEpirbMountingTypes"
import availableEpirbProgrammedTypes from "./availableEpirbProgrammedTypes"
import reviewAndDeclaration from "./reviewAndDeclaration"
import availableVesselSubTypes from "./availableVesselSubTypes"
import defaultEpirbMountingTypeId from "./defaultEpirbMountingTypeId"
import isCommercialVesselType from "./isCommercialVesselType"
import availablePricings from "./availablePricings"
import hasAccess from "./hasAccess"
import requirementsCalculator from "./requirementsCalculator";
import licenceImages from "./licenceImages";
import vesselImages from "./vesselImages";
import availableLicenceApplicationTypes from "./availableLicenceApplicationTypes";
import currentUser from "./currentUser";
import transferLicence from "./transferLicence";
import currentTransfer from "./currentTransfer";
import faqs from "./faqs";
import usefulLinks from "./usefulLinks";
import usefulDocuments from "./usefulDocuments";
import emergencyServices from "./emergencyServices";
import licenceNotes from "./licenceNotes";
import availableNoteTemplates from "./availableNoteTemplates";
import noteTemplate from "./noteTemplate";
import websiteConfig from "./websiteConfig";
import rescueNote from "./rescueNote";
import assignLicence from "./assignLicence";
import licencePayment from "./licencePayment";


export default combineReducers({
    availableCertificateTypes,
    availableHullColor,
    availableSeaAreaOfOperation,
    availableVesseltypes,
    availableSalutations,
    currentApplication,
    currentUserInfo,
    currentVessel,
    defaultEpirbMountingTypeId,
    isCommercialVesselType,
    knownUsers,
    showSpinner,
    vessels,
    licenceApplications,
    availablePhoneCountryCodes,
    emergencyContactList,
    emergencyContact,
    requiredEquipment,
    requiredEquipmentCalculator,
    requiredEpirbs,
    vesselEquipmentVhfDetail,
    availableEquipmentTypeCategories,
    availableEquipmentTypes,
    availableVhfDscEnabledTypes,
    availableVesselEquipmentVhfTypes,
    availableMfHfDscEnabledTypes,
    availableEquipmentMfHfTypes,
    availableRadarBandTypes,
    availablePortableVhfDscEnabledTypes,
    availableEgcReceiverTypes,
    availableLritFacilityTypes,
    availableGmdssSatelliteTypes,
    accountingAuthorityIdentificationCodes,
    fishingVesselPostfixes,
    fishingVesselPrefixes,
    epirb,
    availableEpirbGpsEnabledTypes,
    availableEpirbHomingTypes,
    availableEpirbLinkServiceFeatureTypes,
    availableEpirbMakeTypes,
    availableEpirbModelTypes,
    availableEpirbMountingTypes,
    availableEpirbProgrammedTypes,
    reviewAndDeclaration,
    availableVesselSubTypes,
    availablePricings,
    hasAccess,
    requirementsCalculator,
    licenceImages,
    vesselImages,
    availableLicenceApplicationTypes,
    currentUser,
    transferLicence,
    currentTransfer,
    faqs,
    usefulLinks,
    usefulDocuments,
    emergencyServices,
    licenceNotes,
    availableNoteTemplates,
    noteTemplate,
    websiteConfig,
    currentEquipment,
    currentEpirbs,
    rescueNote,
    assignLicence,
    licencePayment
});


