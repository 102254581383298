import axios from "axios";

import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetRequiredEpirbAction from "./CreateGetRequiredEpirbAction";
import RefreshLicenceIsvalidAction from "./RefreshLicenceIsvalidAction";

export default function () {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const { epirb, currentApplication } = getState();

        const licenceApplicationId = currentApplication.id || 0;
        const licenceStatusId = currentApplication.licenceApplicationStatusTypeId || 0;

        const updatedEpirb = getPostData(licenceApplicationId, epirb);

        axios({
                method: "put",
                url: "epirbDetail",
                data: updatedEpirb
            })
            .then((data) => {
                dispatch(CreateGetRequiredEpirbAction(licenceApplicationId));
                if (licenceStatusId > 1) {
                    dispatch(RefreshLicenceIsvalidAction(licenceApplicationId));
                }
            })
            .catch((error) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

function getPostData(licenceApplicationId, epirb) {

    const updatedEpirb = {
        id: Number(epirb.id) || 0,
        licenceApplicationId: Number(licenceApplicationId) || 0,
        epirbMakeTypeId: epirb.isOtherMake ? null : Number(epirb.epirbMakeTypeId) || null,
        otherMake: epirb.otherMake || "",
        epirbModelTypeId: epirb.isOtherModel? null :Number(epirb.epirbModelTypeId) || null,
        otherModel: epirb.otherModel || "",
        epirbMountingTypeId: Number(epirb.epirbMountingTypeId) || null,
        batteryExpiryDate: new Date((isNaN(epirb.batteryExpiryDate) || epirb.batteryExpiryDate == "")
            ? new Date() : epirb.batteryExpiryDate) || new Date(),
        serialNumber: epirb.serialNumber || "",
        aisMmsiNumber: epirb.aisMmsiNumber || "",
        isMedApproved: epirb.isMedApproved || false,
        epirbGpsEnabledTypeId: Number(epirb.epirbGpsEnabledTypeId) || null,
        hexCode: epirb.hexCode || "",
        epirbHomingTypeId: Number(epirb.epirbHomingTypeId) || null,
        epirbReturnLinkServiceTypeId: Number(epirb.epirbReturnLinkServiceTypeId) || null,
        epirbProgrammedTypeId: null,
        isOtherModel: epirb.isOtherModel || false,
        isOtherMake: epirb.isOtherMake || false,
        isDefault: epirb.isDefault || false
    };

    return updatedEpirb;
}
