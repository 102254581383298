const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_CURRENT_EMERGENCY_CONTACT_APPLICATION":
            return initialState;

        case "EMERGENCY_CONTACT_DETAILS_RETRIEVED":
            return action.emergencyContact;

        case "EMERGENCY_CONTACTS_RETRIEVED":
            return action.data;

        case "EMERGENCY_CONTACT_SALUTATION_ID_UPDATED":
            return {
                ...state,
                salutationId: action.data
            };

        case "EMERGENCY_CONTACT_FIRST_NAME_UPDATED":
            return {
                ...state,
                firstName: action.data
            };
        case "EMERGENCY_CONTACT_FIRST_NAME_REQUIRED":
            return {
                ...state,
                firstNameRequired : action.data
            };

        case "EMERGENCY_CONTACT_LAST_NAME_UPDATED":
            return {
                ...state,
                lastName: action.data
            };
        case "EMERGENCY_CONTACT_LAST_NAME_REQUIRED":
            return {
                ...state,
                lastNameRequired : action.data
            };

        case "EMERGENCY_CONTACT_PHONE_COUNTRY_CODE_UPDATED":
            return {
                ...state,
                phoneCountryCodeId: action.data
            };
        case "EMERGENCY_CONTACT_PHONE_NUMBER_UPDATED":
            return {
                ...state,
                phoneNumber: action.data
            };
        case "EMERGENCY_CONTACT_PHONE_NUMBER_REQUIRED":
            return {
                ...state,
                phoneNumberRequired: action.data
            };
        case "EMERGENCY_CONTACT_PHONE_NUMBER_VALIDATED":
            return {
                ...state,
                phoneNumberValidated: action.data
            };

        case "EMERGENCY_CONTACT_CONFIRM_PHONE_NUMBER_UPDATED":
            return {
                ...state,
                confirmPhoneNumber: action.data
            };
        case "EMERGENCY_CONTACT_CONFIRM_PHONE_NUMBER_REQUIRED":
            return {
                ...state,
                confirmPhoneNumberRequired: action.data
            };

        case "EMERGENCY_CONTACT_ALTERNATIVE_PHONE_COUNTRY_CODE_UPDATED":
            return {
                ...state,
                alternativePhoneCountryCodeId: action.data
            };
        case "EMERGENCY_CONTACT_ALTERNATIVE_PHONE_NUMBER_UPDATED":
            return {
                ...state,
                alternativePhoneNumber: action.data
            };
        case "EMERGENCY_CONTACT_ALTERNATIVE_PHONE_NUMBER_REQUIRED":
            return {
                ...state,
                alternativePhoneNumberRequired: action.data
            };
        case "EMERGENCY_CONTACT_ADDRESS_EIRCODE_UPDATED":
            return {
                ...state,
                addressEirCode: action.data
            };
        case "EMERGENCY_CONTACT_EMAIL_ADDRESS_UPDATED":
            return {
                ...state,
                emailAddress: action.data
            };
        case "EMERGENCY_CONTACT_EMAIL_REQUIRED":
            return {
                ...state,
                emailAddressRequired: action.data
            };

        case "EMERGENCY_CONTACT_CONFIRM_EMAIL_ADDRESS_UPDATED":
            return {
                ...state,
                confirmEmailAddress: action.data
            };
        case "EMERGENCY_CONTACT_CONFIRM_EMAIL_REQUIRED":
            return {
                ...state,
                confirmEmailAddressRequired: action.data
            };

        case "EMERGENCY_CONTACT_ADDRESS_LINE1_UPDATED":
            return {
                ...state,
                addressLine1: action.data
            };
        case "EMERGENCY_CONTACT_ADDRESS_LINE1_REQUIRED":
            return {
                ...state,
                addressLine1Required: action.data
            };

        case "EMERGENCY_CONTACT_ADDRESS_LINE2_UPDATED":
            return {
                ...state,
                addressLine2: action.data
            };
        case "EMERGENCY_CONTACT_ADDRESS_LINE2_REQUIRED":
            return {
                ...state,
                addressLine2Required: action.data
            };

        case "EMERGENCY_CONTACT_ADDRESS_LINE3_UPDATED":
            return {
                ...state,
                addressLine3: action.data
            };
        case "EMERGENCY_CONTACT_ADDRESS_LINE4_UPDATED":
            return {
                ...state,
                addressLine4: action.data
            };

        default:
            return state;
    }
}
