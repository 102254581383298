const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_USEFUL_LINKS":
            return initialState;

        case "USEFUL_LINKS_RETRIEVED":
            return action.usefulLinks;

        default:
            return state;
    }
}
