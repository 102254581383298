import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (licenceApplicationId, statusTypeId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "LicenceApplication/RefreshIsvalid?licenceApplicationId=" + licenceApplicationId;

        GET(url)
            .then(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}