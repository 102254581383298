import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetCurrentUserInfoAction from "../../store/actionCreators/CreateGetCurrentUserInfoAction";


import { GET } from "../adapters/xhr";

export default function (licenceApplicationId, vesselId, applicationTypeId, userType) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        var requestData = {
            licenceApplicationId: licenceApplicationId,
            vesselId: vesselId,
            applicationTypeId: applicationTypeId
        };

        GET("licenceApplication/CancelLicence", requestData)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: "LICENCE_APPLICATIONTYPEID_UPDATED",
                        data: response.data.applicationTypeId
                    });
                    dispatch({
                        type: "LICENCE_STATUSTYPEID_UPDATED",
                        data: response.data.licenceApplicationStatusTypeId
                    });
                }
                //refresh dashboard data if owner cancelling the licence
                if (userType === "owner") dispatch(CreateGetCurrentUserInfoAction());
            })
            .catch((response) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
