import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Accordion } from "react-bootstrap";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import CreateGetFaqsAction from "../../store/actionCreators/CreateGetFaqsAction";

//const $ = require("jquery");

class PublicFAQPage extends Component {
    constructor() {
        super();

        this.doLogin = this.doLogin.bind(this);

        this.state = {
            collapsed: true,
            userIsAuthenticated: false
        };
    }

    componentDidMount() {
        this.props.getFaqs();

        const that = this;

        window.userManager.getUser().then(function (user) {
            that.setState({
                userIsAuthenticated: user != null
            });
        });
    }

     

    doLogin() {
        var { history } = this.props;

        window.userManager.getUser().then(function(user) {
            if (user) {
                history.push("dashboard");
            } else {
                window.userManager.signinRedirect();        
            }
        });
    }


    render() {

        const t = this.props.t;

        return (
            <React.Fragment>
                <Breadcrumb
                    role=""
                    homeLink={t("Common.ButtonText.Home")}
                    pageName={t("PublicFAQPage.FAQPageHeading")} />

                <div className="row">
                    <div className="col-md-12">
                        <div class="h5 text-center">{t("PublicFAQPage.FAQPageHeading")}</div>
                    </div>
                </div>
                <hr className="srl-hr" />

                <div className="mb-5 ">
                    {
                        this.props.faqs.length > 0 &&
                        <Accordion flush>
                            {
                                this.props.faqs.map((faq,index) => {
                                    return (
                                        <Accordion.Item key={index} eventKey={faq.id}>
                                            <Accordion.Header><strong>{faq.name}</strong></Accordion.Header>
                                            <Accordion.Body>
                                                {parse(faq.description)}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        );
                                })
                            }
                        </Accordion>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        faqs: state.faqs || []
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getFaqs: () => {
            dispatch(CreateGetFaqsAction());
        }
    };
};

const languageAwareComponent = withTranslation()(withRouter(PublicFAQPage));

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);