import axios from "axios";

// import authService from "../../components/api-authorization/AuthorizeService";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import CreateGetEmergencyContactDetailsAction from "./CreateGetEmergencyContactDetailsAction";


export default function (id, applicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        axios({
                method: "DELETE",
                url: "EmergencyContact?id=" + id
            })
            .then((response) => {
                dispatch(CreateGetEmergencyContactDetailsAction(applicationId));
            })
            .catch((error) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}