const initialState = {
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_ASSIGN_LICENCE":
            return initialState;

        case "ASSIGN_LICENCE_VESSEL_NAME_UPDATED":
            return {
                ...state,
                vesselName: action.data
            };

        case "ASSIGN_LICENCE_VESSEL_NAME_REQUIRED":
            return {
                ...state,
                vesselNameRequired: action.data
            };

        case "ASSIGN_LICENCE_CALL_SIGN_UPDATED":
            return {
                ...state,
                callSign: action.data
            };

        case "ASSIGN_LICENCE_CALL_SIGN_REQUIRED":
            return {
                ...state,
                callSignRequired: action.data
            };

        case "ASSIGN_LICENCE_LICENCE_NUMBER_UPDATED":
            return {
                ...state,
                licenceNumber: action.data
            };

        case "ASSIGN_LICENCE_LICENCE_NUMBER_REQUIRED":
            return {
                ...state,
                licenceNumberRequired: action.data
            };
        case "ASSIGN_LICENCE_MMSI_NUMBER_UPDATED":
            return {
                ...state,
                mmsiNumber: action.data
            };

        case "ASSIGN_LICENCE_LICENCE_FOUND":
            return {
                ...state,
                licenceFound: action.data
            };

        default:
            return state;
    }
}
