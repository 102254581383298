import { GET } from "../adapters/xhr";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

export default function () {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());
        const url = "account/GetUserInfo";
        
        GET(url)
            .then(function (response) {
                if (response.status === 200) {
                    if (!response.data.isAuthenticated) {
                        dispatch({
                            type: "CLEAR_CURRENT_USER_INFO"
                        });
                    } else {
                        dispatch({
                            type: "CURRENT_USER_INFO_RECEIVED",
                            data: response.data
                        });
                    }
                   
                }
                dispatch(CreateDataRetrievalEndedAction());

            }).catch(function (response) {
                dispatch({
                    type: "CLEAR_CURRENT_USER_INFO"                    
                });
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
