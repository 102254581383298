const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "LRIT_FACILITY_TYPES_RETRIEVED":
            return action.availableLritFacilityTypes;

        default:
            return state;
    }
}
