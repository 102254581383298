import axios from "axios";

import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetRequiredEpirbAction from "./CreateGetRequiredEpirbAction";
import RefreshLicenceIsvalidAction from "./RefreshLicenceIsvalidAction";

export default function (licenceApplicationId) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());
        const { epirb, currentApplication } = getState();

        const licenceStatusId = currentApplication.licenceApplicationStatusTypeId || 0;

        const serialNumber = epirb.serialNumber || "";

        const aisMmsiNumber = epirb.aisMmsiNumber === null || epirb.aisMmsiNumber === "974"
            ? ""
            : epirb.aisMmsiNumber;

        const newEpirb = {
            licenceApplicationId: Number(licenceApplicationId) || 0,
            epirbMakeTypeId: epirb.isOtherMake ? null : Number(epirb.epirbMakeTypeId) || null,
            otherMake: epirb.otherMake || "",
            epirbModelTypeId: epirb.isOtherModel ? null : Number(epirb.epirbModelTypeId) || null,
            otherModel: epirb.otherModel || "",
            epirbMountingTypeId: Number(epirb.epirbMountingTypeId) || null,
            batteryExpiryDate: new Date((isNaN(epirb.batteryExpiryDate) || epirb.batteryExpiryDate == "")
                                            ? new Date() : epirb.batteryExpiryDate) || new Date(),
            serialNumber: serialNumber.trim(),
            aisMmsiNumber: aisMmsiNumber,
            isMedApproved: epirb.isMedApproved || false,
            epirbGpsEnabledTypeId: Number(epirb.epirbGpsEnabledTypeId) || null,
            hexCode: epirb.hexCode || "",
            epirbHomingTypeId: Number(epirb.epirbHomingTypeId) || null,
            epirbReturnLinkServiceTypeId: Number(epirb.epirbReturnLinkServiceTypeId) || null,
            epirbProgrammedTypeId: Number(epirb.epirbProgrammedTypeId) || null,
            isOtherModel: epirb.isOtherModel || false,
            isOtherMake: epirb.isOtherMake || false,
            isDefault: epirb.isDefault || false
        };

        //authService.getAccessToken().then((token) => {
                axios({
                    method: "post",
                    url: "epirbDetail",
                    //headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    data: newEpirb
                })
                    .then((data) => {
                        dispatch(CreateGetRequiredEpirbAction(licenceApplicationId));
                        if (licenceStatusId > 1) {
                            dispatch(RefreshLicenceIsvalidAction(licenceApplicationId));
                        }
                    })
                    .catch((error) => {
                        dispatch(CreateDataRetrievalEndedAction());
                    });
            //});
    };
}
