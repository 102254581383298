const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {

        case "CLEAR_CURRENT_EQUIPMENT":
            return initialState;

        case "VESSEL_EQUIPMENT_VHF_DETAILS_RETRIEVED":
            return action.vesselEquipmentVhfDetail;

        case "VESSEL_EQUIPMENT_DETAILS_EQUIPMENT_CATEGORY_ID_UPDATED":
            return {
                ...state,
                equipmentTypeCategoryId: action.data
            };

        case "VESSEL_EQUIPMENT_DETAILS_EQUIPMENT_TYPE_ID_UPDATED":
            return {
                ...state,
                equipmentTypeId: action.data
            };

        case "VESSEL_OTHER_EQUIPMENT_TYPE_UPDATED":
            return {
                ...state,
                otherEquipmentTypeName: action.data
            };

        case "VESSEL_EQUIPMENT_MFHF_EQUIPMENT_MFHF_TYPE_ID_UPDATED":
            return {
                ...state,
                equipmentMfHfTypeId: action.data
            };

        case "VESSEL_EQUIPMENT_IS_DSC_UPDATED":
            return {
                ...state,
                isDscEnabled: action.data
            };

        case "VESSEL_EQUIPMENT_MAKE_UPDATED":
            return {
                ...state,
                make: action.data
            };
        case "VESSEL_EQUIPMENT_MODEL_UPDATED":
            return {
                ...state,
                model: action.data
            };
        case "VESSEL_EQUIPMENT_IS_MED_APPROVED_UPDATED":
            return {
                ...state,
                isMedApproved: action.data
            };
        case "VESSEL_EQUIPMENT_IS_GPS_ENABLED_UPDATED":
            return {
                ...state,
                isGpsEnabled: action.data
            };
        case "VESSEL_EQUIPMENT_MANUFACTURER_MODEL_IS_OTHER_UPDATED":
            return {
                ...state,
                isOtherMakeModel: action.data
            };
        case "VESSEL_EQUIPMENT_MMSI_NUMBER_UPDATED":
            return {
                ...state,
                mmsiNumber: action.data
            };
        case "VESSEL_EQUIPMENT_MMSI_NUMBER_REQUIRED":
            return {
                ...state,
                mmsiNumberRequired: action.data
            };
        case "VESSEL_EQUIPMENT_RADAR_BAND_TYPE_ID_UPDATED":
            return {
                ...state,
                radarBandTypeId: action.data
            };
        case "VESSEL_EQUIPMENT_PLB_NUMBER_OF_PLBS_UPDATED":
            return {
                ...state,
                numberOfPlbs: action.data
            };
        case "VESSEL_EQUIPMENT_HEX_CODE_UPDATED":
            return {
                ...state,
                hexCode: action.data
            };
        case "VESSEL_EQUIPMENT_HEX_CODE_REQUIRED":
            return {
                ...state,
                hexCodeRequired: action.data
            };
        case "VESSEL_EQUIPMENT_SATELLITE_NUMBER_UPDATED":
            return {
                ...state,
                satelliteNumber: action.data
            };
        case "VESSEL_EQUIPMENT_GMDSS_SATELLITE_TYPE_ID_UPDATED":
            return {
                ...state,
                satelliteTypeId: action.data
            };
        case "VESSEL_EQUIPMENT_IS_EGC_RECEIVER_UPDATED":
            return {
                ...state,
                isEgcReceiver: action.data
            };
        case "VESSEL_EQUIPMENT_IS_LRIT_FACILITY_UPDATED":
            return {
                ...state,
                isLritFacility: action.data
            };
        case "VESSEL_EQUIPMENT_DETAILS_IS_NEW_AMENDMENT":
            return {
                ...state,
                isNewAmendment: action.data
            };
        case "DISABLE_EQUIPMENT_TYPE_CATEGORY_UPDATED":
            return {
                ...state,
                disableEquipmentTypeCategory: action.data
            };
        default:
            return state;
    }
}
