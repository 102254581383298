import axios from "axios";

export default function (licenceApplicationId, vesselId) {
    return (dispatch) => {
        var requestData = {
            licenceApplicationId,
            vesselId
        };

        axios({
                method: "GET",
                url: "licenceApplication/CheckResetLicenceApplicationType",
                params: requestData
            })
            .then((response) => {
                if (response.isOK) {
                    dispatch({
                        type: "LICENCE_AMENDMENT",
                        data: response.data
                    });
                }
               
            })
            .catch((error) => {
            });
    };
}
