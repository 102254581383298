import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetCurrentUserInfoAction from "../../store/actionCreators/CreateGetCurrentUserInfoAction";


import { GET } from "../adapters/xhr";

export default function (licenceApplicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        var requestData = {
            licenceApplicationId: licenceApplicationId
        };

        GET("licenceApplication/CancelLicenceTransfer", requestData)
            .then((response) => {
                if (response.status === 200) {
                    //refresh dashboard data
                    dispatch(CreateGetCurrentUserInfoAction());
                }
            })
            .catch((response) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
