import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import { GET } from "../adapters/xhr";

export default function () {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "/User/UpdateUser";
        const { currentUser } = getState();

        const companyName = currentUser.companyName ? currentUser.companyName.trim() : null;
        const companyRegistrationNumber = currentUser.companyRegistrationNumber ? currentUser.companyRegistrationNumber.trim() : null;
        const alternativePhoneNumber = currentUser.alternativePhoneNumber ? currentUser.alternativePhoneNumber.trim() : null;
        const addressEirCode = currentUser.addressEircode ? currentUser.addressEircode.trim() : null;
        const addressLine3 = currentUser.addressLine3 ? currentUser.addressLine3.trim() : null;
        const addressLine4 = currentUser.addressLine4 ? currentUser.addressLine4.trim() : null;

        const updateModel = {
            id: Number(currentUser.id) || 0,
            licenceApplicationTypeId: Number(currentUser.licenceApplicationTypeId),
            companyName: companyName,
            companyRegisterationNumber: companyRegistrationNumber,
            salutationId: Number(currentUser.salutationId) || null,
            firstName: currentUser.firstName.trim(),
            lastName: currentUser.lastName.trim(),
            emailAddress: currentUser.emailAddress.trim(),
            phoneNumber: currentUser.phoneNumber,
            alternativePhoneNumber: alternativePhoneNumber,
            addressEircode: addressEirCode,
            addressLine1: currentUser.addressLine1.trim(),
            addressLine2: currentUser.addressLine2.trim(),
            addressLine3: addressLine3,
            addressLine4: addressLine4,
            isActive: currentUser.isActive,
            lastUpdatedOn: new Date()
        };

        GET(url, updateModel)
            .then(function (response) {
                if (response.status === 200 && response.data.updateSucceeded ) {
                    dispatch({
                        type: "MY_ACCOUNT_UPDATED_SHOW_ALERT",
                        data: true
                    });
                }
                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });

    };
}
