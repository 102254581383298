import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CreateGetEmergencyServicesLicenceDataAction from "../store/actionCreators/CreateGetEmergencyServicesLicenceDataAction";
import CreateGetVesselImagesAction from "../store/actionCreators/CreateGetVesselImagesByLicenceIdAction";

class EmergencyServicesView extends Component {

    componentDidMount() {
        const id = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
        const urlId = (isNaN(id)) ? 0 : Number(id);
        if (urlId !== 0) {
            this.props.getLicenceApplication(urlId);
        }
        this.props.handleHideDetail();
    }

    render() {
        const { t } = this.props;

        const EquipmentTypeCategories = {
            AisSart: 1,
            AisTransponder: 2,
            ClassMmob: 3,
            MfHf: 4,
            NonGmdss: 5,
            Plb: 6,
            HandheldVhf: 7,
            Radar: 8,
            Sart: 9,
            Vhf: 10,
            GNSS: 11,
            AeronauticalVHF: 12,
            GMDSS: 13,
            Other: 14,
            HandheldUhf: 15
        };

        const formatDate = (dateStr) => {
            var date = new Date(dateStr);
            var minDate = new Date('1900-01-01T00:00:00');

            if (date.getTime() === minDate.getTime()) return "";

            return ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +
                '/' +
                ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +
                '/' +
                date.getFullYear();
        };

        const uniqByKeepFirst = (a, key) => {
            let seen = new Set();
            return a.filter(item => {
                let k = key(item);
                return seen.has(k) ? false : seen.add(k);
            });
        };

        const formatAddress = (addressLine1, addressLine2, addressLine3, addressLine4, addressEirCode) => {
            return addressLine1 ?? "" +
                (addressLine2 === "" ? "" : "<br/>" + addressLine2) +
                (addressLine3 === "" ? "" : "<br/>" + addressLine3) +
                (addressLine4 === "" ? "" : "<br/>" + addressLine4) +
                (addressEirCode === "" ? "" : "<br/>" + addressEirCode);
        };

        const vesselType = this.props.hasVesselSubTypes
            ? this.props.vesselType + ", " + this.props.vesselSubType
            : this.props.vesselType;

        const fishingVesselRegNumber = this.props.fvIdPostfix + this.props.fvVesselIdentificationNumber + this.props.fvIdPrefix;

        const userAddress = formatAddress(this.props.userAddress1, this.props.userAddress2, this.props.userAddress3,
            this.props.userAddress4, this.props.userEircode);

        const epirbCount = this.props?.currentEpirbs?.length || 0;
        const equipmentCount = this.props?.currentEquipment?.length || 0;

        //get unique hexcodes from model list
        const epirbHexCodes = epirbCount > 0
            ? this.props.currentEpirbs.filter(m => m.hexCode != null)
                .map(function(e) {
                    return {
                        hexCode: e.hexCode,
                        aisMmsiNumber: e.aisMmsiNumber ?? ""
                    };
                })
            : [];

        const epirbArray = uniqByKeepFirst(epirbHexCodes, it => it.hexCode);

        var hexCodeDisplay = "";
        var aisDisplay = "";

        epirbArray.forEach(function(x) {
            hexCodeDisplay += hexCodeDisplay === "" ? x.hexCode : ", " + x.hexCode;
            aisDisplay += aisDisplay === "" ? x.aisMmsiNumber : ", " + x.aisMmsiNumber;
        });

        const equipmentIdentifier = equipmentCount > 0
            ? this.props.currentEquipment.filter(x => x.equipmentTypeId != null &&
                (x.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.GMDSS ||
                    x.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.NonGmdss ||
                    x.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.Plb ||
                    (x.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.HandheldVhf && x.dscEnabled) ||
                    x.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.AisSart ||
                    x.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.ClassMmob
                ))
            : [];

        const emergencyContacts = this.props.emergencyContacts;
        const emergencyContactsCount = emergencyContacts.length;

        const emergencyContact1 = emergencyContactsCount > 0
            ? emergencyContacts[0].firstName + " " + emergencyContacts[0].lastName
            : "";
        const emergencyPhone1 = emergencyContactsCount > 0
            ? emergencyContacts[0].phoneNumber ?? ""
            : "";
        const emergencyAlternativePhone1 = emergencyContactsCount > 0
            ? emergencyContacts[0].alternativePhoneNumber ?? ""
            : "";

        const emergencyEmailAddress1 = emergencyContactsCount > 0
            ? emergencyContacts[0].emailAddress ?? ""
            : "";

        const emergencyAddress1 = emergencyContactsCount > 0
            ? formatAddress(emergencyContacts[0].addressLine1,
                emergencyContacts[0].addressLine2,
                emergencyContacts[0].addressLine3,
                emergencyContacts[0].addressLine4,
                emergencyContacts[0].addressEirCode)
            : "";

        const emergencyContact2 = emergencyContactsCount > 1
            ? emergencyContacts[1].firstName + " " + emergencyContacts[1].lastName
            : "";
        const emergencyPhone2 = emergencyContactsCount > 1
            ? emergencyContacts[1].phoneNumber ?? ""
            : "";
        const emergencyAlternativePhone2 = emergencyContactsCount > 1
            ? emergencyContacts[1].alternativePhoneNumber ?? ""
            : "";

        const emergencyEmailAddress2 = emergencyContactsCount > 1
            ? emergencyContacts[1].emailAddress ?? ""
            : "";

        const emergencyAddress2 = emergencyContactsCount > 1
            ? formatAddress(emergencyContacts[1].addressLine1,
                emergencyContacts[1].addressLine2,
                emergencyContacts[1].addressLine3,
                emergencyContacts[1].addressLine4,
                emergencyContacts[1].addressEirCode)
            : "";

        const additionalEmergencyContacts = emergencyContactsCount > 2
            ? emergencyContacts.filter(function(value, index, arr) {
                return index > 1;
            })
            : [];

        const goToPreviousPath = () => {
            this.props.history.goBack();
        }

        var locations = window.locations || [document.referrer];
        var prevUrl = locations[locations.length - 1];

        return (
            <React.Fragment>
            {
                    this.props.showDetail &&
                    <div className="row mt-1">
                        <div className="col-md-7">
                            {
                                prevUrl.indexOf("EmergencyServices") !== -1 &&
                                <button id="returnToEmergencyServicesSearch" className="btn btn-primary"
                                    onClick={goToPreviousPath}>
                                    {t("Common.ButtonText.BackToSearch")}
                                </button>
                            }
                            {
                                prevUrl.indexOf("EmergencyServices") === -1 &&
                                <a id="returnToEmergencyServicesSearch"
                                    className="btn btn-primary"
                                    href={"/EmergencyServices"}>
                                    {t("Common.ButtonText.BackToSearch")}
                                </a>
                            }
                            <a to="#" className="btn btn-primary ms-2"
                               onClick={() => { this.props.handleHideDetail() }}>
                                {t("EmergencyServicesView.ShowSummary")}
                            </a>
                            <a id="btnCreatePdf"
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary ms-2"
                                href={"/LicenceApplication/CreateEmergencyServicesPdf/?id=" + this.props.licenceApplicationId}
                            >
                                {t("Common.ButtonText.CreatePdf")}
                            </a>
                        </div>
                        <div className="col-md-5">
                            <div id="lastUpdated" className="float-end">{t("EmergencyServicesView.LastUpdated")}: {formatDate(this.props.lastUpdated)}</div>
                        </div>
                    </div>
                }
            {
                    !this.props.showDetail &&
                    <div className="row mt-1">
                        <div className="col-md-7">
                            {
                                prevUrl.indexOf("EmergencyServices") !== -1 &&
                                <button id="returnToEmergencyServicesSearch" className="btn btn-primary"
                                    onClick={goToPreviousPath}>
                                    {t("Common.ButtonText.BackToSearch")}
                                </button>
                            }
                            {
                                prevUrl.indexOf("EmergencyServices") === -1 &&
                                <a id="returnToEmergencyServicesSearch"
                                    className="btn btn-primary"
                                    href={"/EmergencyServices"}>
                                    {t("Common.ButtonText.BackToSearch")}
                                </a>
                            }
                            <a to="#" className="btn btn-primary ms-2"
                               onClick={() => { this.props.handleShowDetail() }}>
                                {t("EmergencyServicesView.ShowDetail")}
                            </a>
                            <a id="btnCreatePdf"
                                target="_blank"
                                className="btn btn-primary ms-2"
                                href={"/LicenceApplication/CreateEmergencyServicesPdf/?id=" + this.props.licenceApplicationId}
                            >
                                {t("Common.ButtonText.CreatePdf")}
                            </a>
                        </div>
                        <div className="col-md-5">
                            <div id="lastUpdated" className="float-end">{t("EmergencyServicesView.LastUpdated")}: {formatDate(this.props.lastUpdated)}</div>
                        </div>
                    </div>
                        
                }

            {
                    this.props.noteDescription &&
                    <div className="col-md-12 bg-light p-2 rounded-lg m-3 bg-customWarning">
                        <div className="h5"> {t("EmergencyServicesView.RescueNoteHeading")}</div>
                        {this.props.noteDescription}
                    </div>

                }

            {
                    this.props.vesselImages.length > 0 &&
                    <div>
                        <span className="h6">{t("EmergencyServicesView.VesselPhotos")}</span>
                        <small id="vesselPhotoHelp" className="form-text text-muted ms-2">{t("EmergencyServicesView.ClickPhoto")}</small>
                    </div>
                }
            {
                    this.props.vesselImages.length > 0 &&
                    <div className="row">
                        {
                            this.props.vesselImages.map((img, index) => (
                                <div className="col-md-4" key={index}>
                                    <a href={"/viewLicenceImage?id=" + img.id} target="_blank" rel="noreferrer"><img height="100" width="100" alt={img.name} src={img.src} /></a>
                                </div>
                            ))
                        }
                    </div>
                }
            {
                    this.props.vesselImages.length > 0 &&
                    <hr />
                }

            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="vesselNameLabel" className="form-label">{t("AddVessel.VesselNameLabel")}</label>

                        <input id="vesselName" type="text"
                               className="form-control"
                               disabled={true}
                               value={this.props.vesselName}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="callSignLabel" className="form-label">{t("OwnerLandingPage.CallSign")}</label>

                        <input id="callSignNumber" type="text"
                               className="form-control"
                               disabled={true}
                               value={this.props.callSignNumber}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="mmsiNumberLabel" className="form-label">{t("OwnerLandingPage.MMSINumber")}</label>

                        <input id="mmsiNumber" type="text"
                               className="form-control"
                               disabled={true}
                               value={this.props.mmsiNumber}/>
                    </div>
                </div>

            </div>

            {
                    (hexCodeDisplay !== "" || aisDisplay !== "") &&
                    <div className="row" hidden={this.props.showDetail}>
                        <div className="col-md-4">
                            <label id="hexCodeLabel" className="form-label">{t("EpirbDetails.HexCode")}</label>
                            <input id="hexCode" type="text"
                                className="form-control"
                                disabled={true}
                                value={hexCodeDisplay} />
                        </div>
                        <div className="col-md-4">
                            <label id="aisMmsiNumberLabel" className="form-label">{t("EmergencyServicesView.AisMmsiNumber")}</label>
                            <input id="aisMmsiNumber" type="text"
                                className="form-control"
                                disabled={true}
                                value={aisDisplay} />
                        </div>
                    </div>
                }


            {
                    (epirbCount > 0) && this.props.showDetail &&
                    this.props.currentEpirbs.map((equipment, index) => {
                        const epirbIndex = epirbCount === 1 ? "" : index + 1;
                        const epirbGps =
                            equipment.epirbGpsEnabledTypeId == null || equipment.epirbGpsEnabledTypeId === 2
                                ? ""
                                : "GPS,";
                        const epirbHoming = equipment.epirbHomingTypeId == null || equipment.epirbHomingTypeId === 2
                            ? ""
                            : "121.5Mhz, ";
                        const returnLink = equipment.epirbReturnLinkServiceTypeId == null || equipment.epirbReturnLinkServiceTypeId === 2
                            ? ""
                            : "Return Link Service, ";
                        const mountingType = equipment.epirbMountingTypeId == null
                            ? ""
                            : equipment.epirbMountingType.name;
                        const epirbDetails = (epirbGps + epirbHoming + returnLink + mountingType) === ""
                            ? null
                            : "EPIRB: " + epirbGps + epirbHoming + returnLink + mountingType;
                        const epirbMake = equipment.isOtherMake ? equipment.otherMake : equipment.epirbMakeType.make;
                        const epirbModel = equipment.isOtherModel ? equipment.otherModel : equipment.epirbModelType.name;


                        return (<div key={equipment.id}>
                            <div className="row">
                                <div className="col-md-4">
                                    <label id={"hexCodeLabel" + index} className="form-label">{t("EpirbDetails.HexCode") + epirbIndex}</label>
                                    <input id={"hexCode" + index} type="text"
                                        className="form-control"
                                        disabled={true}
                                        value={equipment.hexCode} />
                                </div>

                                <div className="col-md-4">
                                    <label id={"aisMmsiNumberLabel" + index} className="form-label">{t("EmergencyServicesView.AisMmsiNumber") + epirbIndex}</label>
                                    <input id={"aisMmsiNumber" + index} type="text"
                                        className="form-control"
                                        disabled={true}
                                        value={equipment.aisMmsiNumber} />
                                </div>
                                
                                <div className="col-md-4">
                                    <label id={"epirbManufacturerLabel" + index} className="form-label">{t("EmergencyServicesView.EPIRBManufacturerModel") + epirbIndex}</label>
                                    <input id={"epirbManufacturer" + index} type="text"
                                        className="form-control"
                                        disabled={true}
                                        value={epirbMake + "/" + epirbModel} />
                                </div>
                                {
                                    equipment.serialNumber &&
                                    <div className="col-md-4">
                                        <label id={"serialNumberLabel" + index} className="form-label">{t("EmergencyServicesView.EPIRBSerialNumber") + epirbIndex}</label>
                                        <input id={"serialNumber" + index} type="text"
                                            className="form-control"
                                            disabled={true}
                                            value={equipment.serialNumber} />
                                    </div>
                                }
                                {
                                    epirbDetails &&
                                    <div className="col-md-4">
                                        <label id={"epirbDetailsLabel" + index} className="form-label">{"EPIRB Details" + epirbIndex}</label>
                                        <input id={"epirbDetails" + index} type="text"
                                            className="form-control"
                                            disabled={true}
                                            value={epirbDetails} />
                                    </div>
                                }
                                {
                                    equipment.batteryExpiryDate &&
                                    <div className="col-md-4">
                                        <label id={"batteryExpiryLabel" + index} className="form-label">{t("EmergencyServicesView.EPIRBBatteryExpiryDate") + epirbIndex}</label>
                                        <input id={"batteryExpiry" + index} type="text"
                                            className="form-control"
                                            disabled={true}
                                            value={formatDate(equipment.batteryExpiryDate)} />
                                    </div>
                                }
                            </div>
                            <hr />
                        </div>
                        )
                    })
                }

            {
                    (epirbCount > 0) || !this.props.showDetail &&
                    <hr />
                }

            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="contactOwnerLabel" className="form-label">{t("EmergencyServicesView.ContactOwner")}</label>
                        <input id="contactOwner" type="text"
                               className="form-control"
                               disabled={true}
                               value={this.props.userFirstName + " " + this.props.userLastName}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="phoneNumberLabel" className="form-label">{t("MyAccount.PhoneNumber")}</label>
                        <input id="phoneNumber" type="text"
                               className="form-control"
                               disabled={true}
                               value={this.props.userPhone}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="alternativePhoneNumberLabel" className="form-label">{t("MyAccount.AlternativePhoneNumber")}</label>
                        <input id="alternativePhoneNumber" type="text"
                               className="form-control"
                               disabled={true}
                               value={this.props.userAlternativePhone}/>
                    </div>
                </div>
                <div className="col-md-4" hidden={!this.props.showDetail}>
                    <div className="form-group">
                        <label id="emailAddressLabel" className="form-label">{t("EmergencyServicesView.ContactOwnerEmailAddress")}</label>
                        <input id="emailAddress" type="text"
                               className="form-control"
                               disabled={true}
                               value={this.props.userEmailAddress}/>
                    </div>
                </div>
                <div className="col-md-4" hidden={!this.props.showDetail}>
                    <div className="form-group">
                        <label id="addressLabel" className="form-label">{t("EmergencyServicesView.ContactOwnerAddress")}</label>
                        <div id="address" className="form-control" style={{ backgroundColor:"#e9ecef"}} dangerouslySetInnerHTML={{ __html: userAddress }}/>
                    </div>
                </div>

            </div>

            <hr/>
            <div className="h5">{t("EmergencyServicesView.VesselDetails")}</div>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="vesselTypeLabel" className="form-label">{t("AddVessel.VesselTypeLabel")}</label>
                        <input id="vesselType" type="text"
                               className="form-control"
                               disabled={true}
                               value={vesselType}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="vesselLengthLabel" className="form-label">{t("AddVessel.VesselLengthLabel")}</label>
                        <input id="vesselLength" type="Number"
                               className="form-control"
                               disabled={true}
                               value={this.props.vesselLength}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="hullColourLabel" className="form-label">{t("AddVessel.VesselHullColorLabel")} </label>
                        <input id="hullColour" type="text"
                               className="form-control"
                               disabled={true}
                               value={this.props.hullColour}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="grossTonnageLabel" className="form-label">{t("AddVessel.VesselGrossTonnageLabel")}</label>
                        <input id="grossTonnage" type="Number"
                               className="form-control"
                               disabled={true}
                               value={this.props.grossTonnage}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="maxVesselCapacityLabel" className="form-label">{t("AddVessel.VesselMaxCapacityLabel")}</label>
                        <input id="maxVesselCapacity" type="Number"
                               className="form-control"
                               disabled={true}
                               value={this.props.maxVesselCapacity}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="numberOfLifeBoatsLabel" className="form-label">{t("AddVessel.VesselNumberOfLifeBoatsLabel")}</label>
                        <input id="numberOfLifeBoats" type="Number"
                               className="form-control"
                               disabled={true}
                               value={this.props.numberOfLifeBoats}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="homeportLabel" className="form-label">{t("AddVessel.VesselHomeportLabel")} </label>
                        <input id="homeport" type="text"
                               className="form-control"
                               disabled={true}
                               value={this.props.homeport}/>

                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="maxAreaOfOperationLabel" className="form-label">{t("AddVessel.VesselSeaAreaOfOperationLabel")} </label>
                        <input id="maxAreaOfOperation" type="text"
                               className="form-control"
                               disabled={true}
                               value={this.props.seaArea}/>
                    </div>
                </div>

                {
                        this.props.vesselImoNumber &&
                        <div className="col-md-4">
                            <div className="form-group">
                                <label id="vesselImoNumberLabel" className="form-label">{t("AddVessel.VesselImoNumberLabel")}</label>
                                <input id="vesselImoNumber" type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={this.props.vesselImoNumber} />
                            </div>
                        </div>
                    }

                {
                        this.props.vesselRegistrationNumber &&
                        <div className="col-md-4">
                            <div className="form-group">
                                <label id="vesselRegistrationNumberLabel" className="form-label">{t("AddVessel.VesselRegistrationNumberLabel")} </label>
                                <input id="vesselRegistrationNumber" type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={this.props.vesselRegistrationNumber} />
                            </div>
                        </div>
                    }
                {
                        fishingVesselRegNumber !== "" &&
                            <div className="col-md-4">
                                <div className="form-group">
                                <label id="fvVesselIdentificationNumberLabel" className="form-label">{t("AddVessel.VesselFvIdNumberLabel")} </label>
                                    <input id="fvVesselIdentificationNumber" type="text"
                                           className="form-control"
                                           disabled={true}
                                           value={fishingVesselRegNumber}/>
                                </div>
                            </div>
                    }

            </div>

            {
                    this.props.vesselDescription &&
                    <hr />
                }
            {
                    this.props.vesselDescription &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label id="vesselDescriptionLabel" className="form-label">{t("AddVessel.VesselDescriptionLabel")}</label>
                                <input id="vesselDescription" type="text"
                                       className="form-control"
                                       disabled={true}
                                       value={this.props.vesselDescription} />
                            </div>
                        </div>
                    </div>
                }

            <hr/>

            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="emergencyContact1Label" className="form-label">{t("EmergencyServicesView.EmergencyContact1")}</label>
                        <input id="emergencyContact1" type="text"
                               className="form-control"
                               disabled={true}
                               value={emergencyContact1}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="emergencyPhone1Label" className="form-label">{t("MyAccount.PhoneNumber")}</label>
                        <input id="emergencyPhone1" type="text"
                               className="form-control"
                               disabled={true}
                               value={emergencyPhone1}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="emergencyAlternativePhone1Label" className="form-label">{t("MyAccount.AlternativePhoneNumber")}</label>
                        <input id="emergencyAlternativePhone1" type="text"
                               className="form-control"
                               disabled={true}
                               value={emergencyAlternativePhone1}/>
                    </div>
                </div>
                <div className="col-md-4" hidden={!this.props.showDetail}>
                    <div className="form-group">
                        <label id="emergencyEmailAddress1Label" className="form-label">{t("MyAccount.EmailAddress")}</label>
                        <input id="emergencyEmailAddress1" type="text"
                               className="form-control"
                               disabled={true}
                               value={emergencyEmailAddress1}/>
                    </div>
                </div>
                <div className="col-md-4" hidden={!this.props.showDetail}>
                    <div className="form-group">
                        <label id="emergencyAddress1Label" className="form-label">{t("EmergencyServicesView.Address")}</label>
                        <div id="emergencyAddress1" className="form-control" style={{ backgroundColor: "#e9ecef" }} dangerouslySetInnerHTML={{ __html: emergencyAddress1 }}>
                        </div>
                    </div>
                </div>


            </div>

            <hr/>

            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="emergencyContact2Label" className="form-label">{t("EmergencyServicesView.EmergencyContact2")}</label>
                        <input id="emergencyContact2" type="text"
                               className="form-control"
                               disabled={true}
                               value={emergencyContact2}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="emergencyPhone2Label" className="form-label">{t("MyAccount.PhoneNumber")}</label>
                        <input id="emergencyPhone2" type="text"
                               className="form-control"
                               disabled={true}
                               value={emergencyPhone2}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label id="emergencyAlternativePhone2Label" className="form-label">{t("MyAccount.AlternativePhoneNumber")}</label>
                        <input id="emergencyAlternativePhone2" type="text"
                               className="form-control"
                               disabled={true}
                               value={emergencyAlternativePhone2}/>
                    </div>
                </div>
                <div className="col-md-4" hidden={!this.props.showDetail}>
                    <div className="form-group">
                        <label id="emergencyEmailAddress2Label" className="form-label">{t("MyAccount.EmailAddress")}</label>
                        <input id="emergencyEmailAddress2" type="text"
                               className="form-control"
                               disabled={true}
                               value={emergencyEmailAddress2}/>
                    </div>
                </div>
                <div className="col-md-4" hidden={!this.props.showDetail}>
                    <div className="form-group">
                        <label id="emergencyAddress2Label" className="form-label">{t("EmergencyServicesView.Address")}</label>
                        <div id="emergencyAddress2" className="form-control" style={{ backgroundColor: "#e9ecef" }} dangerouslySetInnerHTML={{ __html: emergencyAddress2 }}>
                        </div>
                    </div>
                </div>

            </div>

            <hr/>

            {
                    emergencyContactsCount > 2 && !this.props.showDetail &&
                        <div className="row">
                            <div className="col-md-4">
                                <label id="additionalEmergencyContactLabel" className="form-label">{t("EmergencyServicesView.AdditionalEmergencyContacts")}</label>
                            </div>
                            <div className="col-md-4">
                                <label id="additionalEmergencyPhoneLabel" className="form-label">{t("MyAccount.PhoneNumber")}</label>
                            </div>
                            <div className = "col-md-4">
                                <label id="emergencyAlternativePhone2Label" className="form-label">{t("MyAccount.AlternativePhoneNumber")}</label>
                            </div >
                        </div>
                }
            {
                    emergencyContactsCount > 2 && !this.props.showDetail &&
                        additionalEmergencyContacts.map((x, index) => (
                            <div key={index} className="row">
                                <div className="col-md-4">
                                    <input id={"additionalEmergencyContact" + index} type="text"
                                           className="form-control"
                                           disabled={true}
                                           value={x.firstName + " " + x.lastName} />
                                </div>
                                <div className="col-md-4">
                                    <input id={"additionalEmergencyPhone" + index} type="text"
                                           className="form-control"
                                           disabled={true}
                                           value={x.phoneNumber} />
                                </div>
                                <div className="col-md-4">
                                    <input id={"additionalEmergencyAlternativePhone" + index} type="text"
                                           className="form-control"
                                           disabled={true}
                                           value={x.alternativePhoneNumber} />
                                </div>
                            </div>
                        ))
                }


            {
                    emergencyContactsCount > 2 && this.props.showDetail &&
                        additionalEmergencyContacts.map((x, index) => (
                            <div>
                                <div key={index} className="row">
                                    <div className="col-md-4">
                                        <label id={"additionalEmergencyContactLabel" + index} className="form-label">{t("EmergencyServicesView.AdditionalEmergencyContact") + (index + 1)}</label>
                                        <input id={"additionalEmergencyContact" + index} type="text"
                                               className="form-control"
                                               disabled={true}
                                               value={x.firstName + " " + x.lastName} />
                                    </div>
                                    <div className="col-md-4">
                                        <label id={"additionalEmergencyPhoneLabel" + index} className="form-label">{t("MyAccount.PhoneNumber")}</label>
                                        <input id={"additionalEmergencyPhone" + index} type="text"
                                               className="form-control"
                                               disabled={true}
                                               value={x.phoneNumber} />
                                    </div>
                                    <div className="col-md-4">
                                        <label id={"additionalEmergencyAlternativePhoneLabel" + index} className="form-label">{t("MyAccount.AlternativePhoneNumber")}</label>
                                        <input id={"additionalEmergencyAlternativePhone" + index} type="text"
                                               className="form-control"
                                               disabled={true}
                                               value={x.alternativePhoneNumber} />
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label id={"additionalEmergencyEmailAddressLabel" + index} className="form-label">{t("MyAccount.EmailAddress")}</label>
                                            <input id={"additionalEmergencyEmailAddress" + index} type="text"
                                                   className="form-control"
                                                   disabled={true}
                                                   value={x.emailAddress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label id={"additionalEmergencyAddressLabel" + index} className="form-label">{t("EmergencyServicesView.Address")}</label>
                                            <div id={"additionalEmergencyAddress" + index} className="form-control" style={{ backgroundColor: "#e9ecef" }}
                                                 dangerouslySetInnerHTML={{
                                                        __html: formatAddress(x.addressLine1, x.addressLine2, x.addressLine3, x.addressLine4, x.addressEirCode)
                                                    }} >
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        ))
                }

            <hr/>

            <div className="row">
                <div className="col-md-12">
                    <div className="h6">{t("EmergencyServicesView.CommunicationsEquipment")}</div>
                    {
                            (equipmentCount > 0) &&
                            <div>
                                {
                                    !this.props.showDetail &&
                                    this.props.currentEquipment.map((equipment, index) => (
                                        <span key={equipment.id}>{equipment.equipmentTypeId == null ? equipment.otherEquipmentType : equipment.equipmentType.equipmentTypeCategory.name}
                                            {equipment.dscEnabled && <span> {t("Equipments.DSCEnabled")}</span>}
                                            {index < this.props.currentEquipment.length - 1 && <span>, </span>}
                                            {index >= this.props.currentEquipment.length - 1 && <span>.</span>}
                                        </span>
                                    ))
                                }
                                {
                                    this.props.showDetail &&
                                    this.props.currentEquipment.map((equipment, index) => (
                                        <div key={equipment.id}>
                                            {equipment.equipmentTypeId == null ? equipment.otherEquipmentType : equipment.equipmentType.equipmentTypeCategory.name}
                                            {equipment.dscEnabled && <span> {t("Equipments.DSCEnabled")} </span>}
                                            {equipment.equipmentTypeId != null && equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.Radar &&
                                                <span> {equipment?.radarBandType?.name ?? ""}</span>}
                                            {equipment.equipmentTypeId != null && equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.GMDSS &&
                                                <span>: {equipment?.gmdssSatelliteType?.name ?? ""}</span>}
                                            {equipment.equipmentTypeId != null && equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.GMDSS &&
                                                <span> {t("EmergencyServicesView.Number")} {equipment.satelliteNumber}</span>}

                                            {/*<span> {t("EmergencyServicesView.ManufacturerModel")}</span>*/}
                                            {/*{equipment.equipmentTypeId == null && <span> {equipment.make} \ {equipment.model}</span>}*/}
                                            {/*{equipment.equipmentTypeId != null &&*/}
                                            {/*    <span> {equipment.equipmentType.make} \ {equipment.equipmentType.model}</span>}*/}

                                            {equipment.equipmentTypeId != null && equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.NonGmdss &&
                                                <span> {t("EmergencyServicesView.Number")} {equipment.satelliteNumber}</span>}
                                            {equipment.equipmentTypeId != null && equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.GMDSS &&
                                                <span> {t("EmergencyServicesView.Lrit")}: {equipment.lritFacility ? "Yes" : "No"}</span>}


                                        </div>
                                    ))
                                }
                            </div>
                        }
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-12">
                    <div className="h6">{t("EmergencyServicesView.EquipmentIdentifiers")}</div>
                    {
                            equipmentIdentifier.length > 0 &&
                            equipmentIdentifier.map((equipment, index) => (
                                <div key={equipment.id}>
                                    {
                                        equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.GMDSS &&
                                        <div>
                                            {t("EmergencyServicesView.GMDSS-Satellite")}
                                            {equipment?.gmdssSatelliteType?.name ?? ""}
                                            {t("EmergencyServicesView.Number")}
                                            {equipment.satelliteNumber}
                                        </div>
                                    }
                                    {
                                        equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.NonGmdss &&
                                        <div>
                                            {t("EmergencyServicesView.Non-GMDSS-SatelliteNumber")}
                                            {equipment.satelliteNumber}
                                        </div>
                                    }
                                    {
                                        equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.Plb &&
                                        <div>
                                            {t("EmergencyServicesView.PlbHexCode")}
                                            {equipment.hexCode}
                                        </div>
                                    }
                                    {
                                        equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.HandheldVhf &&
                                        <div>
                                            {t("EmergencyServicesView.HandheldVhfMmsiNumber")}
                                            {this.props.portableVhfDscMmsiNumber}
                                        </div>
                                    }
                                    {
                                        equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.AisSart &&
                                        <div>
                                            {t("EmergencyServicesView.AIS-SART-MMSI")}
                                            {equipment.mmsiNumber}
                                        </div>
                                    }
                                    {
                                        equipment.equipmentType.equipmentTypeCategory.id === EquipmentTypeCategories.ClassMmob &&
                                        <div>
                                            {t("EmergencyServicesView.MOB-MMSI")}
                                            {equipment.mmsiNumber}
                                        </div>
                                    }
                                </div>
                            ))
                        }
                </div>
            </div>

            <div className="my-3">
                <button id="returnToEmergencyServicesSearch" className="btn btn-primary"
                        onClick={() => { this.props.history.goBack(); }}>
                    {t("Common.ButtonText.BackToSearch")}
                </button>
            </div>

            </React.Fragment>
);
}
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: state.currentVessel.id || 0,
        licenceApplicationId: state.currentApplication.id || 0,
        licenceApplicationStatusTypeId: state.currentApplication.licenceApplicationStatusTypeId || 0,
        lastUpdated: state.currentApplication.updated || "",

        noteDescription: state.rescueNote.noteDescription || "",

        vesselName: state.currentVessel.name || "",
        vesselTypeId: state.currentVessel.vesselTypeId || "",
        vesselType: state.currentVessel.vesselType?.name || "",

        vesselSubTypeId: state.currentVessel.vesselSubTypeId || "",
        hasVesselSubTypes: (Number(state.currentVessel.vesselTypeId) === 1 ||
                Number(state.currentVessel.vesselTypeId) === 3 ||
                Number(state.currentVessel.vesselTypeId) === 4)
            ? true
            : false,
        vesselSubType: state.currentVessel.vesselSubType?.name || "",


        isOtherVesselType: state.currentVessel.isOtherVesselType || false,
        otherVesselTypeDescription: state.currentVessel.otherVesselTypeDescription || "",
        callSignNumber: state.currentVessel.callSignNumber || "",
        mmsiNumber: state.currentVessel.mmsiNumber || "",
        portableVhfDscMmsiNumber: state.currentVessel.portableVhfDscMmsiNumber || "",

        maxVesselCapacity: state.currentVessel.maxCapacityOfPersonsOnBoard || "",
        vesselLength: state.currentVessel.vesselLength || "",
        hullColour: state.currentVessel.hullColor?.name || "",

        grossTonnage: state.currentVessel.grossTonnage || "",
        vesselDescription: state.currentVessel.description || "",
        homeport: state.currentVessel.homePort || "",
        seaArea: state.currentVessel.seaAreaofOperation?.name || "",
        vesselRegistrationNumber: state.currentVessel.registerationNumber || "",

        fvIdPostfixId: state.currentVessel.fishingVesselPostfixTypeId || "",
        fvIdPostfix: state.currentVessel.fishingVesselPostfixType?.name || "",
        fvVesselIdentificationNumber: state.currentVessel.fvVesselIdentificationNumber || "",
        fvIdPrefixId: state.currentVessel.fishingVesselPrefixTypeId || "",
        fvIdPrefix: state.currentVessel.fishingVesselPrefixType?.name || "",

        vesselImoNumber: state.currentVessel.imoNumber || "",
        numberOfLifeBoats: state.currentVessel.numberOfLifeBoats || "",

        applicationTypeId: state.currentApplication.applicationTypeId || 1,
        vesselImages: state.vesselImages || [],

        userFirstName: state.currentApplication.user?.firstName || "",
        userLastName: state.currentApplication.user?.lastName || "",
        userPhone: state.currentApplication.user?.phoneNumber || "",
        userAlternativePhone: state.currentApplication.user?.alternativePhoneNumber || "",
        userEmailAddress: state.currentApplication.user?.emailAddress || "",
        userEircode: state.currentApplication.user?.addressEircode || "",
        userAddress1: state.currentApplication.user?.addressLine1 || "",
        userAddress2: state.currentApplication.user?.addressLine2 || "",
        userAddress3: state.currentApplication.user?.addressLine3 || "",
        userAddress4: state.currentApplication.user?.addressLine4 || "",

        currentEquipment: state.currentEquipment || [],
        currentEpirbs: state.currentEpirbs || [],

        emergencyContacts: state.emergencyContactList,
        emergencyContactsCount: state.emergencyContactList.length || 0,

        showDetail: state.emergencyServices || false
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLicenceApplication: (applicationId) => {
            dispatch(CreateGetEmergencyServicesLicenceDataAction(applicationId));
            dispatch(CreateGetVesselImagesAction(applicationId, true));
        },
        handleShowDetail: () => {
            dispatch({
                type: "EMERGENCY_SERVICES_SHOW_DETAIL",
                data: true
            });
        },
        handleHideDetail: () => {
            dispatch({
                type: "EMERGENCY_SERVICES_SHOW_DETAIL",
                data: false
            });
        }
    };
}

const languageAwareComponent = withTranslation()(EmergencyServicesView);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);

