import axios from "axios";

// import authService from "../../components/api-authorization/AuthorizeService";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetVesselsAction from "./CreateGetVesselsAction";


export default function (licenceApplicationId, vesselId, paymentAmount) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const newPaymentConfirmationDetail = {
            licenceApplicationId: Number(licenceApplicationId) || 0,
            created: new Date(),
            vesselId: Number(vesselId) || 0,
            paymentAmount: paymentAmount
        };

        //authService.getAccessToken().then((token) => {
                axios({
                    method: "post",
                    url: "payment/PaymentSuccess", 
                    //headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    data: newPaymentConfirmationDetail
                })
                    .then((data) => {
                        dispatch(CreateGetVesselsAction(licenceApplicationId));
                    })
                    .then((data) => {
                        dispatch(CreateDataRetrievalEndedAction());
                    })
                    .catch((error) => {
                        dispatch(CreateDataRetrievalEndedAction());
                    });
            //});
    };
}
