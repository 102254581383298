import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LinkButton from "./LandingPage/LinkButton";
import { Link } from "react-router-dom";
import ValidationFailureMessage from "./ValidationFailureMessage";
import parse from "html-react-parser";
import Breadcrumb from "./Breadcrumb";

import CreateGetEquipmentTypeCategoriesAction from "../store/actionCreators/CreateGetEquipmentTypeCategoriesAction";
import CreateGetEquipmentTypesAction from "../store/actionCreators/CreateGetEquipmentTypesAction";
import CreateGetRadarBandTypesAction from "../store/actionCreators/CreateGetRadarBandTypesAction";
import CreateAddVesselEquipmentDetailsAction from "../store/actionCreators/CreateAddVesselEquipmentDetailsAction";
import CreateGetGmdssSatelliteTypesAction from "../store/actionCreators/CreateGetGmdssSatelliteTypesAction"
import CreateGetEquipmentMfHfTypesAction from "../store/actionCreators/CreateGetEquipmentMfHfTypesAction";
import CreateCheckAddEquipmentForAmendmentAction from "../store/actionCreators/CreateCheckAddEquipmentForAmendmentAction";
import CreateDeleteEquipmentAction from "../store/actionCreators/CreateDeleteEquipmentAction";
import CreateCheckDeleteEquipmentForAmendmentAction from "../store/actionCreators/CreateCheckDeleteEquipmentForAmendmentAction";
import CreateSetupEquipmentAmendmentAction from "../store/actionCreators/CreateSetupEquipmentAmendmentAction";

class AddVesselEquipmentVHF extends Component {
    componentDidMount() {
        this.props.getEquipmentTypeCategories();
        this.props.getEquipmentTypes();
        this.props.getRadarBandTypes();
        this.props.getAvailableGmdssSatelliteTypes();
        this.props.getEquipmentMfHfTypes();
    }

    render() {

        const { t } = this.props;

        const EquipmentTypeCategories = {
            AisSart: 1,
            AisTransponder: 2,
            ClassMmob: 3,
            MfHf: 4,
            NonGmdss: 5,
            Plb: 6,
            HandheldVhf: 7,
            Radar: 8,
            Sart: 9,
            Vhf: 10,
            GNSS: 11,
            AeronauticalVHF: 12,
            GMDSS: 13,
            Other: 14,
            HandheldUhf: 15
        };

        const VesselTypes = {
            PleasureVessel: 1,
            FishingVessel: 2,
            PassengerVessel: 3,
            PassengerShip: 4,
            MerchantVessel: 5,
            NavalShip: 6,
            RescueVessel: 7
        };

        const hideDcsEnabledField = this.props.equipmentTypeCategoryId != EquipmentTypeCategories.PortableVhf && this.props.equipmentTypeCategoryId != EquipmentTypeCategories.Vhf
            && this.props.equipmentTypeCategoryId != EquipmentTypeCategories.HandheldVhf && this.props.equipmentTypeCategoryId != EquipmentTypeCategories.MfHf;

        const hideMMSINumberField = this.props.equipmentTypeCategoryId != EquipmentTypeCategories.AisSart && this.props.equipmentTypeCategoryId != EquipmentTypeCategories.ClassMmob;

        const hideRadarBandField = this.props.equipmentTypeCategoryId != EquipmentTypeCategories.Radar;

        const hidePlbFields = this.props.equipmentTypeCategoryId != EquipmentTypeCategories.Plb;

        const hideNonGmdssFields = this.props.equipmentTypeCategoryId != EquipmentTypeCategories.NonGmdss;

        const hideIfOtherCategory = this.props.equipmentTypeCategoryId == EquipmentTypeCategories.Other;

        const hideMfhfField = this.props.equipmentTypeCategoryId != EquipmentTypeCategories.MfHf;

        const hideGmdssFields = this.props.equipmentTypeCategoryId != EquipmentTypeCategories.GMDSS;

        //filter active equipment types for equipment category and if MED approved only
        //will also include the selected equipmentTypeId in case the selected equipment is inActive
        const equipmentTypes = this.props.availableEquipmentTypes
            .filter(x => x?.equipmentTypeCategory?.id === Number(this.props.equipmentTypeCategoryId)
                && (x.name === "Other" || !this.props.isMedApproved || x?.medApproved === this.props.isMedApproved)
                && (x.isActive || x.id === (this.props.equipmentTypeId ?? 0)))
            .sort((a, b) => (a.name > b.name) ? 1 : -1);


        //move 'Other' to top of array
        for (var i = 0; i < equipmentTypes.length; i++) {
            if (equipmentTypes[i].name === "Other") {
                var otherItem = equipmentTypes.splice(i, 1);
                equipmentTypes.unshift(otherItem[0]);//push to front
            }
        }

        // hide the Save button if required fields are missing
        const disableSaveButton = (
            this.props.equipmentTypeCategoryId === "" ||
            (this.props.equipmentTypeCategoryId !== EquipmentTypeCategories.Other && this.props.equipmentTypeId === "") ||
            (this.props.equipmentTypeCategoryId === EquipmentTypeCategories.Other && (this.props.otherEquipmentTypeName.trim() === "" || this.props.make.trim() === "" || this.props.model.trim() === "") ||
                this.props.equipmentTypeCategoryId !== EquipmentTypeCategories.Other && this.props.isOtherMakeModel && (this.props.make.trim() === "" || this.props.model.trim() === "")) ||
            (Number(this.props.equipmentTypeCategoryId) === EquipmentTypeCategories.Plb && (this.props.numberOfPlbs < 1 || this.props.hexCode1 === "" || this.props.hexCodeRequired) ||
                (!hideMMSINumberField && this.props.mmsiNumberRequired))
        );

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6,
            TransferInitiated: 7,
            Transferred: 8
        };

        const isReadOnlyPage = this.props.licenceApplicationStatusTypeId === LicenceStatus.Cancelled
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.TransferInitiated
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.Transferred;

        const isEquipmentTypeCategoryReadOnly = isReadOnlyPage || this.props.id !== 0 || this.props.disableEquipmentTypeCategory;

        const equipmentTypeCategoryName = isEquipmentTypeCategoryReadOnly && this.props.availableEquipmentTypeCategories && this.props.availableEquipmentTypeCategories.length > 0
            ? this.props.availableEquipmentTypeCategories.find(x => x.id === this.props.equipmentTypeCategoryId).name
            : null;

        const vesselLength = this.props.vesselLength;
        const isFishingVessel = Number(this.props.vesselTypeId) === VesselTypes.FishingVessel;
        const isFishingVesselLessThan15Metres = isFishingVessel && Number(vesselLength) < 15;

        //const numberOfPLBs = this.props.numberOfPlbs === -1 ? "" : this.props.numberOfPlbs;

        return (
            <React.Fragment>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    licenceList={t("AdminLandingPage.LicenceList")}
                    dashboard={t("Common.Dashboard")}
                    parentLink={this.props.parentRoute + "/vessel-equipment-details/" + this.props.licenceApplicationId}
                    parentPageName={t("Equipments.Title")}
                    pageName={t("AddEquipment.AddEquipment")} />

                <div className="row col-md-12">
                    <div aria-hidden="true" id="required-description" className="d-flex justify-content-end">* {t("Common.RequiredField")}</div>
                </div>
                <div className="form-group">
                    <label id="equipmentTypeLabel" htmlFor="equipmentTypeCategoryId" className="form-label" tabIndex="0">
                        {t("AddEquipment.EquipmentTypeCategory")}:*</label>
                    {
                        //for screen reader
                        isEquipmentTypeCategoryReadOnly &&
                        <label id="equipmentTypeCategoryId" className="form-control" style={{background: "#eeeeee"}} tabIndex="0">{equipmentTypeCategoryName}</label>
                    }
                    {
                        !isEquipmentTypeCategoryReadOnly &&
                        <select id="equipmentTypeCategoryId"
                            className="form-control"
                            onChange={evt => this.props.onEquipmentTypeCategorySelected(evt, this.props.mmsiNumber)}
                            value={this.props.equipmentTypeCategoryId}
                            tabIndex="0"
                        >
                            <option value="">{t("Common.ButtonText.PleaseSelect")} </option>
                            {
                                this.props.availableEquipmentTypeCategories.map(equipmentTypeCategory => (
                                    <option
                                        key={equipmentTypeCategory.id}
                                        value={equipmentTypeCategory.id}
                                    >
                                        {equipmentTypeCategory.name}
                                    </option>
                                ))
                            }
                        </select>
                    }

                </div>

                <div className="form-group" hidden={hideIfOtherCategory}>
                    <label id="equipmentTypeLabel" htmlFor="equipmentTypeId" className="form-label">{t("AddEquipment.ManufacturerModelNumber")}:*</label>
                    <select id="equipmentTypeId"
                            disabled={isReadOnlyPage}
                            className="form-control" required
                            onChange={this.props.onEquipmentTypeSelected}
                            value={this.props.equipmentTypeId}
                    >
                        <option value="">{t("Common.ButtonText.PleaseSelect")} </option>
                        {
                            equipmentTypes
                                .map(equipmentType => (
                                    <option key={equipmentType.id}
                                        value={equipmentType.id}>
                                        {equipmentType.name}
                                    </option>
                                ))
                        }
                    </select>
                    {
                        this.props.isOtherMakeModel &&
                        <small id="otherEquipmentHelp" className="form-text text-muted">{t("AddEquipment.IfOtherMessage")}</small>
                    }
                </div>

                <div className="form-group" hidden={!hideIfOtherCategory}>
                    <label id="OtherEquipmentTypeLabel" htmlFor="OtherEquipmentType" className="form-label">{t("AddEquipment.OtherEquipmentType")}:*</label>
                    <input id="OtherEquipmentType" type="text" max="9"
                           disabled={isReadOnlyPage}
                           className="form-control"
                           onChange={this.props.onOtherEquipmentTypeChanged}
                           value={this.props.otherEquipmentTypeName}/>
                </div>

                {
                    (this.props.isOtherMakeModel || hideIfOtherCategory) &&
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label id="makeLabel" htmlFor="makeId" className="form-label">{t("AddEquipment.Make")}:*</label>
                                <input id="makeId" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={this.props.onMakeChanged}
                                    value={this.props.make} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label id="modelLabel" htmlFor="modelId" className="form-label">{t("AddEquipment.Model")}:*</label>
                                <input id="modelId" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={this.props.onModelChanged}
                                    value={this.props.model} />
                            </div>
                        </div>
                    </div>

                }

                <div className="form-group" hidden={hideMfhfField}>

                    <label id="equipmentMfHfTypeLabel" htmlFor="equipmentMfHfTypeId" className="form-label">{t("AddEquipment.EquipmentMFHFType")}:</label>
                    <select id="equipmentMfHfTypeId" className="form-control"
                            disabled={isReadOnlyPage}
                            onChange={this.props.onEquipmentMfHfTypeSelected}
                            value={this.props.equipmentMfHfTypeId}>
                        <option value="">{t("Common.ButtonText.PleaseSelect")}</option>
                        {
                            this.props.availableEquipmentMfHfTypes.map(equipmentMfHfType => (
                                <option
                                    key={equipmentMfHfType.id}
                                    value={equipmentMfHfType.id}
                                >
                                    {equipmentMfHfType.name}
                                </option>
                            ))
                        }
                    </select>
                </div>

                <div className="form-check pt-1" hidden={hideDcsEnabledField}>
                    <input id="isDscEnabled" type="checkbox" className="form-check-input"
                           disabled={isReadOnlyPage}
                           onChange={this.props.onIsDscEnabledChanged}
                           value={this.props.isDscEnabled}
                           checked={this.props.isDscEnabled}
                    />
                    <label id="dscEnabledLabel" htmlFor="isDscEnabled" className="form-check-label form-label">{t("AddEquipment.IsDSCEnabled")}</label>
                </div>

                <div className="form-group" hidden={hideMMSINumberField}>
                    <label id="MmsiNumberLabel" htmlFor="MmsiNumber" className="form-label">{t("AddEquipment.MaritimeIdentityNumber")}:</label>
                    <input id="MmsiNumber" type="text" maxLength="9"
                        disabled={isReadOnlyPage}
                        className="form-control"
                        onChange={this.props.onMmsiNumberChanged}
                        onBlur={this.props.onMmsiNumberChangeCompleted}
                        value={this.props.mmsiNumber} />
                    {
                        this.props.mmsiNumberRequired &&
                        <ValidationFailureMessage>
                            {t("AddEquipment.MMSINumberRequired")}
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-group" hidden={hideRadarBandField}>
                    <label id="radarBandTypeLabel" htmlFor="radarBandTypeId" className="form-label">{t("AddEquipment.RadarBandType")}:</label>
                    <select id="radarBandTypeId"
                            disabled={isReadOnlyPage}
                            className="form-control"
                            onChange={this.props.onRadarBandTypeSelected}
                            value={this.props.radarBandTypeId}>
                        <option value="">{t("Common.ButtonText.PleaseSelect")}</option>

                        {
                            this.props.availableRadarBandTypes.map(radarBandType => (
                                <option
                                    key={radarBandType.id}
                                    value={radarBandType.id}
                                >
                                    {radarBandType.name}
                                </option>
                            ))
                        }
                    </select>
                </div>

                {
                    (this.props.isOtherMakeModel || hideIfOtherCategory) && hidePlbFields &&
                        <div className="row pt-1">
                            <div className="col-md-6">
                                <div className="form-check">
                                    <input id="isMedApprovedId" type="checkbox"
                                           disabled={isReadOnlyPage}
                                           className="form-check-input"
                                           onChange={this.props.onIsMedApprovedChanged}
                                           value={this.props.isMedApproved}
                                           checked={this.props.isMedApproved}
                                    />
                                    <label id="isMedApprovedLabel" htmlFor="isMedApprovedId" className="form-check-label form-label">{t("AddEquipment.IsMEDApproved")}?</label>
                                </div>
                            </div>
                        </div>
                }

                <div className="row" hidden={hidePlbFields}>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="numberOfPlbsLabel" htmlFor="numberOfPlbsId" className="form-label">{t("AddEquipment.NumberofPLBs")}: *</label>
                            <input id="numberOfPlbsId" type="number"
                                max="12"
                                disabled={isReadOnlyPage}
                                className="form-control"
                                onChange={this.props.onNumberOfPlbsChanged}
                                onBlur={evt => this.props.onNumberOfPlbsCompleted(evt, this.props.hexCode)}
                                value={this.props.numberOfPlbs}
                            />
                            {
                                isFishingVesselLessThan15Metres &&
                                <small id="plbHelp" className="form-text text-muted" tabIndex="0">{t("AddEquipment.PLBNote")}</small>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="hexCodeLabel" htmlFor="hexCode" className="form-label">{t("AddEquipment.PLBHEXCode")}: *</label>
                            <input id="hexCode1" type="text"
                                disabled={isReadOnlyPage}
                                className="form-control"
                                onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                onBlur={this.props.onHexCodeChangeCompleted}
                                value={this.props.hexCode1}
                            />

                            {
                                this.props.numberOfPlbs > 1 &&
                                <input id="hexCode2" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                    onBlur={this.props.onHexCodeChangeCompleted}
                                    value={this.props.hexCode2}
                                />
                            }
                            {
                                this.props.numberOfPlbs > 2 &&
                                <input id="hexCode3" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                    onBlur={this.props.onHexCodeChangeCompleted}
                                    value={this.props.hexCode3}
                                />
                            }
                            {
                                this.props.numberOfPlbs > 3 &&
                                <input id="hexCode4" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                    onBlur={this.props.onHexCodeChangeCompleted}
                                    value={this.props.hexCode4}
                                />
                            }
                            {
                                this.props.numberOfPlbs > 4 &&
                                <input id="hexCode5" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                    onBlur={this.props.onHexCodeChangeCompleted}
                                    value={this.props.hexCode5}
                                />
                            }
                            {
                                this.props.numberOfPlbs > 5 &&
                                <input id="hexCode6" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                    onBlur={this.props.onHexCodeChangeCompleted}
                                    value={this.props.hexCode6}
                                />
                            }
                            {
                                this.props.numberOfPlbs > 6 &&
                                <input id="hexCode7" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                    onBlur={this.props.onHexCodeChangeCompleted}
                                    value={this.props.hexCode7}
                                />
                            }
                            {
                                this.props.numberOfPlbs > 7 &&
                                <input id="hexCode8" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                    onBlur={this.props.onHexCodeChangeCompleted}
                                    value={this.props.hexCode8}
                                />
                            }
                            {
                                this.props.numberOfPlbs > 8 &&
                                <input id="hexCode9" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                    onBlur={this.props.onHexCodeChangeCompleted}
                                    value={this.props.hexCode9}
                                />
                            }
                            {
                                this.props.numberOfPlbs > 9 &&
                                <input id="hexCode10" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                    onBlur={this.props.onHexCodeChangeCompleted}
                                    value={this.props.hexCode10}
                                />
                            }
                            {
                                this.props.numberOfPlbs > 10 &&
                                <input id="hexCode11" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                    onBlur={this.props.onHexCodeChangeCompleted}
                                    value={this.props.hexCode11}
                                />
                            }
                            {
                                this.props.numberOfPlbs > 11 &&
                                <input id="hexCode12" type="text"
                                    disabled={isReadOnlyPage}
                                    className="form-control"
                                    onChange={evt => this.props.onHexCodeChanged(evt, this.props.hexCode)}
                                    onBlur={this.props.onHexCodeChangeCompleted}
                                    value={this.props.hexCode12}
                                />
                            }
                            {
                                this.props.hexCodeRequired &&
                                <ValidationFailureMessage>
                                    {t("AddEquipment.PLBHexCodeRequired")}
                                </ValidationFailureMessage>
                            }
                            <small id="plbHelp" className="form-text text-muted" tabIndex="0">
                                {t("AddEquipment.PLBRegistrationNote")}
                                <a href="https://www.comreg.ie/industry/licensing/personal-locator-beacons/" target="_blank" rel="noreferrer">{t("AddEquipment.ComReg")}</a>
                            </small>
                        </div>
                    </div>
                </div>
                {
                    (!hideNonGmdssFields || !hideGmdssFields) &&
                    <div className="form-group">
                        <label id="satelliteNumberLabel" htmlFor="satelliteNumber" className="form-label">{t("AddEquipment.SatelliteNumber")}:</label>
                        <input id="satelliteNumber" type="text"
                               disabled={isReadOnlyPage}
                            maxLength="30"
                            className="form-control"
                            onChange={this.props.onSatelliteNumberChanged}
                            value={this.props.satelliteNumber} />
                    </div>
                }

                <div className="form-group" hidden={hideGmdssFields}>
                    <label id="satelliteTypeLabel" htmlFor="satelliteTypeId" className="form-label">{t("AddEquipment.SatelliteType")}: </label>
                    <select id="satelliteTypeId" className="form-control"
                            disabled={isReadOnlyPage}
                            onChange={this.props.onSatelliteTypeSelected}
                            value={this.props.satelliteTypeId}>
                        <option value="">{t("Common.ButtonText.PleaseSelect")}</option>
                        {
                            this.props.availableGmdssSatelliteTypes.map(satelliteType => (
                                <option
                                    key={satelliteType.id}
                                    value={satelliteType.id}
                                >
                                    {satelliteType.name}
                                </option>
                            ))
                        }
                    </select>
                </div>

                <div className="row pt-1" hidden={hideGmdssFields}>
                    <div className="col-md-12">
                        <div className="form-check">
                            <input id="isEgcReceiver" type="checkbox"
                                   disabled={isReadOnlyPage}
                                   className="form-check-input"
                                   onChange={this.props.onIsEgcReceiverChanged}
                                   value={this.props.isEgcReceiver}
                                   checked={this.props.isEgcReceiver}
                            />
                            <label id="isEgcReceiverLabel" htmlFor="isEgcReceiver" className="form-check-label form-label">{t("AddEquipment.EGCReceiver")}</label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-check">
                            <input id="isLritFacility" type="checkbox"
                                   className="form-check-input"
                                   disabled={isReadOnlyPage}
                                   onChange={this.props.onIsLritFacilityChanged}
                                   value={this.props.isLritFacility}
                                   checked={this.props.isLritFacility}
                            />
                            <label id="isLritFacilityLabel" htmlFor="isLritFacility" className="form-check-label form-label">{t("AddEquipment.LRITFacility")}</label>
                        </div>
                    </div>
                </div>

                {
                    this.props.equipmentTypeCategoryId == EquipmentTypeCategories.HandheldVhf && this.props.isDscEnabled &&
                    <div className="h6 mt-2" role="heading" aria-level="2" id="HandheldVhfDscInformation" tabIndex="0">
                        {parse(t("AddEquipment.HandheldVhfDscInformation"))}
                    </div>
                }
                
                <div className="h6 mt-2" role="heading" aria-level="2" id="ComplianceTitle" tabIndex="0">
                    {t("Equipments.ComplianceTitle")}
                </div>

                <div className="my-3">
                    <LinkButton className="btn btn-warning"
                                id="cancelEquipment"
                                to={this.props.parentRoute + "/vessel-equipment-details/" + this.props.licenceApplicationId}>
                        {t("Common.ButtonText.Cancel")}
                    </LinkButton>
                    {
                        !isReadOnlyPage && this.props.id !== 0 &&
                        <LinkButton
                            id="deleteEquipmentDetail"
                            className="btn btn-danger ms-3"
                            onClick={() => {
                                this.props.deleteEquipment(this.props.id, this.props.licenceApplicationId,
                                    this.props.licenceApplicationStatusTypeId, this.props.applicationTypeId,
                                    this.props.equipmentTypeCategoryId, this.props.isNewAmendment);
                            }}
                            to={this.props.parentRoute + "/vessel-equipment-details/" + this.props.licenceApplicationId}
                        >{t("Common.ButtonText.Delete")}
                        </LinkButton>
                    }
                    {
                        !isReadOnlyPage && this.props.id === 0 &&
                        <LinkButton
                            id="addEquipmentButton"
                            className="btn btn-primary ms-3"
                            disabled={disableSaveButton}
                            to={this.props.parentRoute + "/vessel-equipment-details/" + this.props.licenceApplicationId}
                            onClick={() => this.props.addVesselEquipmentDetail(this.props.licenceApplicationId,
                                this.props.licenceApplicationStatusTypeId, this.props.equipmentTypeCategoryId)}
                        >
                            {t("Common.ButtonText.Save")}
                        </LinkButton>
                    }
                    {
                        !isReadOnlyPage && this.props.id !== 0 &&
                        <LinkButton
                            id="updateEquipmentButton"
                            className="btn btn-primary ms-3"
                            disabled={disableSaveButton}
                            to={this.props.parentRoute + "/vessel-equipment-details/" + this.props.licenceApplicationId}
                            onClick={() => this.props.updateVesselEquipmentDetail()}
                        >
                            {t("Common.ButtonText.Save")}
                        </LinkButton>
                    }

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: state.vesselEquipmentVhfDetail.id || 0,
        licenceApplicationId: state.currentApplication.id || 0,
        role: state.currentUserInfo.role || "",

        equipmentTypeCategoryId: state.vesselEquipmentVhfDetail.equipmentTypeCategoryId || "",
        disableEquipmentTypeCategory: state.vesselEquipmentVhfDetail.disableEquipmentTypeCategory || false,
        equipmentTypeId: state.vesselEquipmentVhfDetail.equipmentTypeId || "",
        equipmentTypeName: state.vesselEquipmentVhfDetail.equipmentTypeName || "",
        otherEquipmentTypeName: state.vesselEquipmentVhfDetail.otherEquipmentTypeName || "",
        equipmentMfHfTypeId: state.vesselEquipmentVhfDetail.equipmentMfHfTypeId || "",
        isDscEnabled: state.vesselEquipmentVhfDetail.isDscEnabled || false,
        mmsiNumber: state.vesselEquipmentVhfDetail.mmsiNumber || "",
        radarBandTypeId: state.vesselEquipmentVhfDetail.radarBandTypeId || "",
        licenceApplicationStatusTypeId: state.currentApplication.licenceApplicationStatusTypeId || 0,
        applicationTypeId: state.currentApplication.applicationTypeId || 1,

        make: state.vesselEquipmentVhfDetail.make || "",
        model: state.vesselEquipmentVhfDetail.model || "",
        isOtherMakeModel: state.vesselEquipmentVhfDetail.isOtherMakeModel || false,
        isMedApproved: state.vesselEquipmentVhfDetail.isMedApproved || false,
        isGpsEnabled: state.vesselEquipmentVhfDetail.isGpsEnabled || false,

        numberOfPlbs: state.vesselEquipmentVhfDetail.numberOfPlbs || 1,
        hexCode: state.vesselEquipmentVhfDetail.hexCode || "",

        hexCode1: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 0
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[0]
            : "",

        hexCode2: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 1
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[1]
            : "",

        hexCode3: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 2
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[2]
            : "",

        hexCode4: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 3
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[3]
            : "",

        hexCode5: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 4
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[4]
            : "",

        hexCode6: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 5
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[5]
            : "",

        hexCode7: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 6
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[6]
            : "",

        hexCode8: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 7
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[7]
            : "",

        hexCode9: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 8
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[8]
            : "",

        hexCode10: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 9
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[9]
            : "",

        hexCode11: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 10
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[10]
            : "",

        hexCode12: state.vesselEquipmentVhfDetail.hexCode && state.vesselEquipmentVhfDetail.hexCode.split(", ").length > 11
            ? state.vesselEquipmentVhfDetail.hexCode.split(", ")[11]
            : "",

        satelliteNumber: state.vesselEquipmentVhfDetail.satelliteNumber || "",

        satelliteTypeId: state.vesselEquipmentVhfDetail.satelliteTypeId || "",
        isEgcReceiver: state.vesselEquipmentVhfDetail.isEgcReceiver || false,
        isLritFacility: state.vesselEquipmentVhfDetail.isLritFacility || false,

        isNewAmendment: state.vesselEquipmentVhfDetail.isNewAmendment || false,

        vesselTypeId: state.currentVessel.vesselTypeId || null,
        vesselLength: state.currentVessel.vesselLength || 0,

        // collections
        availableEquipmentTypeCategories: state.availableEquipmentTypeCategories,
        availableEquipmentTypes: state.availableEquipmentTypes,
        availableRadarBandTypes: state.availableRadarBandTypes,
        availableGmdssSatelliteTypes: state.availableGmdssSatelliteTypes,
        availableEquipmentMfHfTypes: state.availableEquipmentMfHfTypes,

        // validations
        hexCodeRequired: state.vesselEquipmentVhfDetail.hexCodeRequired || false,
        mmsiNumberRequired: state.vesselEquipmentVhfDetail.mmsiNumberRequired || false

    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getEquipmentTypeCategories: () => {
            dispatch(CreateGetEquipmentTypeCategoriesAction());
        },
        getEquipmentTypes: () => {
            dispatch(CreateGetEquipmentTypesAction());
        },
        getRadarBandTypes: () => {
            dispatch(CreateGetRadarBandTypesAction());
        },
        getAvailableGmdssSatelliteTypes: () => {
            dispatch(CreateGetGmdssSatelliteTypesAction());
        },
        getEquipmentMfHfTypes: () => {
            dispatch(CreateGetEquipmentMfHfTypesAction());
        },

        addVesselEquipmentDetail: (licenceApplicationId, licenceApplicationStatusTypeId, equipmentTypeCategoryId) => {
            //check if additional payment required. for issued or dispatched licences not already amended.
            //Must check if adding the first equipment in a Category
            if (licenceApplicationStatusTypeId === 4 || licenceApplicationStatusTypeId === 5) {
                //If amending equipment, Save Licence with applicationTypeId 'Licence Amendments'
                //add equipment or equipment amendment as appropriate
                dispatch(CreateCheckAddEquipmentForAmendmentAction(licenceApplicationId, equipmentTypeCategoryId));
            }
            else {
                //add equipment
                dispatch(CreateAddVesselEquipmentDetailsAction());
            }
            
        },
        updateVesselEquipmentDetail: () => {
            
            //update equipment
            dispatch(CreateAddVesselEquipmentDetailsAction());

        },
        deleteEquipment: (id, applicationId, licenceApplicationStatusTypeId, applicationTypeId, equipmentTypeCategoryId, isNewAmendment) => {
            //if equipmentTypeCategoryId is undefined then it is 'Other' equipmentTypeCategory
            var actualEquipmentTypeCategoryId = Number(equipmentTypeCategoryId || 14);

            //check if additional payment required. for issued or dispatched licences not already amended
            //Must check if deleting the last category. exclude Other
            if ((licenceApplicationStatusTypeId === 4 || licenceApplicationStatusTypeId === 5) && applicationTypeId !== 3 && actualEquipmentTypeCategoryId !== 14) {
                //Save Licence with applicationTypeId 'Licence Amendments'
                dispatch(CreateCheckDeleteEquipmentForAmendmentAction(applicationId, id, actualEquipmentTypeCategoryId));
            }
            if ((licenceApplicationStatusTypeId === 4 || licenceApplicationStatusTypeId === 5) && actualEquipmentTypeCategoryId !== 14 && !isNewAmendment) {
                //Setup EquipmentAmendments if last in category and soft delete selected EquipmentAmendment. will just delete if not last in category
                dispatch(CreateSetupEquipmentAmendmentAction(id, applicationId, actualEquipmentTypeCategoryId));
            }
            else {
                //delete Equipment. If New Amendment, delete equipmentAmendment
                dispatch(CreateDeleteEquipmentAction(id, isNewAmendment));
            }

        },

        onEquipmentTypeCategorySelected: (evt, mmsiNumber) => {

            var updatedEquipmentTypeCategoryId = evt.target.value;

            dispatch({
                type: "VESSEL_EQUIPMENT_DETAILS_EQUIPMENT_CATEGORY_ID_UPDATED",
                data: updatedEquipmentTypeCategoryId
            });

            //AisSart
            if (updatedEquipmentTypeCategoryId === "1" && mmsiNumber.substring(0, 3) !== "970") {
                dispatch({
                    type: "VESSEL_EQUIPMENT_MMSI_NUMBER_UPDATED",
                    data: "970"
                });
            }

            //ClassMmob
            if (updatedEquipmentTypeCategoryId === "3" && mmsiNumber.substring(0, 3) !== "972") {
                dispatch({
                    type: "VESSEL_EQUIPMENT_MMSI_NUMBER_UPDATED",
                    data: "972"
                });
            }

            if (updatedEquipmentTypeCategoryId !== "1" && updatedEquipmentTypeCategoryId !== "3" && mmsiNumber !== "") {
                dispatch({
                    type: "VESSEL_EQUIPMENT_MMSI_NUMBER_UPDATED",
                    data: ""
                });
            }
        },
        onEquipmentTypeSelected: (evt) => {
            var updatedEquipmentTypeId = evt.target.value;
            var updatedEquipmentTypeName = "";
            if (evt.target.value !== "")
            {
                updatedEquipmentTypeName = evt.target.options[evt.target.selectedIndex].text;
            }

            dispatch({
                type: "VESSEL_EQUIPMENT_DETAILS_EQUIPMENT_TYPE_ID_UPDATED",
                data: updatedEquipmentTypeId
            });

            if (updatedEquipmentTypeName === "Other") {
                dispatch({
                    type: "VESSEL_EQUIPMENT_MANUFACTURER_MODEL_IS_OTHER_UPDATED",
                    data: true
                });
            } else {
                dispatch({
                    type: "VESSEL_EQUIPMENT_MANUFACTURER_MODEL_IS_OTHER_UPDATED",
                    data: false
                });
            }
        },
        onOtherEquipmentTypeChanged: (evt) => {
            var otherEquipmentType = evt.target.value;
            if (otherEquipmentType.length > 255) {
                return;
            }
            dispatch({
                type: "VESSEL_OTHER_EQUIPMENT_TYPE_UPDATED",
                data: otherEquipmentType
            });
        },
        onEquipmentMfHfTypeSelected: (evt) => {
            var updatedEquipmentMfHfTypeId = evt.target.value;

            dispatch({
                type: "VESSEL_EQUIPMENT_MFHF_EQUIPMENT_MFHF_TYPE_ID_UPDATED",
                data: updatedEquipmentMfHfTypeId
            });
        },
        onIsDscEnabledChanged: (evt) => {
            var updatedIsDscEnabled = evt.target.checked;

            dispatch({
                type: "VESSEL_EQUIPMENT_IS_DSC_UPDATED",
                data: updatedIsDscEnabled
            });
        },
        onMmsiNumberChanged: (evt) => {
            var updatedMmsiNumber = evt.target.value;
            if (isNaN(updatedMmsiNumber) || updatedMmsiNumber.includes(".") || updatedMmsiNumber.length > 9) {
                return;
            }
            dispatch({
                type: "VESSEL_EQUIPMENT_MMSI_NUMBER_UPDATED",
                data: updatedMmsiNumber
            });
        },
        onMmsiNumberChangeCompleted: (evt) => {
            var updatedMmsiNumber = evt.target.value;

            if (isNaN(updatedMmsiNumber) || updatedMmsiNumber.includes(".") || updatedMmsiNumber.length !== 9) {
                dispatch({
                    type: "VESSEL_EQUIPMENT_MMSI_NUMBER_REQUIRED",
                    data: true
                });
                return;
            }

            dispatch({
                type: "VESSEL_EQUIPMENT_MMSI_NUMBER_REQUIRED",
                data: false
            });
            
        },
        onRadarBandTypeSelected: (evt) => {
            var updatedRadarBandTypeId = evt.target.value;

            dispatch({
                type: "VESSEL_EQUIPMENT_RADAR_BAND_TYPE_ID_UPDATED",
                data: updatedRadarBandTypeId
            });
        },
        onMakeChanged: (evt) => {
            var updatedMake = evt.target.value;
            if (updatedMake.length > 255) {
                return;
            }
            dispatch({
                type: "VESSEL_EQUIPMENT_MAKE_UPDATED",
                data: updatedMake
            });
        },
        onModelChanged: (evt) => {
            var updatedModel = evt.target.value;
            if (updatedModel.length > 255) {
                return;
            }
            dispatch({
                type: "VESSEL_EQUIPMENT_MODEL_UPDATED",
                data: updatedModel
            });
        },
        onIsMedApprovedChanged: (evt) => {
            var updatedIsMedApproved = evt.target.checked;

            dispatch({
                type: "VESSEL_EQUIPMENT_IS_MED_APPROVED_UPDATED",
                data: updatedIsMedApproved
            });
        },
        onIsGpsEnabledChanged: (evt) => {
            var updatedIsGpsEnabled = evt.target.checked;

            dispatch({
                type: "VESSEL_EQUIPMENT_IS_GPS_ENABLED_UPDATED",
                data: updatedIsGpsEnabled
            });
        },
        onNumberOfPlbsChanged: (evt) => {
            var updatedNumberOfPlbs = evt.target.value;

            if (isNaN(updatedNumberOfPlbs) || updatedNumberOfPlbs.includes(".") || updatedNumberOfPlbs < 1 || updatedNumberOfPlbs > 12) {
                return;
            }
            
            dispatch({
                type: "VESSEL_EQUIPMENT_PLB_NUMBER_OF_PLBS_UPDATED",
                data: updatedNumberOfPlbs
            });
        },
        onNumberOfPlbsCompleted: (evt, hexCode) => {
            var numberOfPlbs = Number(evt.target.value);

            var errorFound = false;

            if (numberOfPlbs > 0) {
                //check for hexcodes
                const hexCodeArray = hexCode.split(", ");

                for (let i = 0; i < hexCodeArray.length; i++) {

                    if (i > numberOfPlbs - 1) break;

                    let item = hexCodeArray[i];

                    if (item === "" || item === null || item.length < 9) {
                        dispatch({
                            type: "VESSEL_EQUIPMENT_HEX_CODE_REQUIRED",
                            data: true
                        });
                        errorFound = true;
                        break;
                    }

                    item = item.toUpperCase();

                    if (item.substr(0, 4) !== "1F4E" && item.substr(0, 4) !== "1F4F") {
                        dispatch({
                            type: "VESSEL_EQUIPMENT_HEX_CODE_REQUIRED",
                            data: true
                        });
                        errorFound = true;
                        break;
                    }
                };
            }

            if (!errorFound) {
                dispatch({
                    type: "VESSEL_EQUIPMENT_HEX_CODE_REQUIRED",
                    data: false
                });
            }

        },
        onHexCodeChanged: (evt, hexCode) => {
            var updatedHexCode = evt.target.value;

            var hexRegEx = /^[0-9a-fA-F]+$/g;

            if ((updatedHexCode.length > 0 && !hexRegEx.test(updatedHexCode)) || updatedHexCode.length > 15) {
                return;
            }

            //get number form hexcode id
            var hexCodeId = evt.target.id;
            var arrayEntry = hexCodeId.match(/\d+/);

            //update correct array entry
            var hexCodeArray = hexCode.split(", ");
            hexCodeArray[arrayEntry - 1] = updatedHexCode;

            //save as comma delimited string
            dispatch({
                type: "VESSEL_EQUIPMENT_HEX_CODE_UPDATED",
                data: hexCodeArray.join(", ")
            });
        },
        onHexCodeChangeCompleted: (evt) => {
            var hexCode = evt.target.value;

            if (hexCode === "" || hexCode === null || hexCode.length < 15) {
                dispatch({
                    type: "VESSEL_EQUIPMENT_HEX_CODE_REQUIRED",
                    data: true
                });
                return;
            }

            hexCode = hexCode.toUpperCase();

            if (hexCode.substr(0, 4) !== "1F4E" && hexCode.substr(0, 4) !== "1F4F") {
                dispatch({
                    type: "VESSEL_EQUIPMENT_HEX_CODE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "VESSEL_EQUIPMENT_HEX_CODE_REQUIRED",
                    data: false
                });
            }
        },
        onSatelliteNumberChanged: (evt) => {
            var updatedSatelliteNumber = evt.target.value;

            dispatch({
                type: "VESSEL_EQUIPMENT_SATELLITE_NUMBER_UPDATED",
                data: updatedSatelliteNumber
            });
        },
        onSatelliteTypeSelected: (evt) => {
            var updatedSatelliteTypeId = evt.target.value;

            dispatch({
                type: "VESSEL_EQUIPMENT_GMDSS_SATELLITE_TYPE_ID_UPDATED",
                data: updatedSatelliteTypeId
            });
        },
        onIsEgcReceiverChanged: (evt) => {
            var updatedIsEgcReceiver = evt.target.checked;

            dispatch({
                type: "VESSEL_EQUIPMENT_IS_EGC_RECEIVER_UPDATED",
                data: updatedIsEgcReceiver
            });
        },
        onIsLritFacilityChanged: (evt) => {
            var updatedIsLritFacility = evt.target.checked;

            dispatch({
                type: "VESSEL_EQUIPMENT_IS_LRIT_FACILITY_UPDATED",
                data: updatedIsLritFacility
            });
        }
    }
}

const languageAwareComponent = withTranslation()(AddVesselEquipmentVHF);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);

