import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (applicationId, includeSrc) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "vessel/GetVesselImagesByLicenceId";

        const params = {
            licenceApplicationId: applicationId,
            includeSrc: includeSrc
        };

        GET(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    
                    dispatch({
                        type: "VESSEL_IMAGES_RETRIEVED",
                        data: response.data
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

