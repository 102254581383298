import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Switch, Route } from "react-router-dom";

import OwnerLandingPage from "./OwnerLandingPage";
import AdminLandingPage from "./AdminLandingPage";
import RadioSurveyorLandingPage from "./RadioSurveyorLandingPage";
import PublicFAQPage from "./PublicFAQPage";
import PublicUsefulLinksPage from "./PublicUsefulLinksPage";
import PublicUsefulDocumentsPage from "./PublicUsefulDocumentsPage";  
import PublicVideoPage from "./PublicVideoPage";

class LandingPage extends Component {

    render() {

        return (
            <React.Fragment>

                {
                    this.props.userRole === "Ship Owner" &&
                    <Switch>
                        <Route path="/dashboard" component={OwnerLandingPage} />
                        <Route path="/faq" component={PublicFAQPage} />
                        <Route path="/links" component={PublicUsefulLinksPage} />
                        <Route path="/documents" component={PublicUsefulDocumentsPage} />
                        <Route path="/videos" component={PublicVideoPage} />
                    </Switch>
                }
                {
                    (this.props.userRole === "Radio Surveyor") &&
                    <Switch>
                        <Route path="/dashboard" component={RadioSurveyorLandingPage} />
                        <Route path="/faq" component={PublicFAQPage} />
                        <Route path="/links" component={PublicUsefulLinksPage} />
                        <Route path="/documents" component={PublicUsefulDocumentsPage} />
                        <Route path="/videos" component={PublicVideoPage} />
                    </Switch>
                }
                {
                    (this.props.userRole === "Department Admin Staff") &&
                    <Switch>
                        <Route path="/dashboard" component={AdminLandingPage} />
                        <Route path="/faq" component={PublicFAQPage} />
                        <Route path="/links" component={PublicUsefulLinksPage} />
                        <Route path="/documents" component={PublicUsefulDocumentsPage} />
                        <Route path="/videos" component={PublicVideoPage} />
                    </Switch>
                }
                {
                    this.props.userRole === "Emergency Services" &&
                    <Switch>
                        <Route path="/dashboard" component={() => {
                            window.location.href = "/EmergencyServices";
                            return null;
                        }} />
                        <Route path="/faq" component={PublicFAQPage} />
                        <Route path="/links" component={PublicUsefulLinksPage} />
                        <Route path="/documents" component={PublicUsefulDocumentsPage} />
                        <Route path="/videos" component={PublicVideoPage} />
                    </Switch>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userRole: state.currentUserInfo.role || "Ship Owner"
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //getUserInfo: () => { // commenting as duplicate,  the component itself invoke latest information once loaded
        //    dispatch(CreateGetCurrentUserInfoAction());
        //}
    };
}

const languageAwareComponent = withTranslation()(LandingPage);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);


//export default () => {
//    return (
//        <React.Fragment>
//            <Switch>
//                <Route path="/dashboard" component={OwnerLandingPage}/>
//            </Switch>
//        </React.Fragment>
//    );
//};
