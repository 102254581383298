import axios from "axios";
import CreateAddEquipmentAmendmentAction from "./CreateAddEquipmentAmendmentAction";
import CreateAddVesselEquipmentDetailsAction from "./CreateAddVesselEquipmentDetailsAction";

// import authService from "../../components/api-authorization/AuthorizeService";

export default function (licenceApplicationId, equipmentTypeCategoryId) {
    return (dispatch) => {

        axios({
            method: "GET",
            url: "equipment/CheckAddEquipmentForLicenceAmendment",
            params: { licenceApplicationId, equipmentTypeCategoryId }
        })
            .then((response) => {
                dispatch({
                    type: "LICENCE_AMENDMENT",
                    data: response.data
                });
                if (response.data) {
                    //create equipment amendment
                    dispatch(CreateAddEquipmentAmendmentAction(licenceApplicationId));

                } else {
                    //create equipment
                    dispatch(CreateAddVesselEquipmentDetailsAction());
                }
            })
            .catch((error) => {
            });
    };
}
