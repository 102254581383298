import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetLicenceImagesAction from "./CreateGetLicenceImagesByLicenceIdAction";
import CreateGetVesselImagesAction from "./CreateGetVesselImagesByLicenceIdAction";

import { DELETE } from "../adapters/xhr";

export default function (id, fileTypeId) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const { currentApplication } = getState();

        const updatedLicenceApplication = {
            id: Number(currentApplication.id)
        };

        const url = "licenceApplication/DeleteLicenceImage?id=" + id;

        DELETE(url)
            .then(function (response) {
                if (response.status === 200) {

                    //dispatch({
                    //    type: "LICENCE_IMAGES_RETRIEVED",
                    //    data: response.data
                    //});
                }
                if (fileTypeId === 1) {
                    dispatch(CreateGetLicenceImagesAction(updatedLicenceApplication.id));
                } else {
                    dispatch(CreateGetVesselImagesAction(updatedLicenceApplication.id));
                }
                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}