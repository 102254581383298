const initialState = {
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_CURRENT_VESSEL":
            return initialState;

        case "VESSEL_RETRIEVED":
            return {
                ...action.vessel //mmsiNumber: action.vessel.mmsiNumber || "250005693"
            }

        case "VESSEL_DATA_RETRIEVED":
            return {
                ...action.data //mmsiNumber: action.data.mmsiNumber || "250005693"
            }
        case "UPDATE_CURRENT_VESSEL_NAME":
            return {
                ...state,
                name: action.data
            };
        case "UPDATE_VESSEL_NAME_REQUIRED":
            return {
                ...state,
                nameRequired: action.data
            };

        case "UPDATE_VESSEL_MAX_CAPACITY":
            return {
                ...state,
                maxCapacityOfPersonsOnBoard: action.data
            };
        case "UPDATE_VESSEL_MAX_CAPACITY_REQUIRED":
            return {
                ...state,
                maxVesselCapacityRequired: action.data
            };

        case "UPDATE_VESSEL_LENGTH":
            return {
                ...state,
                vesselLength: action.data
            };
        case "UPDATE_VESSEL_LENGTH_REQUIRED":
            return {
                ...state,
                vesselLengthRequired: action.data
            };

        case "UPDATED_GROSS_TONNAGE":
            return {
                ...state,
                grossTonnage: action.data
            };
        case "UPDATE_VESSEL_GROSS_TONNAGE_REQUIRED":
            return {
                ...state,
                grossTonnageRequired: action.data
            };

        case "UPDATED_VESSEL_DESCRIPTION":
            return {
                ...state,
                description: action.data
            };
        case "UPDATE_VESSEL_DESCRIPTION_REQUIRED":
            return {
                ...state,
                vesselDescriptionRequired: action.data
            };

        case "UPDATE_HOMEPORT":
            return {
                ...state,
                homePort: action.data
            };
        case "UPDATE_VESSEL_HOMEPORT_REQUIRED":
            return {
                ...state,
                homeportRequired: action.data
            };

        case "UPDATE_VESSEL_REGISTRATION_NUMBER":
            return {
                ...state,
                registerationNumber : action.data
            };
        case "UPDATE_FV_VESSEL_ID_PREFIX":
            return {
                ...state,
                fishingVesselPrefixTypeId: action.data
            };
        case "UPDATE_FV_VESSEL_ID_NUMBER":
            return {
                ...state,
                fvVesselIdentificationNumber: action.data
            };
        case "UPDATE_FV_VESSEL_ID_POSTFIX":
            return {
                ...state,
                fishingVesselPostfixTypeId: action.data
            };
        case "UPDATE_VESSEL_IMO_NUMBER":
            return {
                ...state,
                imoNumber: action.data
            };
        case "INVALID_VESSEL_IMO_NUMBER":
            return {
                ...state,
                vesselImoNumberInvalid: action.data
            };
        case "UPDATE_LIFE_BOATS":
            return {
                ...state,
                numberOfLifeBoats: action.data
            };
        case "UPDATE_ACCOUNTING_AUTHORITY_ID_CODE":
            return {
                ...state,
                accountingAuthorityIdentificationCodeId: action.data
            };

        case "UPDATE_CURRENT_VESSEL_TYPE":
            return {
                ...state,
                vesselTypeId: action.data
            };
        case "UPDATE_OTHER_VESSEL_TYPE_SELECTED":
            return {
                ...state,
                isOtherVesselType: action.data
            };
        case "UPDATE_CURRENT_VESSEL_HAS_SUB_TYPE":
            return {
                ...state,
                hasVesselSubTypes: action.data
            };
        case "UPDATE_OTHER_VESSEL_TEXT":
            return {
                ...state,
                otherVesselTypeDescription: action.data
            };
        case "UPDATE_CURRENT_VESSEL_SUB_TYPE":
            return {
                ...state,
                vesselSubTypeId: action.data
            };
        case "UPDATE_VESSEL_TYPE_REQUIRED":
            return {
                ...state,
                vesselTypeRequired: action.data
            };
        case "UPDATE_VESSEL_SUB_TYPE_REQUIRED":
            return {
                ...state,
                vesselSubTypeRequired: action.data
            };

        case "UPDATE_VESSEL_HULLCOLOR":
            return {
                ...state,
                hullColorId: action.data
            };
        case "UPDATE_VESSEL_HULL_COLOR_REQUIRED":
            return {
                ...state,
                hullColorRequired: action.data
            };
        case "UPDATE_VESSEL_SEA_AREA_OF_OPERATION":
            return {
                ...state,
                seaAreaOfOperationId: action.data
            };
        case "UPDATE_VESSEL_SEA_AREA_OF_OPERATION_REQUIRED":
            return {
                ...state,
                seaAreaOfOperationRequired: action.data
            };

        case "VESSEL_NAME_IN_USE":
            return {
                ...state,
                nameInUse: action.data
            };
        case "UPDATE_VESSEL_IMAGE_ERROR":
            return {
                ...state,
                vesselImageError: action.data
            };

        default:
            return state;
    }
}
