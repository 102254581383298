import React, { Component } from "react";
import { connect } from "react-redux";
import LinkButton from "./LandingPage/LinkButton";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

import ValidationFailureMessage from "./ValidationFailureMessage";
import Breadcrumb from "./Breadcrumb";

import CreateGetEpirbModelsAction from "../store/actionCreators/CreateGetEpirbModelsAction";
import CreateGetEpirbMountingsAction from "../store/actionCreators/CreateGetEpirbMountingsAction";
import CreateGetEpirbGpsEnabledAction from "../store/actionCreators/CreateGetEpirbGpsEnabledAction";
import CreateGetEpirbLinkServiceFeaturesAction from "../store/actionCreators/CreateGetEpirbLinkServiceFeaturesAction";
import CreateGetVesselTypesAction from "../store/actionCreators/CreateGetVesselTypesAction";
import CreateAddEpirbDetailsAction from "../store/actionCreators/CreateAddEpirbDetailsAction";
import CreateUpdateEpirbDetailsAction from "../store/actionCreators/CreateUpdateEpirbDetailsAction";
import CreateDeleteEpirbAction from "../store/actionCreators/CreateDeleteEpirbAction";
import CheckExistingHexcodeAction from "../store/actionCreators/CheckExistingHexcodeAction";


class AddEpirbDetails extends Component {
    componentDidMount() {
        this.props.getEpirbModels();
        this.props.getEpirbMountings();
        this.props.getGpsEnableds();
        this.props.getLinkServiceFeatures();
        this.props.getVesselTypes();
    }

    render() {
        const { t } = this.props;

        const VesselTypes = {
            PleasureVessel: 1,
            FishingVessel: 2,
            PassengerVessel: 3,
            PassengerShip: 4,
            MerchantVessel: 5,
            NavalShip: 6,
            RescueVessel: 7
        };

        const isCommercialVessel = this.props.vesselTypeId === VesselTypes.FishingVessel || this.props.vesselTypeId === VesselTypes.PassengerVessel
            || this.props.vesselTypeId === VesselTypes.PassengerShip || this.props.vesselTypeId === VesselTypes.MerchantVessel;
        const vesselLength = this.props.vesselLength;
        const isFishingVessel = Number(this.props.vesselTypeId) === VesselTypes.FishingVessel;
        const fishingVesselLessThan15Meters = isFishingVessel && Number(vesselLength) < 15;

        //default IsGpsEnabled to 'Yes' if is fishingVesselLessThan15Meters

        var epirbModels;
        var selectedVesselType = this.props.vesselTypes.filter(v => Number(v.id) === Number(this.props.vesselTypeId));
        var selectedVesselTypeName = selectedVesselType[0]?.name || "Commercial Vessel";

        // filter out only the MED approved makes and models.
        // filter isActive epirbs...include the selected model
        if (isCommercialVessel && !fishingVesselLessThan15Meters) {
            epirbModels = this.props.epirbModels.filter(x => (x.medApproved && x.isActive) || x.id === (this.props.epirbModelId ?? 0));
        }
        else if (fishingVesselLessThan15Meters) {
            // filter out the GPS enabled too
            epirbModels = this.props.epirbModels.filter(x => (x.medApproved && x.gpsEnabled && x.isActive) || x.id === (this.props.epirbModelId ?? 0));
        }
        else {
            epirbModels = this.props.epirbModels.filter(x => x.isActive || x.id === (this.props.epirbModelId ?? 0));
        }

        //get unique makes from model list
        const epirbMakes = epirbModels.filter(m => m.epirbMakeTypeId != null)
            .map(function (e) {
                return {
                    id: e.epirbMakeType.id,
                    make: e.epirbMakeType.make
                };
            });

        const epirbMakesArray = uniqByKeepFirst(epirbMakes, it => it.id);

        var canSubmitForm = ((!this.props.isOtherMake && this.props.epirbMakeId === "") ||
            (!this.props.isOtherModel && this.props.epirbModelId === "") ||
            (this.props.isOtherMake && this.props.otherMake === "") ||
            (this.props.isOtherModel && this.props.otherModel === "") ||
            (isCommercialVessel && this.props.mountingTypeRequired) ||
            this.props.batteryExpiryDate === "" ||
            this.props.batteryExpiryDateHasError ||
            this.props.serialNumber === "" ||
            this.props.gpsEnabledId === "" ||
            this.props.hexCode === "" ||
            (this.props.hexCode.length > 0 && this.props.hexCode.length < 15) ||
            this.props.hexCodeError ||
            this.props.hexCodeExists
        );

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6,
            TransferInitiated: 7,
            Transferred: 8
        };

        //cancelled, TransferInitiated or transferred Status
        const isReadOnlyPage = this.props.licenceApplicationStatusTypeId === LicenceStatus.Cancelled
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.TransferInitiated
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.Transferred;

        return (
            <React.Fragment>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    licenceList={t("AdminLandingPage.LicenceList")}
                    dashboard={t("Common.Dashboard")}
                    parentLink={this.props.parentRoute + "/epirb-list/" + this.props.licenceApplicationId}
                    parentPageName={t("EpirbDetails.EpirbTitle")}
                    pageName={t("EpirbDetails.AddEditEPIRB")} />

                <div className="row col-md-12">
                    <div aria-hidden="true" id="required-description" className="d-flex justify-content-end">* {t("Common.RequiredField")}</div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="epirbManufacturerLabel" htmlFor="epirbMakeId" className="form-label">{t("EpirbDetails.Make")} </label>
                            <select id="epirbMakeId"
                                    className="form-control"
                                    disabled={isReadOnlyPage}
                                    onChange={this.props.onEpirbMakeSelected}
                                    onBlur={this.props.onEpirbMakeChangeCompleted}
                                    value={this.props.isOtherMake ? 0 : this.props.epirbMakeId}
                            >
                                <option value="">{t("Common.ButtonText.Select")} </option>
                                <option value="0">{t("Common.ButtonText.Other")} </option>
                                {
                                    epirbMakesArray.map(EpirbManufacturer => (
                                        <option
                                            key={EpirbManufacturer.id}
                                            value={EpirbManufacturer.id}
                                        >
                                            {EpirbManufacturer.make}
                                        </option>
                                    ))
                                }
                            </select>
                            {
                                this.props.makeRequired &&
                                <ValidationFailureMessage>
                                    {t("EpirbDetails.MakeRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>
                    {
                    this.props.isOtherMake &&
                        <div className="col-md-6">
                            <div className="form-group">
                                <label id="otherMakeLabel" htmlFor="otherMake" className="form-label"> {t("EpirbDetails.ManufacturerLabel")} </label>
                                <input id="otherMake" type="text" maxLength="255"
                                    className="form-control"
                                    onChange={this.props.onMakeChanged}
                                    value={this.props.otherMake} />
                                <small id="otherMakeHelp" className="form-text text-muted">{t("EpirbDetails.OtherMake")}</small>
                        </div>
                    </div>
                }

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="epirbModelLabel" htmlFor="epirbModelId" className="form-label">{t("EpirbDetails.Model")} </label>
                            <select id="epirbModelId"
                                    className="form-control"
                                    disabled={isReadOnlyPage}
                                    onChange={this.props.onEpirbModelSelected}
                                    onBlur={this.props.onEpirbModelChangeCompleted}
                                    value={this.props.isOtherModel ? 0 : this.props.epirbModelId}
                            >
                                <option value="">{t("Common.ButtonText.Select")} </option>
                                <option value="0">{t("Common.ButtonText.Other")} </option>
                                {
                                    epirbModels.filter(model => model.epirbMakeType?.id === Number(this.props.epirbMakeId))
                                        .map(model => (
                                            <option key={model.id} value={model.id}>
                                                {model.model}
                                            </option>
                                        ))
                                }
                            </select>
                            {
                                this.props.modelRequired &&
                                <ValidationFailureMessage>
                                    {t("EpirbDetails.ModelRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>

                    {
                        this.props.isOtherModel &&
                        <div className="col-md-6">
                            <div className="form-group">
                                <label id="otherModelLabel" htmlFor="otherModel" className="form-label">{t("EpirbDetails.ModelLabel")}</label>
                                <input id="otherModel" type="text" max="255"
                                        disabled={isReadOnlyPage}
                                        className="form-control"
                                        onChange={this.props.onModelChanged}
                                    value={this.props.otherModel} />
                                <small id="otherModelHelp" className="form-text text-muted">{t("EpirbDetails.OtherModel")}</small>
                            </div>
                        </div>
                    }

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="epirbMountingLabel" htmlFor="epirbMountingId" className="form-label">{t("EpirbDetails.MountingType")}</label>
                            <select id="epirbMountingId"
                                    className="form-control"
                                    disabled={isReadOnlyPage}
                                    onChange={this.props.onEpirbMountingSelected}
                                    onBlur={this.props.onEpirbMountingChangeCompleted}
                                    value={this.props.epirbMountingId}>
                                <option value="">{t("Common.ButtonText.Select")} </option>
                                {
                                this.props.epirbMountings.map(m => (
                                    <option
                                        key={m.id}
                                        value={m.id}
                                    >
                                        {m.name}
                                    </option>
                                ))
                            }
                            </select>
                            {
                            isCommercialVessel && this.props.mountingTypeRequired &&
                            <ValidationFailureMessage>
                                {t("EpirbDetails.MountingTypeRequired")}
                            </ValidationFailureMessage>
                        }
                            {
                            isCommercialVessel && this.props.mountingTypeWarning &&
                            <ValidationFailureMessage>
                                {t("EpirbDetails.MountingTypeWarning", {selectedVesselTypeName})}
                            </ValidationFailureMessage>
                        }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="BatteryExpiryDateLabel" htmlFor="batteryExpiryDate" className="form-label">{t("EpirbDetails.BatteryExpiryDate")}</label>
                            <br/>
                            <DatePicker
                                id="batteryExpiryDate"
                                disabled={isReadOnlyPage}
                                className="form-control"
                                selected={this.props.batteryExpiryDate}
                                onChange={date => this.props.onDatePickerBatteryExpiryDateChanged(date)}
                                onBlur={this.props.onBatteryExpiryDateChangedCompleted}
                                value={this.props.batteryExpiryDate}
                                dateFormat="dd/MM/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                placeholderText="DD/MM/YYYY"
                            />
                            {
                            this.props.batteryExpiryDateRequired &&
                            <ValidationFailureMessage>
                                {t("EpirbDetails.BatteryExpiryDateRequired")}
                            </ValidationFailureMessage>
                        }
                            {
                            this.props.batteryExpiryDateHasError &&
                            <ValidationFailureMessage>
                                {t("EpirbDetails.BatteryExpiryDateHasError")}
                            </ValidationFailureMessage>
                        }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="SerialNumberLabel" htmlFor="SerialNumber" className="form-label">{t("EpirbDetails.SerialNumber")} </label>
                            <input
                                id="SerialNumber" type="text"
                                disabled={isReadOnlyPage}
                                className="form-control"
                                onChange={this.props.onSerialNumberChanged}
                                onBlur={this.props.onSerialNumberChangeCompleted}
                                value={this.props.serialNumber}/>
                            {
                            this.props.serialNumberRequired &&
                            <ValidationFailureMessage>
                                {t("EpirbDetails.SerialNumberRequired")}
                            </ValidationFailureMessage>
                        }
                        </div>
                    </div>

                    {
                        this.props.isOtherModel &&
                        <div className="col-md-6 d-flex align-items-end">
                            <div className="form-check">
                                <input id="isMedApprovedId" type="checkbox"
                                    className="form-check-input"
                                    onChange={this.props.onIsMedApprovedChanged}
                                    value={this.props.isMedApproved}
                                    checked={this.props.isMedApproved}
                                />
                                <label id="isMedApprovedLabel" htmlFor="isMedApprovedId" className="form-check-label form-label">{t("AddEquipment.IsMEDApproved")}?</label>
                            </div>
                        </div>
                    }

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="AisMmsiNumberLabel" htmlFor="AisMmsiNumber" className="form-label">{t("EpirbDetails.AisMmsiNumber")} </label>
                            <input
                                id="AisMmsiNumber" type="text"
                                maxLength="9"
                                disabled={isReadOnlyPage}
                                className="form-control"
                                onChange={this.props.onAisMmsiNumberChanged}
                                onBlur={this.props.onAisMmsiNumberChangeCompleted}
                                value={this.props.aisMmsiNumber}/>
                            {
                            this.props.aisMmsiNumberHasWarning &&
                            <ValidationFailureMessage>
                                {t("EpirbDetails.AisMmsiNumberInValidWarningText")}
                            </ValidationFailureMessage>
                        }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="HexCodeLabel" htmlFor="HexCode" className="form-label">{t("EpirbDetails.HexCode")} *</label>
                            <input id="HexCode" type="text"
                                maxLength="30"
                                disabled={isReadOnlyPage}
                                data-mmsi-number={this.props.mmsiNumber}
                                data-callsign-number={this.props.callSignNumber}
                                data-gps-enabled={this.props.gpsEnabledId}
                                data-id={this.props.id}
                                className="form-control"
                                onChange={this.props.onHexCodeChanged}
                                onBlur={this.props.onHexCodeChangeCompleted}
                                value={this.props.hexCode}/>
                            {
                                (this.props.hexCodeRequired &&
                                        <ValidationFailureMessage>
                                            {t("EpirbDetails.HexCodeRequired")}
                                        </ValidationFailureMessage>)
                                    ||
                                    (this.props.hexCodeError &&
                                        <ValidationFailureMessage>
                                            {this.props.hexCodeError}
                                    </ValidationFailureMessage>)
                                ||
                                (this.props.hexCodeExists &&
                                    <ValidationFailureMessage>
                                        Hexcode already exists
                                    </ValidationFailureMessage>)
                            }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="GpsEnabledLabel" htmlFor="GpsEnabledId" className="form-label">{t("EpirbDetails.GpsEnabled")} </label>
                            <select id="GpsEnabledId"
                                className="form-control"
                                onChange={this.props.onGpsEnabledSelected}
                                onBlur={this.props.onEpirbGpsEnabledChangeCompleted}
                                value={this.props.gpsEnabledId}
                            >
                                <option value="">{t("Common.ButtonText.Select")} </option>
                                {
                                    this.props.epirbGpsEnableds.map(x => (
                                        <option
                                            key={x.id}
                                            value={x.id}
                                        >
                                            {x.name}
                                        </option>
                                    ))
                                }
                            </select>
                            {
                                this.props.gpsEnabledRequired &&
                                <ValidationFailureMessage>
                                    {t("EpirbDetails.GpsEnabledRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="LinkServiceFeatureLabel" htmlFor="LinkServiceFeatureId" className="form-label">{t("EpirbDetails.LinkServiceFeature")} </label>
                            <select id="LinkServiceFeatureId"
                                    className="form-control"
                                    disabled={isReadOnlyPage}
                                    onChange={this.props.onLinkServiceFeatureSelected}
                                    value={this.props.linkServiceFeatureId}>
                                <option value="">{t("Common.ButtonText.Select")} </option>

                                {
                                    this.props.epirbLinkServiceFeatures.map(x => (
                                        <option
                                            key={x.id}
                                            value={x.id}
                                        >
                                            {x.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div className="h6 mt-2" role="heading" aria-level="2" id="ComplianceTitle" tabIndex="0">
                    {t("Equipments.ComplianceTitle")}
                </div>

                {
                    !isReadOnlyPage &&
                    <div className="my-3">
                        <LinkButton className="btn btn-warning"
                            id="cancelEpirb"
                            to={this.props.parentRoute + "/epirb-list/" + this.props.licenceApplicationId}>
                            {t("Common.ButtonText.Cancel")}
                        </LinkButton>

                        {
                            this.props.id !== 0 &&
                            <LinkButton
                                id="deleteEpirbDetail"
                                className="btn btn-danger ms-3"
                                onClick={() => {
                                    this.props.deleteEPIRB(this.props.id);
                                }}
                                to={this.props.parentRoute + "/epirb-list/" + this.props.licenceApplicationId}
                            >{t("Common.ButtonText.Delete")}
                            </LinkButton>
                        }

                        <LinkButton
                            id="addEpirbDetailButton"
                            className="btn btn-primary ms-3"
                            disabled={canSubmitForm}
                            to={this.props.parentRoute + "/epirb-list/" + this.props.licenceApplicationId}
                            onClick={() => this.props.addEpirbDetail(this.props.licenceApplicationId, this.props.id)}
                        >
                            {t("Common.ButtonText.Save")}
                        </LinkButton>

                    </div>
                }
                {
                    isReadOnlyPage &&
                    <LinkButton className="btn btn-primary my-3"
                        id="cancelEpirb"
                        to={this.props.parentRoute + "/epirb-list/" + this.props.licenceApplicationId}>
                        {t("Common.ButtonText.Back")}
                    </LinkButton>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: state.epirb.id || 0,
        licenceApplicationId: state.currentApplication.id || 0,
        role: state.currentUserInfo.role || "",

        epirbMakeId: state.epirb.epirbMakeTypeId || "",
        otherMake: state.epirb.otherMake || "",
        epirbModelId: state.epirb.epirbModelTypeId || "",
        otherModel: state.epirb.otherModel || "",
        epirbMountingId: state.epirb.epirbMountingTypeId || state.defaultEpirbMountingTypeId,
        batteryExpiryDate: state.epirb.batteryExpiryDate || "",
        serialNumber: state.epirb.serialNumber || "",
        aisMmsiNumber: state.epirb.aisMmsiNumber || "",
        isMedApproved: state.epirb.isMedApproved || false,
        gpsEnabledId: state.epirb.epirbGpsEnabledTypeId || "",
        hexCode: state.epirb.hexCode || "",
        homingId: state.epirb.epirbHomingTypeId || "",
        linkServiceFeatureId: state.epirb.epirbReturnLinkServiceTypeId || "",
        programmedId: state.epirb.epirbProgrammedTypeId || "",
        isOtherModel: state.epirb.isOtherModel || false,
        isOtherMake: state.epirb.isOtherMake || false,
        isDefault: state.epirb.isDefault || false,
        isNewAmendment: state.epirb.isNewAmendment || false,

        // lists
        epirbMakes: state.availableEpirbMakeTypes || "",
        epirbModels: state.availableEpirbModelTypes || "",
        epirbMountings: state.availableEpirbMountingTypes || "",
        epirbGpsEnableds: state.availableEpirbGpsEnabledTypes || "",
        epirbLinkServiceFeatures: state.availableEpirbLinkServiceFeatureTypes || "",
        vesselTypes: state.availableVesseltypes,

        // to check for licence amendment
        requiredEpirbs: state.requiredEpirbs,
        licenceApplicationStatusTypeId: state.currentApplication.licenceApplicationStatusTypeId || 0,
        applicationTypeId: state.currentApplication.applicationTypeId || 1,

        // validations
        makeRequired: state.epirb.makeRequired || false,
        modelRequired: state.epirb.modelRequired || false,
        mountingTypeRequired: state.epirb.mountingTypeRequired || false,
        mountingTypeWarning: state.epirb.mountingTypeWarning || false,
        batteryExpiryDateRequired: state.epirb.batteryExpiryDateRequired || false,
        serialNumberRequired: state.epirb.serialNumberRequired || false,
        hexCodeRequired: state.epirb.hexCodeRequired || false,
        gpsEnabledRequired: state.epirb.gpsEnabledRequired || false,
        batteryExpiryDateHasError: state.epirb.batteryExpiryDateHasError || false,
        aisMmsiNumberHasWarning: state.epirb.aisMmsiNumberHasWarning || false,
        hexCodeError: state.epirb.errorMessage || "",
        hexCodeExists: state.epirb.hexCodeExists || false,

        mmsiNumber: state.currentVessel.mmsiNumber || "",
        callSignNumber: state.currentVessel.callSignNumber || "",

        vesselTypeId: state.currentVessel.vesselTypeId || null,
        vesselLength: state.currentVessel.vesselLength || 0

    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

        getEpirbModels: () => {
            dispatch(CreateGetEpirbModelsAction());
        },
        getEpirbMountings: () => {
            dispatch(CreateGetEpirbMountingsAction());
        },
        getGpsEnableds: () => {
            dispatch(CreateGetEpirbGpsEnabledAction());
        },
        getLinkServiceFeatures: () => {
            dispatch(CreateGetEpirbLinkServiceFeaturesAction());
        },
        getVesselTypes: () => {
            dispatch(CreateGetVesselTypesAction());
        },
        addEpirbDetail: (licenceApplicationId, id) => {

            if (Number(id) === 0) {
                //add epirb
                dispatch(CreateAddEpirbDetailsAction(licenceApplicationId));
            }
            else {
                //update epirb
                dispatch(CreateUpdateEpirbDetailsAction());
            }
        },

        onEpirbMakeSelected: (evt) => {
            var updatedEpirbMakeSelected = evt.target.value;

            dispatch({
                type: "EPIRB_MAKE_ID_UPDATED",
                data: updatedEpirbMakeSelected
            });
            if (updatedEpirbMakeSelected === "0") {
                dispatch({
                    type: "EPIRB_MAKE_IS_OTHER_UPDATED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_MAKE_IS_OTHER_UPDATED",
                    data: false
                });
            }
        },
        onEpirbModelSelected: (evt) => {
            var updatedEpirbModelSelected = evt.target.value;

            dispatch({
                type: "EPIRB_MODEL_ID_UPDATED",
                data: updatedEpirbModelSelected
            });

            if (updatedEpirbModelSelected === "0") {
                dispatch({
                    type: "EPIRB_MODEL_IS_OTHER_UPDATED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_MODEL_IS_OTHER_UPDATED",
                    data: false
                });
            }
        },
        onMakeChanged: (evt) => {
            var updatedMake = evt.target.value;
            if (updatedMake.length > 255) {
                return;
            }
            dispatch({
                type: "EPIRB_MAKE_UPDATED",
                data: updatedMake
            });
        },

        onModelChanged: (evt) => {
            var updatedModel = evt.target.value;
            if (updatedModel.length > 255) {
                return;
            }
            dispatch({
                type: "EPIRB_MODEL_UPDATED",
                data: updatedModel
            });
        },

        onEpirbMountingSelected: (evt) => {
            var updatedEpirbMounting = evt.target.value;

            dispatch({
                type: "EPIRB_MOUNTING_ID_UPDATED",
                data: updatedEpirbMounting
            });
            if (updatedEpirbMounting == "1") {
                dispatch({
                    type: "EPIRB_MOUNTING_WARNING",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_MOUNTING_WARNING",
                    data: false
                });
            }
        },
        onDatePickerBatteryExpiryDateChanged: (date) => {

            dispatch({
                type: "EPIRB_EXPIRY_DATE_UPDATED",
                data: date
            });

            //var selectedDate = new Date(date);
            var dateTimeNow = new Date();
            var maxExpiryDate = new Date(dateTimeNow.getFullYear() + 10, dateTimeNow.getMonth(), dateTimeNow.getDate());
            if (isNaN(date) || date > maxExpiryDate || date < dateTimeNow) {
                dispatch({
                    type: "EPIRB_BATTERY_EXPIRY_DATE_HAS_ERROR",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_BATTERY_EXPIRY_DATE_HAS_ERROR",
                    data: false
                });
                dispatch({
                    type: "EPIRB_BATTERY_EXPIRY_DATE_REQUIRED",
                    data: false
                });
            }
        },
        onSerialNumberChanged: (evt) => {
            var updatedSerialNumber = evt.target.value;

            dispatch({
                type: "EPIRB_SERIAL_NUMBER_UPDATED",
                data: updatedSerialNumber
            });
        },
        onAisMmsiNumberChanged: (evt) => {
            var updatedAisMmsiNumber = evt.target.value;
            if (updatedAisMmsiNumber.length > 9 || updatedAisMmsiNumber.includes(".") || isNaN(updatedAisMmsiNumber)) {
                return;
            }
            dispatch({
                type: "EPIRB_AIS_MMSI_NUMBER_UPDATED",
                data: updatedAisMmsiNumber
            });
        },
        onIsMedApprovedChanged: (evt) => {
            var updatedIsMedApproved = evt.target.checked;

            dispatch({
                type: "EPIRB_IS_MED_APPROVED_UPDATED",
                data: updatedIsMedApproved
            });
        },
        onHexCodeChanged: (evt) => {
            var updatedHexCode = evt.target.value;

            var hexRegEx = /^[0-9a-fA-F]+$/g;

            if (updatedHexCode.length > 0 && !hexRegEx.test(updatedHexCode)) {
                return;
            }

            dispatch({
                type: "EPIRB_HEX_CODE_UPDATED",
                data: updatedHexCode
            });
        },
        onGpsEnabledSelected: (evt) => {
            var updatedId = evt.target.value;

            dispatch({
                type: "EPIRB_GPS_ENABLED_ID_UPDATED",
                data: updatedId
            });
        },
        onLinkServiceFeatureSelected: (evt) => {
            var updatedSalutationId = evt.target.value;

            dispatch({
                type: "EPIRB_LINK_SERVICE_FEATURE_ID_UPDATED",
                data: updatedSalutationId
            });
        },
        onProgrammedSelected: (evt) => {
            var updatedSalutationId = evt.target.value;

            dispatch({
                type: "EPIRB_PROGRAMMED_ID_UPDATED",
                data: updatedSalutationId
            });
        },

        // required field validations
        onEpirbMakeChangeCompleted: (evt) => {
            var epirbMake = evt.target.value;

            if (epirbMake === "" || epirbMake === null || epirbMake.length === 0) {
                dispatch({
                    type: "EPIRB_MAKE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_MAKE_REQUIRED",
                    data: false
                });
            }
        },
        onEpirbModelChangeCompleted: (evt) => {
            var epirbModel = evt.target.value;

            if (epirbModel === "" || epirbModel === null || epirbModel.length === 0) {
                dispatch({
                    type: "EPIRB_MODEL_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_MODEL_REQUIRED",
                    data: false
                });
            }
        },
        onEpirbMountingChangeCompleted: (evt) => {
            var epirbMounting = evt.target.value;

            if (epirbMounting === "" || epirbMounting === null || epirbMounting.length === 0) {
                dispatch({
                    type: "EPIRB_MOUNTING_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_MOUNTING_REQUIRED",
                    data: false
                });
            }
        },
        onBatteryExpiryDateChangedCompleted: (evt) => {
            var updatedDate = evt.target.value;
            if (updatedDate === "" || updatedDate === null || updatedDate.length === 0) {
                dispatch({
                    type: "EPIRB_BATTERY_EXPIRY_DATE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_BATTERY_EXPIRY_DATE_REQUIRED",
                    data: false
                });
            }
        },
        onSerialNumberChangeCompleted: (evt) => {
            var serialNumber = evt.target.value;

            if (serialNumber.trim() === "") {
                dispatch({
                    type: "EPIRB_SERIAL_NUMBER_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_SERIAL_NUMBER_REQUIRED",
                    data: false
                });
            }
        },
        onEpirbGpsEnabledChangeCompleted: (evt) => {
            var gpsEnabled = evt.target.value;

            if (gpsEnabled === "" || gpsEnabled === null || gpsEnabled.length === 0) {
                dispatch({
                    type: "EPIRB_GPS_ENABLED_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_GPS_ENABLED_REQUIRED",
                    data: false
                });
            }
        },
        onAisMmsiNumberChangeCompleted: (evt) => {
            var updatedAisMmsiNumber = evt.target.value;

            if (updatedAisMmsiNumber.length > 0 && updatedAisMmsiNumber !== 974 && (updatedAisMmsiNumber < 974010000 || updatedAisMmsiNumber > 974999999)) {
                dispatch({
                    type: "EPIRB_AIS_MMSI_NUMBER_ERROR",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_AIS_MMSI_NUMBER_ERROR",
                    data: false
                });
            }
        },
        onHexCodeChangeCompleted: (evt) => {
            var hexCode = evt.target.value;
            var mmsiNumber = evt.target.dataset.mmsiNumber;
            var callSignNumber = evt.target.dataset.callsignNumber;
            var gpsEnabledId = evt.target.dataset.gpsEnabled;
            var id = evt.target.dataset.id;

            var lastChar = hexCode != null && hexCode.length > 1
                ? hexCode.slice(-1).toUpperCase()
                : "";
            var homingValue = lastChar === "1" || lastChar === "5" || lastChar === "9" || lastChar === "D"
                ? "1" //yes
                : "2"; //no      

            if (hexCode === "" || hexCode === null || hexCode.length === 0) {
                dispatch({
                    type: "EPIRB_HEX_CODE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_HEX_CODE_REQUIRED",
                    data: false
                });
            }

            //set homing value
            dispatch({
                type: "EPIRB_HOMING_ID_UPDATED",
                data: homingValue
            });

            // Call validation logic
            var res = validateHexCode(hexCode);

            if (!res.isValid) {
                dispatch({
                    type: "EPIRB_HEX_CODE_VALID",
                    data: {
                        isValid: res.isValid,
                        errorMessage: res.errorMessage
                    }
                });
                return;
            }

            if (res.callSignNumber !== "") {
                if (trim(res.callSignNumber) !== callSignNumber) {
                    dispatch({
                        type: "EPIRB_HEX_CODE_VALID",
                        data: {
                            isValid: false,
                            errorMessage: "The CallSign decoded from the Hex Code is " +
                                res.callSignNumber +
                                ". This does not match the Call Sign Number provided."
                        }
                    });
                    return;
                }
            } else if (`${res.country}${res.mmsiNumber}` !== mmsiNumber) {
                dispatch({
                    type: "EPIRB_HEX_CODE_VALID",
                    data: {
                        isValid: false,
                        errorMessage: "The MMSI decoded from the Hex Code is " +
                            res.country + res.mmsiNumber +
                            ". This does not match the MMSI provided."
                    }
                });
                return;
            }

            //all ok
            if (gpsEnabledId === "") {
                //only set gpsEnabled value if it has not already been selected
                var gpsEnabledValue = "2"; //no
                if (hexCode != null && hexCode.length > 3 && (hexCode.substr(0, 3).toUpperCase() === "9F4"
                    || hexCode.substr(0, 3).toUpperCase() === "1F4"
                    || hexCode.substr(0, 3).toUpperCase() === "1F5")) {
                    gpsEnabledValue = "1"; //yes
                }

                //set gps enabled value
                dispatch({
                    type: "EPIRB_GPS_ENABLED_ID_UPDATED",
                    data: gpsEnabledValue
                });

                dispatch({
                    type: "EPIRB_GPS_ENABLED_REQUIRED",
                    data: false
                });
            }

            dispatch({
                type: "EPIRB_HEX_CODE_VALID",
                data: {
                    isValid: true,
                    errorMessage: ""
                }
            });

            dispatch(CheckExistingHexcodeAction(hexCode, id));
            
        },
        onEpirbGpsEnabledChangeCompleted: (evt) => {
            var gpsEnabled = evt.target.value;

            if (gpsEnabled === "" || gpsEnabled === null || gpsEnabled.length === 0) {
                dispatch({
                    type: "EPIRB_GPS_ENABLED_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_GPS_ENABLED_REQUIRED",
                    data: false
                });
            }
        },
        onEpirbHomingChangeCompleted: (evt) => {
            var updatedFirstName = evt.target.value;

            if (updatedFirstName === "" || updatedFirstName === null || updatedFirstName.length === 0) {
                dispatch({
                    type: "EPIRB_HOMING_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "EPIRB_HOMING_REQUIRED",
                    data: false
                });
            }
        },
        deleteEPIRB: (id) => {

            //delete Epirb Detail
            dispatch(CreateDeleteEpirbAction(id));
            
        }
    }
}

const languageAwareComponent = withTranslation()(AddEpirbDetails);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);


function uniqByKeepFirst(a, key) {
    let seen = new Set();
    return a.filter(item => {
        let k = key(item);
        return seen.has(k) ? false : seen.add(k);
    });
}

//declare hex validation variables
var hexa = new Array(),
    bit = new Array(),
    bitadj = new Array(),
    bch1 = new Array(),
    bch2 = new Array(),
    gx = new Array(),
    gx2 = new Array(),
    locations = new Array(),
    myCountry = new Array();

//initialise values
myCountry['501'] = "Adelie Land"; myCountry['401'] = "Afghanistan"; myCountry['303'] = "Alaska";
myCountry['201'] = "Albania"; myCountry['605'] = "Algeria"; myCountry['559'] = "American Samoa";
myCountry['202'] = "Andorra"; myCountry['603'] = "Angola"; myCountry['301'] = "Anguilla";
myCountry['304'] = "Antigua"; myCountry['305'] = "Barbuda"; myCountry['701'] = "Argentine";
myCountry['216'] = "Armenia"; myCountry['307'] = "Aruba"; myCountry['608'] = "Ascension Island";
myCountry['503'] = "Australia"; myCountry['203'] = "Austria"; myCountry['423'] = "Azerbaijani";
myCountry['204'] = "Azores"; myCountry['308'] = "Bahamas"; myCountry['309'] = "Bahamas";
myCountry['311'] = "Bahamas"; myCountry['408'] = "Bahrain"; myCountry['405'] = "Bangladesh";
myCountry['314'] = "Barbados"; myCountry['206'] = "Belarus"; myCountry['205'] = "Belgium";
myCountry['312'] = "Belize"; myCountry['610'] = "Benin"; myCountry['310'] = "Bermuda";
myCountry['410'] = "Bhutan"; myCountry['720'] = "Bolivia"; myCountry['478'] = "Bosnia";
myCountry['478'] = "Herzegovina"; myCountry['611'] = "Botswana"; myCountry['710'] = "Brazil";
myCountry['378'] = "British Virgin Islands"; myCountry['508'] = "Brunei Darussalam"; myCountry['207'] = "Bulgaria";
myCountry['633'] = "Burkina Faso"; myCountry['609'] = "Burundi"; myCountry['514'] = "Cambodia"; myCountry['515'] = "Cambodia";
myCountry['613'] = "Cameroon"; myCountry['316'] = "Canada"; myCountry['617'] = "Cape Verde";
myCountry['319'] = "Cayman Islands"; myCountry['612'] = "Central African Republic"; myCountry['670'] = "Chad";
myCountry['725'] = "Chile"; myCountry['412'] = "China"; myCountry['413'] = "China";
myCountry['516'] = "Christmas Island"; myCountry['523'] = "Cocos"; myCountry['730'] = "Colombia";
myCountry['616'] = "Comoros"; myCountry['615'] = "Congo"; myCountry['518'] = "Cook Islands";
myCountry['321'] = "Costa Rica"; myCountry['619'] = "C te d'Ivoire"; myCountry['238'] = "Croatia";
myCountry['618'] = "Crozet Archipelago"; myCountry['323'] = "Cuba"; myCountry['209'] = "Cyprus";
myCountry['210'] = "Cyprus"; myCountry['212'] = "Cyprus"; myCountry['270'] = "Czech Republic";
myCountry['445'] = "Korea"; myCountry['676'] = "Congo"; myCountry['219'] = "Denmark";
myCountry['220'] = "Denmark"; myCountry['621'] = "Djibouti"; myCountry['325'] = "Dominica";
myCountry['327'] = "Dominican"; myCountry['735'] = "Ecuador"; myCountry['622'] = "Egypt";
myCountry['359'] = "El Salvador"; myCountry['631'] = "Equatorial Guinea"; myCountry['625'] = "Eritrea";
myCountry['276'] = "Estonia"; myCountry['624'] = "Ethiopia"; myCountry['740'] = "Falkland Islands";
myCountry['231'] = "Faroe Islands"; myCountry['520'] = "Fiji"; myCountry['230'] = "Finland";
myCountry['226'] = "France"; myCountry['227'] = "France"; myCountry['228'] = "France";
myCountry['546'] = "French Polynesia"; myCountry['626'] = "Gabonese Republic"; myCountry['629'] = "Gambia";
myCountry['213'] = "Georgia"; myCountry['211'] = "Germany"; myCountry['218'] = "Germany";
myCountry['627'] = "Ghana"; myCountry['236'] = "Gibraltar"; myCountry['237'] = "Greece"; myCountry['241'] = "Greece";
myCountry['239'] = "Greece"; myCountry['240'] = "Greece"; myCountry['331'] = "Greenland";
myCountry['330'] = "Grenada"; myCountry['329'] = "Guadeloupe"; myCountry['332'] = "Guatemala";
myCountry['745'] = "Guiana"; myCountry['630'] = "Guinea-Bissau"; myCountry['750'] = "Guyana";
myCountry['632'] = "Guinea"; myCountry['336'] = "Haiti"; myCountry['334'] = "Honduras";
myCountry['477'] = "Hong Kong"; myCountry['243'] = "Hungary"; myCountry['251'] = "Iceland";
myCountry['419'] = "India"; myCountry['525'] = "Indonesia"; myCountry['422'] = "Iran";
myCountry['425'] = "Iraq"; myCountry['250'] = "Ireland"; myCountry['428'] = "Israel";
myCountry['247'] = "Italy"; myCountry['339'] = "Jamaica"; myCountry['431'] = "Japan";
myCountry['432'] = "Japan"; myCountry['438'] = "Jordan"; myCountry['436'] = "Kazakhstan";
myCountry['634'] = "Kenya"; myCountry['635'] = "Kerguelen Islands"; myCountry['529'] = "Kiribati";
myCountry['440'] = "Korea"; myCountry['441'] = "Korea"; myCountry['447'] = "Kuwait";
myCountry['451'] = "Kyrgyz Republic"; myCountry['531'] = "Lao"; myCountry['275'] = "Latvia";
myCountry['450'] = "Lebanon"; myCountry['644'] = "Lesotho"; myCountry['636'] = "Liberia";
myCountry['637'] = "Liberia"; myCountry['252'] = "Liechtenstein"; myCountry['277'] = "Lithuania";
myCountry['253'] = "Luxembourg"; myCountry['453'] = "Macao"; myCountry['647'] = "Madagascar";
myCountry['255'] = "Madeira"; myCountry['655'] = "Malawi"; myCountry['533'] = "Malaysia";
myCountry['455'] = "Maldives"; myCountry['649'] = "Mali"; myCountry['215'] = "Malta";
myCountry['248'] = "Malta"; myCountry['249'] = "Malta"; myCountry['256'] = "Malta";
myCountry['538'] = "Marshall Islands"; myCountry['347'] = "Martinique"; myCountry['654'] = "Mauritania";
myCountry['645'] = "Mauritius"; myCountry['345'] = "Mexico"; myCountry['510'] = "Micronesia";
myCountry['214'] = "Moldova"; myCountry['254'] = "Monaco"; myCountry['457'] = "Mongolia";
myCountry['262'] = "Montenegro"; myCountry['348'] = "Montserrat"; myCountry['242'] = "Morocco";
myCountry['650'] = "Mozambique"; myCountry['506'] = "Myanmar"; myCountry['659'] = "Namibia";
myCountry['544'] = "Nauru"; myCountry['459'] = "Nepal"; myCountry['244'] = "Netherlands";
myCountry['245'] = "Netherlands"; myCountry['246'] = "Netherlands"; myCountry['306'] = "Netherlands Antilles";
myCountry['540'] = "New Caledonia"; myCountry['512'] = "New Zealand"; myCountry['350'] = "Nicaragua";
myCountry['656'] = "Niger"; myCountry['657'] = "Nigeria"; myCountry['542'] = "Niue";
myCountry['536'] = "Northern Mariana Islands"; myCountry['257'] = "Norway"; myCountry['258'] = "Norway";
myCountry['259'] = "Norway"; myCountry['461'] = "Oman"; myCountry['463'] = "Pakistan";
myCountry['511'] = "Palau"; myCountry['443'] = "Palestine"; myCountry[''] = "";
myCountry['351'] = "Panama"; myCountry['352'] = "Panama"; myCountry['353'] = "Panama";
myCountry['354'] = "Panama"; myCountry['355'] = "Panama"; myCountry['356'] = "Panama";
myCountry['357'] = "Panama"; myCountry['370'] = "Panama"; myCountry['371'] = "Panama"; myCountry['373'] = "Panama"; myCountry['374'] = "Panama";
myCountry['372'] = "Panama"; myCountry['553'] = "Papua New Guinea"; myCountry['755'] = "Paraguay ";
myCountry['760'] = "Peru"; myCountry['548'] = "Philippines"; myCountry['555'] = "Pitcairn Island";
myCountry['261'] = "Poland"; myCountry['263'] = "Portugal"; myCountry['358'] = "Puerto Rico";
myCountry['466'] = "Qatar"; myCountry['660'] = "Reunion"; myCountry['264'] = "Romania";
myCountry['273'] = "Russian Federation"; myCountry['661'] = "Rwanda"; myCountry['665'] = "Saint Helena";
myCountry['341'] = "Saint Kitts and Nevis"; myCountry['343'] = "Saint Lucia";
myCountry['607'] = "Saint Paul and Amsterdam Islands"; myCountry['361'] = "Saint Pierre and Miquelon";
myCountry['375'] = "Saint Vincent and the Grenadines"; myCountry['376'] = "Saint Vincent and the Grenadines";
myCountry['377'] = "Saint Vincent and the Grenadines"; myCountry['561'] = "Samoa"; myCountry['268'] = "San Marino";
myCountry['668'] = "Sao Tome and Principe"; myCountry['403'] = "Saudi Arabia"; myCountry['663'] = "Senegal";
myCountry['279'] = "Serbia"; myCountry['664'] = "Seychelles"; myCountry['667'] = "Sierra Leone";
myCountry['563'] = "Singapore";
myCountry['564'] = "Singapore";
myCountry['565'] = "Singapore";
myCountry['566'] = "Singapore";
myCountry['267'] = "Slovak Republic"; myCountry['278'] = "Slovenia";
myCountry['642'] = "Socialist People's Libyan Arab Jamahiriya";
myCountry['557'] = "Solomon Islands"; myCountry['666'] = "Somali Democratic Republic"; myCountry['601'] = "South Africa";
myCountry['224'] = "Spain"; myCountry['225'] = "Spain"; myCountry['417'] = "Sri Lanka";
myCountry['662'] = "Sudan"; myCountry['765'] = "Suriname"; myCountry['669'] = "Swaziland";
myCountry['265'] = "Sweden"; myCountry['266'] = "Sweden"; myCountry['269'] = "Switzerland";
myCountry['468'] = "Syrian Arab Republic"; myCountry['416'] = "Taiwan"; myCountry['674'] = "Tanzania";
myCountry['677'] = "Tanzania"; myCountry['567'] = "Thailand"; myCountry['274'] = "Macedonia";
myCountry['671'] = "Togolese"; myCountry['570'] = "Tonga"; myCountry['362'] = "Trinidad and Tobago";
myCountry['672'] = "Tunisia"; myCountry['271'] = "Turkey"; myCountry['434'] = "Turkmenistan";
myCountry['364'] = "Turks and Caicos Islands"; myCountry['572'] = "Tuvalu"; myCountry['675'] = "Uganda";
myCountry['272'] = "Ukraine"; myCountry['470'] = "United Arab Emirates";
myCountry['232'] = "United Kingdom of Great Britain and Northern Ireland";
myCountry['233'] = "United Kingdom of Great Britain and Northern Ireland";
myCountry['234'] = "United Kingdom of Great Britain and Northern Ireland";
myCountry['235'] = "United Kingdom of Great Britain and Northern Ireland";
myCountry['379'] = "United States Virgin Islands"; myCountry['338'] = "United States of America";
myCountry['366'] = "United States of America"; myCountry['368'] = "United States of America";
myCountry['367'] = "United States of America"; myCountry['369'] = "United States of America";
myCountry['770'] = "Uruguay"; myCountry['437'] = "Uzbekistan"; myCountry['576'] = "Vanuatu";
myCountry['208'] = "Vatican City State"; myCountry['775'] = "Venezuela"; myCountry['574'] = "Viet Nam";
myCountry['578'] = "Wallis and Futuna Islands"; myCountry['473'] = "Yemen"; myCountry['475'] = "Yemen";
myCountry['678'] = "Zambia"; myCountry['679'] = "Zimbabwe";

function trim(str, chars) {
    return ltrim(rtrim(str, chars), chars);
}

function ltrim(str, chars) {
    chars = chars || "\\s";
    return str.replace(new RegExp("^[" + chars + "]+", "g"), "");
}

function rtrim(str, chars) {
    chars = chars || "\\s";
    return str.replace(new RegExp("[" + chars + "]+$", "g"), "");
}

function convbin(length) {
    var count = 0;
    var chek = "ok";

    for (var i = 1; i <= length; i++) {
        count = 4 * (i - 1);

        hexa[i] = hexa[i].toUpperCase();

        switch (hexa[i]) {
            case "F":
                bit[count + 1] = "1";
                bit[count + 2] = "1";
                bit[count + 3] = "1";
                bit[count + 4] = "1";
                break;
            case "E":
                bit[count + 1] = "1";
                bit[count + 2] = "1";
                bit[count + 3] = "1";
                bit[count + 4] = "0";
                break;
            case "D":
                bit[count + 1] = "1";
                bit[count + 2] = "1";
                bit[count + 3] = "0";
                bit[count + 4] = "1";
                break;
            case "C":
                bit[count + 1] = "1";
                bit[count + 2] = "1";
                bit[count + 3] = "0";
                bit[count + 4] = "0";
                break;
            case "B":
                bit[count + 1] = "1";
                bit[count + 2] = "0";
                bit[count + 3] = "1";
                bit[count + 4] = "1";
                break;
            case "A":
                bit[count + 1] = "1";
                bit[count + 2] = "0";
                bit[count + 3] = "1";
                bit[count + 4] = "0";
                break;
            case "9":
                bit[count + 1] = "1";
                bit[count + 2] = "0";
                bit[count + 3] = "0";
                bit[count + 4] = "1";
                break;
            case "8":
                bit[count + 1] = "1";
                bit[count + 2] = "0";
                bit[count + 3] = "0";
                bit[count + 4] = "0";
                break;
            case "7":
                bit[count + 1] = "0";
                bit[count + 2] = "1";
                bit[count + 3] = "1";
                bit[count + 4] = "1";
                break;
            case "6":
                bit[count + 1] = "0";
                bit[count + 2] = "1";
                bit[count + 3] = "1";
                bit[count + 4] = "0";
                break;
            case "5":
                bit[count + 1] = "0";
                bit[count + 2] = "1";
                bit[count + 3] = "0";
                bit[count + 4] = "1";
                break;
            case "4":
                bit[count + 1] = "0";
                bit[count + 2] = "1";
                bit[count + 3] = "0";
                bit[count + 4] = "0";
                break;
            case "3":
                bit[count + 1] = "0";
                bit[count + 2] = "0";
                bit[count + 3] = "1";
                bit[count + 4] = "1";
                break;
            case "2":
                bit[count + 1] = "0";
                bit[count + 2] = "0";
                bit[count + 3] = "1";
                bit[count + 4] = "0";
                break;
            case "1":
                bit[count + 1] = "0";
                bit[count + 2] = "0";
                bit[count + 3] = "0";
                bit[count + 4] = "1";
                break;
            case "0":
                bit[count + 1] = "0";
                bit[count + 2] = "0";
                bit[count + 3] = "0";
                bit[count + 4] = "0";
                break;

        }
    }
    return (chek);
}

function adj15() {
    var iteration = 60;
    for (var i = 1; i <= iteration; i++) {
        bitadj[i + 25] = bit[i];
    }
}
function adj2230() {
    var iteration = 120;
    for (var i = 1; i <= iteration; i++) {
        bitadj[i + 24] = bit[i];
    }
}

function frmtchek() {
    return (bitadj[25] === "0")
        ? "short format"
        : "long format";
}

function concatbits(start, stop) {
    var concat = "";
    for (var i = start; i <= stop; i++) {
        concat = concat + bitadj[i];
    }
    return (concat);
}

function concatcalcbits(start, stop) {
    var concat = "";
    for (var i = start; i <= stop; i++) {
        concat = concat + bch1[i];
    }
    return (concat);
}

function concatcalcbits2(start, stop) {
    var concat = "";
    for (var i = start; i <= stop; i++) {
        concat = concat + bch2[i];
    }
    return (concat);
}

function sign(value) {
    var plusminus = "not yet assigned by sub";
    if (bitadj[value] === "0") { plusminus = "-"; }
    if (bitadj[value] === "1") { plusminus = "+"; }
    return (plusminus);
}


function calcbch1() {
    var i;
    gx = new Array();
    gx[0] = "1"; gx[1] = "0"; gx[2] = "0"; gx[3] = "1"; gx[4] = "1"; gx[5] = "0"; gx[6] = "1"; gx[7] = "1"; gx[8] = "0"; gx[9] = "1";
    gx[10] = "1"; gx[11] = "0"; gx[12] = "0"; gx[13] = "1"; gx[14] = "1"; gx[15] = "1"; gx[16] = "1"; gx[17] = "0"; gx[18] = "0"; gx[19] = "0";
    gx[20] = "1"; gx[21] = "1";
    for (i = 25; i <= 85; i++) {
        bch1[i] = bitadj[i];
    }
    for (i = 86; i <= 106; i++) {
        bch1[i] = "0";
    }
    for (i = 25; i <= 85; i++) {
        if (bch1[i] === "1") {
            for (var k = 0; k <= 21; k++) {
                if (bch1[i + k] === gx[k]) {
                    bch1[i + k] = "0";
                } else {
                    bch1[i + k] = "1";
                }
            }
        }
    }
}

function calcbch2() {
    var i;
    gx = new Array();
    gx2[0] = "1"; gx2[1] = "0"; gx2[2] = "1"; gx2[3] = "0"; gx2[4] = "1"; gx2[5] = "0"; gx2[6] = "0"; gx2[7] = "1"; gx2[8] = "1"; gx2[9] = "1";
    gx2[10] = "0"; gx2[11] = "0"; gx2[12] = "1";
    for (i = 107; i <= 132; i++) {
        bch2[i] = bitadj[i];
    }
    for (i = 133; i <= 144; i++) {
        bch2[i] = "0";
    }
    for (i = 107; i <= 132; i++) {
        if (bch2[i] === "1") {
            for (var k = 0; k <= 12; k++) {
                if (bch2[i + k] === gx2[k]) {
                    bch2[i + k] = "0";
                } else {
                    bch2[i + k] = "1";
                }
            }
        }
    }
}

function protchek() {
    return (bitadj[26] === "0")
        ? "Location Protocol"
        : "User";
}

function uchek3739() {
    var detailedprot = "Invalid";
    if ((bitadj[37] === "0") && (bitadj[38] === "1") && (bitadj[39] === "0")) { detailedprot = "Maritime User"; }
    if ((bitadj[37] === "1") && (bitadj[38] === "1") && (bitadj[39] === "0")) { detailedprot = "Radio Call Sign"; }
    if ((bitadj[37] === "0") && (bitadj[38] === "0") && (bitadj[39] === "1")) { detailedprot = "Aviation User"; }
    if ((bitadj[37] === "0") && (bitadj[38] === "1") && (bitadj[39] === "1")) { detailedprot = "Serial User"; }
    if ((bitadj[37] === "1") && (bitadj[38] === "1") && (bitadj[39] === "1")) { detailedprot = "Test User"; }
    if ((bitadj[37] === "0") && (bitadj[38] === "0") && (bitadj[39] === "0")) { detailedprot = "Orbitography"; }
    if ((bitadj[37] === "1") && (bitadj[38] === "0") && (bitadj[39] === "0")) { detailedprot = "National User"; }
    if ((bitadj[37] === "1") && (bitadj[38] === "0") && (bitadj[39] === "1")) { detailedprot = "Spare"; }
    return (detailedprot);
}

function michek107() {
    var suppuse = "not yet assigned by sub";
    if (bitadj[107] === "0") { suppuse = "Emergency Code Data Not Entered"; }
    if (bitadj[107] === "1") { suppuse = "Emergency Code Data Entered"; }
    return (suppuse);
}

function michek108() {
    var activationtype = "Not yet assigned by sub";
    if (bitadj[108] == "0") { activationtype = "Manual Activation Only"; }
    if (bitadj[108] == "1") { activationtype = "Automatic and Manual Activation"; }
    return (activationtype);
}

function convhex(start, stop) {
    var hexvalue = "";
    for (var i = start; i <= stop; i = i + 4) {
        switch (concatbits(i, i + 3)) {
            case "0000":
                hexvalue = hexvalue + "0";
                break;
            case "0001":
                hexvalue = hexvalue + "1";
                break;
            case "0010":
                hexvalue = hexvalue + "2";
                break;
            case "0011":
                hexvalue = hexvalue + "3";
                break;
            case "0100":
                hexvalue = hexvalue + "4";
                break;
            case "0101":
                hexvalue = hexvalue + "5";
                break;
            case "0110":
                hexvalue = hexvalue + "6";
                break;
            case "0111":
                hexvalue = hexvalue + "7";
                break;
            case "1000":
                hexvalue = hexvalue + "8";
                break;
            case "1001":
                hexvalue = hexvalue + "9";
                break;
            case "1010":
                hexvalue = hexvalue + "A";
                break;
            case "1011":
                hexvalue = hexvalue + "B";
                break;
            case "1100":
                hexvalue = hexvalue + "C";
                break;
            case "1101":
                hexvalue = hexvalue + "D";
                break;
            case "1110":
                hexvalue = hexvalue + "E";
                break;
            case "1111":
                hexvalue = hexvalue + "F";
                break;
        }
    }
    return (hexvalue);
}


function decimalvalue(start, stop) {
    var returnValue = 0;
    var initexp = stop - start;
    for (var i = start; i <= stop; i++) {
        if (bitadj[i] === "1") {
            returnValue = returnValue + Math.pow(2, initexp);
        }
        initexp = initexp - 1;
    }
    return (returnValue);
}

function getCountryName(countryNumber) {

    for (var key in myCountry) {
        if (Object.prototype.hasOwnProperty.call(myCountry, key)) {
            if (parseInt(key) === countryNumber) {
                return myCountry[key];
            } else {
                continue;
            }
        } else continue;
    }
    return "N/A";
}

function getBaudotValue(char1, char2, char3, char4, char5, char6) {

    if ((char1 === "1") && (char2 === "1") && (char3 === "1") && (char4 === "0") && (char5 === "0") && (char6 === "0")) {
        return "A";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "0") && (char4 === "0") && (char5 === "1") && (char6 === "1")) {
        return "B";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "1") && (char4 === "1") && (char5 === "1") && (char6 === "0")) {
        return "C";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "0") && (char4 === "0") && (char5 === "1") && (char6 === "0")) {
        return "D";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "0") && (char4 === "0") && (char5 === "0") && (char6 === "0")) {
        return "E";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "0") && (char4 === "1") && (char5 === "1") && (char6 === "0")) {
        return "F";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "1") && (char4 === "0") && (char5 === "1") && (char6 === "1")) {
        return "G";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "0") && (char4 === "1") && (char5 === "0") && (char6 === "1")) {
        return "H";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "1") && (char4 === "1") && (char5 === "0") && (char6 === "0")) {
        return "I";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "1") && (char4 === "0") && (char5 === "1") && (char6 === "0")) {
        return "J";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "1") && (char4 === "1") && (char5 === "1") && (char6 === "0")) {
        return "K";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "1") && (char4 === "0") && (char5 === "0") && (char6 === "1")) {
        return "L";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "0") && (char4 === "1") && (char5 === "1") && (char6 === "1")) {
        return "M";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "0") && (char4 === "1") && (char5 === "1") && (char6 === "0")) {
        return "N";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "0") && (char4 === "0") && (char5 === "1") && (char6 === "1")) {
        return "O";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "1") && (char4 === "1") && (char5 === "0") && (char6 === "1")) {
        return "P";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "1") && (char4 === "1") && (char5 === "0") && (char6 === "1")) {
        return "Q";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "1") && (char4 === "0") && (char5 === "1") && (char6 === "0")) {
        return "R";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "0") && (char4 === "1") && (char5 === "0") && (char6 === "0")) {
        return "S";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "0") && (char4 === "0") && (char5 === "0") && (char6 === "1")) {
        return "T";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "1") && (char4 === "1") && (char5 === "0") && (char6 === "0")) {
        return "U";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "1") && (char4 === "1") && (char5 === "1") && (char6 === "1")) {
        return "V";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "1") && (char4 === "0") && (char5 === "0") && (char6 === "1")) {
        return "W";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "0") && (char4 === "1") && (char5 === "1") && (char6 === "1")) {
        return "X";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "0") && (char4 === "1") && (char5 === "0") && (char6 === "1")) {
        return "Y";
    }
    if ((char1 === "1") && (char2 === "1") && (char3 === "0") && (char4 === "0") && (char5 === "0") && (char6 === "1")) {
        return "Z";
    }
    if ((char1 === "1") && (char2 === "0") && (char3 === "0") && (char4 === "1") && (char5 === "0") && (char6 === "0")) {
        return " ";
    }
    if ((char1 === "0") && (char2 === "1") && (char3 === "1") && (char4 === "0") && (char5 === "0") && (char6 === "0")) {
        return "-";
    }
    if ((char1 === "0") && (char2 === "1") && (char3 === "0") && (char4 === "1") && (char5 === "1") && (char6 === "1")) {
        return "/";
    }
    if ((char1 === "0") && (char2 === "0") && (char3 === "1") && (char4 === "1") && (char5 === "0") && (char6 === "1")) {
        return "0";
    }
    if ((char1 === "0") && (char2 === "1") && (char3 === "1") && (char4 === "1") && (char5 === "0") && (char6 === "1")) {
        return "1";
    }
    if ((char1 === "0") && (char2 === "1") && (char3 === "1") && (char4 === "0") && (char5 === "0") && (char6 === "1")) {
        return "2";
    }
    if ((char1 === "0") && (char2 === "1") && (char3 === "0") && (char4 === "0") && (char5 === "0") && (char6 === "0")) {
        return "3";
    }
    if ((char1 === "0") && (char2 === "0") && (char3 === "1") && (char4 === "0") && (char5 === "1") && (char6 === "0")) {
        return "4";
    }
    if ((char1 === "0") && (char2 === "0") && (char3 === "0") && (char4 === "0") && (char5 === "0") && (char6 === "1")) {
        return "5";
    }
    if ((char1 === "0") && (char2 === "1") && (char3 === "0") && (char4 === "1") && (char5 === "0") && (char6 === "1")) {
        return "6";
    }
    if ((char1 === "0") && (char2 === "1") && (char3 === "1") && (char4 === "1") && (char5 === "0") && (char6 === "0")) {
        return "7";
    }
    if ((char1 === "0") && (char2 === "0") && (char3 === "1") && (char4 === "1") && (char5 === "0") && (char6 === "0")) {
        return "8";
    }
    if ((char1 === "0") && (char2 === "0") && (char3 === "0") && (char4 === "0") && (char5 === "1") && (char6 === "1")) {
        return "9";
    }
    return "?";
}

function baudot(start, stop) {
    var midvar = "";
    stop = stop - 5;
    for (var i = start; i <= stop; i = i + 6) {
        midvar = midvar + getBaudotValue(bitadj[i], bitadj[i + 1], bitadj[i + 2], bitadj[i + 3], bitadj[i + 4], bitadj[i + 5]);
    }
    return (midvar);
}

function baudotshort(start, stop) {
    var midvar = "";
    stop = stop - 4;
    for (var i = start; i <= stop; i = i + 5) {
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "0")) { midvar = midvar + "A"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "1")) { midvar = midvar + "B"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "0")) { midvar = midvar + "C"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "0")) { midvar = midvar + "D"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "0")) { midvar = midvar + "E"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "0")) { midvar = midvar + "F"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "1")) { midvar = midvar + "G"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "1")) { midvar = midvar + "H"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "0")) { midvar = midvar + "I"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "0")) { midvar = midvar + "J"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "0")) { midvar = midvar + "K"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "1")) { midvar = midvar + "L"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "1")) { midvar = midvar + "M"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "0")) { midvar = midvar + "N"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "1")) { midvar = midvar + "O"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "1")) { midvar = midvar + "P"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "1")) { midvar = midvar + "Q"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "0")) { midvar = midvar + "R"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "0")) { midvar = midvar + "S"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "1")) { midvar = midvar + "T"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "0")) { midvar = midvar + "U"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "1")) { midvar = midvar + "V"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "1")) { midvar = midvar + "W"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "1") && (bitadj[i + 4] == "1")) { midvar = midvar + "X"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "1")) { midvar = midvar + "Y"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "1")) { midvar = midvar + "Z"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0") && (bitadj[i + 4] == "0")) { midvar = midvar + " "; }
    }
    return (midvar);
}



function bincodedec(start, stop) {
    var midvar = "";
    stop = stop - 3;
    for (var i = start; i <= stop; i = i + 4) {
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "0")) { midvar = midvar + "0"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "1")) { midvar = midvar + "1"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0")) { midvar = midvar + "2"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "1")) { midvar = midvar + "3"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "0")) { midvar = midvar + "4"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "1")) { midvar = midvar + "5"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0")) { midvar = midvar + "6"; }
        if ((bitadj[i] == "0") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "1")) { midvar = midvar + "7"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "0")) { midvar = midvar + "8"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "1")) { midvar = midvar + "9"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0")) { midvar = midvar + "ERROR"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "0") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "1")) { midvar = midvar + "ERROR"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "0")) { midvar = midvar + "ERROR"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "0") && (bitadj[i + 3] == "1")) { midvar = midvar + "ERROR"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "0")) { midvar = midvar + "ERROR"; }
        if ((bitadj[i] == "1") && (bitadj[i + 1] == "1") && (bitadj[i + 2] == "1") && (bitadj[i + 3] == "1")) { midvar = midvar + "ERROR"; }
    }
    return (midvar);
}

function micheck8485() {
    var auxiliarydevice = "not yet assigned by sub routine";
    if ((bitadj[84] === "0") && bitadj[85] === "0") { auxiliarydevice = "No Auxiliary Radio-locating Device"; }
    if ((bitadj[84] === "0") && bitadj[85] === "1") { auxiliarydevice = "121.5 MHz"; }
    if ((bitadj[84] === "1") && bitadj[85] === "0") { auxiliarydevice = "9 GHZ SART"; }
    if ((bitadj[84] === "1") && bitadj[85] === "1") { auxiliarydevice = "Other"; }
    return (auxiliarydevice);
}

function michek109112() {
    var maremergcode = "not yet assigned by sub";
    if ((bitadj[109] === "0") && (bitadj[110] === "0") && (bitadj[111] === "0") && (bitadj[112] === "1")) { maremergcode = "Fire/Explosion"; }
    if ((bitadj[109] === "0") && (bitadj[110] === "0") && (bitadj[111] === "1") && (bitadj[112] === "0")) { maremergcode = "Flooding"; }
    if ((bitadj[109] === "0") && (bitadj[110] === "0") && (bitadj[111] === "1") && (bitadj[112] === "1")) { maremergcode = "Collision"; }
    if ((bitadj[109] === "0") && (bitadj[110] === "1") && (bitadj[111] === "0") && (bitadj[112] === "0")) { maremergcode = "Grounding"; }
    if ((bitadj[109] === "0") && (bitadj[110] === "1") && (bitadj[111] === "0") && (bitadj[112] === "1")) { maremergcode = "Listing, Danger of Capsizing"; }
    if ((bitadj[109] === "0") && (bitadj[110] === "1") && (bitadj[111] === "1") && (bitadj[112] === "0")) { maremergcode = "Sinking"; }
    if ((bitadj[109] === "0") && (bitadj[110] === "1") && (bitadj[111] === "1") && (bitadj[112] === "1")) { maremergcode = "Disabled and Adrift"; }
    if ((bitadj[109] === "0") && (bitadj[110] === "0") && (bitadj[111] === "0") && (bitadj[112] === "0")) { maremergcode = "Unspecified Distress"; }
    if ((bitadj[109] === "1") && (bitadj[110] === "0") && (bitadj[111] === "0") && (bitadj[112] === "0")) { maremergcode = "Abandoning Ship"; }
    return (maremergcode);
}

function chekblank(digits, end) {
    var blanks = 0;
    for (var i = 1; i <= digits; i = i + 1) {
        if ((bitadj[end - 3] === "1") && (bitadj[end - 2] === "0") && (bitadj[end - 1] === "1") && (bitadj[end] === "0")) {
            blanks = blanks + 1;
        } else {
            return (blanks);
        }
        end = end - 4;
    }
    return (blanks);
}

function nonmar109() {
    switch (bitadj[109]) {
        case "0":
            return "No Fire";
        case "1":
            return "Fire";
        default:
            return "not yet assigned by sub";
    }
}
function nonmar110() {
    switch (bitadj[110]) {
        case "0":
            return "No Medical Help Required";
        case "1":
            return "Medical Help Required";
        default:
            return "not yet assigned by sub";
    }
}
function nonmar111() {
    switch (bitadj[111]) {
        case "0":
            return "Not Disabled";
        case "1":
            return "Disabled";
        default:
            return "not yet assigned by sub";
    }
}

function uchek4042() {
    var serident = "Checking in subroutine to determine type of serial protocol";
    if ((bitadj[40] == "0") && (bitadj[41] == "0") && (bitadj[42] == "0")) { serident = "ELT with Serial Identification"; }
    if ((bitadj[40] == "0") && (bitadj[41] == "0") && (bitadj[42] == "1")) { serident = "ELT with Aircraft Operator Designator & Serial Number"; }
    if ((bitadj[40] == "0") && (bitadj[41] == "1") && (bitadj[42] == "0")) { serident = "Float Free EPIRB with Serial Identification Number"; }
    if ((bitadj[40] == "1") && (bitadj[41] == "0") && (bitadj[42] == "0")) { serident = "Non Float Free EPIRB with Serial Identification"; }
    if ((bitadj[40] == "1") && (bitadj[41] == "1") && (bitadj[42] == "0")) { serident = "PLB with Serial Identification Number"; }
    if ((bitadj[40] == "0") && (bitadj[41] == "1") && (bitadj[42] == "1")) { serident = "ELT with Aircraft 24-bit Address"; }
    if ((bitadj[40] == "1") && (bitadj[41] == "0") && (bitadj[42] == "1")) { serident = "Spare"; }
    if ((bitadj[40] == "1") && (bitadj[41] == "1") && (bitadj[42] == "1")) { serident = "Spare"; }
    return (serident);
}
function uchek43() {
    var csnat = "sub routine does not yet know";
    if (bitadj[43] == "0") { csnat = "No"; }
    if (bitadj[43] == "1") { csnat = "Yes"; }
    return (csnat);
}

function intextchek(databitposition) {
    var value = "Not Yet Assigned by Sub";
    if (bitadj[databitposition] == "1") { value = "Encoded Position Data Source From Internal Navigation Device"; }
    if (bitadj[databitposition] == "0") { value = "Encoded Position Data Source From External Navigation Device"; }
    return (value);
}


function northsouthchek(databitposition) {

    switch (bitadj[databitposition]) {
        case "0":
            return "North";
        case "1":
            return "South";
        default:
            return "Not Yet Assigned by Sub";
    }
}

function eastwestchek(databitposition) {
    var value = "Not Yet Assigned by Sub";
    if (bitadj[databitposition] == "0") { value = "East"; }
    if (bitadj[databitposition] == "1") { value = "West"; }
    return (value);
}

function userlocation() {
    locations[0] = northsouthchek(108);
    locations[1] = decimalvalue(109, 115);//lat in degrees
    locations[2] = 4 * decimalvalue(116, 119);// lat minutes
    locations[3] = eastwestchek(120);
    locations[4] = decimalvalue(121, 128);//long in degrees
    locations[5] = 4 * decimalvalue(129, 132);//long minutes

    if ((bitadj[108] == "0") && (bitadj[109] == "1") && (bitadj[110] == "1") && (bitadj[111] == "1") && (bitadj[112] == "1") && (bitadj[113] == "1") && (bitadj[114] == "1") && (bitadj[115] == "1") && (bitadj[116] == "0") && (bitadj[117] == "0") && (bitadj[118] == "0") && (bitadj[119] == "0")) {
        locations[0] = "default";
        locations[1] = "default";
        locations[2] = "default";
    }

    if ((bitadj[120] == "0") && (bitadj[121] == "1") && (bitadj[122] == "1") && (bitadj[123] == "1") && (bitadj[124] == "1") && (bitadj[125] == "1") && (bitadj[126] == "1") && (bitadj[127] == "1") && (bitadj[128] == "1") && (bitadj[129] == "0") && (bitadj[130] == "0") && (bitadj[131] == "0") && (bitadj[132] == "0")) {
        locations[3] = "default";
        locations[4] = "default";
        locations[5] = "default";
    }
}

function locprottype() {
    var prottype = "Invalid";
    if ((bitadj[37] == "0") && (bitadj[38] == "0") && (bitadj[39] == "1") && (bitadj[40] == "0")) { prottype = "Standard Location - EPIRB (MMSI)"; }
    if ((bitadj[37] == "0") && (bitadj[38] == "0") && (bitadj[39] == "1") && (bitadj[40] == "1")) { prottype = "Standard Location - ELT (24-bit Address)"; }
    if ((bitadj[37] == "0") && (bitadj[38] == "1") && (bitadj[39] == "0") && (bitadj[40] == "0")) { prottype = "Standard Location - ELT (Serial)"; }
    if ((bitadj[37] == "0") && (bitadj[38] == "1") && (bitadj[39] == "0") && (bitadj[40] == "1")) { prottype = "Standard Location - ELT (Aircraft Operator Designator)"; }
    if ((bitadj[37] == "0") && (bitadj[38] == "1") && (bitadj[39] == "1") && (bitadj[40] == "0")) { prottype = "Standard Location - EPIRB (Serial)"; }
    if ((bitadj[37] == "0") && (bitadj[38] == "1") && (bitadj[39] == "1") && (bitadj[40] == "1")) { prottype = "Standard Location - PLB (Serial)"; }
    if ((bitadj[37] == "1") && (bitadj[38] == "0") && (bitadj[39] == "0") && (bitadj[40] == "0")) { prottype = "National Location - ELT"; }
    if ((bitadj[37] == "1") && (bitadj[38] == "0") && (bitadj[39] == "0") && (bitadj[40] == "1")) { prottype = "National Location - Spare"; }
    if ((bitadj[37] == "1") && (bitadj[38] == "0") && (bitadj[39] == "1") && (bitadj[40] == "0")) { prottype = "National Location - EPIRB"; }
    if ((bitadj[37] == "1") && (bitadj[38] == "0") && (bitadj[39] == "1") && (bitadj[40] == "1")) { prottype = "National Location - PLB"; }
    if ((bitadj[37] == "1") && (bitadj[38] == "1") && (bitadj[39] == "1") && (bitadj[40] == "0")) { prottype = "Standard Location - Test"; }
    if ((bitadj[37] == "1") && (bitadj[38] == "1") && (bitadj[39] == "1") && (bitadj[40] == "1")) { prottype = "National Location - Test"; }
    if ((bitadj[37] == "0") && (bitadj[38] == "0") && (bitadj[39] == "0") && (bitadj[40] == "0")) { prottype = "Invalid"; }
    if ((bitadj[37] == "0") && (bitadj[38] == "0") && (bitadj[39] == "0") && (bitadj[40] == "1")) { prottype = "Invalid"; }
    if ((bitadj[37] == "1") && (bitadj[38] == "1") && (bitadj[39] == "0") && (bitadj[40] == "0")) { prottype = "Ship Security"; }
    if ((bitadj[37] == "1") && (bitadj[38] == "1") && (bitadj[39] == "0") && (bitadj[40] == "1")) { prottype = "RLS Location Protocol"; } //new protocol May 2024
    return (prottype);
}

function homerlocationprot() {
    var homer = "Not yet assigned by sub";
    if (bitadj[112] == "1") { homer = "121.5 MHz homer"; } else { homer = "No 121.5 MHz homer"; }
    return (homer);
}

function standardlocation() {
    var latpdf1 = concatbits(65, 74); //default for latitude in pdf1
    var latpdf2 = concatbits(113, 122); //default for latititude in pdf2
    var longpdf1 = concatbits(75, 85); //default for longtitude in pdf1
    var longpdf2 = concatbits(123, 132);

    locations[0] = northsouthchek(65); if (latpdf1 === "0111111111") { locations[0] = "default"; }//
    locations[1] = decimalvalue(66, 72); if (latpdf1 === "0111111111") { locations[1] = "default"; }//latitude in degrees PDF-1
    locations[2] = 15 * decimalvalue(73, 74); if (latpdf1 === "0111111111") { locations[2] = "default"; } //latitude in minutes PDF-1
    locations[3] = decimalvalue(114, 118); if (latpdf2 === "1000001111") { locations[3] = "default"; }//latitude offset minutes PDF-2
    locations[4] = 4 * decimalvalue(119, 122); if (latpdf2 === "1000001111") { locations[4] = "default"; }//latitude offset seconds PDF-2
    locations[5] = eastwestchek(75); if (longpdf1 === "01111111111") { locations[5] = "default"; }
    locations[6] = decimalvalue(76, 83); if (longpdf1 === "01111111111") { locations[6] = "default"; } //longtitude in degrees PDF-1
    locations[7] = 15 * decimalvalue(84, 85); if (longpdf1 === "01111111111") { locations[7] = "default"; } //longtitude in minutes PDF-1
    locations[8] = decimalvalue(124, 128); if (longpdf2 === "1000001111") { locations[8] = "default"; }// longtitude offset in minutes
    locations[9] = 4 * decimalvalue(129, 132); if (longpdf2 === "1000001111") { locations[9] = "default"; }// longitude offset in seconds
}

function shiftchekstd() {
    return (bitadj[107] === "1") && (bitadj[108] === "1") && (bitadj[109] === "0") && (bitadj[110] === "1")
        ? "Pass"
        : "Fail";
}

function shiftcheknat() {
    return (bitadj[107] === "1") && (bitadj[108] === "1") && (bitadj[109] === "0")
        ? "Pass"
        : "Fail";
}

function nationallocation() {
    var latpdf1 = concatbits(59, 71); //default for latitude in pdf1
    var latpdf2 = concatbits(113, 119); //default for latititude in pdf2
    var longpdf1 = concatbits(72, 85); //default for longtitude in pdf1
    var longpdf2 = concatbits(120, 126);

    locations[0] = northsouthchek(59); if (latpdf1 === "0111111100000") { locations[0] = "default"; }//
    locations[1] = decimalvalue(60, 66); if (latpdf1 === "0111111100000") { locations[1] = "default"; }//latitude in degrees PDF-1
    locations[2] = 2 * decimalvalue(67, 71); if (latpdf1 === "0111111100000") { locations[2] = "default"; } //latitude in minutes PDF-1
    locations[3] = decimalvalue(114, 115); if (latpdf2 === "1001111") { locations[3] = "default"; }//latitude offset minutes PDF-2
    locations[4] = 4 * decimalvalue(116, 119); if (latpdf2 === "1001111") { locations[4] = "default"; }//latitude offset seconds PDF-2
    locations[5] = eastwestchek(72); if (longpdf1 === "01111111100000") { locations[5] = "default"; }
    locations[6] = decimalvalue(73, 80); if (longpdf1 === "01111111100000") { locations[6] = "default"; } //longtitude in degrees PDF-1
    locations[7] = 2 * decimalvalue(81, 85); if (longpdf1 === "01111111100000") { locations[7] = "default"; } //longtitude in minutes PDF-1
    locations[8] = decimalvalue(121, 122); if (longpdf2 === "1001111") { locations[8] = "default"; }// longtitude offset in minutes
    locations[9] = 4 * decimalvalue(123, 126); if (longpdf2 === "1001111") { locations[9] = "default"; }// longitude offset in seconds
}



function validateHexCode(hexCodeToBeValidated) {


    var hex24bit = "Not yet assigned";//convert back to hex from bin
    //var encbch1 = "Not yet assigned";
    //var calculatedbch1 = "Not yet assigned";

    var hex = trim(hexCodeToBeValidated);
    var len = hexCodeToBeValidated.length;
    var format = "Not provided in 15 hex id";
    var protocol = "Protocol Unknown";
    var country = 99999;
    var countryName = "";
    var protdetailed = "Unknown detailed prot";
    var callsign4char = "Unknown detailed prot4";
    var callsign3char = "Unknown detailed prot3";
    var serident = "Serial User Ident not determined";
    var csornat = "Unknown whether the serial number was assigned by C-S or nationally";
    var mid = "Not yet assigned";
    var specbcnum = "not yet assigned";
    var auxdevice = "not yet assigned";
    var supuse = "not yet assigned";
    var emergcode = "Nationally assigned";
    var activtype = "Not yet assigned";
    var aircraftid = "Not yet assigned";
    var enonmar109 = "Not yet assigned";
    var enonmar110 = "Not yet assigned";
    var enonmar111 = "Not yet assigned";
    var serialnumber = "Not yet assigned";
    var csnumber = "Not yet assigned";
    var numberofblank = "Not yet assigned";
    var numberofelts = "Not yet assigned";
    var eltop3desig = "Not yet assigned";
    var intext = "Not yet determined if navigation device is internal or external";
    var locationprotocol = "Not yet assigned";
    var number24bit = "Not yet assigned";
    var addflag;
    var convertchek;
    var bitshift = "Not yet assigned";
    var i;

    // call validation stuff
    if (len !== 15 && len !== 22 && len !== 30) {
        return {
            isValid: false,
            errorMessage: "Invalid Hex length. Number of characters entered was not correct."
        };
    }

    //setup hex array
    for (i = 1; i <= len; i++) {
        hexa[i] = hex.charAt(i - 1);
    }

    //convert hex array to binary array
    convertchek = convbin(len);
    if (convertchek !== "ok") {
        return {
            isValid: false,
            errorMessage: "Invalid Hex Character(s)"
        };
    }

    // adjust bit positions for either 15 hex or for 22/30 as appropriate
    if (len === 15) {
        adj15();
    }
    else {
        adj2230();
        format = frmtchek();
    }

    calcbch1();
    calcbch2();
    protocol = protchek();
    country = decimalvalue(27, 36);

    if (country !== 250) {
        return {
            isValid: false,
            errorMessage: "Invalid Country Code"
        };
    }

    countryName = getCountryName(country);

    var mmsiNumber, item4, bits4, value4,
        item5, bits5, value5,
        item6, bits6, value6,
        item7, bits7, value7,
        item8, bits8, value8,
        item9, bits9, value9,
        item10, bits10, value10,
        item11, bits11, value11,
        item12, bits12, value12,
        item13, bits13, value13,
        item14, bits14, value14,
        item15, bits15, value15,
        item16, bits16, value16,
        item17, bits17, value17,
        item18, bits18, value18,
        item19, bits19, value19,
        item20, bits20, value20,
        item21, bits21, value21,
        item22, bits22, value22,
        item23, bits23, value23,
        item24, bits24, value24,
        item25, bits25, value25,
        item26, bits26, value26,
        item27, bits27, value27,
        item28, bits28, value28;
    var callSignNumber = "";

    if (protocol === "User") {
        protdetailed = uchek3739();//
        supuse = michek107(); //  checks is sup data is national or assigned use
        activtype = michek108(); //  checks is sup data is national or assigned use
        item4 = "User type: " + protdetailed;
        bits4 = "37-39";
        value4 = concatbits(37, 39);

        //National User - National User - National User
        if (protdetailed === "National User") {
            item5 = "National Use";
            bits5 = "40-85";
            value5 = concatbits(40, 85);

            item6 = "15 Hex ID: ";
            bits6 = "N/A";
            value6 = convhex(26, 85);

            if (len === 22 || len === 30) {
                item6 = "Encoded BCH 1: ";
                bits6 = "86-106";
                value6 = concatbits(86, 106);

                item7 = "Calculated BCH 1: ";
                bits7 = "N/A";
                value7 = concatcalcbits(86, 106);

                item8 = "National Use";
                bits8 = "107-112";
                value8 = concatbits(107, 112);

                item9 = "15 Hex ID: ";
                bits9 = "N/A";
                value9 = convhex(26, 85);
            }

            if (len === 30) {

                if (format === "short format" && concatbits(113, 144) === "00000000000000000000000000000000") {
                    item9 = "Assigned by LUT correctly";
                    bits9 = "113-144";
                    value9 = concatbits(113, 144);

                    item10 = "15 Hex ID: ";
                    bits10 = "N/A";
                    value10 = convhex(26, 85);
                }
                else {
                    item9 = "Invalid: ";
                    bits9 = "113-114";
                    value9 = concatbits(113, 144);

                    item10 = "15 Hex ID: ";
                    bits10 = "N/A";
                    value10 = convhex(26, 85);
                }


                if (format === "long format") {
                    if (concatbits(113, 144) === "11111111111111111111111111111111") {
                        item9 = "Assigned by LUT correctly";
                        bits9 = "113-144";
                        value9 = concatbits(113, 144);

                        item10 = "15 Hex ID: ";
                        bits10 = "N/A";
                        value10 = convhex(26, 85);
                    }
                    else {
                        item9 = "National Use";
                        bits9 = "113-132";
                        value9 = concatbits(113, 132);

                        item10 = "BCH 2 Encoded: ";
                        bits10 = "133-144";
                        value10 = concatbits(133, 144);

                        item11 = "BCH 2 Calculated: ";
                        bits11 = "N/A";
                        value11 = concatcalcbits2(133, 144);

                        item12 = "15 Hex ID: ";
                        bits12 = "N/A";
                        value12 = convhex(26, 85);
                    }
                }//end of long

            }//end of charrs 30
        }//end of national user



        //Maritime User -  Maritime User - Maritime User
        if (protdetailed === "Maritime User") {
            mid = baudot(40, 75); //       decodes the mid for the maritime user protocol
            specbcnum = baudot(76, 81); //  decodes the specific beacon number maritime or radio callsign user protocol
            auxdevice = micheck8485(); //  decodes the auxilary radio device
            if (supuse === "Emergency Code Data Entered") {
                emergcode = michek109112();
            } //  checks for maritime emergency code
            if ((bitadj[40] === "0") && (bitadj[46] === "0") && (bitadj[52] === "0") && (bitadj[58] === "0") && (bitadj[64] === "0") && (bitadj[70] === "0") && (bitadj[70] === "0")) {
                item5 = "Maritime MMSI (6 digits):  " + mid;
                bits5 = "40-75";
                value5 = concatbits(40, 75);
                mmsiNumber = mid;
            } else {
                item5 = "Radio Call Sign (6 digits):  " + mid;
                bits5 = "40-75";
                value5 = concatbits(40, 75);
                callSignNumber = mid;
            }
            item6 = "Specific bcn:  " + specbcnum;
            bits6 = "76-81";
            value6 = concatbits(76, 81);
            item7 = "Spare ";
            bits7 = "82-83";
            value7 = concatbits(82, 83);
            item8 = "Aux radio device:  " + auxdevice;
            bits8 = "84-85";
            value8 = concatbits(84, 85);
            if ((len > 15) && (format === "short format")) {
                item9 = "Encoded BCH 1: ";
                bits9 = "86-106";
                value9 = concatbits(86, 106);
                item10 = "Calculated BCH 1: ";
                bits10 = "N/A";
                value10 = concatcalcbits(86, 106);
                item11 = "Emerg Code: " + supuse;
                bits11 = "107";
                value11 = concatbits(107, 107);
                item12 = "Activation Type: " + activtype;
                bits12 = "108";
                value12 = concatbits(108, 108);
                if (supuse === "Emergency Code Data Entered") {
                    item13 = "Emergency Code: " + emergcode;
                    bits13 = "109-112";
                    value13 = concatbits(109, 112);
                } else {
                    item13 = "Emergency Code: No information entered or " + emergcode;
                    bits13 = "109-112";
                    value13 = concatbits(109, 112);
                }
                if (len === 30) {
                    if (concatbits(113, 144) === "00000000000000000000000000000000") {
                        item14 = "Assigned by LUT correctly";
                        bits14 = "113-144";
                        value14 = concatbits(113, 144);
                    }
                    else {
                        item14 = "Non Standard";
                        bits14 = "113-144";
                        value14 = concatbits(113, 144);
                    }
                }
            }//short message 22 hex representation
            item15 = "15 Hex ID: ";
            bits15 = "N/A";
            value15 = convhex(26, 85);
        }//end maritime user


        // Radio Call Sign - Radio Call Sign - Radio Call Sign
        if (protdetailed === "Radio Call Sign") {
            callsign4char = baudot(40, 63); //       decodes first four characters of radio call sign
            numberofblank = chekblank(3, 75);//      calculates the number of blanks for (numbr of digits,end bit)
            switch (numberofblank) {
                case 3:
                    callsign3char = "";
                    break;
                case 0:
                    callsign3char = bincodedec(64, 75);
                    break;
                case 1:
                    callsign3char = bincodedec(64, 71);
                    break;
                case 2:
                    callsign3char = bincodedec(64, 67);
                    break;
            }

            specbcnum = baudot(76, 81); //           decodes the specific beacon number adio callsign user protocol
            auxdevice = micheck8485(); //           decodes the auxilary radio device
            supuse = michek107(); //                checks is sup data is national or assigned use
            activtype = michek108(); //             checks is sup data is national or assigned use
            if (supuse === "Emergency Code Data Entered") {
                emergcode = michek109112();
            } //  checks for maritime emergency code
            item5 = "Radio Call Sign Identification:   " + callsign4char + callsign3char;
            callSignNumber = callsign4char + callsign3char;
            bits5 = "40-75";
            value5 = concatbits(40, 75);
            item6 = "Specific bcn:  " + specbcnum;
            bits6 = "76-81";
            value6 = concatbits(76, 81);
            item7 = "Spare ";
            bits7 = "82-83";
            value7 = concatbits(82, 83);
            item8 = "Aux radio device:  " + auxdevice;
            bits8 = "84-85";
            value8 = concatbits(84, 85);
            if ((len > 15) && (format === "short format")) {
                item9 = "Encoded BCH 1: ";
                bits9 = "86-106";
                value9 = concatbits(86, 106);
                item10 = "Calculated BCH 1: ";
                bits10 = "N/A";
                value10 = concatcalcbits(86, 106);
                item11 = "Emerg Code: " + supuse;
                bits11 = "107";
                value11 = concatbits(107, 107);
                item12 = "Activation Type: " + activtype;
                bits12 = "108";
                value12 = concatbits(108, 108);
                if (supuse === "Emergency Code Data Entered") {
                    item13 = "Emergency Code: " + emergcode;
                    bits13 = "109-112";
                    value13 = concatbits(109, 112);
                } else {
                    item13 = "Emergency Code: No information entered if all 0s, otherwise " + emergcode;
                    bits13 = "109-112";
                    value13 = concatbits(109, 112);
                }
                if (len === 30) {
                    if (concatbits(113, 144) === "00000000000000000000000000000000") {
                        item14 = "Assigned by LUT correctly";
                        bits14 = "113-144";
                        value14 = concatbits(113, 144);
                    }
                    else {
                        item14 = "Non Standard";
                        bits14 = "113-144";
                        value14 = concatbits(113, 144);
                    }
                }
            }//short message 22 hex representation
            item15 = "15 Hex ID: ";
            bits15 = "N/A";
            value15 = convhex(26, 85);
        }//end of radio call sign


        // Aviation User - Aviation User -Aviation User -Aviation User
        if (protdetailed === "Aviation User") {
            aircraftid = baudot(40, 81); //          decodes aircraft id
            auxdevice = micheck8485(); //           decodes the auxilary radio device
            supuse = michek107(); //                checks is sup data is national or assigned use
            activtype = michek108(); //             checks is sup data is national or assigned use
            if (supuse === "Emergency Code Data Entered") {
                enonmar109 = nonmar109(); //  checks for non-maritime emergency code fire/nofire
                enonmar110 = nonmar110();
                enonmar111 = nonmar111();
            }
            item5 = "Aircraft Identification:   " + aircraftid;
            bits5 = "40-81";
            value5 = concatbits(40, 81);
            item6 = "Spare ";
            bits6 = "82-83";
            value6 = concatbits(82, 83);
            item7 = "Aux radio device:  " + auxdevice;
            bits7 = "84-85";
            value7 = concatbits(84, 85);
            if ((len > 15) && (format === "short format")) {
                item8 = "Encoded BCH 1: ";
                bits8 = "86-106";
                value8 = concatbits(86, 106);
                item9 = "Calculated BCH 1: ";
                bits9 = "N/A";
                value9 = concatcalcbits(86, 106);
                item10 = "Emerg Code: " + supuse;
                bits10 = "107";
                value10 = concatbits(107, 107);
                item11 = "Activation Type: " + activtype;
                bits11 = "108";
                value11 = concatbits(108, 108);
                if (supuse === "Emergency Code Data Entered") {
                    item12 = "Emergency Code: " + enonmar109;
                    bits12 = "109";
                    value12 = concatbits(109, 109);
                    item13 = "Emergency Code: " + enonmar110;
                    bits13 = "110";
                    value13 = concatbits(110, 110);
                    item14 = "Emergency Code: " + enonmar111;
                    bits14 = "111";
                    value14 = concatbits(111, 111);
                    item15 = "Spare: ";
                    bits15 = "112";
                    value15 = concatbits(112, 112);
                } else {
                    item13 = "Emergency Code: No information entered if all 0s, otherwise " + emergcode;
                    bits13 = "109-112";
                    value13 = concatbits(109, 112);
                }
                if (len === 30) {
                    if (concatbits(113, 144) === "00000000000000000000000000000000") {
                        item16 = "Assigned by LUT correctly";
                        bits16 = "113-144";
                        value16 = concatbits(113, 144);
                    }
                    else {
                        item16 = "Non Standard";
                        bits16 = "113-144";
                        value16 = concatbits(113, 144);
                    }
                }
            }//short message 22 hex representation
            item17 = "15 Hex ID: ";
            bits17 = "N/A";
            value17 = convhex(26, 85);
        }//end of Aviation User


        // Serial User -Serial User -Serial User - Serial User
        if (protdetailed === "Serial User") {
            serident = uchek4042();//  checks to determine what type of serial user protocol
            csornat = uchek43();//    checks to determine if serial number is assigned by C/S or nationally
            auxdevice = micheck8485(); //           decodes the auxilary radio device
            serialnumber = decimalvalue(44, 63);// This returns a decimal value for binary string, with the first digit being the start bit and the second being the last
            supuse = michek107(); //                checks is sup data is national or assigned use
            if (csornat === "Yes") { csnumber = decimalvalue(74, 83) } else {
                csnumber = "Nationally Assigned (not Cospas-Sarsat)";
            }
            item9 = "C/S Number or National Use (bit 43 refers):   " + csnumber;
            bits9 = "74-83";
            value9 = concatbits(74, 83);
            item10 = "Aux radio device:  " + auxdevice;
            bits10 = "84-85";
            value10 = concatbits(84, 85);
            if ((serident === "ELT with Serial Identification") || (serident === "Float Free EPIRB with Serial Identification Number") || (serident === "Non Float Free EPIRB with Serial Identification") || (serident === "PLB with Serial Identification Number")) {
                activtype = michek108(); //             checks if provides automatic/manual activation
                if ((supuse === "Emergency Code Data Entered") &&
                    ((serident === "Float Free EPIRB with Serial Identification Number") || (serident === "Non Float Free EPIRB with Serial Identification"))) {
                    emergcode = michek109112();
                } else { //  checks for maritime emergency code
                    if (supuse === "Emergency Code Data Entered") {
                        enonmar109 = nonmar109(); //  checks for non-maritime emergency code fire/nofire
                        enonmar110 = nonmar110();
                        enonmar111 = nonmar111();
                    } else {
                        item9 = "Emergency Code: No information entered " + emergcode;
                        bits13 = "109-112";
                        value13 = concatbits(109, 112);
                    }
                }
                item5 = "Serial Type:   " + serident;
                bits5 = "40-42";
                value5 = concatbits(40, 42);
                item6 = "Cospas-Sarsat Certificate Number in bits 74-83:   " + csornat;
                bits6 = "43";
                value6 = concatbits(43, 43);
                item7 = "Serial Number:     " + serialnumber;
                bits7 = "44-63";
                value7 = concatbits(44, 63);
                item8 = "All 0s or National Use";
                bits8 = "64-73";
                value8 = concatbits(64, 73);
                item10 = "Aux radio device:  " + auxdevice;
                bits10 = "84-85";
                value10 = concatbits(84, 85);
                if ((len > 15) && (format === "short format")) {
                    item11 = "Encoded BCH 1: ";
                    bits11 = "86-106";
                    value11 = concatbits(86, 106);
                    item12 = "Calculated BCH 1: ";
                    bits12 = "N/A";
                    value12 = concatcalcbits(86, 106);
                    item13 = "Emerg Code: " + supuse;
                    bits13 = "107";
                    value13 = concatbits(107, 107);
                    item14 = "Activation Type: " + activtype;
                    bits14 = "108";
                    value14 = concatbits(108, 108);
                    if ((supuse === "Emergency Code Data Entered") &&
                        ((serident === "Float Free EPIRB with Serial Identification Number") || (serident === "Non Float Free EPIRB with Serial Identification"))) {
                        item15 = "Emergency Code: " + emergcode;
                        bits15 = "109-112";
                        value15 = concatbits(109, 112);
                    }
                    else {
                        if (supuse === "Emergency Code Data Entered") {
                            item15 = "Emergency Code: " + enonmar109;
                            bits15 = "109";
                            value15 = concatbits(109, 109);
                            item16 = "Emergency Code: " + enonmar110;
                            bits16 = "110";
                            value16 = concatbits(110, 110);
                            item17 = "Emergency Code: " + enonmar111;
                            bits17 = "111";
                            value17 = concatbits(111, 111);
                        }
                    }
                    if ((supuse !== "Emergency Code Data Entered") && (len > 15)) {
                        item15 = "Emergency Code: No information entered if all 0s, otherwise " + emergcode;
                        bits15 = "109-112";
                        value15 = concatbits(109, 112);
                    }
                    if (len === 30) {
                        if (concatbits(113, 144) === "00000000000000000000000000000000") {
                            item18 = "Assigned by LUT correctly";
                            bits18 = "113-144";
                            value18 = concatbits(113, 144);
                        }
                        else {
                            item18 = "Non Standard";
                            bits18 = "113-144";
                            value18 = concatbits(113, 144);
                        }
                    }
                    item19 = "15 Hex ID: ";
                    bits19 = "N/A";
                    value19 = convhex(26, 85);
                }//short msg 22 hex
                if (len === 15) {
                    item13 = "15 Hex ID: ";
                    bits13 = "N/A";
                    value13 = convhex(26, 85);
                }
            }//end of loop for serials 000, 010, 100, 110

            if (serident === "ELT with Aircraft 24-bit Address") {
                serialnumber = decimalvalue(44, 67);// This returns a decimal value for binary string, with the first digit being the start bit and the second being the last
                hex24bit = convhex(44, 67);//This converts bits 44 to 67 to hex and stores in hexid
                numberofelts = decimalvalue(68, 73);// Number of additional elts on aircraft
                if (supuse === "Emergency Code Data Entered") {
                    enonmar109 = nonmar109(); //  checks for non-maritime emergency code fire/nofire
                    enonmar110 = nonmar110();
                    enonmar111 = nonmar111();
                }//end of loop checking for emergency data entered
                item5 = "Serial Type:   " + serident;
                bits5 = "40-42";
                value5 = concatbits(40, 42);
                item6 = "Cospas-Sarsat Certificate Number in bits 74-83:   " + csornat;
                bits6 = "43";
                value6 = concatbits(43, 43);
                item7 = "24-bit Aircraft ID:  " + serialnumber + " or in hexidecimal " + hex24bit;
                bits7 = "44-67";
                value7 = concatbits(44, 67);
                item8 = "Number of Additional ELTs:   " + numberofelts;
                bits8 = "68-73";
                value8 = concatbits(68, 73);
                item11 = "Aux radio device:  " + auxdevice;
                bits11 = "84-85";
                value11 = concatbits(84, 85);
                if ((len > 15) && (format === "short format")) {
                    item12 = "Encoded BCH 1: ";
                    bits12 = "86-106";
                    value12 = concatbits(86, 106);
                    item13 = "Calculated BCH 1: ";
                    bits13 = "N/A";
                    value13 = concatcalcbits(86, 106);
                    item14 = "Emerg Code: " + supuse;
                    bits14 = "107";
                    value14 = concatbits(107, 107);
                    item15 = "Activation Type: " + activtype;
                    bits15 = "108";
                    value15 = concatbits(108, 108);
                    if (supuse === "Emergency Code Data Entered") {
                        item16 = "Emergency Code: " + enonmar109;
                        bits16 = "109";
                        value16 = concatbits(109, 109);
                        item17 = "Emergency Code: " + enonmar110;
                        bits17 = "110";
                        value17 = concatbits(110, 110);
                        item18 = "Emergency Code: " + enonmar111;
                        bits18 = "111";
                        value18 = concatbits(111, 111);
                        item19 = "Spare: 0 Spec; 1 Non Spec";
                        bits19 = "112";
                        value19 = concatbits(112, 112);
                    }
                } else {
                    item16 = "Emergency Code: No information entered " + emergcode;
                    bits16 = "109-112";
                    value16 = concatbits(109, 112);
                }
                if ((supuse !== "Emergency Code Data Entered") && (len > 15)) {
                    item16 = "Emergency Code: No information entered if all 0s, otherwise " + emergcode;
                    bits16 = "109-112";
                    value16 = concatbits(109, 112);
                }
                if (len === 30) {
                    if (concatbits(113, 144) === "00000000000000000000000000000000") {
                        item20 = "Assigned by LUT correctly";
                        bits20 = "113-144";
                        value20 = concatbits(113, 144);
                    }
                    else {
                        item20 = "Non Standard";
                        bits20 = "113-144";
                        value20 = concatbits(113, 144);
                    }
                }
                item21 = "15 Hex ID: ";
                bits21 = "N/A";
                value21 = convhex(26, 85);
            }// end of serial aircraft 24 bit


            //---------------------------------------------------------------------------------------------------------------
            if (serident === "ELT with Aircraft Operator Designator & Serial Number") {
                if (csornat === "Cospas-Sarsat Type Approval") {
                    csnumber = decimalvalue(74, 83);
                } else {
                    csnumber = "Nationally Assigned (not Cospas-Sarsat)";
                }
                serialnumber = decimalvalue(62, 73);// This returns a decimal value for the serial number
                eltop3desig = baudot(44, 61); //          operator 3 letter designator
                if (supuse === "Emergency Code Data Entered") {
                    enonmar109 = nonmar109(); //  checks for non-maritime emergency code fire/nofire
                    enonmar110 = nonmar110();
                    enonmar111 = nonmar111();
                }//end of loop checking for emergency data entered
                item5 = "Serial Type:   " + serident;
                bits5 = "40-42";
                value5 = concatbits(40, 42);
                item6 = "Cospas-Sarsat Certificate Number in bits 74-83:   " + csornat;
                bits6 = "43";
                value6 = concatbits(43, 43);
                item7 = "Three letter designator:  " + eltop3desig;
                bits7 = "44-61";
                value7 = concatbits(44, 61);
                item8 = "Serial number   " + serialnumber;
                bits8 = "62-73";
                value8 = concatbits(62, 73);
                item11 = "Aux radio device:  " + auxdevice;
                bits11 = "84-85";
                value11 = concatbits(84, 85);
                if ((len > 15) && (format === "short format")) {
                    item12 = "Encoded BCH 1: ";
                    bits12 = "86-106";
                    value12 = concatbits(86, 106);
                    item13 = "Calculated BCH 1: ";
                    bits13 = "N/A";
                    value13 = concatcalcbits(86, 106);
                    item14 = "Emerg Code: " + supuse;
                    bits14 = "107";
                    value14 = concatbits(107, 107);
                    item15 = "Activation Type: " + activtype;
                    bits15 = "108";
                    value15 = concatbits(108, 108);
                    if (supuse === "Emergency Code Data Entered") {
                        item16 = "Emergency Code: " + enonmar109;
                        bits16 = "109";
                        value16 = concatbits(109, 109);
                        item17 = "Emergency Code: " + enonmar110;
                        bits17 = "110";
                        value17 = concatbits(110, 110);
                        item18 = "Emergency Code: " + enonmar111;
                        bits18 = "111";
                        value18 = concatbits(111, 111);
                    } else {
                        item16 = "Emergency Code: No information entered if all 0s, otherwise " + emergcode;
                        bits16 = "109-112";
                        value16 = concatbits(109, 112);
                    }
                }
                if (len === 30) {
                    if (concatbits(113, 144) === "00000000000000000000000000000000") {
                        item19 = "Assigned by LUT correctly";
                        bits19 = "113-144";
                        value19 = concatbits(113, 144);
                    }
                    else {
                        item19 = "Non Standard";
                        bits19 = "113-144";
                        value19 = concatbits(113, 144);
                    }
                }
                item20 = "15 Hex ID: ";
                bits20 = "N/A";
                value20 = convhex(26, 85);
            }// end of Aircraft operator designator and serial number
        }//end of serial user loop

        // Test User - Test User - Test User - Test User
        if (protdetailed === "Test User") {
            item5 = "National Use, Hex value: " + convhex(40, 85);
            bits5 = "40-85";
            value5 = concatbits(40, 85);
            item6 = "15 Hex ID: ";
            bits6 = "N/A";
            value6 = convhex(26, 85);

            if (len === 22) {
                item6 = "Encoded BCH 1: ";
                bits6 = "86-106";
                value6 = concatbits(86, 106);
                item7 = "Calculated BCH 1: ";
                bits7 = "N/A";
                value7 = concatcalcbits(86, 106);
                item8 = "Emerg Code: " + supuse;
                bits8 = "107";
                value8 = concatbits(107, 107);
                item9 = "Activation Type: " + activtype;
                bits9 = "108";
                value9 = concatbits(108, 108);

                if (supuse === "Emergency Code Data Entered") {
                    enonmar109 = nonmar109(); //  checks for non-maritime emergency code fire/nofire
                    enonmar110 = nonmar110();
                    enonmar111 = nonmar111();
                    item10 = "Emergency Code: " + enonmar109;
                    bits10 = "109";
                    value10 = concatbits(109, 109);
                    item11 = "Emergency Code: " + enonmar110;
                    bits11 = "110";
                    value11 = concatbits(110, 110);
                    item12 = "Emergency Code: " + enonmar111;
                    bits12 = "111";
                    value12 = concatbits(111, 111);
                    item13 = "Spare, should be 0 ";
                    bits13 = "112";
                    value13 = concatbits(112, 112);
                } //end of loop checking for emergency data entered
                else {
                    item10 = "Emergency Code: No information entered if all 0s, otherwise " + emergcode;
                    bits10 = "109-112";
                    value10 = concatbits(109, 112);
                }
                //end of loop checking for emergency data entered

                item14 = "15 Hex ID: ";
                bits14 = "N/A";
                value14 = convhex(26, 85);
            }


            if (len === 30) {

                if (format === "short format" && concatbits(113, 144) === "00000000000000000000000000000000") {
                    item14 = "Assigned by LUT correctly";
                    bits14 = "113-144";
                    value14 = concatbits(113, 144);
                    item15 = "15 Hex ID: ";
                    bits15 = "N/A";
                    value15 = convhex(26, 85);
                }
                else {
                    item14 = "Invalid: ";
                    bits14 = "113-114";
                    value14 = concatbits(113, 144);
                    item15 = "15 Hex ID: ";
                    bits15 = "N/A";
                    value15 = convhex(26, 85);
                }


                if (format === "long format") {
                    if (concatbits(113, 144) === "11111111111111111111111111111111") {
                        item14 = "Assigned by LUT correctly";
                        bits14 = "113-144";
                        value14 = concatbits(113, 144);
                        item15 = "15 Hex ID: ";
                        bits15 = "N/A";
                        value15 = convhex(26, 85);
                    }
                    else {
                        item14 = "National Use";
                        bits14 = "113-132";
                        value14 = concatbits(113, 132);
                        item15 = "BCH 2 Encoded: ";
                        bits15 = "133-144";
                        value15 = concatbits(133, 144);
                        item16 = "BCH 2 Calculated: ";
                        bits16 = "N/A";
                        value16 = concatcalcbits2(133, 144);
                        item17 = "15 Hex ID: ";
                        bits17 = "N/A";
                        value17 = convhex(26, 85);
                    }
                }//end of long

            }//end of charrs 30
        }//end of test user

        // Orbitography - Orbitography - Orbitography -Orbitography
        if (protdetailed === "Orbitography") {
            item5 = "Identification Bits, Hex value: " + convhex(40, 85);
            bits5 = "40-85";
            value5 = concatbits(40, 85);
            item6 = "15 Hex ID: ";
            bits6 = "N/A";
            value6 = convhex(26, 85);

            if (len === 22 || len === 30) {
                item6 = "Encoded BCH 1: "; bits6 = "86-106"; value6 = concatbits(86, 106);
                item7 = "Calculated BCH 1: "; bits7 = "N/A"; value7 = concatcalcbits(86, 106);
                item8 = "National Use"; bits8 = "107-112"; value8 = concatbits(107, 112);
                item9 = "15 Hex ID: "; bits9 = "N/A"; value9 = convhex(26, 85);
            }

            if (len === 30) {

                if (format === "short format" && concatbits(113, 144) === "00000000000000000000000000000000") {
                    item9 = "Assigned by LUT correctly";
                    bits9 = "113-144";
                    value9 = concatbits(113, 144);
                    item10 = "15 Hex ID: ";
                    bits10 = "N/A";
                    value10 = convhex(26, 85);
                }
                else {
                    item9 = "Invalid: ";
                    bits9 = "113-114";
                    value9 = concatbits(113, 144);
                    item10 = "15 Hex ID: ";
                    bits10 = "N/A";
                    value10 = convhex(26, 85);
                }


                if (format === "long format") {
                    if (concatbits(113, 144) === "11111111111111111111111111111111") {
                        item9 = "Assigned by LUT correctly";
                        bits9 = "113-144";
                        value9 = concatbits(113, 144);
                        item10 = "15 Hex ID: ";
                        bits10 = "N/A";
                        value10 = convhex(26, 85);
                    }
                    else {
                        item9 = "National Use";
                        bits9 = "113-132";
                        value9 = concatbits(113, 132);
                        item10 = "BCH 2 Encoded: ";
                        bits10 = "133-144";
                        value10 = concatbits(133, 144);
                        item11 = "BCH 2 Calculated: ";
                        bits11 = "N/A";
                        value11 = concatcalcbits2(133, 144);
                        item12 = "15 Hex ID: ";
                        bits12 = "N/A";
                        value12 = convhex(26, 85);
                    }
                }//end of long

            }//end of charrs 30
        }//end of orbitography



        //                                                  code for user location protocol - location calculations
        if (((protdetailed === "Serial User") || (protdetailed === "Maritime User") || (protdetailed === "Radio Call Sign") || (protdetailed === "Aviation User") || (protdetailed === "Test User")) && (len > 21) && (format === "long format")) {
            intext = intextchek(107);
            userlocation();//subroutine which calculates location
            item11 = "Encoded BCH 1: ";
            bits11 = "86-106";
            value11 = concatbits(86, 106);
            item12 = "Calculated BCH 1: ";
            bits12 = "N/A";
            value12 = concatcalcbits(86, 106);
            item13 = intext;
            bits13 = "107";
            value13 = concatbits(107, 107);
            item14 = locations[0];
            bits14 = "108";
            value14 = concatbits(108, 108);


            if (len === 22) {
                item15 = "Initial Bits for Latitude. All 30 bits required to decode complete location:"; bits15 = "109-112"; value15 = concatbits(109, 112)
                item16 = "15 Hex ID: "; bits16 = "N/A"; value16 = convhex(26, 85);
            }

            if (len === 30) {
                if (concatbits(113, 144) === "11111111111111111111111111111111") {
                    item15 = "Initial Bits for Latitude, remaining bits truncated by LUT:";
                    bits15 = "109-112";
                    value15 = concatbits(109, 112);
                    item16 = "Truncated Message ";
                    bits16 = "113-144";
                    value16 = "All 1s";
                    item17 = "15 Hex ID: ";
                    bits17 = "N/A";
                    value17 = convhex(26, 85);
                }
                else {
                    item15 = "Latitude (degrees):  " + locations[1]; bits15 = "109-115"; value15 = concatbits(109, 115);
                    item16 = "Latitude (minutes): " + locations[2]; bits16 = "116-119"; value16 = concatbits(116, 119);
                    item17 = locations[3]; bits17 = "120";
                    value17 = concatbits(120, 120);
                    item18 = "Longitude (degrees):  " + locations[4];
                    bits18 = "121-128";
                    value18 = concatbits(121, 128);
                    item19 = "Longitude (minutes): " + locations[5];
                    bits19 = "129-132";
                    value19 = concatbits(129, 132);
                    item20 = "Encoded BCH 2: ";
                    bits20 = "133-144";
                    value20 = concatbits(133, 144);
                    item21 = "Calculated BCH 2: ";
                    bits21 = "N/A";
                    value21 = concatcalcbits2(133, 144);
                    item22 = "15 Hex ID: ";
                    bits22 = "N/A";
                    value22 = convhex(26, 85);
                }
            }

        }//end of user location
    }// end of user protocol loop



    if (protocol === "Location Protocol") {
        locationprotocol = locprottype();
        item4 = "Type of location protocol: " + locationprotocol;
        bits4 = "37-40";
        value4 = concatbits(37, 40);
        if (locationprotocol !== "Invalid") {
            if ((locationprotocol === "National Location - ELT") ||
                (locationprotocol === "National Location - EPIRB") ||
                (locationprotocol === "National Location - PLB") ||
                (locationprotocol === "Orbitography") ||
                (locationprotocol === "National Location - Test") ||
                (locationprotocol === "National Location - Spare")) {
                auxdevice = homerlocationprot();
                intext = intextchek(111);
                if (format === "long format") {
                    if (bitadj[110] === "0") {
                        addflag = "Bits 113 - 132 assigned nationally";
                    } else {
                        addflag = "Bits 113 - 132 provides offset data location";
                    }
                } else {
                    addflag = bitadj[110] === "0"
                        ? "Fixed bit(1): Fail"
                        : "Fixed bit(1): Pass";
                }
                bitshift = shiftcheknat();
            }
            else if (locationprotocol === "RLS Location Protocol") {

                //New May 2024 protocol

                //bits start at 26.  get 20 bits starting at position 21
                //var testBits = concatbits(47, 66);

                mid = decimalvalue(47, 66);
                if (mid === 0) {
                    mid = "00000" + mid;
                }
                if ((9 >= mid) && (mid >= 1)) {
                    mid = "0" + "0" + "0" + "0" + "0" + mid;
                }
                if ((99 >= mid) && (mid >= 10)) {
                    mid = "0000" + mid;
                }
                if ((999 >= mid) && (mid >= 100)) {
                    mid = "0" + "0" + "0" + mid;
                }
                if ((9999 >= mid) && (mid >= 1000)) {
                    mid = "0" + "0" + mid;
                }
                if ((99999 >= mid) && (mid >= 10000)) {
                    mid = "0" + mid;
                }

                specbcnum = decimalvalue(61, 64);
                item5 = "MID: " + mid;
                bits5 = "41-60";
                value5 = concatbits(41, 60);
                item6 = "Specific Beacon: " + specbcnum;
                bits6 = "61-64";
                value6 = concatbits(61, 64);
                mmsiNumber = mid;

            }
            else {
                auxdevice = homerlocationprot();
                intext = intextchek(111);
                standardlocation(); //location calculations for standard location
                bitshift = shiftchekstd();

                if (locationprotocol === "Ship Security") {
                    mid = decimalvalue(41, 60);
                    if (mid === 0) {
                        mid = "00000" + mid;
                    }
                    if ((9 >= mid) && (mid >= 1)) {
                        mid = "0" + "0" + "0" + "0" + "0" + mid;
                    }
                    if ((99 >= mid) && (mid >= 10)) {
                        mid = "0000" + mid;
                    }
                    if ((999 >= mid) && (mid >= 100)) {
                        mid = "0" + "0" + "0" + mid;
                    }
                    if ((9999 >= mid) && (mid >= 1000)) {
                        mid = "0" + "0" + mid;
                    }
                    if ((99999 >= mid) && (mid >= 10000)) {
                        mid = "0" + mid;
                    }
                    if (bitadj[25] === "0") {
                        //item1 = "Message format: Short format.  Fails validation since short format messages not allowed for Ship Security Alerts:  ";
                        //bits1 = "25";
                        //value1 = bitadj[25];
                        return {
                            isValid: false,
                            errorMessage: "Message format: Short format.  Fails validation since short format messages not allowed for Ship Security Alerts."
                        };
                    }
                    if (decimalvalue(61, 64) === 0) {
                        specbcnum = "Passed";
                    } else {
                        specbcnum = "Failed since these bits must be set to 0 for this protocol";
                        return {
                            isValid: false,
                            errorMessage: "Validation Bits: " + specbcnum
                        };
                    }
                    if (bitadj[112] === "1") {
                        auxdevice = "Failed since this bit must be set to 0 for this protocol";
                        return {
                            isValid: false,
                            errorMessage: "Aux Device: " + auxdevice
                        };
                    }

                    item5 = "MMSI (trailing 6 digits): " + mid;
                    bits5 = "41-60";
                    value5 = concatbits(41, 60);
                    item6 = "Validation Bits: " + specbcnum;
                    bits6 = "61-64";
                    value6 = concatbits(61, 64);
                } //end of Ship Security


                if (locationprotocol === "Standard Location - EPIRB (MMSI)") {
                    mid = decimalvalue(41, 60);
                    if (mid === 0) {
                        mid = "00000" + mid;
                    }
                    if ((9 >= mid) && (mid >= 1)) {
                        mid = "0" + "0" + "0" + "0" + "0" + mid;
                    }
                    if ((99 >= mid) && (mid >= 10)) {
                        mid = "0000" + mid;
                    }
                    if ((999 >= mid) && (mid >= 100)) {
                        mid = "0" + "0" + "0" + mid;
                    }
                    if ((9999 >= mid) && (mid >= 1000)) {
                        mid = "0" + "0" + mid;
                    }
                    if ((99999 >= mid) && (mid >= 10000)) {
                        mid = "0" + mid;
                    }

                    specbcnum = decimalvalue(61, 64);
                    item5 = "MID: " + mid;
                    bits5 = "41-60";
                    value5 = concatbits(41, 60);
                    item6 = "Specific Beacon: " + specbcnum;
                    bits6 = "61-64";
                    value6 = concatbits(61, 64);
                    mmsiNumber = mid;
                } //end of standard location EPIRB
                if (locationprotocol === "Standard Location - ELT (24-bit Address)") {
                    number24bit = decimalvalue(41, 64);
                    hex24bit = convhex(41, 64); //This converts bits 44 to 67 to hex and stores in hexid
                    item5 = "24 Bit Aircraft ID (decimal): " + number24bit;
                    bits5 = "41-64";
                    value5 = concatbits(41, 64);
                    item6 = "24 Bit Aircraft ID (Hex): " + hex24bit;
                    bits6 = "41-64";
                    value6 = concatbits(41, 64);
                }
                if ((locationprotocol === "Standard Location - ELT (Serial)") ||
                    (locationprotocol === "Standard Location - EPIRB (Serial)") ||
                    (locationprotocol === "Standard Location - PLB (Serial)")) {
                    csnumber = decimalvalue(41, 50);
                    serialnumber = decimalvalue(51, 64);
                    item5 = "Cospas-Sarsat #: " + csnumber;
                    bits5 = "41-50";
                    value5 = concatbits(41, 50);
                    item6 = "Serial Number: " + serialnumber;
                    bits6 = "51-64";
                    value6 = concatbits(51, 64);
                }
                if (locationprotocol === "Standard Location - ELT (Aircraft Operator Designator)") {
                    eltop3desig = baudotshort(41, 55);
                    serialnumber = decimalvalue(56, 64);
                    item5 = "ELT Operator:  " + eltop3desig;
                    bits5 = "41-55";
                    value5 = concatbits(41, 55);
                    item6 = "ELT Number: " + serialnumber;
                    bits6 = "55-64";
                    value6 = concatbits(55, 64);
                }
                if (locationprotocol === "Standard Location - Test") {
                    serialnumber = "Test Protocol (No Decode information in bits 41 to 64)";
                    item5 = "Test Protocol:  " + serialnumber;
                    bits5 = "41-64";
                    value5 = concatbits(41, 64);
                }
                //location output
                item7 = "Latitude Sign: " + locations[0];
                bits7 = "65";
                value7 = concatbits(65, 65);
                item8 = "Latitude Degrees: " + locations[1];
                bits8 = "66-72";
                value8 = concatbits(66, 72);
                item9 = "Latitude Minutes: " + locations[2];
                bits9 = "73-74";
                value9 = concatbits(73, 74);
                item10 = "Longitude Sign: " + locations[5];
                bits10 = "75";
                value10 = concatbits(75, 75);
                item11 = "Longitude Degrees: " + locations[6];
                bits11 = "76-83";
                value11 = concatbits(76, 83);
                item12 = "Longitude Minutes: " + locations[7];
                bits12 = "84-85";
                value12 = concatbits(84, 85);
                if (len !== 15) {
                    item13 = "BCH 1 Encoded: ";
                    bits13 = "86-106";
                    value13 = concatbits(86, 106);
                    item14 = "BCH 1 Calculated: ";
                    bits14 = "N/A";
                    value14 = concatcalcbits(86, 106);
                    item15 = "Fixed bits (1101): " + bitshift;
                    bits15 = "107-110";
                    value15 = concatbits(107, 110);
                    item16 = "Position Data: " + intext;
                    bits16 = "111";
                    value16 = concatbits(111, 111);
                    item17 = "Aux Device: " + auxdevice;
                    bits17 = "112";
                    value17 = concatbits(112, 112);
                    if ((format === "long format") && (len === 30)) {
                        if (concatbits(113, 144) === "11111111111111111111111111111111") {
                            item18 = "Truncated Message ";
                            bits18 = "113-144";
                            value18 = "All 1s";
                        } else {
                            item18 = "Latitude Offset Sign: " + sign(113);
                            bits18 = "113";
                            value18 = concatbits(113, 113);
                            if (locations[3] === "default") {
                                item18 = "Latitude Offset Sign: default";
                            }
                            item19 = "Latitude Offset Minutes: " + locations[3];
                            bits19 = "114-118";
                            value19 = concatbits(114, 118);
                            item20 = "Latitude Offset Seconds: " + locations[4];
                            bits20 = "119-122";
                            value20 = concatbits(119, 122);
                            item21 = "Longitude Offset Sign: " + sign(123);
                            bits21 = "123";
                            value21 = concatbits(123, 123);
                            if (locations[8] === "default") {
                                item21 = "Longitude Offset Sign: default";
                            }
                            item22 = "Longitude Offset Minutes: " + locations[8];
                            bits22 = "124-128";
                            value22 = concatbits(124, 128);
                            item23 = "Longitude Offset Seconds: " + locations[9];
                            bits23 = "129-132";
                            value23 = concatbits(129, 132);
                            item24 = "BCH 2 Encoded: ";
                            bits24 = "133-144";
                            value24 = concatbits(133, 144);
                            item25 = "BCH 2 Calculated: ";
                            bits25 = "N/A";
                            value25 = concatcalcbits2(133, 144);
                        }
                    } //end of loop for 15 hex
                    if ((len === 30) && (format === "short format")) {
                        if (concatbits(113, 144) === "00000000000000000000000000000000") {
                            item18 = "Assigned by LUT correctly";
                            bits18 = "113-144";
                            value18 = concatbits(113, 144);
                        } else {
                            item18 = "Non Standard";
                            bits18 = "113-144";
                            value18 = concatbits(113, 144);
                        }
                    }
                }
                //            composite location calculations

                if ((locations[0] === "default") || (locations[5] === "default")) {
                    item26 = "Composite Latitude: " + locations[0];
                    bits26 = "N/A";
                    value26 = "Composite Longitude: " + locations[5];
                } else {
                    if (format === "short format") {
                        locations[1] = locations[1] + locations[2] / 60;
                        locations[6] = locations[6] + locations[7] / 60;
                        item26 = "Composite Latitude: " + locations[1] + " Degrees " + locations[0];
                        bits26 = "N/A";
                        value26 = "Composite Longitude: " + locations[6] + " Degrees " + locations[5];
                    } else {
                        if ((locations[4] == "default") ||
                            (locations[9] == "default") ||
                            (concatbits(113, 144) == "11111111111111111111111111111111")) {
                            locations[1] = locations[1] + locations[2] / 60;
                            locations[6] = locations[6] + locations[7] / 60;
                            item26 = "Composite Latitude: " + locations[1] + " Degrees " + locations[0];
                            bits26 = "N/A";
                            value26 = "Composite Longitude: " + locations[6] + " Degrees " + locations[5];
                        } else {
                            if (bitadj[113] === "0") {
                                locations[1] = locations[1] +
                                    locations[2] / 60 -
                                    locations[3] / 60 -
                                    locations[4] / 3600;
                            } else {
                                locations[1] = locations[1] +
                                    locations[2] / 60 +
                                    locations[3] / 60 +
                                    locations[4] / 3600;
                            }
                            if (bitadj[123] === "0") {
                                locations[6] = locations[6] +
                                    locations[7] / 60 -
                                    locations[8] / 60 -
                                    locations[9] / 3600;
                            } else {
                                locations[6] = locations[6] +
                                    locations[7] / 60 +
                                    locations[8] / 60 +
                                    locations[9] / 3600;
                            }
                            item26 = "Composite Latitude: " + locations[1] + " Degrees " + locations[0];
                            bits26 = "N/A";
                            value26 = "Composite Longitude: " + locations[6] + " Degrees " + locations[5];

                        }
                    }
                } //    for hex ID must set the location bits to default value
                for (i = 65; i <= 85; i++) { //                      start to set default values
                    bitadj[i] = "1";
                }
                bitadj[65] = "0";
                bitadj[75] = "0";
                item27 = "15 Hex ID: ";
                bits27 = "N/A";
                value27 = convhex(26, 85);

            }
            //end of else for standard location


            if ((locationprotocol === "National Location - ELT") ||
                (locationprotocol === "National Location - Spare") ||
                (locationprotocol === "National Location - EPIRB") ||
                (locationprotocol === "National Location - PLB") ||
                (locationprotocol === "Orbitography") ||
                (locationprotocol === "National Location - Test")) {
                serialnumber = decimalvalue(41, 58);
                nationallocation(); // location calculations for national location
                item5 = "Serial Number:  " + serialnumber;
                bits5 = "41-58";
                value5 = concatbits(41, 58);
                item6 = "Latitude Flag:  " + locations[0];
                bits6 = "59";
                value6 = concatbits(59, 59);
                item7 = "Latitude (Degrees):  " + locations[1];
                bits7 = "60-66";
                value7 = concatbits(60, 66);
                item8 = "Latitude (Minutes):  " + locations[2];
                bits8 = "67-71";
                value8 = concatbits(67, 71);
                item9 = "Longitude Flag:  " + locations[5];
                bits9 = "72";
                value9 = concatbits(72, 72);
                item10 = "Longitude (Degrees):  " + locations[6];
                bits10 = "73-80";
                value10 = concatbits(73, 80);
                item11 = "Longitude (Minutes):  " + locations[7];
                bits11 = "81-85";
                value11 = concatbits(81, 85);
                if (len !== 15) {
                    item12 = "BCH 1 Encoded: ";
                    bits12 = "86-106";
                    value12 = concatbits(86, 106);
                    item13 = "BCH 1 Calculated: ";
                    bits13 = "86-106";
                    value13 = concatcalcbits(86, 106);
                    item14 = "Fixed bits (110): " + bitshift;
                    bits14 = "107-109";
                    value14 = concatbits(107, 109);
                    item15 = addflag;
                    bits15 = "110";
                    value15 = concatbits(110, 110);
                    item16 = "Position Data: " + intext;
                    bits16 = "111";
                    value16 = concatbits(111, 111);
                    item17 = "Aux Loc. Device: " + auxdevice;
                    bits17 = "112";
                    value17 = concatbits(112, 112);
                    if (concatbits(113, 144) === "11111111111111111111111111111111") {
                        item18 = "Truncated Message ";
                        bits18 = "113-144";
                        value18 = "All 1s";
                    } else {
                        if ((format === "long format") && (len === 30)) {
                            if (addflag === "Bits 113 - 132 provides offset data location") {
                                item18 = "Latitude Offset Sign: " + sign(113);
                                bits18 = "113";
                                value18 = concatbits(113, 113);
                                if (locations[4] === "default") {
                                    item18 = "Latitude Offset Sign: default";
                                }
                                item19 = "Latitude Offset Minutes: " + locations[3];
                                bits19 = "114-115";
                                value19 = concatbits(114, 115);
                                item20 = "Latitude Offset Seconds: " + locations[4];
                                bits20 = "116-119";
                                value20 = concatbits(116, 119);
                                item21 = "Longitude Offset Sign: " + sign(120);
                                bits21 = "120";
                                value21 = concatbits(120, 120);

                                if (locations[8] === "default") {
                                    item21 = "Longitude Offset Sign: default";
                                }
                                item22 = "Longitude Offset Minutes: " + locations[8];
                                bits22 = "121-122";
                                value22 = concatbits(121, 122);
                                item23 = "Longitude Offset Seconds: " + locations[9];
                                bits23 = "123-126";
                                value23 = concatbits(123, 126);
                                item24 = "Additional Id (Nat Use)";
                                bits24 = "127-132";
                                value24 = concatbits(127, 132);
                                item25 = "BCH 2 Encoded: ";
                                bits25 = "133-144";
                                value25 = concatbits(133, 144);
                                item26 = "BCH 2 Calculated: ";
                                bits26 = "N/A";
                                value26 = concatcalcbits2(133, 144);
                            } else {
                                item18 = "National Use";
                                bits18 = "113-132";
                                value18 = concatbits(113, 132);
                                item19 = "BCH 2 Encoded: ";
                                bits19 = "133-144";
                                value19 = concatbits(133, 144);
                                item20 = "BCH 2 Calculated: ";
                                bits20 = "N/A";
                                value20 = concatcalcbits2(133, 144);
                            } //end of addflag loop
                        } //long message loop
                    } //end of 15 hex loop
                    if ((len === 30) && (format === "short format")) {
                        if (concatbits(113, 144) === "00000000000000000000000000000000") {
                            item18 = "Assigned by LUT correctly";
                            bits18 = "113-144";
                            value18 = concatbits(113, 144);
                        } else {
                            item18 = "Non Standard";
                            bits18 = "113-144";
                            value18 = concatbits(113, 144);
                        }
                    }
                }
                //            composite location calculations
                if ((locationprotocol === "National Location - ELT") ||
                    (locationprotocol === "National Location - Spare") ||
                    (locationprotocol === "National Location - EPIRB") ||
                    (locationprotocol === "National Location - PLB") ||
                    (locationprotocol === "Orbitography") ||
                    (locationprotocol === "National Location - Test")) {

                    if ((locations[0] === "default") || (locations[5] === "default")) {
                        item27 = "Composite Latitude: " + locations[0];
                        bits27 = "N/A";
                        value27 = "Composite Longitude: " + locations[5];
                    } else {
                        if (format === "short format") {
                            locations[1] = locations[1] + locations[2] / 60;
                            locations[6] = locations[6] + locations[7] / 60;
                            item27 = "Composite Latitude: " + locations[1] + " Degrees " + locations[0];
                            bits27 = "N/A";
                            value27 = "Composite Longitude: " + locations[6] + " Degrees " + locations[5];
                        } else {
                            if ((locations[4] === "default") ||
                                (locations[9] == "default") ||
                                (concatbits(113, 144) == "11111111111111111111111111111111")) {
                                locations[1] = locations[1] + locations[2] / 60;
                                locations[6] = locations[6] + locations[7] / 60;
                                item27 = "Composite Latitude: " + locations[1] + " Degrees " + locations[0];
                                bits27 = "N/A";
                                value27 = "Composite Longitude: " + locations[6] + " Degrees " + locations[5];
                            } else {
                                if (bitadj[113] === "0") {
                                    locations[1] = locations[1] + locations[2] / 60 - locations[3] / 60 - locations[4] / 3600;
                                } else {
                                    locations[1] = locations[1] + locations[2] / 60 + locations[3] / 60 + locations[4] / 3600;
                                }
                                if (bitadj[120] === "0") {
                                    locations[6] = locations[6] + locations[7] / 60 - locations[8] / 60 - locations[9] / 3600;
                                } else {
                                    locations[6] = locations[6] + locations[7] / 60 + locations[8] / 60 + locations[9] / 3600;
                                }
                            }
                        }
                        item27 = "Composite Latitude: " + locations[1] + " Degrees " + locations[0];
                        bits27 = "N/A";
                        value27 = "Composite Longitude: " + locations[6] + " Degrees " + locations[5];
                    }

                    //    for hex ID must set the location bits to default value
                    bitadj[59] = bitadj[67] = bitadj[68] = bitadj[69] = bitadj[70] = bitadj[71] = bitadj[72] =
                        bitadj[81] = bitadj[82] = bitadj[83] = bitadj[84] = bitadj[85] = "0";
                    bitadj[60] = bitadj[61] = bitadj[62] = bitadj[63] = bitadj[64] = bitadj[65] = bitadj[66] =
                        bitadj[73] = bitadj[74] = bitadj[75] = bitadj[76] = bitadj[77] = bitadj[78] = bitadj[79] =
                        bitadj[80] = "1";

                    item28 = "15 Hex ID: ";
                    bits28 = "N/A";
                    value28 = convhex(26, 85);
                }
            } //end of national location
        } //end of check for invalid location protocol
        else {
            //item5 = "Decode not possible as invalid data encoded in beacon in bits 37 through 40";
            return {
                isValid: false,
                errorMessage: "Decode not possible as invalid data encoded in beacon in bits 37 through 40"
            };
        }
    }//End of Location Protocol checks

    // return {};
    var obj = {
        isValid: true,
        errorMessage: "",
        country: country,
        mmsiNumber: mmsiNumber,
        callSignNumber: callSignNumber,
        item1: "Message format:  " + format,
        bits1: "25",
        value1: bitadj[25],
        item2: "Protocol: " + protocol,
        bits2: "26",
        value2: bitadj[26],
        item3: "Country code: " + country + " - <b>" + countryName + "</b>",
        bits3: "27-36",
        value3: concatbits(27, 36),
        item4: item4, bits4: bits4, value4: value4,
        item5: item5, bits5: bits5, value5: value5,
        item6: item6, bits6: bits6, value6: value6,
        item7: item7, bits7: bits7, value7: value7,
        item8: item8, bits8: bits8, value8: value8,
        item9: item9, bits9: bits9, value9: value9,
        item10: item10, bits10: bits10, value10: value10,
        item11: item11, bits11: bits11, value11: value11,
        item12: item12, bits12: bits12, value12: value12,
        item13: item13, bits13: bits13, value13: value13,
        item14: item14, bits14: bits14, value14: value14,
        item15: item15, bits15: bits15, value15: value15,
        item16: item16, bits16: bits16, value16: value16,
        item17: item17, bits17: bits17, value17: value17,
        item18: item18, bits18: bits18, value18: value18,
        item19: item19, bits19: bits19, value19: value19,
        item20: item20, bits20: bits20, value20: value20,
        item21: item21, bits21: bits21, value21: value21,
        item22: item22, bits22: bits22, value22: value22,
        item23: item23, bits23: bits23, value23: value23,
        item24: item24, bits24: bits24, value24: value24,
        item25: item25, bits25: bits25, value25: value25,
        item26: item26, bits26: bits26, value26: value26,
        item27: item27, bits27: bits27, value27: value27,
        item28: item28, bits28: bits28, value28: value28
    }

    return obj;

}