import axios from "axios";

// import authService from "../../components/api-authorization/AuthorizeService";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

export default function () {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());
        const { currentVessel, currentApplication } = getState();

        const licenceApplicationId = currentApplication.id || 0;

        const newVessel = {
            id: Number(currentVessel.id) || 0,
            name: currentVessel.name,
            licenceApplicationId: licenceApplicationId,
            vesselTypeId: currentVessel.vesselTypeId && Number(currentVessel.vesselTypeId),
            vesselSubTypeId: currentVessel.vesselSubTypeId && Number(currentVessel.vesselSubTypeId),
            isOtherVesselType: currentVessel.isOtherVesselType || false,
            otherVesselTypeDescription: currentVessel.otherVesselTypeDescription || "",
            hullColorId: currentVessel.hullColorId && Number(currentVessel.hullColorId),
            seaAreaOfOperationId: currentVessel.seaAreaOfOperationId && Number(currentVessel.seaAreaOfOperationId),
            maxCapacityOfPersonsOnBoard: Number(currentVessel.maxCapacityOfPersonsOnBoard),
            vesselLength: Number(currentVessel.vesselLength),
            grossTonnage: Number(currentVessel.grossTonnage),
            description: currentVessel.description,
            homePort: currentVessel.homePort,
            registerationNumber: currentVessel.registerationNumber,
            fishingVesselPrefixTypeId: Number(currentVessel.fishingVesselPrefixTypeId) || null,
            fvVesselIdentificationNumber: currentVessel.fvVesselIdentificationNumber,
            fishingVesselPostfixTypeId: Number(currentVessel.fishingVesselPostfixTypeId) || null,
            imoNumber: currentVessel.imoNumber,
            numberOfLifeBoats: currentVessel.numberOfLifeBoats && Number(currentVessel.numberOfLifeBoats),
            accountingAuthorityIdentificationCodeId: Number(currentVessel.accountingAuthorityIdentificationCodeId) || null,
            callSignNumber: currentVessel.callSignNumber,
            mmsiNumber: currentVessel.mmsiNumber
        }

        const model = {
            vessel: newVessel,
            licenceStatusTypeId: Number(currentApplication.licenceApplicationStatusTypeId || 1)
        };

        //authService.getAccessToken().then((token) => {
        axios({
            method: "put",
            url: "vessel/updateVessel",
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            data: model
        })
            .catch((error) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
        //});
    };
}
