import axios from "axios";

// import authService from "../../components/api-authorization/AuthorizeService";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetOperatorCertificateImagesByLicenceApplicationIdAction from "./CreateGetOperatorCertificateImagesByLicenceApplicationIdAction";
import CreateGetLicenceImagesAction from "./CreateGetLicenceImagesByLicenceIdAction";

export default function (file) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const { currentApplication } = getState();

        const updatedLicenceApplication = {
            id: Number(currentApplication.id)
        };

        var formData = new FormData();
        formData.append("image", file);

        // authService.getAccessToken().then((token) => {
            axios({
                method: "POST",
                url: "FileUpload/UploadOperatorCertificateImage?licenceApplicationId=" + updatedLicenceApplication.id,
                // headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData
            })
            .then((response) => {
                if (response.data) {
                    dispatch(CreateGetOperatorCertificateImagesByLicenceApplicationIdAction());
                }
                dispatch(CreateGetLicenceImagesAction(updatedLicenceApplication.id));
                dispatch(CreateDataRetrievalEndedAction());
            })
            .catch((error) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
        // });
    };
}
