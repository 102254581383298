import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (licenceApplicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        var requestData = {
            licenceApplicationId: licenceApplicationId
        };

        GET("licenceApplication/UndoCancelLicence", requestData)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: "LICENCE_STATUSTYPEID_UPDATED",
                        data: response.data.licenceApplicationStatusTypeId
                    });
                }
            })
            .catch((response) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
