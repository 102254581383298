import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GETAnonymous } from "../adapters/xhr";

export default function () {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "file/GetUsefulDocuments";

        GETAnonymous(url)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "USEFUL_DOCUMENTS_RETRIEVED",
                        usefulDocs: response.data
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    }
}
