import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LinkButton from "./LinkButton";
import PageHeader from "./PageHeader";
import Breadcrumb from "./Breadcrumb";
import CreateGetRequiredEpirbAction from "../store/actionCreators/CreateGetRequiredEpirbAction";

class EpirbDetailsList extends Component {

    componentDidMount() {
        this.props.getRequiredEpirbs(this.props.licenceApplicationId);
    }

    render() {
        const { t } = this.props;

        const VesselTypes = {
            PleasureVessel: 1,
            FishingVessel: 2,
            PassengerVessel: 3,
            PassengerShip: 4,
            MerchantVessel: 5,
            NavalShip: 6,
            RescueVessel: 7
        };

        var isPleasureVessel = Number(this.props.vesselTypeId) === VesselTypes.PleasureVessel;

        const epirbMatchingCount = this.props?.requiredEpirbs?.addedEpirbsMatchingRequirement?.length || 0;
        const epirbAdditionalCount = this.props?.requiredEpirbs?.additionalEpirbs?.length || 0;

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6,
            TransferInitiated: 7,
            Transferred: 8
        };

        //cancelled, TransferInitiated or transferred Status
        const isReadOnlyPage = this.props.licenceApplicationStatusTypeId === LicenceStatus.Cancelled
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.TransferInitiated
            || this.props.licenceApplicationStatusTypeId === LicenceStatus.Transferred;

        const isShipOwerWithNonIssuedLicence = this.props.role === "Ship Owner" && (this.props.licenceApplicationStatusTypeId == LicenceStatus.InProgress
            || this.props.licenceApplicationStatusTypeId == LicenceStatus.ReadyToReview);

        return (
            <React.Fragment>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    licenceList={t("AdminLandingPage.LicenceList")}
                    dashboard={t("Common.Dashboard")}
                    parentLink=""
                    parentPageName=""
                    pageName={t("EpirbDetails.EpirbTitle")} />

                <PageHeader
                    pageTitle={t("EpirbDetails.EpirbTitle")}
                    vesselLabel={t("OwnerLandingPage.VesselName")}
                    vesselName={this.props.vesselName}
                    isShipOwerWithNonIssuedLicence={isShipOwerWithNonIssuedLicence}
                    mmsiLabel={t("OwnerLandingPage.MMSINumber")}
                    mmsiNumber={this.props.mmsiNumber}
                    callSignLabel={t("OwnerLandingPage.CallSign")}
                    callSignNumber={this.props.callSignNumber}
                    pageDescription={t("EpirbDetails.StepEpirbLabel")}
                    requiredField="" />

                {
                    this.props.requiredEpirbs && this.props.requiredEpirbs.outstandingEpirbs == null
                    && this.props.requiredEpirbs.outstandingRequiredEquipmentGrouped == null
                    && this.props.requiredEpirbs.addedEpirbsMatchingRequirement == null
                    && !isPleasureVessel
                    &&
                    <div className="row">
                        <div className="col-md-12 bg-light p-2 rounded-lg mb-2 bg-customGood">
                            <p className="h6" tabIndex="0">{t("EpirbDetails.NoRequiredEPIRBsTitle", { vesselHeader: this.props.requiredEpirbs.vesselHeader })}</p>
                        </div>
                    </div>
                }
                {
                    this.props.requiredEpirbs && this.props.requiredEpirbs.outstandingEpirbs &&
                    this.props.requiredEpirbs.outstandingEpirbs.length > 0
                    &&
                    <div className="col-md-12 bg-light p-2 mb-2 rounded-lg bg-customWarning">
                        <p className="h5" tabIndex="0">{t("EpirbDetails.OutstandingRequiredEPIRBsTitle1")}</p>
                        <p className="h6" tabIndex="0">{t("EpirbDetails.OutstandingRequiredEPIRBsTitle2", { vesselHeader: this.props.requiredEpirbs.vesselHeader})}: </p>
                        <ul aria-label="Outstanding Epirb Equipment">
                            {
                                this.props.requiredEpirbs.outstandingEpirbs &&
                                this.props.requiredEpirbs.outstandingEpirbs.length > 0 &&
                                <span>
                                    {
                                        this.props.requiredEpirbs.outstandingEpirbs.map(equipment => (
                                            <li key={equipment.id}>
                                                {
                                                    isReadOnlyPage &&
                                                    <span>
                                                        {
                                                        equipment.minimumQuantityRequired > 1 &&
                                                            <span>{equipment.minimumQuantityRequired} x </span>
                                                        }
                                                        <span>{t("EpirbDetails.EPIRB")}</span>
                                                            {
                                                            equipment?.epirbMountingType?.name != null &&
                                                            <span> - {equipment.epirbMountingType.name}.</span>
                                                        }
                                                        {equipment.isMedApproved && <span> {t("Equipments.MedApproved")}.</span>}
                                                        {equipment.gpsEnabledRequired && <span> {t("Equipments.GpsEnabled")}.</span>}
                                                    </span>
                                                }

                                                {
                                                    !isReadOnlyPage &&
                                                    <Link to="/apply/add-epirb"
                                                        className="btn btn-link listItem-Button"
                                                        onClick={() => {
                                                            this.props.initialiseEpirbData(equipment.epirbMountingTypeId, equipment.gpsEnabledRequired, equipment.isMedApproved);
                                                        }}>{t("Common.ButtonText.ClickHereToAdd")}&nbsp;
                                                        {
                                                            equipment.minimumQuantityRequired > 1 &&
                                                            <span>{equipment.minimumQuantityRequired} x </span>
                                                        }
                                                        <span>{t("EpirbDetails.EPIRB")}</span>
                                                        {
                                                            equipment?.epirbMountingType?.name != null &&
                                                            <span> - {equipment.epirbMountingType.name}.</span>
                                                        }
                                                        {equipment.isMedApproved && <span> {t("Equipments.MedApproved")}.</span>}
                                                        {equipment.gpsEnabledRequired && <span> {t("Equipments.GpsEnabled")}.</span>}
                                                    </Link>
                                                }

                                            </li>
                                        ))
                                    }
                                </span>
                            }
                        </ul>
                    </div>

                }

                {
                    epirbMatchingCount > 0 &&
                    <div className="col-md-12 bg-light p-2 rounded-lg mb-2 bg-customGood">
                        <div className="h5" tabIndex="0">{t("EpirbDetails.AddedEPIRBsTitle1")}</div>
                        <span className="h6" tabIndex="0">{t("EpirbDetails.AddedEPIRBsTitle2")}:</span>
                        <ul aria-label="Required Epirb Equipment Added">
                            {
                                this.props.requiredEpirbs.addedEpirbsMatchingRequirement.map((equipment) => (
                                    <li key={equipment.id}>
                                        <Link className="btn btn-link listItem-Button"
                                                to="/apply/add-epirb"
                                                onClick={() => {
                                                this.props.editEPIRB(equipment);
                                            }} 
                                        >{t("Common.ButtonText.ViewEdit")}&nbsp;
                                            <span>
                                                {equipment.isOtherMake ? equipment.otherMake : equipment.epirbMakeType.make} - {equipment.isOtherModel ? equipment.otherModel : equipment.epirbModelType.name}. {equipment?.epirbMountingType?.name}.
                                            </span>
                                            {equipment.isOtherModel && equipment.isMedApproved && <span> {t("Equipments.MedApproved")}.</span>}
                                            {(!equipment.isOtherModel && equipment.epirbModelTypeId != null && equipment.epirbModelType.medApproved) && <span> {t("Equipments.MedApproved")}.</span>}
                                            {(equipment?.epirbGpsEnabledTypeId != null && equipment?.epirbGpsEnabledType?.name === 'Yes') && <span> {t("Equipments.GpsEnabled")}.</span>}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                }

                {
                    epirbAdditionalCount > 0 &&
                    <div className="col-md-12 bg-light p-2 rounded-lg mb-2 bg-customNeutral">
                        <div className="h5" tabIndex="0">{t("EpirbDetails.OtherEPIRBsTitle1")}:</div>

                        <ul aria-label="Additional Epirb Equipment Added">
                            {
                                this.props.requiredEpirbs.additionalEpirbs.map(equipment => (
                                    <li key={equipment.id}>
                                        <LinkButton key={equipment.id} className="btn btn-link listItem-Button"
                                                onClick={() => {
                                                this.props.editEPIRB(equipment);
                                            }} to="/apply/add-epirb">
                                            {t("Common.ButtonText.ViewEdit")}&nbsp;
                                            <span>
                                                {equipment.isOtherMake ? equipment.otherMake : equipment.epirbMakeType.make} - {equipment.isOtherModel ? equipment.otherModel : equipment.epirbModelType.name}. {equipment?.epirbMountingType?.name}.
                                            </span>
                                            {(equipment.epirbModelTypeId != null && equipment.epirbModelType.medApproved === 1) && <span> {t("Equipments.MedApproved")}.</span>}
                                            {(equipment.epirbGpsEnabledTypeId != null && equipment.epirbGpsEnabledType.name === "Yes") && <span> {t("Equipments.GpsEnabled")}.</span>}
                                        </LinkButton>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                }

                <div className="h6 mt-2" role="heading" aria-level="2" id="ComplianceTitle" tabIndex="0">
                    {t("Equipments.ComplianceTitle")}
                </div>

                <div className="d-grid gap-2 d-md-flex justify-content-md-end my-3">
                    {
                        !isReadOnlyPage &&
                        <Link className="btn btn-primary me-3"
                            to="/apply/add-epirb"
                            onClick={() => {
                                this.props.clearEPIRB();
                            }}
                        >{t("EpirbDetails.ButtonText.AddEpirb")}</Link>
                    }
                    <Link
                        id="btnReview"
                        className="btn btn-primary"
                        to={this.props.parentRoute + "/review-declarations/" + this.props.licenceApplicationId}
                    >
                        {t("Common.ButtonText.Next")}
                    </Link>

                </div >


            </React.Fragment>

        );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        requiredEpirbs: state.requiredEpirbs,
        licenceApplicationId: state.currentApplication.id || 0,
        licenceApplicationStatusTypeId: state.currentApplication.licenceApplicationStatusTypeId || 0,
        applicationTypeId: state.currentApplication.applicationTypeId || 1,
        role: state.currentUserInfo.role || "",
        vesselTypeId: state.currentVessel.vesselTypeId || null,

        vesselName: state.currentVessel.name || "",
        callSignNumber: state.currentVessel.callSignNumber || "",
        mmsiNumber: state.currentVessel.mmsiNumber || ""
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getRequiredEpirbs: (applicationId) => {
            dispatch(CreateGetRequiredEpirbAction(applicationId));
        },
        editEPIRB: (epirb) => {
            dispatch({
                type: "EPIRB_DETAILS_RETRIEVED",
                epirb: epirb
            });
        },
        initialiseEpirbData: (epirbMountingTypeId, gpsEnabledRequired, isMedApproved) => {

            //clear any existing Epirb data
            dispatch({
                type: "CLEAR_CURRENT_EPIRB_APPLICATION"
            });

            const gpsEnabledId = gpsEnabledRequired ? 1 : 2;
            dispatch({
                type: "EPIRB_MOUNTING_ID_UPDATED",
                data: epirbMountingTypeId
            });
            dispatch({
                type: "EPIRB_IS_MED_APPROVED_UPDATED",
                data: isMedApproved
            });
            dispatch({
                type: "EPIRB_GPS_ENABLED_ID_UPDATED",
                data: gpsEnabledId
            });
            dispatch({
                type: "EPIRB_AIS_MMSI_NUMBER_UPDATED",
                data: "974"
            });
        },
        clearEPIRB: () => {
            //clear any existing Epirb data
            dispatch({
                type: "CLEAR_CURRENT_EPIRB_APPLICATION"
            });
            dispatch({
                type: "EPIRB_AIS_MMSI_NUMBER_UPDATED",
                data: "974"
            });
        }
    };
};

const languageAwareComponent = withTranslation()(EpirbDetailsList);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);