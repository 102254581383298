import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (applicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "RequiredEquipmentConfiguration/GetEpirbByVesselType?licenceApplicationId=" + applicationId;

        GET(url)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "VESSEL_REQUIRED_EPIRB_RETRIEVED",
                        requiredEpirbRecords: response.data
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}