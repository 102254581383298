import React from "react";
import { connect } from "react-redux";
import LinkButton from "./LinkButton";

const doLogin = () => {
    window.userManager.signinRedirect();
}

const doLogout = () => {
    window.userManager.signoutRedirect();
}

const doRegister = () => {
    window.location.href = "/Account/Register";
}


var displayComponent = ({ userIsAuthenticated, t, clearLicenceApplication }) => {
    return (
        <div>
            {
                userIsAuthenticated
                    ? <div className="btn-group">
                        <LinkButton to={"/apply"} onClick={clearLicenceApplication} className="btn btn-primary" >{t("Common.ButtonText.NewLicenceRequest")}</LinkButton>
                          <button id="logout-button" className="btn btn-light ms-1" onClick={doLogout}>{t("Common.ButtonText.Logout")}</button>
                      </div>
                    :
                    <div className="btn-group">
                        <button id="register-button" className="btn btn-secondary" onClick={doRegister}>{t("Common.ButtonText.Register")}</button>
                        <button id="login-button" className="btn btn-light ms-1" onClick={doLogin}>{t("Common.ButtonText.Login")}</button>
                    </div>
            }
        </div>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        clearLicenceApplication: () => {
            dispatch({
                type: "CLEAR_CURRENT_APPLICATION"
            });
            dispatch({
                type: "CLEAR_LICENCE_IMAGES"
            });
            dispatch({
                type: "CLEAR_CURRENT_VESSEL"
            });
            dispatch({
                type: "CLEAR_VESSEL_IMAGES"
            });
        }
    }
}
export default connect(null, mapDispatchToProps)(displayComponent);