import "bootstrap/dist/css/bootstrap.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router} from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";
import App from "./App";

import "./localization/i18n";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const currentHost = window.location.host;

const identityConfig = {
    authority: "https://" + currentHost + "/",
    client_id: "SRLWebClient",
    redirect_uri: "https://" + currentHost + "/login.html",
    response_type: "code",
    scope: "openid profile IdentityServerApi",
    post_logout_redirect_uri: "https://" + currentHost + "/logout"
}

window.userManager = new window.Oidc.UserManager(identityConfig);

ReactDOM.render(
    <Provider store={store}>
        <Router basename={baseUrl}>
            <React.Suspense fallback="Loading ...">
                <App />
            </React.Suspense>
        </Router>
    </Provider>,
    rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();
// Also, add the following import at the top of this file.
//import registerServiceWorker from './registerServiceWorker';
