import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { POST } from "../adapters/xhr";

export default function (licenceApplicationId) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const { rescueNote } = getState();

        const model = {
            id: rescueNote.rescueNoteId ?? 0,
            licenceApplicationId: licenceApplicationId,
            note: rescueNote.noteDescription,
            startDate: new Date(isNaN(rescueNote.rescueNoteStartDate) ? new Date() : rescueNote.rescueNoteStartDate),
            endDate: new Date(isNaN(rescueNote.rescueNoteEndDate) ? new Date() : rescueNote.rescueNoteEndDate),
            created: new Date(),
            updated: new Date()
        };

        POST("RescueNote/UpdateRescueNote", model)
            .then((response) => {
                if (response.status === 200) {
                    //refresh dashboard data
                    //dispatch(CreateGetCurrentUserInfoAction());
                }
                dispatch(CreateDataRetrievalEndedAction());
            })
            .catch((response) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
