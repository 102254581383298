const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "VESSEL_EQUIPMENT_VHF_TYPES_RETRIEVED":
            return action.vesselEquipmentVhfTypes;

        default:
            return state;
    }
}
