import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (applicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "emergencyContact/GetByLicenceApplicationId";

        const params = {
            licenceApplicationId: applicationId
        };

        GET(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "EMERGENCY_CONTACT_LIST_RETRIEVED",
                        emergencyContacts: response.data
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
