import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (hexcode, epirbDetailId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "EpirbDetail/CheckExistingHexCode";
        const params = {
            hexcode,
            epirbDetailId
        };

        GET(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "EPIRB_HEX_CODE_EXISTS",
                        data: response.data
                    });
                } 

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
