import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (applicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "licenceApplication/GetEmergencyServicesLicenceData";

        const params = {
            licenceApplicationId: applicationId
        };

        GET(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.licenceDetail == null) {
                        dispatch({
                            type: "CLEAR_CURRENT_APPLICATION",
                            data: response.data.licenceDetail
                        });
                    } else {
                        dispatch({
                            type: "LICENCE_APPLICATION_RETRIEVED",
                            data: response.data.licenceDetail
                        });
                    }

                    if (response.data.rescueNote == null) {
                        dispatch({
                            type: "CLEAR_CURRENT_RESCUE_NOTE",
                            data: response.data.licenceDetail
                        });
                    } else {
                        dispatch({
                            type: "RESCUE_NOTE_DESCRIPTION_UPDATED",
                            data: response.data.rescueNote.note
                        });
                    }

                    if (response.data.vesselDetail != null) {
                        dispatch({
                            type: "VESSEL_DATA_RETRIEVED",
                            data: response.data.vesselDetail
                        });
                    }
                    if (response.data.emergencyContacts != null) {
                        dispatch({
                            type: "EMERGENCY_CONTACTS_RETRIEVED",
                            data: response.data.emergencyContacts
                        });
                    }
                    if (response.data.equipment != null) {
                        dispatch({
                            type: "CURRENT_EQUIPMENT_RETRIEVED",
                            data: response.data.equipment
                        });
                    }
                    if (response.data.epirbDetails != null) {
                        dispatch({
                            type: "CURRENT_EPIRB_RETRIEVED",
                            data: response.data.epirbDetails
                        });
                    }

                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

