import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function () {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "licenceApplication/GetWebsiteConfig";

        GET(url)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "WEBSITE_CONFIGS_RETRIEVED",
                        data: response.data
                    });
                }
                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    }
}
