import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function () {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const { currentApplication } = getState();

        const licenceApplicationId = Number(currentApplication.id);

        const url = "FileUpload/GetOperatorCertificateImages";
        const params = {
            licenceApplicationId
        };

        GET(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    var filteredFileUploads = response.data;

                    dispatch({
                        type: "OPERATOR_CERTIFICATE_UPLOADED",
                        data: filteredFileUploads.length
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

