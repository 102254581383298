import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

class NoAccessPage extends Component {

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className="my-3 text-center">
                    <div className="h3">
                        {t("NoAccessPage.TitleLabel")}
                    </div>
                    <div className="fa-icons" >
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'red' }} />
                    </div>

                    <label id="NoAccessLabel" className="h4">{t("NoAccessPage.StepLabel")}</label>
                </div>
                
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

const languageAwareComponent = withTranslation()(NoAccessPage);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);