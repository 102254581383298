import axios from "axios";

// import authService from "../../components/api-authorization/AuthorizeService";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetVesselEquipmentsVHFDetailsAction from "./CreateGetVesselEquipmentsVHFDetailsAction";

export default function (licenceApplicationId) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());
        const { reviewAndDeclaration } = getState();

        const newreviewAndDeclarationDetail = {
            licenceApplicationId: Number(licenceApplicationId) || 0,
            created: new Date(),
            updated: new Date(),
            declarationAccepted: reviewAndDeclaration.delarationsCheckbox || false
        };

        //authService.getAccessToken().then((token) => {
                axios({
                    method: "post",
                    url: "payment/PaymentFailed?licenceApplicationId=" + licenceApplicationId,
                    //headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    //data: newreviewAndDeclarationDetail
                })
                    .then((data) => {
                        dispatch(CreateDataRetrievalEndedAction());
                    })
                    .catch((error) => {
                        dispatch(CreateDataRetrievalEndedAction());
                    });
            //});
    };
}
