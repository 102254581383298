const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "PORTABLE_VHF_DSC_ENABLED_TYPES_RETRIEVED":
            return action.portableVhfDscEnabledTypes;

        default:
            return state;
    }
}
