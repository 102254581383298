import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GETAnonymous } from "../adapters/xhr";

export default function (selectedSeaAreaOfOperationId, vesselTypeId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "certificateType/GetFilteredForDropdown";
        const params = {
            selectedSeaAreaOfOperationId,
            vesselTypeId
        };

        GETAnonymous(url,params)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "CERTIFICATE_TYPES_RETRIEVED",
                        certificateTypes: response.data
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    }
}
