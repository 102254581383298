const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "HULLCOLOR_TYPES_RETRIEVED":
            return action.hullcolors;

        default:
            return state;
    }
}
