import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { POST } from "../adapters/xhr";

export default function (licenceApplicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "manageVesselLicence/IssueLicence?id=" + licenceApplicationId;

        POST(url)
            .then(function (response) {
                dispatch({
                    type: "LICENCE_STATUSTYPEID_UPDATED",
                    data: 4
                });
                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}