import React from "react";
import { connect } from "react-redux";

const doLogin = () => {
    window.userManager.signinRedirect();
}

const doLogout = () => {
    window.userManager.signoutRedirect();
}

const doRegister = () => {
    window.location.href = "/Account/Register";
}


var displayComponent = ({ userIsAuthenticated, t }) => {
    const pathname = window.location.pathname;
    const isEmergencyServicesView = pathname.indexOf("emergency-services-licence-view") > 0;

    return (
        <div>
            {
                userIsAuthenticated && !isEmergencyServicesView &&
                <button id="logout-button" className="btn btn-light ms-1" onClick={doLogout}>{t("Common.ButtonText.Logout")}</button>
            }
            {
                !userIsAuthenticated &&
                <div className="btn-group">
                    <button id="register-button" className="btn btn-secondary" onClick={doRegister}>
                        {t("Common.ButtonText.Register")}
                    </button>
                    <button id="login-button" className="btn btn-light ms-1" onClick={doLogin}>
                        {t("Common.ButtonText.Login")}
                    </button>
                </div>
            }
        </div>
    );
}

export default connect(null, null)(displayComponent);