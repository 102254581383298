import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetRequiredEquipmentAction from "./CreateGetRequiredEquipmentAction";

import { PUT } from "../adapters/xhr";

export default function (id, applicationId, categoryId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "EquipmentAmendment/SetupEquipmentAmendments?licenceApplicationId=" + applicationId
            + "&equipmentId=" + id + "&categoryId=" + categoryId;

        PUT(url)
            .then(function (response) {
                dispatch(CreateGetRequiredEquipmentAction(applicationId));
                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}