const initialState = {
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_CURRENT_RESCUE_NOTE":
            return initialState;

        case "RESCUE_NOTE_RETRIEVED":
            return {
                ...action.data
            };
        case "RESCUE_NOTE_ID_UPDATED":
            return {
                ...state,
                rescueNoteId: action.data
            };
        case "RESCUE_NOTE_DESCRIPTION_UPDATED":
            return {
                ...state,
                noteDescription: action.data
            };
        case "RESCUE_NOTE_START_DATE_UPDATED":
            return {
                ...state,
                rescueNoteStartDate: action.data
            };
        case "RESCUE_NOTE_END_DATE_UPDATED":
            return {
                ...state,
                rescueNoteEndDate: action.data
            };
        case "RESCUE_NOTE_DESCRIPTION_IS_REQUIRED":
            return {
                ...state,
                rescueNoteDescriptionRequired: action.data
            };
        case "RESCUE_NOTE_START_DATE_IS_REQUIRED":
            return {
                ...state,
                rescueNoteStartDateRequired: action.data
            };
        case "RESCUE_NOTE_END_DATE_IS_REQUIRED":
            return {
                ...state,
                rescueNoteEndDateRequired: action.data
            };

        default:
            return state;
    }
}

