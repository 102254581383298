import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GETAnonymous } from "../adapters/xhr";

export default function (selectedCertificateTypeId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "seaAreaOfOperation/GetFilteredForDropdown";
        const params = {
            selectedCertificateTypeId
        };

        GETAnonymous(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "SEA_AREA_OF_OPERATION_TYPES_RETRIEVED",
                        seaAreasOfOperation: response.data
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
