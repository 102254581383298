import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { POST } from "../adapters/xhr";

export default function () {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const { assignLicence } = getState();

        var requestData = {
            vesselName: assignLicence.vesselName,
            callSign: assignLicence.callSign,
            licenceNumber: assignLicence.licenceNumber,
            mmsiNumber: assignLicence.mmsiNumber || ""
        };

        POST("licenceApplication/AssignLicence", requestData)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data) {
                        //success
                        dispatch({
                            type: "CLEAR_ASSIGN_LICENCE"
                        });
                        dispatch({
                            type: "ASSIGN_LICENCE_LICENCE_FOUND",
                            data: 2
                        });
                    } else {
                        //failure
                        dispatch({
                            type: "ASSIGN_LICENCE_LICENCE_FOUND",
                            data: 1
                        });
                    }
                } else {
                    //failure
                    dispatch({
                        type: "ASSIGN_LICENCE_LICENCE_FOUND",
                        data: 1
                    });
                }
                dispatch(CreateDataRetrievalEndedAction());
            })
            .catch((response) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
