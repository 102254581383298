import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ValidationFailureMessage from "./ValidationFailureMessage";

import CreatePaymentFailedAction from "../store/actionCreators/CreateAddPaymentFailureAction";


class PaymentFailed extends Component {
    componentDidMount() {
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className="form-group" style={{ textAlignLast: 'center' }}>

                    <div className="fa-icons" >
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'red' }} />
                    </div>
                    
                    <label id="PaymentFailedLabel"> <h2> {t("PaymentPages.PaymentFailedLabel")}</h2> </label>
                    <br/>

           

                <div>
                    <Link
                        id="addHomeButton"
                        className="btn btn-primary"
                        to={this.props.parentRoute + "/make-payment"}
                        // onClick={() => this.props.addReviewAndDeclaration(this.props.licenceApplicationId)}
                    >
                            {t("PaymentPages.ButtonText.RetryNow")}
                    </Link>

                        <br /> <br />

                    <Link
                        id="addHomeButton"
                        className="btn btn-primary"
                        to={this.props.parentRoute + "/review-declarations"}
                    // onClick={() => this.props.addReviewAndDeclaration(this.props.licenceApplicationId)}
                    >
                        {t("Common.ButtonText.Back")}
                    </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state, ownProps) => {
    return {
        licenceApplicationId: state.currentApplication.id || 0

    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
}

const languageAwareComponent = withTranslation()(PaymentFailed);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);

