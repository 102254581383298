import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CreateAddPaymentConfirmationAction from "../store/actionCreators/CreateAddPaymentConfirmationAction";
import CreateAddPaymentFailureAction from "../store/actionCreators/CreateAddPaymentFailureAction";

class AddReviewAndDeclarations extends Component {

    render() {
        const { t } = this.props;

       


        return (
            <React.Fragment>

                <div className="form-group">

                    <label id="ProgrammedLabel"> Make Payments here.... </label>
                    <br />
                    <br/>

                </div>

                <div>
                    <Link
                        id="addPaymentSuccessButton"
                        className="btn btn-primary"
                        to={this.props.parentRoute + "/payment-success"}
                        onClick={
                            () => { this.props.addPaymentConfimration(this.props.licenceApplicationId, this.props.vesselId, this.props.paymentAmount) }
                        }
                    >
                        {t("Common.ButtonText.Submit")} Success
                    </Link>
                </div>
                <br/> <br/>
                <div>
                    <Link
                        id="addPaymentFailedButton"
                        className="btn btn-primary"
                        to={this.props.parentRoute + "/payment-failed"}
                        onClick={() => this.props.addPaymentFailure(this.props.licenceApplicationId)}
                    >
                        {t("Common.ButtonText.Submit")} Failure
                    </Link>
                </div>

            </React.Fragment>
        );
    };
}

// map redux state attributes to this view's property collection.
const mapStateToProps = (state, ownProps) => {
    return {
        licenceApplicationId: state.currentApplication.id || 0,
        vesselId: state.currentVessel.id || 0,
        paymentAmount: state.paymentAmount
    };
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addPaymentConfimration: (licenceApplicationId, vesselId, paymentAmount) => {
            dispatch(CreateAddPaymentConfirmationAction(licenceApplicationId, vesselId, paymentAmount));
        },
        addPaymentFailure: (licenceApplicationId, vesselId) => {
            dispatch(CreateAddPaymentFailureAction(licenceApplicationId));
        }

    }
}

const languageAwareComponent = withTranslation()(AddReviewAndDeclarations);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);

