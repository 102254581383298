const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "LICENCE_NOTES_RETRIEVED":
            return action.licenceNotes;

        default:
            return state;
    }
}
