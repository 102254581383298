import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ValidationFailureMessage from "./ValidationFailureMessage";
import FileUploader from "./FileUploader";

import CreateAddVesselAction from "../store/actionCreators/CreateAddVesselAction";
import CreateGetVesselTypesAction from "../store/actionCreators/CreateGetVesselTypesAction";
import CreateGetHullColoursAction from "../store/actionCreators/CreateGetHullColorsAction";
import CreateGetSeaAreasOfOperationAction from "../store/actionCreators/CreateGetSeaAreasOfOperationAction";
import CreateCheckIfNamedVesselExistsAction from "../store/actionCreators/CreateCheckIfNamedVesselExistsAction";
import CreateGetAccountingAuthorityIdentificationCodesAction from "../store/actionCreators/CreateGetAccountingAuthorityIdentificationCodesAction"
import CreateUploadVesselPhotoAction from "../store/actionCreators/CreateUploadVesselPhotoAction";
import CreateGetFishingVesselPostfixesAction from "../store/actionCreators/CreateGetFishingVesselPostfixesAction"
import CreateGetFishingVesselPrefixesAction from "../store/actionCreators/CreateGetFishingVesselPrefixesAction"


class AddVessel extends Component {
    componentDidMount() {
        this.props.getVesselTypes();
        this.props.getHullColors();
        this.props.getAccountingAuthorityIdentificationCodes();
        this.props.getFishingVesselPrefixes();
        this.props.getFishingVesselPostfixes();
        this.props.getSeaAreasOfOperation(this.props.selectedCertificateType);
    }

    render() {
        const VesselTypes = {
            PleasureVessel: 1,
            FishingVessel: 2,
            PassengerVessel: 3,
            PassengerShip: 4,
            MerchantVessel: 5
        }

        const hideFVNumberField = this.props.vesselTypeId != VesselTypes.FishingVessel;
        const hideIMONumberField =
            this.props.vesselTypeId != VesselTypes.MerchantVessel
            && this.props.vesselTypeId != VesselTypes.PassengerShip
            && this.props.vesselTypeId != VesselTypes.FishingVessel;

        return (
            <React.Fragment>
                <div className="form-group">
                    <label id="vesselNameLabel">Vessel Name: *</label>

                    <input id="vesselName" type="text" max="50"
                        className="form-control"
                        onChange={this.props.onVesselNameChanged}
                        onBlur={this.props.onVesselNameChangeCompleted}
                        value={this.props.name}
                        data-vessel-id={this.props.id} />
                    <span><i>The name given here will be printed on the radio licence. Please do not use abbreviations, e.g. FV or SY.</i></span>
                    {
                        this.props.nameInUse &&
                        <ValidationFailureMessage>
                            Our records indicate that a vessel with this name exists. If your vessel was previously owned in Ireland, it may already have a ship radio licence. If this is the case, transfer of ownership this radio licence is required.
                        </ValidationFailureMessage>
                    }
                    {
                        this.props.nameRequired &&
                        <ValidationFailureMessage>
                            Vessel Name is required
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-group">
                    <label id="vesselTypeLabel">Type of Vessel: *</label>
                    <select id="vesselTypeId"
                        className="form-control"
                        onChange={this.props.onVesselTypeSelected}
                        onBlur={this.props.onVesselTypeSelectionCompleted}
                        value={this.props.vesselTypeId}
                    >
                        <option value="">Select One Vessel type</option>
                        {
                            this.props.vesselTypes.map(vesselType => (
                                <option key={vesselType.id} value={vesselType.id}>{vesselType.name}</option>
                            ))
                        }
                    </select>
                    {
                        this.props.vesselTypeRequired &&
                        <ValidationFailureMessage>
                            Type of Vessel is required
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-group">
                    <label id="maxVesselCapacityLabel">Maximum Capacity of persons on board: *</label>

                    <input id="maxVesselCapacity" type="Number" min="0" max = "9999"
                        className="form-control"
                        onChange={this.props.onMaxVesselCapacityChanged}
                        onBlur={this.props.onMaxVesselCapacityChangeCompleted}
                        value={this.props.maxVesselCapacity}
                    />
                    {
                        this.props.maxVesselCapacityRequired &&
                        <ValidationFailureMessage>
                            Maximum Capacity of persons on board is required
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-group">
                    <label id="vesselLengthLabel">Vessel Length in Meters: *</label>

                    <input id="vesselLength" type="Number" min="0" step=".01" max="999"
                        className="form-control"
                        onChange={this.props.onVesselLengthChanged}
                        onBlur={this.props.onVesselLengthChangeCompleted}
                        value={this.props.vesselLength}
                    />
                    <span><i>Enter Vessel Length in Metres: This is the vessel's exact registered length. If not known, please insert the overall length in metres. </i></span>
                    {
                        this.props.vesselLengthRequired &&
                        <ValidationFailureMessage>
                            Vessel Length in Meters is required
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-group">
                    <label id="grossTonnageLabel">Gross Tonnage: *</label>

                    <input id="grossTonnage" type="Number" min="0" step=".01"
                        className="form-control"
                        onChange={this.props.onGrossTonnageChanged}
                        onBlur={this.props.onGrossTonnageChangeCompleted}
                        value={this.props.grossTonnage}
                    />
                    <span><i>If an Open Boat the Vessel's Approximate Weight. </i></span>
                    {
                        this.props.grossTonnageRequired &&
                        <ValidationFailureMessage>
                            Gross Tonnage is required
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-group">
                    <label id="hullColorLabel">Hull Colour: </label>
                    <select id="hullColorId"
                        className="form-control"
                        onChange={this.props.onHullColorSelected}
                        value={this.props.hullColorId}
                    >
                        <option value="">Select</option>
                        {
                            this.props.hullColors.map(hullColor => (
                                <option key={hullColor.id} value={hullColor.id}>{hullColor.name}</option>
                            ))
                        }
                    </select>
                    <span><i>Select vessel hull colour (closest match) from the following list. </i></span>
                </div>

                <div className="form-group">
                    <label id="vesselDescriptionLabel"> Vessel Description: *</label>

                    <input id="vesselDescription" type="text"
                        className="form-control"
                        onChange={this.props.onVesselDescriptionChanged}
                        onBlur={this.props.onVesselDescriptionChangeCompleted}
                        value={this.props.vesselDescription}
                    />
                    <span><i>Please provide a short description of the Vessel. </i></span>
                    {
                        this.props.vesselDescriptionRequired &&
                        <ValidationFailureMessage>
                            Vessel Description is required
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-group">
                    <label id="vesselPhotoUploadLabel"> Vessel Photo Upload: </label>

                    {this.props.vesselImagesCount < 6 &&
                        <FileUploader
                            id="vesselPhotoUpload"
                            buttonText="Upload Image"
                            onChange={this.props.uploadVesselPhoto} />
                    }

                    <div> {this.props.vesselImagesCount} Images Uploaded.</div>

                    <span><i> Please attach external photographs of your vessel (maximum 6). These may be referred to by Search and Rescue Authorities as a visual aid in the event of an emergency, to assist in the locating and identification of your vessel. </i></span>
                </div>

                <div className="form-group">
                    <label id="homeportLabel"> Homeport/Normal Area of Operation: *</label>

                    <input id="homeport" type="text" max="255"
                        className="form-control"
                        onChange={this.props.onHomeportChanged}
                        onBlur={this.props.onHomeportChangeCompleted}
                        value={this.props.homeport}
                    />
                    {
                        this.props.homeportRequired &&
                        <ValidationFailureMessage>
                            Homeport/Normal Area of Operation is required
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-group">
                    <label id="maxAreaOfOperationLabel">Maximum Sea Area of Operation: *</label>
                    <select id="maxAreaOfOperationId"
                        className="form-control"
                        onChange={this.props.onSeaAreaOfOperationSelected}
                        onBlur={this.props.onSeaAreaOfOperationSelectionCompleted}
                        value={this.props.seaAreaOfOperationId}
                    >
                        <option value="">Select</option>
                        {
                            this.props.seaAreasOfOperation.map(seaAreaOfOperation => (
                                <option key={seaAreaOfOperation.id} value={seaAreaOfOperation.id}>{seaAreaOfOperation.name}</option>
                            ))
                        }
                    </select>
                    {
                        this.props.seaAreaOfOperationRequired &&
                        <ValidationFailureMessage>
                            Maximum Sea Area of Operation is required
                        </ValidationFailureMessage>
                    }
                </div>

                <div className="form-group">
                    <label id="vesselRegistrationNumberLabel"> Vessel Registration Number: </label>

                    <input id="vesselRegistrationNumber" type="text"
                        className="form-control"
                        onChange={this.props.onVesselRegistrationNumberChanged}
                        value={this.props.vesselRegistrationNumber}
                    />
                    <span><i> If Vessel is Registered.</i></span>
                </div>

                <div className="form-group" hidden={hideFVNumberField}>

                    <label id="fvIdPrefixLabel"> Fisihing Vessel Prefix: </label>
                    <select id="fvIdPrefixId"
                        className="form-control"
                        onChange={this.props.onFvIdPrefixSelected}
                        value={this.props.fvIdPrefixId}
                    >
                        <option value="">Select</option>
                        {
                            this.props.fishingVesselPrefixes.map(fvIdPrefix => (
                                <option key={fvIdPrefix.id} value={fvIdPrefix.id}>{fvIdPrefix.name}</option>
                            ))
                        }
                    </select>

                    <label id="fvVesselIdentificationNumberLabel"> FV Vessel Identification Number: </label>
                    <input id="fvVesselIdentificationNumber" type="text"
                        className="form-control"
                        onChange={this.props.onFvVesselIdentificationNumberChanged}
                        value={this.props.fvVesselIdentificationNumber}
                    />

                    <label id="fvIdPostfixLabel"> Fisihing Vessel Postfix: </label>
                    <select id="fvIdPostfixId"
                        className="form-control"
                        onChange={this.props.onFvIdPostfixSelected}
                        value={this.props.fvIdPostfixId}
                    >
                        <option value="">Select</option>
                        {
                            this.props.fishingVesselPostfixes.map(fvIdPostfix => (
                                <option key={fvIdPostfix.id} value={fvIdPostfix.id}>{fvIdPostfix.name}</option>
                            ))
                        }
                    </select>

                </div>

                <div className="form-group" hidden={hideIMONumberField}>
                    <label id="vesselImoNumberLabel"> IMO Number: </label>

                    <input id="vesselImoNumber" type="text"
                        className="form-control"
                        onChange={this.props.onVesselImoNumberChanged}
                        value={this.props.vesselImoNumber}
                    />
                    <span><i> If Applicable.</i></span>
                </div>

                <div className="form-group">
                    <label id="numberOfLifeBoatsLabel"> Number of Life Boats/Life Rafts: </label>

                    <input id="numberOfLifeBoats" type="Number"
                        className="form-control"
                        onChange={this.props.onNumberOfLifeBoatsChanged}
                        value={this.props.numberOfLifeBoats}
                    />
                    <span><i> If Applicable.</i></span>
                </div>

                <div className="form-group">
                    <label id="accountingAuthorityIdentificationCodeLabel"> Accounting Authority Identification Code (AAIC): </label>

                    <select id="accountingAuthorityIdentificationCode"
                        className="form-control"
                        onChange={this.props.onAccountingAuthorityIdentificationCodeChanged}
                        value={this.props.accountingAuthorityIdentificationCode}
                    >
                    <option value="">Select</option>
                        {
                            this.props.accountingAuthorityIdentificationCodes.map(accountingAuthorityIdentificationCode => (
                                <option key={accountingAuthorityIdentificationCode.id}
                                    value={accountingAuthorityIdentificationCode.id}>
                                    {accountingAuthorityIdentificationCode.name}
                                </option>
                        ))
                    }
                    </select>
                    <span><i>Vessels which operate internationally may have an agreement with an Accounting Authority. An AAIC is a billing intermediary company used to handle charges if radio to telephone calls are made via a coast radio station.</i></span>
                </div>

                <div>
                    <Link
                        id="addVesselButton"
                        className="btn btn-primary"
                        to={this.props.parentRoute + "/emergency-contacts-list"}
                        onClick={() => this.props.addVessel(this.props.licenceApplicationId)}
                    >
                        Next
                </Link>

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: state.currentVessel.id || 0,
        licenceApplicationId: state.currentApplication.id || 0,
        name: state.currentVessel.name || "",
        nameInUse: state.currentVessel.nameInUse,
        vesselTypeId: state.currentVessel.vesselTypeId || "",
        hullColorId: state.currentVessel.hullColorId || "",
        seaAreaOfOperationId: state.currentVessel.seaAreaOfOperationId || "",
        vesselTypes: state.availableVesseltypes,
        hullColors: state.availableHullColor,
        seaAreasOfOperation: state.availableSeaAreaOfOperation,
        accountingAuthorityIdentificationCodes: state.accountingAuthorityIdentificationCodes,
        fishingVesselPrefixes: state.fishingVesselPrefixes,
        fishingVesselPostfixes: state.fishingVesselPostfixes,

        maxVesselCapacity: state.currentVessel.maxVesselCapacity || "",
        vesselLength: state.currentVessel.vesselLength || "",
        grossTonnage: state.currentVessel.grossTonnage || "",
        vesselDescription: state.currentVessel.vesselDescription || "",
        homeport: state.currentVessel.homeport || "",
        vesselRegistrationNumber: state.currentVessel.vesselRegistrationNumber || "",
        fvIdPostfixId: state.currentVessel.fvIdPostfixId || "",
        fvVesselIdentificationNumber: state.currentVessel.fvVesselIdentificationNumber || "",
        fvIdPrefixId: state.currentVessel.fvIdPrefixId || "",
        vesselImoNumber: state.currentVessel.vesselImoNumber || "",
        numberOfLifeBoats: state.currentVessel.numberOfLifeBoats || "",
        accountingAuthorityIdentificationCodeId: state.currentVessel.accountingAuthorityIdentificationCodeId || "",
        selectedCertificateType: state.currentApplication && state.currentApplication.certificateTypeId,
        vesselImagesCount: (state.currentApplication && state.currentApplication.vesselImagesCount) || 0,

        // validations
        nameRequired: state.currentVessel.nameRequired || false,
        vesselTypeRequired: state.currentVessel.vesselTypeRequired || false,
        maxVesselCapacityRequired: state.currentVessel.maxVesselCapacityRequired || false,
        vesselLengthRequired: state.currentVessel.vesselLengthRequired || false,
        grossTonnageRequired: state.currentVessel.grossTonnageRequired || false,
        vesselDescriptionRequired: state.currentVessel.vesselDescriptionRequired || false,
        homeportRequired: state.currentVessel.homeportRequired || false,
        seaAreaOfOperationRequired: state.currentVessel.seaAreaOfOperationRequired || false,

    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getVesselTypes: () => {
            dispatch(CreateGetVesselTypesAction());
        },
        getHullColors: () => {
            dispatch(CreateGetHullColoursAction());
        },
        getAccountingAuthorityIdentificationCodes: () => {
            dispatch(CreateGetAccountingAuthorityIdentificationCodesAction());
        },
        getFishingVesselPrefixes: () => {
            dispatch(CreateGetFishingVesselPrefixesAction());
        },
        getFishingVesselPostfixes: () => {
            dispatch(CreateGetFishingVesselPostfixesAction());
        },
        getSeaAreasOfOperation: (selectedCertificateType) => {
            dispatch(CreateGetSeaAreasOfOperationAction(selectedCertificateType));
        },

        addVessel: (licenceApplicationId) => {
            dispatch(CreateAddVesselAction(licenceApplicationId));
        },
        onVesselNameChanged: (evt) => {
            var updatedName = evt.target.value;
            if (updatedName.length > 50) {
                return;
            }
            dispatch({
                type: "UPDATE_CURRENT_VESSEL_NAME",
                data: updatedName
            });

            var vesselId = 0;

            if (evt.target.dataset.vesselId) {
                vesselId = Number(evt.target.dataset.vesselId);
            }

            dispatch(CreateCheckIfNamedVesselExistsAction(updatedName, vesselId));
        },
        onVesselNameChangeCompleted: (evt) => {
            var updatedName = evt.target.value;

            if (updatedName === "" || updatedName === null || updatedName.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_NAME_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_NAME_REQUIRED",
                    data: false
                });
            }
        },

        onMaxVesselCapacityChanged: (evt) => {
            var maxCapicity = evt.target.value;
            if (maxCapicity > 9999) {
                return;
            }
            dispatch({
                type: "UPDATE_VESSEL_MAX_CAPACITY",
                data: maxCapicity
            });
        },
        onMaxVesselCapacityChangeCompleted: (evt) => {
            var maxCapicity = evt.target.value;
            if (maxCapicity > 999) {
                return;
            }
            if (maxCapicity === "" || maxCapicity === null || maxCapicity.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_MAX_CAPACITY_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_MAX_CAPACITY_REQUIRED",
                    data: false
                });
            }
        },

        onVesselLengthChanged: (evt) => {
            var vesselLength = evt.target.value;

            if (isNaN(vesselLength)) {
                return;
            }

            if (Number(vesselLength) < 0 || Number(vesselLength) > 999) {
                return;
            }

            if (vesselLength.indexOf('.') > -1) {
                var vesselLengthCharCount = vesselLength.length;

                if (vesselLength.indexOf('.') < vesselLengthCharCount - 3) {
                    return;
                }
            }

            dispatch({
                type: "UPDATE_VESSEL_LENGTH",
                data: vesselLength
            });
        },
        onVesselLengthChangeCompleted: (evt) => {
            var vesselLength = evt.target.value;
            if (vesselLength === "" || vesselLength === null || vesselLength.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_LENGTH_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_LENGTH_REQUIRED",
                    data: false
                });
            }
        },
        onGrossTonnageChanged: (evt) => {
            var updatedGrossTonnage = evt.target.value;

            if (isNaN(updatedGrossTonnage)) {
                return;
            }

            if (Number(updatedGrossTonnage) < 0 || Number(updatedGrossTonnage) > 999999) {
                return;
            }

            if (updatedGrossTonnage.indexOf('.') > -1) {
                var updatedGrossTonnageCharCount = updatedGrossTonnage.length;

                if (updatedGrossTonnage.indexOf('.') < updatedGrossTonnageCharCount - 3) {
                    return;
                }
            }


            dispatch({
                type: "UPDATED_GROSS_TONNAGE",
                data: updatedGrossTonnage
            });
        },
        onGrossTonnageChangeCompleted: (evt) => {
            var updatedGrossTonnage = evt.target.value;
            if (updatedGrossTonnage === "" || updatedGrossTonnage === null || updatedGrossTonnage.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_GROSS_TONNAGE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_GROSS_TONNAGE_REQUIRED",
                    data: false
                });
            }
        },
        onVesselDescriptionChanged: (evt) => {
            var updatedVesselDescription = evt.target.value;
            if (updatedVesselDescription.length > 400) {
                return;
            }
            dispatch({
                type: "UPDATED_VESSEL_DESCRIPTION",
                data: updatedVesselDescription
            });
        },
        onVesselDescriptionChangeCompleted: (evt) => {
            var updatedVesselDescription = evt.target.value;
            if (updatedVesselDescription === "" || updatedVesselDescription === null || updatedVesselDescription.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_DESCRIPTION_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_DESCRIPTION_REQUIRED",
                    data: false
                });
            }
        },

        onHomeportChanged: (evt) => {
            var updatedHomeport = evt.target.value;
            if (updatedHomeport.length > 50 ) {
                return;
            }
            dispatch({
                type: "UPDATE_HOMEPORT",
                data: updatedHomeport
            });
        },
        onHomeportChangeCompleted: (evt) => {
            var updatedHomeport = evt.target.value;
            if (updatedHomeport === "" || updatedHomeport === null || updatedHomeport.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_HOMEPORT_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_HOMEPORT_REQUIRED",
                    data: false
                });
            }
        },

        onVesselRegistrationNumberChanged: (evt) => {
            var updateVesselRegistrationNumber = evt.target.value;
            if (updateVesselRegistrationNumber.length >7) {
                return;
            }
            dispatch({
                type: "UPDATE_VESSEL_REGISTRATION_NUMBER",
                data: updateVesselRegistrationNumber
            });
        },
        onFvIdPrefixSelected: (evt) => {
            var updateFvIdPrefix = evt.target.value;
            
            dispatch({
                type: "UPDATE_FV_VESSEL_ID_PREFIX",
                data: updateFvIdPrefix
            });
        },
        onFvVesselIdentificationNumberChanged: (evt) => {
            var updateFvVesselIdentificationNumber = evt.target.value;
            if (updateFvVesselIdentificationNumber.includes(".") || isNaN(updateFvVesselIdentificationNumber) || updateFvVesselIdentificationNumber.length > 4) {
                return;
            }
            dispatch({
                type: "UPDATE_FV_VESSEL_ID_NUMBER",
                data: updateFvVesselIdentificationNumber
            });
        },
        onFvIdPostfixSelected: (evt) => {
            var updateFvIdPostfix = evt.target.value;

            dispatch({
                type: "UPDATE_FV_VESSEL_ID_POSTFIX",
                data: updateFvIdPostfix
            });
        },
        onVesselImoNumberChanged: (evt) => {
            var updatedVesselImoNumber = evt.target.value;
            if (updatedVesselImoNumber.length > 7) {
                return;
            }
            dispatch({
                type: "UPDATE_VESSEL_IMO_NUMBER",
                data: updatedVesselImoNumber
            });
        },
        onNumberOfLifeBoatsChanged: (evt) => {
            var updatedNumberOfLifeBoats = evt.target.value;
            if (updatedNumberOfLifeBoats > 100) {
                return;
            }
            dispatch({
                type: "UPDATE_LIFE_BOATS",
                data: updatedNumberOfLifeBoats
            });
        },
        onAccountingAuthorityIdentificationCodeChanged: (evt) => {
            var updatedAccountingAuthorityIdentificationCode = evt.target.value;
            dispatch({
                type: "UPDATE_ACCOUNTING_AUTHORITY_ID_CODE",
                data: updatedAccountingAuthorityIdentificationCode
            });
        },

        onVesselTypeSelected: (evt) => {
            var selectedVesselType = evt.target.value;

            dispatch({
                type: "UPDATE_CURRENT_VESSEL_TYPE",
                data: selectedVesselType
            });
        },
        onVesselTypeSelectionCompleted: (evt) => {
            var selectedVesselType = evt.target.value;

            if (selectedVesselType === "" || selectedVesselType === null || selectedVesselType.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_TYPE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_TYPE_REQUIRED",
                    data: false
                });
            }
        },
        onHullColorSelected: (evt) => {
            var selectedHullColor = evt.target.value;

            dispatch({
                type: "UPDATE_VESSEL_HULLCOLOR",
                data: selectedHullColor
            });
        },
        onSeaAreaOfOperationSelected: (evt) => {
            var selectedSeaAreaOfOperation = evt.target.value;

            dispatch({
                type: "UPDATE_VESSEL_SEA_AREA_OF_OPERATION",
                data: selectedSeaAreaOfOperation
            });
        },
        onSeaAreaOfOperationSelectionCompleted: (evt) => {
            var selectedSeaAreaOfOperation = evt.target.value;
            if (selectedSeaAreaOfOperation === "" || selectedSeaAreaOfOperation === null || selectedSeaAreaOfOperation.length === 0) {
                dispatch({
                    type: "UPDATE_VESSEL_SEA_AREA_OF_OPERATION_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_VESSEL_SEA_AREA_OF_OPERATION_REQUIRED",
                    data: false
                });
            }
        },
        uploadVesselPhoto: (file) => {
            dispatch(CreateUploadVesselPhotoAction(file));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVessel);

