import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../datatables.min.css";
import PageHeader from "./PageHeader";
import Breadcrumb from "./Breadcrumb";

import CreateGetLicenceNotesAction from "../store/actionCreators/CreateGetLicenceNotesAction";
import CreateGetNoteTemplatesAction from "../store/actionCreators/CreateGetNoteTemplatesAction";
import CreateGetNoteTemplateDescriptionAction from "../store/actionCreators/CreateGetNoteTemplateDescriptionAction";
import CreateSubmitNoteAction from "../store/actionCreators/CreateSubmitNoteAction";

const $ = require("jquery");
$.DataTable = require("datatables.net");

class LicenceNote extends Component {
    constructor() {
        super();

        this.initialiseTable = this.initialiseTable.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.destroyTable = this.destroyTable.bind(this);
    }

    initialiseTable(notes) {
        $(this.refs.main).DataTable({
            //dom: '<"data-table-wrapper"t>',
            dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            data: notes,
            pageLength: 5,
            lengthMenu: [5, 10, 25, 50, 100],
            order: [[0, "desc"]],
            language: { emptyTable: "No notes exist for this licence." },
            columns: [
                {
                    data: "created",
                    render: function (data, type, full) {
                        if (type === "sort") {
                            return data;
                        }
                        return new Date(data).toLocaleDateString("en-gb") + " " + new Date(data).toLocaleTimeString();
                    }
                },
                {
                    data: "isInternal",
                    render: function (data, type, full) {
                        return data ? "No" : "Yes";
                    },
                    className: "text-center"
                },
                {
                    data: "description"
                }
            ]
        });
    }

    destroyTable() {
        const table = $(this.refs.main)
            .DataTable();

        if (table) {
            table.destroy();
        }
    }

    updateTable(notes) {
        this.destroyTable();
        this.initialiseTable(notes);
    }

    componentDidMount() {
        this.props.getLicenceNotes(this.props.licenceApplicationId);
        this.initialiseTable();
        this.props.getNoteTemplates();
    }

    componentDidUpdate() {
        this.updateTable(this.props.licenceNotes);
    }

    componentWillUnmount() {
        $(this.refs.main).DataTable().destroy();
    }

    render() {
        const { t } = this.props;

        const noteTemplateFields = [
            { text: "First Name", value: "{{firstName}}" },
            { text: "Last Name", value: "{{lastName}}" },
            { text: "Username", value: "{{userName}}" },
            { text: "Address", value: "{{address}}" },
            { text: "SRL Web Address", value: "{{srlUrl}}" },
            { text: "Vessel Name", value: "{{vesselName}}" },
            { text: "Vessel Type", value: "{{vesselType}}" },
            { text: "MMSI Number", value: "{{mmsiNumber}}" },
            { text: "Call Sign Number", value: "{{callSignNumber}}"},
            { text: "Issue Date", value: "{{issueDate}}" },
            { text: "Licence Number", value: "{{licenceNumber}}" }
        ];
        const isInternal = this.props.isInternal === undefined || this.props.isInternal;

        // disable the submit button if required fields are missing
        const canSubmitForm = ((isInternal && this.props.noteDescription.trim() !== "") ||
            (!isInternal && this.props.noteDescription.trim() !== "" && this.props.noteTemplateId !== "")
        );

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6,
            TransferInitiated: 7,
            Transferred: 8
        };

        const isShipOwerWithNonIssuedLicence = this.props.role === "Ship Owner" && (this.props.licenceApplicationStatusTypeId == LicenceStatus.InProgress
            || this.props.licenceApplicationStatusTypeId == LicenceStatus.ReadyToReview);

        return (
            <React.Fragment>

                <Modal size="lg" show={this.props.showModal} onHide={this.props.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Common.ButtonText.AddNote")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 mb-2" onChange={event => this.props.setIsInternal(event)}>
                                <input className="form-check-input" type="radio" name="isInternal" id="isInternalTrue" defaultChecked />
                                <label className="form-check-label ms-1 form-label" htmlFor="isInternalTrue">
                                    {t("LicenceNotes.InternalNote")}
                                </label>
                                <input className="form-check-input ms-3" type="radio" name="isInternal" id="isInternalFalse" />
                                <label className="form-check-label ms-1 form-label" htmlFor="isInternalFalse">
                                    {t("LicenceNotes.ExternalNote")}
                                </label>
                            </div>

                            <div className="form-group col-md-12" hidden={isInternal}>
                                <label id="noteTemplateLabel" htmlFor="noteTemplateId" className="form-label">{t("LicenceNotes.Template")} *</label>
                                <select id="noteTemplateId"
                                        className="form-control"
                                        onChange={this.props.onNoteTemplateSelected}
                                        value={this.props.noteTemplateId}
                                >
                                    <option value="">{t("LicenceNotes.NoteTemplateSelect")}</option>
                                    {
                                        this.props.noteTemplates.map(x => (
                                            <option key={x.id} value={x.id}>{x.name}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="form-group col-md-12" hidden={isInternal}>
                                <label id="noteTemplateFieldLabel" htmlFor="noteTemplateField" className="form-label">{t("LicenceNotes.TemplateField")}</label>
                                {
                                    this.props.templateField && this.props.templateField !== "" &&
                                    <span className="ms-3 alert-info">{t("LicenceNotes.TemplateFieldCopied", { fieldName: this.props.templateField })}</span>
                                }
                                <select id="noteTemplateField"
                                        className="form-control"
                                        onChange={this.props.onNoteTemplateFieldSelected}
                                >
                                    <option value="">{t("LicenceNotes.NoteTemplateFieldSelect")}</option>
                                    {
                                        noteTemplateFields.map(x => (
                                            <option key={x.value} value={x.value}>{x.text}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="form-group col-md-12" hidden={isInternal}>
                                <label id="noteAttachmentLabel" htmlFor="noteAttachment" className="form-label">{t("LicenceNotes.NoteAttachment")}</label>
                                <input type="file"
                                    id="noteAttachment"
                                    onChange={this.props.onInputChange}
                                    key={this.props.noteAttachmentName}
                                    className="form-control"
                                    multiple="" />
                            </div>

                            <div className="form-group mt-1">
                                <label id="noteDescriptionLabel" htmlFor="noteDescription" className="form-label">{t("LicenceNotes.Note")} *</label>
                                <textarea id="noteDescription"
                                    className="form-control" required
                                    onChange={this.props.onNoteDescriptionChanged}
                                    value={this.props.noteDescription}
                                    rows={5}
                                    cols={20} />
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.handleClose}>
                            {t("Common.ButtonText.Cancel")}
                        </Button>
                        <Button variant="primary"
                            disabled={!canSubmitForm}
                            onClick={() => { this.props.submitNote(this.props.licenceApplicationId) }}>
                            {t("Common.ButtonText.Save")}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    licenceList={t("AdminLandingPage.LicenceList")}
                    dashboard={t("Common.Dashboard")}
                    parentLink=""
                    parentPageName=""
                    pageName={t("LicenceNotes.PageHeading")} />

                <PageHeader
                    pageTitle={t("LicenceNotes.PageHeading")}
                    vesselLabel={t("OwnerLandingPage.VesselName")}
                    vesselName={this.props.vesselName}
                    isShipOwerWithNonIssuedLicence={isShipOwerWithNonIssuedLicence}
                    mmsiLabel={t("OwnerLandingPage.MMSINumber")}
                    mmsiNumber={this.props.mmsiNumber}
                    callSignLabel={t("OwnerLandingPage.CallSign")}
                    callSignNumber={this.props.callSignNumber}
                    pageDescription=""
                    requiredField="" />
                
                <table ref="main" className="table table-responsive table-striped table-bordered">
                    <thead>
                        <tr>
                            <th style={{ maxWidth: "90px" }}>{t("LicenceNotes.Created")}</th>
                            <th style={{ maxWidth: "60px" }}>{t("LicenceNotes.Emailed")}</th>
                            <th>{t("LicenceNotes.Note")}</th>
                        </tr>
                    </thead>
                </table>

                <div className="my-3">
                    <Link id="btnAddNote"
                        to="#" className="btn btn-secondary"
                            onClick={() => { this.props.handleShow() }}>
                        {t("Common.ButtonText.AddNote")}
                    </Link>
                    <Link
                        id="btnReview"
                        className="btn btn-primary ms-3"
                        to={this.props.parentRoute + "/review-declarations/" + this.props.licenceApplicationId}>
                        {t("Common.ButtonText.Review")}
                    </Link>
                </div >
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        licenceApplicationId: state.currentApplication.id || 0,
        role: state.currentUserInfo.role || "",
        licenceNotes: state.licenceNotes || [],

        noteTemplates: state.availableNoteTemplates,

        isInternal: state.noteTemplate.isInternal,
        noteTemplateId: state.noteTemplate.noteTemplateId || "",
        noteDescription: state.noteTemplate.noteDescription || "",
        showModal: state.noteTemplate.showModal || false,

        templateField: state.noteTemplate.noteTemplateField || "",
        noteAttachmentName: state.noteTemplate.noteAttachmentName || "",

        vesselName: state.currentVessel.name || "",
        callSignNumber: state.currentVessel.callSignNumber || "",
        mmsiNumber: state.currentVessel.mmsiNumber || ""
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLicenceNotes: (licenceApplicationId) => {
            dispatch(CreateGetLicenceNotesAction(licenceApplicationId));
        },
        getNoteTemplates: () => {
            dispatch(CreateGetNoteTemplatesAction());
        },
        setIsInternal: (evt) => {
            var isInternalTrue = evt.target.id === "isInternalTrue" ? true : false; // evt.target.checked;

            dispatch({
                type: "RESET_NOTE_TEMPLATE_DATA"
            });

            dispatch({
                type: "NOTE_TEMPLATE_IS_INTERNAL_UPDATED",
                data: isInternalTrue
            });

            //var d = new Date();
            //var dateTimeString = d.getFullYear()
            //    + ("0" + (d.getMonth() + 1)).slice(-2)
            //    + ("0" + d.getDate()).slice(-2)
            //    + ("0" + d.getHours()).slice(-2)
            //    + ("0" + d.getMinutes()).slice(-2)
            //    + ("0" + d.getSeconds()).slice(-2);

            dispatch({
                type: "NOTE_TEMPLATE_ATTACHMENT_NAME_UPDATED",
                data: Date.now()
            });
        },
        onNoteTemplateSelected: (evt) => {
            var noteTemplateId = evt.target.value;
            const noteTemplateName = evt.target.options[evt.target.selectedIndex].text;

            dispatch({
                type: "NOTE_TEMPLATE_TEMPLATEID_UPDATED",
                data: noteTemplateId
            });

            dispatch({
                type: "NOTE_TEMPLATE_NAME_UPDATED",
                data: noteTemplateName
            });

            if (noteTemplateId === "") {
                dispatch({
                    type: "NOTE_TEMPLATE_DESCRIPTION_UPDATED",
                    noteDescription: ""
                });
            } else {
                dispatch(CreateGetNoteTemplateDescriptionAction(noteTemplateId));
            }
        },
        onNoteTemplateFieldSelected: (evt) => {
            const noteTemplateField = evt.target.value;
            const noteTemplateFieldName = evt.target.options[evt.target.selectedIndex].text;

            if (noteTemplateField === "") {
                dispatch({
                    type: "NOTE_TEMPLATE_FIELD_UPDATED",
                    data: ""
                });
                return;
            }

            dispatch({
                type: "NOTE_TEMPLATE_FIELD_UPDATED",
                data: noteTemplateFieldName
            });

            /* Copy the text inside the text field */
            navigator.clipboard.writeText(noteTemplateField);

        },
        onInputChange: (e) => {

            dispatch({
                type: "NOTE_TEMPLATE_ATTACHMENT_UPDATED",
                data: e.target.files[0]
            });
        },
        onNoteDescriptionChanged: (evt) => {
            var noteDescription = evt.target.value;
          
            dispatch({
                type: "NOTE_TEMPLATE_DESCRIPTION_UPDATED",
                noteDescription: noteDescription
            });
        },
        handleShow: () => {
            dispatch({
                type: "NOTE_TEMPLATE_UPDATED_SHOW_MODAL",
                data: true
            });
            //var d = new Date();
            //var dateTimeString = d.getFullYear()
            //    + ("0" + (d.getMonth() + 1)).slice(-2)
            //    + ("0" + d.getDate()).slice(-2)
            //    + ("0" + d.getHours()).slice(-2)
            //    + ("0" + d.getMinutes()).slice(-2)
            //    + ("0" + d.getSeconds()).slice(-2);

            dispatch({
                type: "NOTE_TEMPLATE_ATTACHMENT_NAME_UPDATED",
                data: Date.now()
            });
        },
        handleClose: () => {
            dispatch({
                type: "NOTE_TEMPLATE_UPDATED_SHOW_MODAL",
                data: false
            });
            dispatch({
                type: "CLEAR_NOTE_TEMPLATE_DATA"
            });
        },
        submitNote: (licenceApplicationId) => {

            dispatch(CreateSubmitNoteAction(licenceApplicationId));

            dispatch({
                type: "CLEAR_NOTE_TEMPLATE_DATA"
            });
        }
    };
};


const languageAwareComponent = withTranslation()(LicenceNote);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);