import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GETAnonymous } from "../adapters/xhr";

export default function (updatedCertTypeId, seaAreaOfOperationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "seaAreaOfOperation/IsSeaAreaValid";
        const params = {
            updatedCertTypeId,
            seaAreaOfOperationId
        };

        GETAnonymous(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    
                    dispatch({
                        type: "UPDATE_VESSEL_SEA_AREA_OF_OPERATION_REQUIRED",
                        data: !response.data
                    });

                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
