import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (dob) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "seafarerInformation/IsDobValid";
        
        const params = getParams(dob, getState);

        GET(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "DOB_VALIDATED",
                        data: response.data
                    });
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

function getParams(dob, getState)
{
    const dateOfBirth = new Date(dob);
    dateOfBirth.setHours(4, 4, 4, 4);

    const { currentApplication } = getState();

    const params = {
        dob: dateOfBirth.toISOString(),
        certificateNumber: currentApplication.certificateNumber,
        certificateTypeId: currentApplication.certificateTypeId
    };

    return params;
}