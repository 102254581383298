import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Accordion } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileWord, faFileVideo } from '@fortawesome/free-solid-svg-icons';
import Breadcrumb from "../Breadcrumb";

import CreateGetUsefulDocumentsAction from "../../store/actionCreators/CreateGetUsefulDocumentsAction";

class PublicVideoPage extends Component {
    constructor() {
        super();

        this.doLogin = this.doLogin.bind(this);

        this.state = {
            collapsed: true,
            userIsAuthenticated: false
        };
    }

    componentDidMount() {
        this.props.getUsefulDocuments();

        const that = this;

        window.userManager.getUser().then(function (user) {
            that.setState({
                userIsAuthenticated: user != null
            });
        });
    }

     

    doLogin() {
        var { history } = this.props;

        window.userManager.getUser().then(function(user) {
            if (user) {
                history.push("dashboard");
            } else {
                window.userManager.signinRedirect();        
            }
        });
    }


    render() {

        //const t = this.props.t;
        const { t } = this.props;

        const usefulDocumentVideos = this.props.usefulDocs.length > 0
            ? this.props.usefulDocs.filter(x => x.mimeType === "video/mp4")
            : null;

        return (
            <React.Fragment>

                <Breadcrumb
                    role=""
                    homeLink={t("Common.ButtonText.Home")}
                    pageName={t("PublicLandingPage.Videos")} />

                <div className="row">
                    <div className="col-md-12">
                        <div class="h5 text-center">{t("PublicLandingPage.Videos")}</div>
                    </div>
                </div>
                <hr className="srl-hr" />
                <div className="mb-5">
                    {
                        usefulDocumentVideos && usefulDocumentVideos.length > 0 &&
                        <Accordion flush>
                            {
                                usefulDocumentVideos.map((x, index) => {
                                    return (
                                        <Accordion.Item key={index} eventKey={x.id}>
                                            <Accordion.Header><strong>{x.name}</strong></Accordion.Header>
                                            <Accordion.Body>
                                                <FontAwesomeIcon icon={faFileVideo} style={{ fontSize: 4 }} />
                                                <Link className="mx-2" to={"/usefulDocuments/" + x.path} target="_blank" >{x.name}</Link>               
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })
                            }
                        </Accordion>
                    }
                </div>  
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        usefulDocs: state.usefulDocuments || []
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUsefulDocuments: () => {
            dispatch(CreateGetUsefulDocumentsAction());
        }
    };
};

const languageAwareComponent = withTranslation()(withRouter(PublicVideoPage));

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);