const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "EPIRB_HOMING_TYPES_RETRIEVED":
            return action.epirbHomings;

        default:
            return state;
    }
}
