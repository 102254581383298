import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import LinkButton from "./LandingPage/LinkButton";
import ValidationFailureMessage from "./ValidationFailureMessage";
import MastercardImage from "../Images/ECMC.gif";
import VisaImage from "../Images/VISA.gif";
import MaestroImage from "../Images/MAESTRO.gif";
import PageHeader from "./PageHeader";
import Breadcrumb from "./Breadcrumb";

import CreateAddReviewAndDeclarationsAction from "../store/actionCreators/CreateAddReviewAndDeclarationsAction";
import CreateGetPricingsAction from "../store/actionCreators/CreateGetPricingsAction"
import CreateIssueLicenceApplicationAction from "../store/actionCreators/CreateIssueLicenceApplicationAction";
import CreateGetWebsiteConfigAction from "../store/actionCreators/CreateGetWebsiteConfigAction";
import CreateCancelLicenceApplicationAction from "../store/actionCreators/CreateCancelLicenceApplicationAction";
import CreateUndoCancelLicenceApplicationAction from "../store/actionCreators/CreateUndoCancelLicenceApplicationAction";
import CreateGetLicenceNotesAction from "../store/actionCreators/CreateGetLicenceNotesAction";

class AddReviewAndDeclarations extends Component {
    componentDidMount() {
        this.props.setDeclarationsCheckBoxToDefault();
        this.props.getPricingsAction();
        this.props.getWebsiteConfig();
        //this.props.getLicenceNotes(this.props.licenceApplicationId); retrieved in LicenceApplicationForm.jsx
    }

    render() {
        const { t } = this.props;

        const LicenceStatus = {
            InProgress: 1,
            ReadyToReview: 2,
            AwaitingResponse: 3,
            LicenceIssued: 4,
            Dispatched: 5,
            Cancelled: 6
        };

        const ApplicationType = {
            NewShipRadioLicence: 1,
            TransferLicence: 2,
            AmendLicence: 3
        };

        var paymentDetail = this.props.paymentDetails.filter(p => p.id === this.props.applicationTypeId)[0];
        var paymentAmount = Number(paymentDetail?.price) || 100;
        var licenceTypeText;

        switch (this.props.applicationTypeId) {
            case ApplicationType.NewShipRadioLicence:
                licenceTypeText = t("ReviewAndDeclarations.NewLicence");
                break;
            case ApplicationType.TransferLicence:
                licenceTypeText = t("ReviewAndDeclarations.TransferLicence");
                break;
            default:
                licenceTypeText = t("ReviewAndDeclarations.LicenceAmendment");
                break;
        }

        const hasRequiredEpirbs = (this.props.requiredEpirbs !== null);
        const outstandingEpirbs = hasRequiredEpirbs && this.props.requiredEpirbs?.outstandingEpirbs || [];
        var hasOtherEpirb = false;
        if (hasRequiredEpirbs) {
            if (this.props.requiredEpirbs.addedEpirbsMatchingRequirement && this.props.requiredEpirbs.addedEpirbsMatchingRequirement.filter(e => e.isOtherModel).length > 0) {
                /* required epirb contains Other Model */
                hasOtherEpirb = true;
            } else if (this.props.requiredEpirbs.additionalEpirbs && this.props.requiredEpirbs.additionalEpirbs.filter(e => e.isOtherModel).length > 0) {
                /* additional epirb contains Other Model */
                hasOtherEpirb = true;
            }
        }

        const hasRequiredEquipment = (this.props.requiredEquipment !== null);
        var noEquipmentAdded = true;
        const addedEquipmentMatchingRequirement = this.props.requiredEquipment?.addedEquipmentMatchingRequirement || [];
        const outstanding = this.props.requiredEquipment?.outstandingRequiredEquipment || [];
        const outstandingGroups = this.props.requiredEquipment?.outstandingRequiredEquipmentGrouped || [];
        const additionalAddedEquipment = this.props.requiredEquipment?.additionalAddedEquipment || [];
        var hasOtherEquipment = false;

        if (hasRequiredEquipment) {

            noEquipmentAdded = addedEquipmentMatchingRequirement.length === 0 && additionalAddedEquipment.length === 0;

            if (addedEquipmentMatchingRequirement.length > 0 && addedEquipmentMatchingRequirement.filter(e => e.customMakeModel).length > 0) {
                /* required equipment contains Other Model */
                hasOtherEquipment = true;
            } else if (additionalAddedEquipment.length > 0 && additionalAddedEquipment.filter(e => e.customMakeModel).length > 0) {
                /* additional equipment contains Other Model */
                hasOtherEquipment = true;
            }
        }

        const notesButtonLabel = this.props.licenceNotes && this.props.licenceNotes.length > 0
            ? t("Common.ButtonText.Notes") + " *"
            : t("Common.ButtonText.Notes");

        const equipmentClassName = noEquipmentAdded
            ? 'bg-customError bg-light p-2 rounded-lg m-3'
            : hasRequiredEquipment && (outstanding.length > 0 || outstandingGroups.length > 0)
                ? 'bg-customWarning bg-light p-2 rounded-lg m-3'
                : 'bg-customGood bg-light p-2 rounded-lg m-3';

        const emergencyContactsClassName = this.props.emergencyContactsCount < 2
            ? 'bg-customError bg-light p-2 rounded-lg m-3'
            : 'bg-customGood bg-light p-2 rounded-lg m-3';

        const isShipOwerWithNonIssuedLicence = this.props.role === "Ship Owner" && (this.props.licenceApplicationStatusTypeId == LicenceStatus.InProgress
            || this.props.licenceApplicationStatusTypeId == LicenceStatus.ReadyToReview);

        return (
            <React.Fragment>

                <Modal show={this.props.showIssueModal} onHide={this.props.handleCloseIssueModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("ReviewAndDeclarations.IssueLicence")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {t("ReviewAndDeclarations.IssueLicenceQuestion", { vesselName: this.props.vesselName })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button id="issueNoButton" variant="secondary" onClick={this.props.handleCloseIssueModal}>
                            &times; {t("Common.ButtonText.No")}
                        </Button>
                        <LinkButton id="issueYesButton"
                                    className="btn btn-primary"
                                    to={"/dashboard/"}
                                    onClick={() => { this.props.issueLicence(this.props.licenceApplicationId) }}>
                            &#10003; {t("Common.ButtonText.Yes")}
                        </LinkButton>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.props.showCancelModal} onHide={this.props.handleCloseCancelModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("LicenceDetailsPage.CancelLicenceTile")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {t("LicenceDetailsPage.CancelLicenceQuestion")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button id="cancelNoButton"
                            variant="success" onClick={this.props.handleCloseCancelModal}>
                            {t("Common.ButtonText.No")}
                        </Button>
                        <Button id="cancelYesButton"
                            variant="danger"
                            onClick={() => {
                                this.props.cancelLicence(this.props.licenceApplicationId, this.props.vesselId, this.props.applicationTypeId)
                            }}
                        >
                            {t("Common.ButtonText.Yes")}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.props.showUndoCancelModal} onHide={this.props.handleCloseUndoCancelModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Undo Cancel Licence</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to undo the licence cancellation?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button id="undoCancelNoButton"
                            variant="secondary"
                            onClick={
                                this.props.handleCloseUndoCancelModal}>
                            {t("Common.ButtonText.No")}
                        </Button>
                        <Button id="undoCancelYesButton"
                            variant="primary"
                            onClick={() => {
                                this.props.undoCancelLicence(this.props.licenceApplicationId)
                            }}>
                            {t("Common.ButtonText.Yes")}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    licenceList={t("AdminLandingPage.LicenceList")}
                    dashboard={t("Common.Dashboard")}
                    parentLink=""
                    parentPageName=""
                    pageName={t("ReviewAndDeclarations.TitleLabel")} />

                <PageHeader
                    pageTitle={t("ReviewAndDeclarations.TitleLabel")}
                    vesselLabel={t("OwnerLandingPage.VesselName")}
                    vesselName={this.props.vesselName}
                    isShipOwerWithNonIssuedLicence={isShipOwerWithNonIssuedLicence}
                    mmsiLabel={t("OwnerLandingPage.MMSINumber")}
                    mmsiNumber={this.props.mmsiNumber}
                    callSignLabel={t("OwnerLandingPage.CallSign")}
                    callSignNumber={this.props.callSignNumber}
                    pageDescription={t("ReviewAndDeclarations.StepLabel")}
                    requiredField="" />
               
                <div className="row">
                    <div className="rowCustom">
                        <div className="bg-customGoodFull">
                            <h6>{t("ReviewAndDeclarations.Good")}</h6>
                        </div>
                        <div className="bg-customWarningFull">
                            <h6>{t("ReviewAndDeclarations.Warning")}</h6>
                        </div>
                        <div className="bg-customErrorFull">
                            <h6>{t("ReviewAndDeclarations.ActionRequired")}</h6>
                        </div>
                    </div>

                    <div>
                        <div className="bg-light p-2 rounded-lg m-3 bg-customGood ">
                            <div className="row">
                                <div className="col-md-9">
                                    <label id="RadioOperatorCertificateDetailsLabel" tabIndex="0">
                                        <h5>{t("ReviewAndDeclarations.Step1")}{t("ReviewAndDeclarations.RadioOperatorCertificateDetailsLabel")}</h5>
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    {
                                        this.props.licenceApplicationId > 0 &&
                                        <Link
                                            id="updateOperatorCertificateButton"
                                            className="btn btn-primary float-end"
                                            to={this.props.parentRoute + "/licence/" + this.props.licenceApplicationId}>
                                            {t("Common.ButtonText.Review")}
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div className="bg-light p-2 rounded-lg m-3 bg-customGood">
                            <div className="row">
                                <div className="col-md-9">
                                    <label id="VesselDetailsLabel" tabIndex="0">
                                        <h5>{t("ReviewAndDeclarations.Step2")}{t("ReviewAndDeclarations.VesselDetailsLabel")}</h5>
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    {
                                        this.props.licenceApplicationId > 0 &&
                                        <Link
                                            id="updateVesselDetailsButton"
                                            className="btn btn-primary float-end"
                                            to={this.props.parentRoute + "/add-vessel/" + this.props.licenceApplicationId}>
                                            {t("Common.ButtonText.Review")}
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>

                        <hr/>
                        {/* Step3 - Emergency Details */}
                        <div className={emergencyContactsClassName}>
                            <div className="row">
                                <div className="col-md-9">
                                    <label id="EmergencyDetailsLabel" tabIndex="0">
                                        <h5>{t("ReviewAndDeclarations.Step3")}{t("ReviewAndDeclarations.EmergencyDetailsLabel")} </h5>
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    {
                                        this.props.licenceApplicationId > 0 &&
                                        <Link
                                            id="updateEmergencyContactButton"
                                            className="btn btn-primary float-end"
                                            to={this.props.parentRoute + "/emergency-contacts-list/" + this.props.licenceApplicationId}>
                                            {t("Common.ButtonText.Review")}
                                        </Link>
                                    }
                                </div>
                                {
                                    (this.props.emergencyContactsCount < 2) &&
                                    <div className="col-md-12">
                                        <ValidationFailureMessage>
                                            {t("ReviewAndDeclarations.EmergencyContactsRequired")}
                                        </ValidationFailureMessage>
                                    </div>
                                }
                            </div>
                        </div>
                        <hr/>

                        {/* Step4 - Equipment Details */}
                        <div className={equipmentClassName}>
                            <div className="row">
                                <div className="col-md-9">
                                    <label id="vesselEquipmentDetailsLabel" tabIndex="0">
                                        <h5>{t("ReviewAndDeclarations.Step4")}{t("ReviewAndDeclarations.VesselEquipmentDetailsLabel")}</h5>
                                    </label>
                                    <span className="visually-hidden">
                                        Status is
                                        {
                                            noEquipmentAdded
                                                ? "Error"
                                                : hasRequiredEquipment && (outstanding.length > 0 || outstandingGroups.length > 0)
                                                    ? "Warning"
                                                    : "Good"
                                        }
                                    </span>
                                </div>
                                <div className="col-md-3">
                                    {
                                        this.props.licenceApplicationId > 0 &&
                                        <Link
                                            id="updateVesselEquipmentButton"
                                            className="btn btn-primary float-end"
                                            to={this.props.parentRoute + "/vessel-equipment-details/" + this.props.licenceApplicationId}>
                                            {t("Common.ButtonText.Review")}
                                        </Link>
                                    }
                                </div>
                                {
                                    hasOtherEquipment && (this.props.role === "Radio Surveyor" || this.props.role === "Department Admin Staff") &&
                                    <div className="col-md-12">
                                        <ValidationFailureMessage>
                                            {t("ReviewAndDeclarations.OtherEquipmentAdded")}
                                        </ValidationFailureMessage>
                                    </div>
                                }
                                {
                                    (noEquipmentAdded || (hasRequiredEquipment && (outstanding.length > 0 || outstandingGroups.length > 0))) &&
                                    <div className="col-md-12">
                                        <ValidationFailureMessage>
                                            {t("ReviewAndDeclarations.MinEquipmentRequired")}
                                        </ValidationFailureMessage>
                                    </div>
                                }
                            </div>
                        </div>
                        <hr/>
                        {/* Epirb Details */}
                        <div
                            className={hasRequiredEpirbs && outstandingEpirbs.length > 0
                               ? 'bg-customWarning bg-light p-2 rounded-lg m-3'
                               : 'bg-customGood bg-light p-2 rounded-lg m-3'
                            }>
                            <div className="row">
                                <div className="col-md-9">
                                    <label id="epirbDetailsLabel" tabIndex="0">
                                        <h5>{t("ReviewAndDeclarations.EpirbDetailsLabel")} </h5>
                                    </label>
                                    <span className="visually-hidden">
                                        Status is
                                        {
                                            hasRequiredEpirbs && outstandingEpirbs.length > 0
                                                ? "Warning"
                                                : "Good"
                                        }
                                    </span>
                                </div>
                                {
                                    this.props.licenceApplicationStatusTypeId !== LicenceStatus.InProgress &&
                                    <div className="col-md-3">
                                        {
                                            this.props.licenceApplicationId > 0 &&
                                            <Link
                                                id="updateEpirbDetailsButton"
                                                className="btn btn-primary float-end"
                                                to={this.props.parentRoute + "/epirb-list/" + this.props.licenceApplicationId}
                                            >
                                                {t("Common.ButtonText.Review")}
                                            </Link>
                                        }
                                    </div>
                                }
                                {
                                    (this.props.role === "Radio Surveyor" || this.props.role === "Department Admin Staff") && hasOtherEpirb &&
                                    <div className="col-md-12">
                                        <ValidationFailureMessage>
                                            {t("ReviewAndDeclarations.OtherEpirbAdded")}
                                        </ValidationFailureMessage>
                                    </div>
                                }
                                <div className="col-md-12">
                                    <span tabIndex="0">
                                        <i>{t("ReviewAndDeclarations.EpirbDetailsNote")} </i>
                                    </span>
                                </div>
                            </div>
                        </div>

                        {
                            this.props.role === "Radio Surveyor" &&
                            this.props.licenceApplicationStatusTypeId !== LicenceStatus.Cancelled &&

                                    this.props.licenceApplicationStatusTypeId !== LicenceStatus.Cancelled &&
                                    <Link
                                        id="cancelButton"
                                        className="btn btn-warning me-3"
                                        to="#"
                                        onClick={() => { this.props.handleShowCancelModal() }}>
                                        {t("LicenceDetailsPage.CancelLicenceTile")}
                                    </Link>
                               
                        }

                        {
                            this.props.role === "Radio Surveyor" &&
                            this.props.licenceApplicationStatusTypeId === LicenceStatus.ReadyToReview &&
                           
                                    this.props.licenceApplicationStatusTypeId === LicenceStatus.ReadyToReview &&
                                    <Link
                                        id="issueButton"
                                        className="btn btn-success"
                                        to="#"
                                        onClick={() => { this.props.handleShowIssueModal() }}>
                                        {t("ReviewAndDeclarations.IssueLicence")}
                                    </Link>
                        }

                        {
                            (this.props.licenceApplicationStatusTypeId === LicenceStatus.LicenceIssued || 
                            this.props.licenceApplicationStatusTypeId === LicenceStatus.Dispatched) &&
                            (this.props.role === "Radio Surveyor" || this.props.role === "Department Admin Staff") &&
                            
                                <Link
                                    id="printButton"
                                    target="_new"
                                    className="my-3 btn btn-success"
                                    to={"/licenceApplication/PrintShipsRadioLicence/?id=" + this.props.licenceApplicationId}
                                >
                                    {t("LicenceDetailsPage.ViewAndPrintLicenceTile")}
                                </Link>         
                        }

                        {
                            this.props.licenceApplicationStatusTypeId === LicenceStatus.Cancelled &&
                            this.props.role === "Radio Surveyor" &&
                            <Button id="btnUndoCancelLicence"
                                variant="primary" onClick={this.props.handleShowUndoCancelModal}>
                                Undo Licence Cancellation
                            </Button>
                        }

                        {
                            (this.props.role === "Radio Surveyor" || this.props.role === "Department Admin Staff") &&
                            <Link
                                id="btnNotes"
                                className="btn btn-secondary ms-3"
                                to={this.props.parentRoute + "/notes/" + this.props.licenceApplicationId}
                            >
                                {notesButtonLabel}
                            </Link>
                        }

                        {
                            (this.props.licenceApplicationStatusTypeId === LicenceStatus.InProgress ||
                                this.props.licenceApplicationStatusTypeId === LicenceStatus.LicenceIssued ||
                                this.props.licenceApplicationStatusTypeId === LicenceStatus.Dispatched) && this.props.declarationsCheckbox &&
                            
                            <div>
                                <hr />
                                <div className="bg-light p-2 rounded-lg m-3 bg-customGood">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <label id="DeclarationsLabel" tabIndex="0"><b><h5>{t("ReviewAndDeclarations.DeclarationsLabel")}</h5></b> </label>
                                        </div>
                                        <div className="col-md-3">
                                            <Link onClick={this.props.declarationsCheckboxCheckboxValueChanged}
                                                id="updateOperatorCertificateButton"
                                                style={{ float: "right" }}
                                                   className="btn btn-primary"
                                                   to="#"
                                            >
                                                {t("Common.ButtonText.Review")} 
                                            </Link>
                                        </div>
                                        <div className="col-md-12">
                                            <label id="delarationsCheckboxLabel" tabIndex="0"> {t("ReviewAndDeclarations.DeclarationsCheckboxLabel")}</label>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                               <div className="bg-light p-2 rounded-lg m-3 bg-customError"> 
                                    <label id="PaymentSummaryLabel" tabIndex="0"><h5>{t("ReviewAndDeclarations.PaymentSummaryLabel")}</h5></label>
                                 
                                    <p id="licenceLabel" tabIndex="0">{licenceTypeText}</p>
                                    <label id="TotalAmountLabel" tabIndex="0">{t("ReviewAndDeclarations.TotalAmountLabel")} </label> <br />
                                    <p> <span>&#8364;</span>{paymentAmount} </p>
                                </div>
                            </div>
                        }
                        {/* Declarations */}
                        {
                            (this.props.licenceApplicationStatusTypeId === LicenceStatus.InProgress ||
                                ((this.props.licenceApplicationStatusTypeId === LicenceStatus.LicenceIssued ||
                                this.props.licenceApplicationStatusTypeId === LicenceStatus.Dispatched) &&
                                    this.props.applicationTypeId !== ApplicationType.NewShipRadioLicence)) &&
                            !this.props.declarationsCheckbox &&
                            !noEquipmentAdded &&
                            this.props.emergencyContactsCount > 1 &&
                            <div>
                                <hr />
                               <div className="bg-light p-2 rounded-lg m-3 bg-customError">
                                    <label id="DeclarationsLabel" tabIndex="0"><h5>{t("ReviewAndDeclarations.DeclarationsLabel")}</h5></label>
                                    <p id="DeclarationLine1" tabIndex="0"> {t("ReviewAndDeclarations.DeclarationLine1")}</p> <br />
                                    <p id="DeclarationLine2" tabIndex="0"> {t("ReviewAndDeclarations.DeclarationLine2")}</p> <br />
                                    <p id="DeclarationLine3" tabIndex="0"> {t("ReviewAndDeclarations.DeclarationLine3")}</p> <br />
                                    <p id="DeclarationLine4" tabIndex="0"> {t("ReviewAndDeclarations.DeclarationLine4")}</p> <br />
                                    <p id="DeclarationLine5" tabIndex="0"> {t("ReviewAndDeclarations.DeclarationLine5")}</p>

                                   <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="declarationsCheckboxCheckbox"
                                            onChange={evt => this.props.declarationsCheckboxCheckboxValueChanged(evt,
                                                this.props.licenceApplicationId, paymentAmount)}
                                            value={this.props.declarationsCheckbox}
                                       />

                                        <label id="declarationsCheckboxLabel" className="form-check-label" tabIndex="0">
                                            {t("ReviewAndDeclarations.DeclarationsCheckboxLabel")}
                                       </label>
                                   </div>

                                </div>
                            </div>
                        }
                        <hr/>
                        <br/>
                    </div>
                    <div>
                        {
                            this.props.declarationsCheckbox && !(hasRequiredEquipment && outstanding.length === 0 && outstandingGroups.length === 0) &&
                                <div className="h5" tabIndex="0"> {t("ReviewAndDeclarations.WarningWeMayNeedMoreInformation")}</div>
                        }
                        {
                            this.props.declarationsCheckbox && this.props.licencePaymentId > 0 &&
                            <div>
                                <form action={this.props.paymentEndpoint} method="POST">
                                    <input type="hidden" name="instId" value={this.props.paymentKey} />
                                    <input type="hidden" name="cartId" value={this.props.licencePaymentId} />
                                    <input type="hidden" name="amount" value={paymentAmount} />
                                    <input type="hidden" name="currency" value="EUR" />
                                    <input type="hidden" name="desc" value={licenceTypeText + " for " + this.props.vesselName} />
                                    {
                                        this.props.environment !== "Production" &&
                                        <input type="hidden" name="testMode" value="100" />
                                    }
                                    <input type="hidden" name="hideCurrency" value="" />
                                    <input type="hidden" name="lang" value="IE" />
                                    <input type="hidden" name="noLanguageMenu" value="" />
                                    <input type="hidden" name="MC_callback" value={ this.props.websiteUrl + "Payment/PaymentProcessed" } />
                                    <input type="hidden" name="MC_returnurl" value={ this.props.websiteUrl + "apply/payment-success/" + this.props.licenceApplicationId } />
                                    <input type="hidden" name="MC_cancelurl" value={ this.props.websiteUrl + "apply/review-declarations/" + this.props.licenceApplicationId } />

                                    <div className="row">
                                        <div className="col-md-8">
                                            <input type="submit" id="addPaymentDetailsButton" className="btn btn-primary mb-3" value={t("ReviewAndDeclarations.SubmitAndPay")} />
                                        </div>
                                        <div className="col-md-4">
                                            <img alt="MasterCard" src={MastercardImage} className="mx-1" />
                                            <img alt="Visa" src={VisaImage} className="mx-1" />
                                            <img alt="Maestro" src={MaestroImage} className="mx-1" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>

                </div>

            </React.Fragment>
);
}
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: state.reviewAndDeclaration.id || 0,
        licenceApplicationId: state.currentApplication.id || 0,
        declarationsCheckbox: state.reviewAndDeclaration.declarationsCheckbox || false,
        paymentDetails: state.availablePricings,
        requiredEquipment: state.requiredEquipment || null,
        requiredEpirbs: state.requiredEpirbs || null,
        licenceApplicationStatusTypeId: state.currentApplication.licenceApplicationStatusTypeId || 0,
        applicationTypeId: state.currentApplication.applicationTypeId || 1,

        emergencyContacts: state.emergencyContactList,
        emergencyContactsCount: state.emergencyContactList.length || 0,

        role: state.currentUserInfo.role || "",
        vesselName: state.currentVessel.name || "",
        callSignNumber: state.currentVessel.callSignNumber || "",
        mmsiNumber: state.currentVessel.mmsiNumber || "",
        vesselId: state.currentVessel.id || 0,
        showIssueModal: state.currentApplication.showIssueModal || false,
        licencePaymentId: state.licencePayment.id || 0,
        environment: state.websiteConfig.environment || "",
        paymentEndpoint: state.websiteConfig.paymentEndpoint || "",
        paymentKey: state.websiteConfig.paymentKey || "",
        websiteUrl: state.websiteConfig.websiteUrl || "",

        showCancelModal: state.currentUserInfo.showCancelModal || false,
        showUndoCancelModal: state.currentUserInfo.showUndoCancelModal || false,

        licenceNotes: state.licenceNotes || []
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getPricingsAction: () => {
            dispatch(CreateGetPricingsAction());
        },
        getWebsiteConfig: () => {
            dispatch(CreateGetWebsiteConfigAction());
        },
        getLicenceNotes: (licenceApplicationId) => {
            dispatch(CreateGetLicenceNotesAction(licenceApplicationId));
        },
        setDeclarationsCheckBoxToDefault: () => {
            // set to false by default while returning from reviewing the previous forms
            dispatch({
                type: "DECLARATIONS_CHECKBOX_UPDATED",
                data: false
            });
        },
        declarationsCheckboxCheckboxValueChanged: (evt, licenceApplicationId, paymentAmount) => {
            var updateDeclarationsCheckbox = evt.target.checked;

            if (updateDeclarationsCheckbox) {
                dispatch(CreateAddReviewAndDeclarationsAction(licenceApplicationId, paymentAmount));
            }

            dispatch({
                type: "DECLARATIONS_CHECKBOX_UPDATED",
                data: updateDeclarationsCheckbox
            });
        },
        handleShowIssueModal: () => {
            dispatch({
                type: "ISSUE_LICENCE_SHOW_MODAL",
                data: true
            });
        },
        handleCloseIssueModal: () => {
            dispatch({
                type: "ISSUE_LICENCE_SHOW_MODAL",
                data: false
            });
        },
        issueLicence: (licenceApplicationId) => {

            //set licence application to 4 (Licence Issued) status and send email
            dispatch(CreateIssueLicenceApplicationAction(licenceApplicationId));

            dispatch({
                type: "ISSUE_LICENCE_SHOW_MODAL",
                data: false
            });
        },
        handleShowCancelModal: () => {
            dispatch({
                type: "CANCEL_LICENCE_UPDATED_SHOW_CANCEL_MODAL",
                data: true
            });
        },
        handleCloseCancelModal: () => {
            dispatch({
                type: "CANCEL_LICENCE_UPDATED_SHOW_CANCEL_MODAL",
                data: false
            });
        },
        cancelLicence: (licenceApplicationId, vesselId, applicationTypeId) => {
            dispatch(CreateCancelLicenceApplicationAction(licenceApplicationId, vesselId, applicationTypeId, "admin"));
            dispatch({
                type: "CANCEL_LICENCE_UPDATED_SHOW_CANCEL_MODAL",
                data: false
            });
        },
        handleShowUndoCancelModal: () => {
            dispatch({
                type: "UNDO_CANCEL_LICENCE_SHOW_MODAL",
                data: true
            });
        },
        handleCloseUndoCancelModal: () => {
            dispatch({
                type: "UNDO_CANCEL_LICENCE_SHOW_MODAL",
                data: false
            });
        },
        undoCancelLicence: (licenceApplicationId) => {
            dispatch(CreateUndoCancelLicenceApplicationAction(licenceApplicationId));
            dispatch({
                type: "UNDO_CANCEL_LICENCE_SHOW_MODAL",
                data: false
            });
        },
    }
}

const languageAwareComponent = withTranslation()(AddReviewAndDeclarations);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);

