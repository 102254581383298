import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next"; 
import { Container, Navbar, NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom"; 
import LanguageSelector from "./LanguageSelector";
import LoginMenu from "./LoginMenu"; 
import "./NavMenu.css";
import CreateGetIsAuthenticatedAction from "../../store/actionCreators/CreateGetIsAuthenticatedAction";

class NavMenu extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        
        this.state = {
            collapsed: true,
            userIsAuthenticated: false
        };
    }

    componentDidMount() {
        const that = this;

        window.userManager.getUser().then(function(user) {
            that.setState({
                userIsAuthenticated: user != null
            });
        });

        this.props.getIsAuthenticatedAction();
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    doLogin() {
        window.userManager.signinRedirect();
    }

    doLogout() {
        window.userManager.signoutRedirect();
    }

    render() {
        const t = this.props.t;
        const isAuthenticated = this.props.userIsAuthenticated;
        return (
            <header className="ShipsRadioHeader">  
                <Navbar className="navbar-expand-md border-bottom box-shadow mb-3" dark style={{ backgroundColor: "#004D44"}}>
                    <Container>
                        <div className="HarpImage mx-1" > </div>
                        <NavbarBrand tag={Link} to="/">{t("Common.NavMenuTitle")}</NavbarBrand>
                        <LanguageSelector />
                        <LoginMenu userIsAuthenticated={isAuthenticated} t={t} />
                    </Container>  
                </Navbar>  
            </header >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userIsAuthenticated: state.currentUserInfo.isAuthenticated || false
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getIsAuthenticatedAction: () => {
            dispatch(CreateGetIsAuthenticatedAction());
        }
    };
};

const languageAwareComponent = withTranslation()(NavMenu);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);