import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faBroadcastTower, faHdd } from '@fortawesome/free-solid-svg-icons';
import LinkButton from "./LandingPage/LinkButton";
import Breadcrumb from "./Breadcrumb";

import CreateClearCurrentApplicationAction from "../store/actionCreators/CreateClearCurrentApplicationAction";
import CreateClearCurrentVesselAction from "../store/actionCreators/CreateClearCurrentVesselAction";

class ApplicationDetailsPage extends Component {
    componentDidMount() {
        const id = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
        const urlId = (isNaN(id)) ? 0 : Number(id);

        if (urlId !== 0 && urlId !== this.props.selectedLicenceApplicationIdForDetails) {
            this.props.setSelectedApplicationIdForDetails(urlId);
        }
        this.props.clearCurrentApplication();
        this.props.clearCurrentVessel();
    }

    render() {
        const { t } = this.props;
        const applicationDetail = this.props.ownerDashboardInformation.filter(v => Number(v.licenceApplicationId)
            === Number(this.props.selectedLicenceApplicationIdForDetails))[0];
        const vesselName = applicationDetail?.vesselName || "";
        const isInProcess = (applicationDetail?.applicationStatusTypeId || 0) === 1;

        return (
            <React.Fragment>
                <Breadcrumb
                    role={this.props.role}
                    homeLink={t("Common.ButtonText.Home")}
                    dashboard={t("Common.Dashboard")}
                    pageName={t("ApplicationDetailsPage.PageHeaderTitle", { vesselName })} />

                <div className="form-group text-center">
                    <div className="h4 my-2" tabIndex="0">{t("ApplicationDetailsPage.PageHeaderTitle", { vesselName })}</div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="ShipsRadioColumnItem">
                            <LinkButton id="editLicenceDetails"
                                icon={faEdit} to="" className="btn btn-licence-actions"
                                to={"/apply/licence/" + this.props.selectedLicenceApplicationIdForDetails} >
                                <FontAwesomeIcon icon={faEdit} style={{ fontSize: 12 }} />
                                <div>{t("LicenceDetailsPage.EditDetailsTile")}</div>
                            </LinkButton>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="ShipsRadioColumnItem">
                            <LinkButton id="editEpirbDetails"
                                icon={faBroadcastTower} to="" className="btn btn-licence-actions"
                                to={"/apply/epirb-list/" + this.props.selectedLicenceApplicationIdForDetails}
                                disabled={isInProcess}>
                                <FontAwesomeIcon icon={faBroadcastTower} style={{ fontSize: 12 }} />
                                <div>{t("LicenceDetailsPage.AddEpirbTile")}</div>
                            </LinkButton>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="ShipsRadioColumnItem">
                            <LinkButton id="editEquipmentDetails"
                                icon={faHdd} to="" className="btn btn-licence-actions"
                                to={"/apply/vessel-equipment-details/" + this.props.selectedLicenceApplicationIdForDetails}>
                                <FontAwesomeIcon icon={faHdd} style={{ fontSize: 12 }} />
                                <div>{t("LicenceDetailsPage.AddEquipmentTile")}</div>
                            </LinkButton>
                        </div>
                    </div>
            </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        licenceApplications: state.currentUserInfo.licenceApplications,
        vessels: state.currentUserInfo.vessels,
        ownerDashboardInformation: state.currentUserInfo.ownerDashboardInformation,
        selectedLicenceApplicationIdForDetails: state.currentUserInfo.selectedLicenceApplicationIdForDetails
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setSelectedApplicationIdForDetails: (licenceApplicationId) => {
            dispatch({
                type: "UPDATED_SELECTED_APPLICATION_ID_FOR_DETAILS",
                data: licenceApplicationId
            });
        },
        clearCurrentApplication: () => {
            dispatch(CreateClearCurrentApplicationAction());
        },
        clearCurrentVessel: () => {
            dispatch(CreateClearCurrentVesselAction());
        }
    };
};


const languageAwareComponent = withTranslation()(ApplicationDetailsPage);

export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);