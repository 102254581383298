import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { POST } from "../adapters/xhr";

export default function (licenceApplicationId, isValid) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "LicenceApplication/SetIsValid?licenceApplicationId=" + licenceApplicationId + "&isValid=" + isValid;

        POST(url)
            .then(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}