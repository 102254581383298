import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next"; 
import { Container, Navbar} from "reactstrap";
import "./BottomBar.css"; 

class BottomBar extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        
        this.state = {
            collapsed: true,
            userIsAuthenticated: false
        };
    }

    componentDidMount() {
        const that = this;

        window.userManager.getUser().then(function(user) {
            that.setState({
                userIsAuthenticated: user != null
            });
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    doLogin() {
        window.userManager.signinRedirect();
    }

    doLogout() {
        window.userManager.signoutRedirect();
    }

    render() {
        const t = this.props.t;

        return (
            <footer className="ShipsRadioFooter">    
                <Navbar className="navbar-expand-md border-bottom box-shadow mb-3" dark style={{ backgroundColor: "#004D44" }}>
                    <Container>
                        <div className="row">
                            <div className="col-md-1">
                                <div className="HarpImage mx-1" ></div>
                            </div>
                        </div>
                            <div className="col-md-11">
                            <div className="footer-main-text-div"> {t("Common.FooterInfo")}</div>
                            <div className="footer-main-text-div">
                                <label className="footer-label">Email:&nbsp;</label>
                                <a href="mailto:IMRAD@transport.gov.ie">IMRAD@transport.gov.ie</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="footer-label">Telephone: </label>
                                    {t("Common.FooterPhone")}
                                </div>
                            </div>

                    </Container>
                </Navbar>
           
            </footer >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userRole: state.currentUserInfo.role || ""
    };
};

const languageAwareComponent = withTranslation()(BottomBar);
export default connect(mapStateToProps, null)(languageAwareComponent);