import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import ValidationFailureMessage from "./ValidationFailureMessage";
import Breadcrumb from "./Breadcrumb";

import CreateGetCertificateTypesForSeaAreaAction from "../store/actionCreators/CreateGetCertificateTypesForSeaAreaAction";
import CreateGetVesselTypesAction from "../store/actionCreators/CreateGetVesselTypesAction";
import CreateGetVesselSubTypesAction from "../store/actionCreators/CreateGetVesselSubTypesAction";
import CreateGetAllSeaAreasOfOperationAction from "../store/actionCreators/CreateGetAllSeaAreasOfOperationAction";
import CreateCalculateRequiredEquipmentAction from "../store/actionCreators/CreateCalculateRequiredEquipmentAction";


class LicenceRequirementsCalculator extends Component {
    componentDidMount() {
        this.props.getVesselTypes();
        this.props.getVesselSubTypes();
        this.props.getSeaAreasOfOperation();
    }

    getSeaAreaChoices = (seaAreasOfOperation, isMerchantOrFvOver24, isPassengerBoat, isPassengerBoatP5) => {

        const seaAreas = {
            SeaArea1: 1,
            SeaArea2: 2,
            SeaArea3: 3,
            SeaArea4: 4
        };

        if (isMerchantOrFvOver24) {
            return seaAreasOfOperation;
        }

        if (isPassengerBoat && isPassengerBoatP5) {
            return seaAreasOfOperation.filter(l => Number(l.id) === seaAreas.SeaArea1 || Number(l.id) === seaAreas.SeaArea2);
        }

        if (isPassengerBoat) {
            return seaAreasOfOperation.filter(l => Number(l.id) === seaAreas.SeaArea1);
        }

        return seaAreasOfOperation.filter(l => Number(l.id) !== seaAreas.SeaArea4);
    }

    render() {
        const { t } = this.props;

        const vesselTypes = {
            PleasureVessel: 1,
            FishingVessel: 2,
            PassengerBoat: 3,
            PassengerShip: 4,
            MerchantVessel: 5,
            NavalShip: 6,
            RescueVessel: 7,
            Other: 8
        };

        const EquipmentTypeCategories = {
            AisSart: 1,
            AisTransponder: 2,
            ClassMmob: 3,
            MfHf: 4,
            NonGmdss: 5,
            Plb: 6,
            HandheldVhf: 7,
            Radar: 8,
            Sart: 9,
            Vhf: 10,
            GNSS: 11,
            AeronauticalVHF: 12,
            GMDSS: 13,
            Other: 14,
            HandheldUhf: 15
        };

        const passengerBoatSubTypes = {
            P1: 3,
            P2: 4,
            P3: 5,
            P4: 6,
            P5: 7,
            P6: 8
        };

        //limit vesselType choices. remove rescue, navel and other
        const vesselTypeChoices = this.props.vesselTypes.filter(l => Number(l.id) !== vesselTypes.NavalShip
            && Number(l.id) !== vesselTypes.RescueVessel
            && Number(l.id) !== vesselTypes.Other);

        const isPleasureVessel = Number(this.props.vesselTypeId) === vesselTypes.PleasureVessel;
        const isFishingVessel = Number(this.props.vesselTypeId) === vesselTypes.FishingVessel;
        const isMerchantVessel = Number(this.props.vesselTypeId) === vesselTypes.MerchantVessel;
        const isPassengerBoat = Number(this.props.vesselTypeId) === vesselTypes.PassengerBoat;
        const isPassengerShip = Number(this.props.vesselTypeId) === vesselTypes.PassengerShip;
        const isMerchantOrFvOver24 = Number(this.props.vesselTypeId) === vesselTypes.MerchantVessel ||
            (Number(this.props.vesselTypeId) === vesselTypes.FishingVessel && this.props.vesselLength > 24);
        const isPassengerBoatP5 = isPassengerBoat && Number(this.props.vesselSubTypeId) === passengerBoatSubTypes.P5;


        //Compute SeaAreaChoices
        const seaAreaChoices = this.getSeaAreaChoices(this.props.seaAreasOfOperation, isMerchantOrFvOver24, isPassengerBoat, isPassengerBoatP5);

        // disable the Calculate button if required fields are missing
        var hasTonnage = Number(this.props.vesselTypeId) !== vesselTypes.MerchantVessel ||
            (Number(this.props.vesselTypeId) === vesselTypes.MerchantVessel && this.props.grossTonnage !== "");


        var canSubmitForm = (this.props.vesselTypeRequired || this.props.vesselTypeId === "" ||
            (this.props.hasVesselSubTypes && (this.props.vesselSubTypeRequired || this.props.vesselSubTypeId === "")) ||
            this.props.seaAreaOfOperationRequired || this.props.seaAreaOfOperationId === "" ||
            this.props.vesselLength === "" ||
            !hasTonnage
        );

        const certificateType =
            this.props.certificateTypes != null && this.props.certificateTypes.length > 0
                ? this.props.certificateTypes[0].name
                : "";

        return (
            <React.Fragment>

                <Breadcrumb
                    role=""
                    homeLink={t("Common.ButtonText.Home")}
                    pageName={t("RequirementsCalculator.TitleLabel1")} />
                
                <div className="bg-light p-2 rounded-lg mb-2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="h4" role="heading" aria-level="1" id="RequirementsCalculatorTitleLabel1" tabIndex="0">
                                {
                                    t("RequirementsCalculator.TitleLabel1")
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-md-9">
                            <p className="h6" role="heading" aria-level="2" id="RequirementsCalculatorTitleLabel2" tabIndex="0">
                                {t("RequirementsCalculator.TitleLabel2")}
                            </p>
                        </div>
                        <div className="col-md-3">
                            <div aria-hidden="true" id="required-description" className="float-end">
                                * {t("Common.RequiredField")}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="vesselTypeLabel" htmlFor="vesselTypeId" className="form-label">{t("AddVessel.VesselTypeLabel")}: *</label>
                            <select id="vesselTypeId"
                                    className="form-control" required
                                    onChange={this.props.onVesselTypeSelected}
                                    onBlur={this.props.onVesselTypeSelectionCompleted}
                                    value={this.props.vesselTypeId}>
                                <option value="">{t("AddVessel.VesselTypeSelect")}</option>
                                {
                                    vesselTypeChoices.map(x => (
                                    <option key={x.id} value={x.id}>{x.name}</option>
                                ))
                            }
                            </select>
                            {
                            this.props.vesselTypeRequired &&
                                <ValidationFailureMessage>
                                    {t("AddVessel.VesselTypeRequired")}
                                </ValidationFailureMessage>
                        }
                        </div>
                    </div>

                    {
                    this.props.hasVesselSubTypes && !isPleasureVessel &&
                        <div className="col-md-6">
                            <div className="form-group">
                                <label id="vesselSubTypeLabel" htmlFor="vesselSubTypeId" className="form-label">
                                    {t("AddVessel.VesselSubTypeLabel")}
                                </label>
                                <select id="vesselSubTypeId"
                                        className="form-control" required
                                        onChange={this.props.onVesselSubTypeSelected}
                                        onBlur={this.props.onVesselSubTypeSelectionCompleted}
                                        value={this.props.vesselSubTypeId}>
                                    <option value="">{t("AddVessel.VesselTypeSelect")}</option>
                                    {
                                    this.props.vesselSubTypes.filter(
                                        vesselType => vesselType.vesselTypeId === Number(this.props.vesselTypeId)
                                    ).map(vesselSubType => (
                                        <option key={vesselSubType.id}
                                            value={vesselSubType.id}>
                                            {vesselSubType.name}
                                        </option>
                                    ))
                                }
                                </select>
                                {
                                this.props.vesselSubTypeRequired &&
                                    <ValidationFailureMessage>
                                    {t("AddVessel.VesselSubTypeRequired")}
                                </ValidationFailureMessage>
                            }
                            </div>
                        </div>
                }

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="maxAreaOfOperationLabel" htmlFor="maxAreaOfOperationId" className="form-label">
                                {t("AddVessel.VesselSeaAreaOfOperationLabel")}: *
                            </label>
                            <select id="maxAreaOfOperationId"
                                className="form-control" required
                                onChange={this.props.onSeaAreaOfOperationSelected}
                                onBlur={seaArea => this.props.onSeaAreaOfOperationSelectionCompleted(seaArea,
                                    this.props.vesselTypeId)}
                                value={this.props.seaAreaOfOperationId}>
                                <option value="">{t("Common.ButtonText.Select")}</option>
                                {
                                    seaAreaChoices.map(seaAreaOfOperation => (
                                        <option key={seaAreaOfOperation.id} value={seaAreaOfOperation.id}>{
                                            seaAreaOfOperation.name}</option>
                                    ))
                                }
                            </select>
                            {
                                this.props.seaAreaOfOperationRequired &&
                                <ValidationFailureMessage>
                                    {t("AddVessel.VesselSeaAreaOfOperationRequired")}
                                </ValidationFailureMessage>
                            }
                        </div>
                    </div>

                    {
                        !isPleasureVessel && !isMerchantVessel && !isPassengerBoat && !isPassengerShip &&
                        <div className="col-md-6">
                            <div className="form-group">
                                <label id="vesselLengthLabel" htmlFor="vesselLength" className="form-label">{t("AddVessel.VesselLengthLabel")}: *</label>
                                <input id="vesselLength" type="Number" min="0" step=".1" max="999"
                                    className="form-control" required
                                    onChange={this.props.onVesselLengthChanged}
                                    onBlur={this.props.onVesselLengthChangeCompleted}
                                    value={this.props.vesselLength} />
                            </div>
                        </div>
                    }

                    {
                        !isPleasureVessel && !isFishingVessel && ! isPassengerBoat && !isPassengerShip &&
                        <div className="col-md-6">
                            <div className="form-group">
                                <label id="grossTonnageLabel" htmlFor="grossTonnage" className="form-label">{t("AddVessel.VesselGrossTonnageLabel")}: {this.props.vesselTypeId == vesselTypes.MerchantVessel && "*"}</label>
                                <input id="grossTonnage" type="Number" min="0" step=".1"
                                    className="form-control" required
                                    onChange={this.props.onGrossTonnageChanged}
                                    onBlur={this.props.onGrossTonnageChangeCompleted}
                                    value={this.props.grossTonnage} />
                            </div>
                        </div>
                    }

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="certificateTypeLabel" htmlFor="certificateType" className="form-label">{t("RequirementsCalculator.MinimumCertificateTypeLabel")}</label>
                            <input id="certificateType"
                                   type="text"
                                   className="form-control"
                                   disabled={true}
                                   value={certificateType}/>
                        </div>
                    </div>
                </div>
                <div>
                    <Link
                        id="calculatorButton"
                        className="btn btn-primary my-3"
                        disabled={canSubmitForm}
                        to="#"
                        onClick={() => this.props.calculateRequirements(
                            this.props.vesselTypeId,
                            this.props.vesselSubTypeId,
                            this.props.seaAreaOfOperationId,
                            this.props.vesselLength,
                            this.props.grossTonnage)}>
                        {t("Common.ButtonText.Calculate")}
                    </Link>
                </div>
                {
                    this.props.requiredEquipment &&
                    this.props.requiredEquipment.vesselHeader &&
                    this.props.requiredEquipment.requiredEquipment == null &&
                    this.props.requiredEquipment.requiredEquipmentGrouped == null &&
                    this.props.requiredEquipment.requiredEpirbs == null &&
                    <div className="col-md-12 bg-light p-2 rounded-lg bg-customGood">
                        <p className="h6">{t("Equipments.NoOutstandingEquipmentTitle",
                            { vesselHeader: this.props.requiredEquipment.vesselHeader })}
                        </p>
                        {
                            isPassengerBoat &&
                            <p className="h6">{t("Equipments.NoOutstandingPassengerBoatEquipment")}</p>
                            
                        }
                    </div>
                }
                {
                    this.props.requiredEquipment &&
                    ((this.props.requiredEquipment.requiredEquipment &&
                            this.props.requiredEquipment.requiredEquipment.length > 0) ||
                        (this.props.requiredEquipment.requiredEquipmentGrouped &&
                            this.props.requiredEquipment.requiredEquipmentGrouped.length > 0)) &&
                    <div className="col-md-12 bg-light p-2 rounded-lg bg-customWarning">
                        <p className="h6" tabIndex="0">{t("Equipments.OutstandingEquipmentTitle2",
                            { vesselHeader: this.props.requiredEquipment.vesselHeader })}:</p>
                        <ul>
                            {
                                this.props.requiredEquipment.requiredEquipment &&
                                this.props.requiredEquipment.requiredEquipment.length > 0 &&
                                <span>
                                    {
                                        this.props.requiredEquipment.requiredEquipment.map(equipment => (
                                        <li key={equipment.id}>
                                            {
                                                equipment.minimumQuantityRequired > 1 &&
                                                <span>{equipment.minimumQuantityRequired} x </span>
                                            }
                                            {
                                                equipment.equipmentTypeCategory.id === EquipmentTypeCategories.Plb &&
                                                <span>{t("Equipments.Plb")}</span>
                                            }
                                            {
                                                equipment.equipmentTypeCategory.id !== EquipmentTypeCategories.Plb &&
                                                equipment.equipmentTypeCategory.name
                                            }
                                            {
                                                equipment.equipmentTypeCategory.id === EquipmentTypeCategories.Radar && equipment.radarBandTypeId === 1 &&
                                                <span> (9 GHz, X-Band)</span>
                                            }
                                            {
                                                equipment.equipmentTypeCategory.id === EquipmentTypeCategories.Radar && equipment.radarBandTypeId === 2 &&
                                                <span> (3 GHz, S-Band)</span>
                                            }
                                            {equipment.dscRequired && <span> {t("Equipments.DSCEnabled")}.</span>}
                                            {equipment.isMedApproved &&
                                                <span> {t("Equipments.MedApproved")}.</span>}
                                        </li>
                                        ))
                                    }
                                </span>
                            }

                            {
                                this.props.requiredEquipment.requiredEquipmentGrouped &&
                                    this.props.requiredEquipment.requiredEquipmentGrouped.length > 0 &&
                                    <span>
                                        {
                                        this.props.requiredEquipment.requiredEquipmentGrouped.map(groupedEquipment => (
                                            <li key={groupedEquipment.requiredEquipment[0].equipmentTypeCategoryGroupId}>
                                                {
                                                    groupedEquipment.minimumQuantityRequired === 1 &&
                                                    <span>{groupedEquipment.equipmentGroup
                                                                .split("DSCEnabled").join(t("Equipments.DSCEnabled"))
                                                                .split("MEDApproved").join(t("Equipments.MedApproved"))
                                                        }
                                                    </span>
                                                }
                                                {
                                                    groupedEquipment.minimumQuantityRequired > 1 &&
                                                    <span>{t("Equipments.SelectEquipmentFrom",
                                                        {
                                                            selectNumber: groupedEquipment.minimumQuantityRequired,
                                                            equipmentGroup: groupedEquipment.equipmentGroup
                                                                .split("DSCEnabled").join(t("Equipments.DSCEnabled"))
                                                                .split("MEDApproved").join(t("Equipments.MedApproved"))
                                                        })}:
                                                    </span>
                                                }
                                            </li>
                                        ))
                                    }
                                    </span>
                            }
                            {
                                this.props.requiredEquipment.requiredEpirbs &&
                                this.props.requiredEquipment.requiredEpirbs.length > 0 &&
                                <span>
                                    {
                                        this.props.requiredEquipment.requiredEpirbs.map(equipment => (
                                            <li key={equipment.id}>

                                                <span>
                                                    {
                                                        equipment.minimumQuantityRequired > 1 &&
                                                        <span>{equipment.minimumQuantityRequired} x </span>
                                                    }
                                                    <span>{t("EpirbDetails.EPIRB")}</span>
                                                    {
                                                        equipment?.epirbMountingType?.name != null &&
                                                        <span> - {equipment.epirbMountingType.name}.</span>
                                                    }
                                                    {equipment.isMedApproved && <span> {t("Equipments.MedApproved")}.</span>}
                                                    {equipment.gpsEnabledRequired && <span> {t("Equipments.GpsEnabled")}.</span>}
                                                </span>
                                            </li>
                                        ))
                                    }
                                </span>
                            }
                        </ul>
                    </div>
                }
                {
                    this.props.requiredEquipment && this.props.requiredEquipment.vesselHeader &&
                    !isPleasureVessel &&
                    <p className="h6 mt-2" tabIndex="0">{t("Equipments.RequiredEquipmentNote", { vesselHeader: this.props.requiredEquipment.vesselHeader })}</p>
                }
                <div className="h6 mt-2" role="heading" aria-level="2" id="ComplianceTitle" tabIndex="0">
                    {t("Equipments.ComplianceTitle")}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {

        certificateTypeId: state.requirementsCalculator.certificateTypeId || "",
        vesselTypeId: state.requirementsCalculator.vesselTypeId || "",
        vesselSubTypeId: state.requirementsCalculator.vesselSubTypeId || "",
        hasVesselSubTypes: state.requirementsCalculator.hasVesselSubTypes ||
            (Number(state.requirementsCalculator.vesselTypeId) === 1 || Number(state.requirementsCalculator.vesselTypeId) === 3 ||
            Number(state.requirementsCalculator.vesselTypeId) === 4) ? true : false,
        seaAreaOfOperationId: state.requirementsCalculator.seaAreaOfOperationId || "",
        vesselLength: state.requirementsCalculator.vesselLength || "",
        grossTonnage: state.requirementsCalculator.grossTonnage || "",

        certificateTypes: state.availableCertificateTypes,
        vesselTypes: state.availableVesseltypes,
        vesselSubTypes: state.availableVesselSubTypes,
        seaAreasOfOperation: state.availableSeaAreaOfOperation,

        requiredEquipment: state.requiredEquipmentCalculator,

        // validations
        vesselTypeRequired: state.requirementsCalculator.vesselTypeRequired || false,
        vesselSubTypeRequired: state.requirementsCalculator.vesselSubTypeRequired || false,
        seaAreaOfOperationRequired: state.requirementsCalculator.seaAreaOfOperationRequired || false

    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCertificateTypesForSeaArea: (selectedSeaAreaOfOperationId) => {
            dispatch(CreateGetCertificateTypesForSeaAreaAction(selectedSeaAreaOfOperationId));
        },
        getVesselTypes: () => {
            dispatch(CreateGetVesselTypesAction());
        },
        getVesselSubTypes: () => {
            dispatch(CreateGetVesselSubTypesAction());
        },
        getSeaAreasOfOperation: () => {
            dispatch(CreateGetAllSeaAreasOfOperationAction());
        },

        calculateRequirements: (vesselTypeId, vesselSubTypeId, seaAreaOfOperationId,
            vesselLength, grossTonnage) => {

            const vesselSubTypeIdParam = vesselSubTypeId === ""
                ? 0
            : Number(vesselSubTypeId);

            const vesselLengthParam = isNaN(vesselLength)
                    ? 0
                : Number(vesselLength);

            const grossTonnageParam = isNaN(grossTonnage)
                ? 0
                : Number(grossTonnage);
            
            dispatch(CreateCalculateRequiredEquipmentAction(vesselTypeId, vesselSubTypeIdParam, seaAreaOfOperationId,
                vesselLengthParam, grossTonnageParam));
        },

        onVesselLengthChanged: (evt) => {
            var vesselLength = evt.target.value;

            if (isNaN(vesselLength)|| Number(vesselLength) < 0 || Number(vesselLength) > 999) {
                return;
            }

            if (vesselLength.indexOf('.') > -1) {
                const vesselLengthCharCount = vesselLength.length;

                if (vesselLength.indexOf('.') < vesselLengthCharCount - 2) {
                    return;
                }
            }

            dispatch({
                type: "UPDATE_CALCULATOR_VESSEL_LENGTH",
                data: vesselLength
            });
        },
        
        onGrossTonnageChanged: (evt) => {
            var updatedGrossTonnage = evt.target.value;

            if (isNaN(updatedGrossTonnage)|| Number(updatedGrossTonnage) < 0 || Number(updatedGrossTonnage) > 999999) {
                return;
            }

            if (updatedGrossTonnage.indexOf('.') > -1) {
                const updatedGrossTonnageCharCount = updatedGrossTonnage.length;

                if (updatedGrossTonnage.indexOf('.') < updatedGrossTonnageCharCount - 2) {
                    return;
                }
            }

            dispatch({
                type: "UPDATED_CALCULATOR_GROSS_TONNAGE",
                data: updatedGrossTonnage
            });
        },

        onVesselTypeSelected: (evt) => {
            var selectedVesselType = evt.target.value;

            dispatch({
                type: "UPDATE_CALCULATOR_VESSEL_TYPE",
                data: selectedVesselType
            });

            // show/hide the sub types.  PleasureVessel: 1, PassengerBoat: 3, PassengerShip: 4
            if (selectedVesselType === "1" || selectedVesselType === "3" || selectedVesselType === "4") {
                dispatch({
                    type: "UPDATE_CALCULATOR_VESSEL_HAS_SUB_TYPE",
                    data: true
                });
            }
            else {
                dispatch({
                    type: "UPDATE_CALCULATOR_VESSEL_SUB_TYPE",
                    data: null
                });
                dispatch({
                    type: "UPDATE_CALCULATOR_VESSEL_HAS_SUB_TYPE",
                    data: false
                });
            }
        },
        onVesselTypeSelectionCompleted: (evt) => {
            var selectedVesselType = evt.target.value;

            if (selectedVesselType === "" || selectedVesselType === null || selectedVesselType.length === 0) {
                dispatch({
                    type: "UPDATE_CALCULATOR_VESSEL_TYPE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_CALCULATOR_VESSEL_TYPE_REQUIRED",
                    data: false
                });
            }
        },

        onVesselSubTypeSelected: (evt) => {
            var selectedVesselType = evt.target.value;

            dispatch({
                type: "UPDATE_CALCULATOR_VESSEL_SUB_TYPE",
                data: selectedVesselType
            });
        },
        onVesselSubTypeSelectionCompleted: (evt) => {
            var selectedVesselType = evt.target.value;

            if (selectedVesselType === "" || selectedVesselType === null || selectedVesselType.length === 0) {
                dispatch({
                    type: "UPDATE_CALCULATOR_VESSEL_SUB_TYPE_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "UPDATE_CALCULATOR_VESSEL_SUB_TYPE_REQUIRED",
                    data: false
                });
            }
        },
        onSeaAreaOfOperationSelected: (evt) => {
            var selectedSeaAreaOfOperation = evt.target.value;

            dispatch({
                type: "UPDATE_CALCULATOR_VESSEL_SEA_AREA_OF_OPERATION",
                data: selectedSeaAreaOfOperation
            });
        },
        onSeaAreaOfOperationSelectionCompleted: (evt, vesselTypeId) => {
            var selectedSeaAreaOfOperation = evt.target.value;
            if (selectedSeaAreaOfOperation === "" || selectedSeaAreaOfOperation === null || selectedSeaAreaOfOperation.length === 0) {
                dispatch({
                    type: "UPDATE_CALCULATOR_VESSEL_SEA_AREA_OF_OPERATION_REQUIRED",
                    data: true
                });
            } else {

                dispatch(CreateGetCertificateTypesForSeaAreaAction(selectedSeaAreaOfOperation, vesselTypeId));

                dispatch({
                    type: "UPDATE_CALCULATOR_VESSEL_SEA_AREA_OF_OPERATION_REQUIRED",
                    data: false
                });
            }
        }
       
    }
}

const languageAwareComponent = withTranslation()(LicenceRequirementsCalculator);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);

