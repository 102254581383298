import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PaymentConfirmation extends Component {
    componentDidMount() {
    }

    render() {
        const { t } = this.props;
        const vesselName = this.props.vesselName;

        const formatDate = (dateStr) => {
            var date = new Date(dateStr);
            var minDate = new Date('1900-01-01T00:00:00');

            if (date.getTime() === minDate.getTime()) return "";

            return ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +
                '/' +
                ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +
                '/' +
                date.getFullYear();
        };

        return (
            <React.Fragment>
                <div className="form-group" style={{ textAlignLast: 'center' }}>

                    <label id="PaymentConfimrationLabel" tabIndex="1"><h2> {t("PaymentPages.PaymentConfirmationLine1")}  </h2></label>

                    <div className="fa-icons mt-0 mb-0">
                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 12 }} />
                    </div>

                    <label id="PaymentConfimrationLin1" tabIndex="1" className="h5">{t("PaymentPages.PaymentConfirmationLine2")}</label> <br />
                    <label id="PaymentConfimrationLin2" tabIndex="1"> {t("PaymentPages.PaymentConfirmationLine3", {vesselName})}</label> <br/>

                    <div>
                        <label id="paymentMmsiNumber" tabIndex="1"> {t("PaymentPages.paymentMmsiNumber")} {this.props.mmsiNumber} </label> <br />
                        <label id="paymentReceived" tabIndex="1"> {t("PaymentPages.paymentReceived")} <span>&#8364;</span>{this.props.paymentReceived} </label> <br />
                        <label id="paymentDate" tabIndex="1"> {t("PaymentPages.paymentDate")} {formatDate(this.props.paymentDate)} </label> <br />
                    </div>
                    <div>
                        <Link
                            id="addHomeButton"
                            className="btn btn-primary mt-2 mb-3"
                            to={ "/dashboard"}
                        >
                            {t("Common.ButtonText.Home")}
                        </Link>
                    </div>

                </div>

            </React.Fragment>
        );
    };
}

const mapStateToProps = (state, ownProps) => {
    return {
        licenceApplicationId: state.currentApplication.id || 0,
        vesselName: state.currentVessel.name || "",
        mmsiNumber: state.currentVessel.mmsiNumber || "",
        paymentReceived: state.licencePayment.paymentAmount || "",
        paymentDate: state.licencePayment.paymentDate || new Date().toJSON().slice(0, 10).split('-').reverse().join('/')  // dd/mm/yyyy
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
}

const languageAwareComponent = withTranslation()(PaymentConfirmation);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);

