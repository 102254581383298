import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (noteTemplateId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "noteTemplate/GetById?id=" + noteTemplateId;

        GET(url)
            .then(function (response) {
                dispatch({
                    type: "NOTE_TEMPLATE_DESCRIPTION_UPDATED",
                    noteDescription: response.data.description
                });
                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}