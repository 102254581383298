import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

import { GET } from "../adapters/xhr";

export default function (guid) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());

        const url = "licenceApplication/GetLicenceTransferByGuid";

        const params = {
            guid: guid
        };

        GET(url, params)
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.licenceTransfer == null) {
                        dispatch({
                            type: "CLEAR_CURRENT_TRANSFER",
                            data: null
                        });
                    } else {
                        dispatch({
                            type: "LICENCE_TRANSFER_RETRIEVED",
                            data: response.data.licenceTransfer
                        });
                        dispatch({
                            type: "LICENCE_TRANSFER_VESSEL_NAME_RETRIEVED",
                            data: response.data.vesselName
                        });
                    }
                }

                dispatch(CreateDataRetrievalEndedAction());
            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

