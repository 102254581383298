import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

const LinkButton = (props) => {
    const {
        history, location, match, staticContext, // Provided by the call to "withRouter" in the export below
        to, onClick,
        // ⬆ filtering out props that "button" doesn't know what to do with.
        ...rest // The remaining props after the above is excluded.
    } = props;

    return (
        <button type="button"
            {...rest} // 'children' is just another prop!
            onClick={(event) => {
                onClick && onClick(event);
                history.push(to);
            }}
        />
    );
};

LinkButton.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default withRouter(LinkButton);