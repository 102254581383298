import axios from "axios";

// import authService from "../../components/api-authorization/AuthorizeService";

export default function (licenceApplicationId, id,equipmentTypeCategoryId) {
    return (dispatch) => {
        var requestData = {
            licenceApplicationId,
            id,
            equipmentTypeCategoryId
        };

        axios({
            method: "GET",
            url: "equipment/CheckDeleteEquipmentForLicenceAmendment",
            params: requestData
        })
            .then((response) => {
                dispatch({
                    type: "LICENCE_AMENDMENT",
                    data: response.data
                });
            })
            .catch((error) => {
            });
    };
}
