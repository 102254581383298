import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const component = ({ t }) => {
    return (
        <React.Fragment>
            <div className="mt-3 mb-5">
                {t("Common.ComingSoonMessageText")}
            </div>

            <Link id="back-button" className="btn btn-primary" to="/owners">{t("Common.ButtonText.ViewDashboard")}</Link>
        </React.Fragment>
    );
};


const languageAwareComponent = withTranslation()(component);

export default languageAwareComponent;