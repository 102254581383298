import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LinkButton from "./LandingPage/LinkButton";
import { Link } from "react-router-dom";

import ValidationFailureMessage from "./ValidationFailureMessage";
import Breadcrumb from "./Breadcrumb";

import CreateGetCurrentUserAction from "../store/actionCreators/CreateGetCurrentUserAction";

import CreateAssignLicenceAction from "../store/actionCreators/CreateAssignLicenceAction";

class AssignLicence extends Component {
    componentDidMount() {
        this.props.getCurrentUser();
        this.props.clearAssignLicence();
    }

    render() {
        const { t } = this.props;

        // disable the save button if required fields are missing
        const canSubmitForm = (this.props.vesselNameRequired ||
            this.props.vesselName === "" ||
            this.props.callSignRequired ||
            this.props.callSign === "" ||
            this.props.licenceNumberRequired ||
            this.props.licenceNumber === "" || this.props.licenceNumber === "LSR");

        return (
            <React.Fragment>
                <Breadcrumb
                    homeLink={t("Common.ButtonText.Home")}
                    dashboard={t("Common.Dashboard")}
                    pageName={t("Common.ButtonText.AssignLicence")} />

                {
                    this.props.licenceFound === 2 &&
                    <div className="bg-light p-2 rounded-lg m-3 bg-customGood mt-2" role="alert">
                        {t("MyAccount.AssignLicenceSuccessMessage")}
                    </div>

                }

                {
                    this.props.licenceFound === 1 &&
                    <div className="bg-light p-2 rounded-lg m-3 bg-customError mt-2" role="alert">
                        {t("MyAccount.AssignLicenceFailureMessage")}
                    </div>
                }

                <div className="bg-light p-2 rounded-lg mb-2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="h4" role="heading" aria-level="1" id="TitleLabel" tabIndex="0">
                                {
                                    t("MyAccount.AssignLicenceTitleLabel")
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {t("MyAccount.AssignLicenceSubtitleText")}
                        </div>
                    </div>
                </div>

                <div className="row col-md-12">
                    <div aria-hidden="true" id="required-description" className="d-flex justify-content-end">* {t("Common.RequiredField")}</div>
                </div>

                <div className="row">

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="vesselNameLabel" htmlFor="vesselName" className="form-label">{t("AddVessel.VesselNameLabel")}: *</label>
                            <input id="vesselName" type="text" max="50"
                                   className="form-control" required
                                   onChange={this.props.onVesselNameChanged}
                                   onBlur={this.props.onVesselNameChangeCompleted}
                                   value={this.props.vesselName}/>
                            {
                                    this.props.vesselNameRequired &&
                                    <ValidationFailureMessage>
                                        {t("AddVessel.VesselNameRequired")}
                                    </ValidationFailureMessage>
                                }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="callSignLabel" htmlFor="callSign" className="form-label">{t("MyAccount.CallSign")}: *</label>
                            <input
                                id="callSign" type="text"
                                className="form-control" required
                                onChange={this.props.onCallSignChanged}
                                onBlur={this.props.onCallSignChangeCompleted}
                                value={this.props.callSign}/>

                            {
                                    this.props.callSignRequired &&
                                    <ValidationFailureMessage>
                                        {t("MyAccount.CallSignRequired")}
                                    </ValidationFailureMessage>
                                }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="licenceNumberLabel" htmlFor="licenceNumber" className="form-label">{t("MyAccount.LicenceNumber")}: *</label>
                            <input
                                id="licenceNumber" type="text"
                                className="form-control" required
                                onChange={this.props.onLicenceNumberChanged}
                                onBlur={this.props.onLicenceNumberChangeCompleted}
                                value={this.props.licenceNumber}/>
                            {
                                    this.props.licenceNumberRequired &&
                                    <ValidationFailureMessage>
                                        {t("MyAccount.LicenceNumberRequired")}
                                    </ValidationFailureMessage>
                                }
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label id="mmsiNumberLabel" htmlFor="mmsiNumber" className="form-label">{t("OwnerLandingPage.MMSINumber")}:</label>
                            <input
                                id="mmsiNumber" type="text"
                                className="form-control" required
                                onChange={this.props.onMmsiNumberChanged}
                                value={this.props.mmsiNumber}/>
                        </div>
                    </div>
                </div>

                <div className="my-3">
                    <LinkButton className="btn btn-warning"
                                id="cancelAssignLicence"
                                to={"/dashboard"}>
                        {t("Common.ButtonText.Cancel")}
                    </LinkButton>
                    {
                       this.props.role === "Ship Owner" &&
                           <Link
                               id="updateUserButton"
                               className="btn btn-primary ms-3"
                               disabled={canSubmitForm}
                               to={"#"}
                               onClick={() => this.props.assignLicence()}
                           >
                            {t("Common.ButtonText.Submit")}
                           </Link>
                   }

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: state.currentUser.id || 0,
        role: state.currentUserInfo.role || "",

        callSign: state.assignLicence.callSign || "",
        vesselName: state.assignLicence.vesselName || "",
        licenceNumber: state.assignLicence.licenceNumber || "",
        mmsiNumber: state.assignLicence.mmsiNumber || "",
        licenceFound: state.assignLicence.licenceFound || 0,

        // validations
        callSignRequired: state.assignLicence.callSignRequired || false,
        vesselNameRequired: state.assignLicence.vesselNameRequired || false,
        licenceNumberRequired: state.assignLicence.licenceNumberRequired || false
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCurrentUser: () => {
            dispatch(CreateGetCurrentUserAction());
        },
        clearAssignLicence: () => {
            dispatch({
                type: "CLEAR_ASSIGN_LICENCE"
            });
            dispatch({
                type: "ASSIGN_LICENCE_LICENCE_NUMBER_UPDATED",
                data: "LSR"
            });
        },
        assignLicence: () => {

            //assignLicence
            dispatch(CreateAssignLicenceAction());
        },

        onVesselNameChanged: (evt) => {
            var vesselName = evt.target.value;

            dispatch({
                type: "ASSIGN_LICENCE_VESSEL_NAME_UPDATED",
                data: vesselName
            });
        },
        onVesselNameChangeCompleted: (evt) => {
            var vesselName = evt.target.value;

            if (vesselName.trim() === "") {
                dispatch({
                    type: "ASSIGN_LICENCE_VESSEL_NAME_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "ASSIGN_LICENCE_VESSEL_NAME_REQUIRED",
                    data: false
                });
            }
        },
        onCallSignChanged: (evt) => {
            var callSign = evt.target.value;

            dispatch({
                type: "ASSIGN_LICENCE_CALL_SIGN_UPDATED",
                data: callSign
            });
        },
        onCallSignChangeCompleted: (evt) => {
            var callSign = evt.target.value;

            if (callSign.trim() === "") {
                dispatch({
                    type: "ASSIGN_LICENCE_CALL_SIGN_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "ASSIGN_LICENCE_CALL_SIGN_REQUIRED",
                    data: false
                });
            }
        },

        onLicenceNumberChanged: (evt) => {
            var licenceNumber = evt.target.value;

            dispatch({
                type: "ASSIGN_LICENCE_LICENCE_NUMBER_UPDATED",
                data: licenceNumber
            });
        },
        onLicenceNumberChangeCompleted: (evt) => {
            var licenceNumber = evt.target.value;

            if (licenceNumber.trim() === "" || licenceNumber.trim() === "LSR") {
                dispatch({
                    type: "ASSIGN_LICENCE_LICENCE_NUMBER_REQUIRED",
                    data: true
                });
            } else {
                dispatch({
                    type: "ASSIGN_LICENCE_LICENCE_NUMBER_REQUIRED",
                    data: false
                });
            }
        },

        onMmsiNumberChanged: (evt) => {
            var mmsiNumber = evt.target.value;

            dispatch({
                type: "ASSIGN_LICENCE_MMSI_NUMBER_UPDATED",
                data: mmsiNumber
            });
        }

    }
}

const languageAwareComponent = withTranslation()(AssignLicence);
export default connect(mapStateToProps, mapDispatchToProps)(languageAwareComponent);

