import axios from "axios";

import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";
import CreateGetEmergencyContactDetailsAction from "./CreateGetEmergencyContactDetailsAction";

export default function (licenceApplicationId) {
    return (dispatch, getState) => {
        dispatch(CreateDataRetrievalBegunAction());
        
        const updatedEmergencyContact = getPostData(licenceApplicationId, getState);

        axios({
                method: "put",
                url: "emergencyContact",
                data: updatedEmergencyContact
            })
            .then((data) => {
                dispatch(CreateGetEmergencyContactDetailsAction(licenceApplicationId));
            })
            .catch((error) => {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}

function getPostData(licenceApplicationId, getState) {
    const { emergencyContact } = getState();

    const updatedEmergencyContact = {
        id: Number(emergencyContact.id) || 0,
        licenceApplicationId: Number(licenceApplicationId) || 0,
        salutationId: Number(emergencyContact.salutationId) || null,
        firstName: emergencyContact.firstName || "",
        lastName: emergencyContact.lastName || "",
        phoneCountryCodeId: Number(emergencyContact.phoneCountryCodeId) || 1,
        phoneNumber: emergencyContact.phoneNumber || "",
        confirmPhoneNumber: emergencyContact.confirmPhoneNumber || "",
        alternativePhoneCountryCodeId: Number(emergencyContact.alternativePhoneCountryCodeId) || 1,
        alternativePhoneNumber: emergencyContact.alternativePhoneNumber || "",
        addressEirCode: emergencyContact.addressEirCode || "",
        emailAddress: emergencyContact.emailAddress || "",
        confirmEmailAddress: emergencyContact.confirmEmailAddress || "",
        addressLine1: emergencyContact.addressLine1 || "",
        addressLine2: emergencyContact.addressLine2 || "",
        addressLine3: emergencyContact.addressLine3 || "",
        addressLine4: emergencyContact.addressLine4 || ""
    };

    return updatedEmergencyContact;
}
