import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import CreateGetCurrentUserInfoAction from "./store/actionCreators/CreateGetCurrentUserInfoAction";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";

import Spinner from "./components/LoadingSpinner";
import NavMenu from "./components/NavMenu";
import EmergencyServicesNavMenu from "./components/EmergencyServicesNavMenu";
import BottomBar from "./components/BottomBar";
import LandingPage from "./components/LandingPage";
import LicenceApplicationForm from "./components/LicenceApplicationForm";
import PublicLandingPage from "./components/LandingPage/PublicLandingPage";
import NoAccessPage from "./components/LandingPage/NoAccessPage";
import PublicUsefulLinksPage from "./components/LandingPage/PublicUsefulLinksPage";
import PublicUsefulDocumentsPage from "./components/LandingPage/PublicUsefulDocumentsPage";
import PublicVideoPage from "./components/LandingPage/PublicVideoPage";
import PublicFAQPage from "./components/LandingPage/PublicFAQPage";
import ComingSoonPage from "./components/LandingPage/ComingSoonPage";
import LicenceDetailsPage from "./components/LicenceDetailsPage";
import ApplicationDetailsPage from "./components/ApplicationDetailsPage";
import LicenceRequirementsCalculator from "./components/LicenceRequirementsCalculator";
import MyAccount from "./components/MyAccount";
import AssignLicence from "./components/AssignLicence";
import LicenceTransfer from "./components/LicenceTransfer";
import EmergencyServicesView from "./components/EmergencyServicesView";
import "./custom.css";

class App extends Component {
    componentDidMount() {
        this.props.getUserInfo();
    }

    render() {

        return (
            <Container id="main">
                <div id="main-content-container">
                    {
                        this.props.userRole === "Emergency Services" &&
                        <EmergencyServicesNavMenu />
                    }
                    {
                        this.props.userRole !== "Emergency Services" &&
                        <NavMenu />
                    }
                    <div id="main-content" className="px-4">
                        <Spinner />
                        <Switch>
                            <AuthorizeRoute path="/apply" component={LicenceApplicationForm} />
                            <AuthorizeRoute path="/dashboard" component={LandingPage} />
                            <AuthorizeRoute exact path="/licence-details-page" component={LicenceDetailsPage} />
                            <AuthorizeRoute path="/licence-details-page/:id" component={LicenceDetailsPage} />
                            <AuthorizeRoute exact path="/application-details-page" component={ApplicationDetailsPage} />
                            <AuthorizeRoute path="/application-details-page/:id" component={ApplicationDetailsPage} />
                            <AuthorizeRoute path="/no-access" component={NoAccessPage} />
                            <AuthorizeRoute path="/links2" component={PublicUsefulLinksPage} />
                            <AuthorizeRoute path="/documents2" component={PublicUsefulDocumentsPage} />
                            <Route exact path="/not-yet-implemented" component={ComingSoonPage} />
                            <Route exact path="/faqs" component={PublicFAQPage} /> 
                            <Route exact path="/requirementsCalculator" component={LicenceRequirementsCalculator} /> 
                            <Route exact path="/links" component={PublicUsefulLinksPage} /> 
                            <Route exact path="/documents" component={PublicUsefulDocumentsPage} />
                            <Route exact path="/videos" component={PublicVideoPage} />
                            <AuthorizeRoute exact path="/my-account" component={MyAccount} />
                            <AuthorizeRoute exact path="/assign-licence" component={AssignLicence} />
                            <AuthorizeRoute exact path="/licencetransfer/:guid" component={LicenceTransfer} />
                            <AuthorizeRoute path="/emergency-services-licence-view/:id" component={EmergencyServicesView} />
                            <Route path="/" component={PublicLandingPage} />
                        </Switch>
                    </div>
                    <BottomBar />
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userIsAuthenticated: state.currentUserInfo.isAuthenticated || false,
        userRole: state.currentUserInfo.role || ""
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: () => {
            dispatch(CreateGetCurrentUserInfoAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
