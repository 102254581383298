const initialState = {
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "CLEAR_CURRENT_USER":
            return initialState;

        case "CURRENT_USER_RETRIEVED":
            return {
                ...action.user
            };

        case "CURRENT_USER_SALUTATION_ID_UPDATED":
            return {
                ...state,
                salutationId: action.data
            };

        case "CURRENT_USER_LICENCE_APPLICATION_TYPE_ID_UPDATED":
            return {
                ...state,
                licenceApplicationTypeId: action.data
            };

        case "CURRENT_USER_LICENCE_APPLICATION_TYPE_REQUIRED":
            return {
                ...state,
                licenceApplicationTypeRequired: action.data
            };

        case "CURRENT_USER_COMPANY_NAME_UPDATED":
            return {
                ...state,
                companyName: action.data
            };

        case "CURRENT_USER_COMPANY_REGISTRATION_UPDATED":
            return {
                ...state,
                companyRegistrationNumber: action.data
            };

        case "CURRENT_USER_FIRST_NAME_UPDATED":
            return {
                ...state,
                firstName: action.data
            };

        case "CURRENT_USER_FIRST_NAME_REQUIRED":
            return {
                ...state,
                firstNameRequired: action.data
            };
        case "CURRENT_USER_LAST_NAME_UPDATED":
            return {
                ...state,
                lastName: action.data
            };

        case "CURRENT_USER_LAST_NAME_REQUIRED":
            return {
                ...state,
                lastNameRequired: action.data
            };
        case "CURRENT_USER_PHONE_NUMBER_UPDATED":
            return {
                ...state,
                phoneNumber: action.data
            };

        case "CURRENT_USER_PHONE_NUMBER_REQUIRED":
            return {
                ...state,
                phoneNumberRequired: action.data
            };

        case "CURRENT_USER_ALTERNATIVE_PHONE_NUMBER_UPDATED":
            return {
                ...state,
                alternativePhoneNumber: action.data
            };

        case "CURRENT_USER_ADDRESS_EIRCODE_UPDATED":
            return {
                ...state,
                addressEircode: action.data
            };
       
        case "CURRENT_USER_ADDRESS_LINE1_UPDATED":
            return {
                ...state,
                addressLine1 : action.data
            };
        case "CURRENT_USER_ADDRESS_LINE2_UPDATED":
            return {
                ...state,
                addressLine2: action.data
            };
        case "CURRENT_USER_ADDRESS_LINE3_UPDATED":
            return {
                ...state,
                addressLine3: action.data
            };
        case "CURRENT_USER_ADDRESS_LINE4_UPDATED":
            return {
                ...state,
                addressLine4: action.data
            };
        case "CURRENT_USER_ADDRESS_LINE1_REQUIRED":
            return {
                ...state,
                addressLine1Required: action.data
            };
        case "CURRENT_USER_ADDRESS_LINE2_REQUIRED":
            return {
                ...state,
                addressLine2Required: action.data
            };

        case "MY_ACCOUNT_UPDATED_SHOW_ALERT":
            return {
                ...state,
                showMyAccountAlert: action.data
            };


        default:
            return state;
    }
}
