const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "EQUIPMENT_TYPE_CATEGORIES_RETRIEVED":
            return action.equipmentTypeCategories;

        default:
            return state;
    }
}
