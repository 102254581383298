// import authService from "../../components/api-authorization/AuthorizeService";

import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

export default function (applicationId) {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());


        //authService.getAccessToken().then((token) => {
        fetch(
            "RequiredEquipmentConfiguration/GetByVesselType?licenceApplicationId=" + applicationId,
                {
                    //              headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
            .then((response) => response.json())
            .then((data) => {
                dispatch({
                    type: "VESSEL_REQUIRED_EQUIPMENT_RETRIEVED",
                    requiredEquipmentRecords: data
                });

                dispatch(CreateDataRetrievalEndedAction());
            })
            .catch((error) => {

                dispatch(CreateDataRetrievalEndedAction());
            });
        //});
    };
}