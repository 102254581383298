import { GETAnonymous } from "../adapters/xhr";
import CreateDataRetrievalBegunAction from "./CreateDataRetrievalBegunAction";
import CreateDataRetrievalEndedAction from "./CreateDataRetrievalEndedAction";

export default function () {
    return (dispatch) => {
        dispatch(CreateDataRetrievalBegunAction());
        const url = "account/GetIsUserAuthenticated";
        
        GETAnonymous(url)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch({
                            type: "CURRENT_USER_INFO_IS_AUTHENTICATED",
                            data: response.data.isAuthenticated
                        });
                }
                dispatch(CreateDataRetrievalEndedAction());

            }).catch(function (response) {
                dispatch(CreateDataRetrievalEndedAction());
            });
    };
}
